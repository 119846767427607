import React, { memo } from "react";
import arrowRight from "../../assets/images/chevron_right.svg";
import CSS from "./LeftWidget.module.scss";

const LeftWidget = ({ icon, title, fat, callback, children }) => (
  <>
    <div className={CSS.wrapper}>
      <div className={CSS.header}>
        <div className={CSS.name}>
          {!!icon && <img alt="widget-icon" className={CSS.icon} src={icon}/>}
          {!!title && title.toUpperCase()}
        </div>
        {!!callback && <img alt="arrow-icon" className={CSS.icon} style={{cursor: 'pointer'}} onClick={callback} src={arrowRight} />}
      </div>
      {children}
    </div>
    {fat && <div className={CSS.border} />}
  </>
)

export default memo(LeftWidget);
