import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class CharacterService {

  constructor() {
    this.config = new Configuration();
    this.character = {}
  }

  async retrieveCharacterInfo(characterName) {
    return API.get(this.config.CHARACTERS, { params: { 'characterName': characterName, steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.character = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default CharacterService;
