  import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";

function animateVector3(vectorToAnimate, target, options){
  options = options || {};
  const to = target || new THREE.Vector3();
  const easing = options.easing || TWEEN.Easing.Quadratic.In;
  const duration = options.duration || 2000;
  const tweenVector3 = new TWEEN.Tween(vectorToAnimate)
    .to({ x: to.x, y: to.y, z: to.z, }, duration)
    .easing(easing)
    .onUpdate(function(d) {
      if(options.update){
        options.update(d);
      }
    })
    .onComplete(function(){
      if(options.callback) setTimeout(() => options.callback(), 0);
    });
  tweenVector3.start();
  return tweenVector3;
}

function isPlayerTile(tile, player) {
  return tile.gameData.players.controlledBy === player.id;
}

export {
  animateVector3,
  isPlayerTile,
}
