import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class MatesService {

  constructor() {
    this.config = new Configuration();

    // mock mates
    this.mates =  [];
    this.messages = {};
  }

  async getMate({ tiers, randomCount, excludeAlreadyInUse }) {
    return API.get(this.config.MATES, {
      params: {
        'tiers': tiers,
        'randomCount': randomCount,
        'excludeAlreadyInUse': excludeAlreadyInUse,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.items = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async buyMate({ excludeAlreadyInUse }) {
    return API.get(this.config.MATES, {
      params: {
        'tiers': 1,
        'randomCount': 1,
        'excludeAlreadyInUse': excludeAlreadyInUse,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.items = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getMates() {
    return API.get(this.config.MATES, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.mates = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getMessages(mateId) {
    return API.get(this.config.MESSENGER, {
      params: {
        'id': mateId,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.messages[mateId] = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default MatesService;
