import Configuration from "./_config";

class PlayerService {

  constructor() {
    this.config = new Configuration();
  }

  async retrievePlayer() {
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default PlayerService;
