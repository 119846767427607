import userActions from './userActions';
import playerActions from "./playerActions";
import notesActions from "./notesActions";
import compareActions from "./compareActions";
import matesActions from "./matesActions";
import mapActions from "./mapActions";
import widgetsActions from "./widgetsActions";
import appActions from "./appActions";
import diceActions from "./diceActions";
import sectorActions from "./sectorActions";
import inventoryActions from "./inventoryActions";
import dialogSwitcherActions from "./dialogSwitcherActions";
import encountersNotesActions from "./encountersNotesActions";
import generalActions from "./generalActions";

const allActions = {
  userActions,
  playerActions,
  notesActions,
  compareActions,
  matesActions,
  mapActions,
  widgetsActions,
  appActions,
  diceActions,
  sectorActions,
  inventoryActions,
  dialogSwitcherActions,
  encountersNotesActions,
  generalActions,
}

export default allActions;
