import React, { useState } from "react";
import {
  Box,
  Button,
  ItemBox,
  Description,
  ItemWrapper,
  Level,
  Name,
  ItemName,
  ItemIcon,
  TextCloud,
  Wrapper
} from "./StartView";
import get from "lodash/get";
import Popover from '@material-ui/core/Popover';
import { colorByTier } from '../../../../utils/utils';
import CSS from './StartView.module.scss';
import CSSFirstBuy from '../../../FirstBuy/FirstBuy.module.scss';
import ItemPopover from '../../../Inventory/ItemPopover';

// "ТОР"
function TorView(state, onBuy, onEnd) {

  const [anchorEl, setAnchorEl] = useState({});
  const item = get(state, 'items[0]', {});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  return (
    <Wrapper>
      <Box>
        <img style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
          {get(state, 'messages.start', '')}
        </TextCloud>
        <ItemBox>
            <ItemWrapper
              color={colorByTier(item.tier)}
              aria-describedby={item.id}
              onClick={(e) => handlePopoverOpen(e, item.id)}
            >
              <ItemIcon src={item.image} />
              <ItemName
                color={colorByTier(item.tier)}
              >
                {item.name}
              </ItemName>
            </ItemWrapper>
            <Popover
                    id={item.id}
                    className={CSSFirstBuy.popover}
                    open={anchorEl.id === item.id}
                    anchorEl={anchorEl.target}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                  <ItemPopover anchorEl={{ ...anchorEl, item }} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
                </Popover>
          </ItemBox>
        <button className={CSS.buttonEnd} onClick={() => onBuy(state.name, get(state, 'items[0]', {}))}>Принять предложение</button>
      </div>
    </Wrapper>
  )
}

export default TorView;
