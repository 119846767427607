export function rand(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function getRandomLetter() {
    const arr_en = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const arr_ru = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ь', 'ы', 'ъ', 'э', 'ю', 'я'];
    const arr_RU = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ь', 'Ы', 'Ъ', 'Э', 'Ю', 'Я'];
    const arr_num = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    const arr_symb = ['!', '@', '#', '$', '%', '&', '?', '-', '+', '=', '~'];
    const alphabet = [...arr_en, ...arr_EN, ...arr_ru, ...arr_RU, ...arr_num, ...arr_symb];
    return alphabet[rand(0,alphabet.length - 1)]
}
export function getRandomWord(word) {
  var text = word
  
  var finalWord = ''
  for(var i=0;i<text.length;i++) {
    finalWord += text[i] === ' ' ? ' ' : getRandomLetter()
  }
 
  return finalWord
}