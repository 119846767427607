import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import closeIcon from "../../assets/icons/clear.svg";
import lockIcon from "../../assets/icons/lock.svg";
import lockOpenIcon from "../../assets/icons/lock_open.svg";
import useEventListener from "../../hooks/useEventListener";
import { changeSlotPosition } from '../../api/socket';
import allActions from './../../state/actions/index';
import CSS from './DragComponent.module.scss';

const defaultLimits = {
  canDrag: true,
  minX: 0,
  minY: 0,
  maxX: window.innerWidth,
  maxY: window.innerHeight,
};

const Draggable = ({ isVisible, widget, widgets, image, weight, type, limits=defaultLimits, children }) => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState(null);
  const [pos, setPos] = useState({x: limits.minX || 0, y: limits.minY || 0});
  const [isDraggable, setIsDraggable] = useState(true);
  const ref = useRef(null);

  const setInitialPos = () => {
    let _x = pos.x;
    let _y = pos.y;
    let _predictX = widget.initialPos.x;
    let _predictY = widget.initialPos.y;
    switch (type) {
      case "inventory":
        // TODO:
        if (checkX(_predictX)) {
          _x = _predictX;
        }
        if (checkY(_predictY)) {
          _y = _predictY;
        }
        break;
      case "widget":
      default:
        if (checkExistPosition()) {
          [_predictX, _predictY] = getPositionFromStorage();
          if (checkX(_predictX)) {
            _x = _predictX;
          }
          if (checkY(_predictY)) {
            _y = _predictY;
          }
        }
        // TODO: check pos %
        break;
    }
    setPos({
      x: _x,
      y: _y,
    });
  }

  const initIsDraggable = () => {
    if (checkExistDraggable()) {
      setIsDraggable(_.get(widgets[widget.name], 'isDraggable', widget.isDraggable));
    }
  }

  const checkExistDraggable = () => {
    if (_.has(widgets, widget.name)) {
      return _.has(widgets[widget.name], 'isDraggable');
    }
    return false;
  }

  const checkExistPosition = () => {
    if (_.has(widgets, widget.name)) {
      return _.has(widgets[widget.name], 'x')
        && _.has(widgets[widget.name], 'y');
    }
    return false;
  }

  const getPositionFromStorage = () => {
    return [widgets[widget.name].x, widgets[widget.name].y];
  }

  const updatePosition = (x, y) => {
    switch (type) {
      case "inventory":
        changeSlotPosition(widget.id, { x, y });
        break;
      case "widget":
      default:
        dispatch(allActions.widgetsActions.changePosition(widget.name,{ x, y }));
        break;
    }
  }

  const handlePinButton = () => {
    setIsDraggable(prevState => {
      const newState = !prevState;
      saveIsDraggable(newState);
      return newState;
    });
  }

  const handleClose = () => {
    switch (type) {
      case "inventory":
        // TODO:
        break;
      case "widget":
      default:
        dispatch(allActions.widgetsActions.changeIsOpened(widget.name,false));
        break;
    }
  }

  const saveIsDraggable = (draggable) => {
    switch (type) {
      case "inventory":
        // TODO:
        break;
      case "widget":
      default:
        dispatch(allActions.widgetsActions.changePosition(widget.name, draggable));
        break;
    }
  }

  const onMouseDown = (e) => {
    if (!limits.canDrag) return;
    if (!isDraggable) return;
    if (!widget.isDraggable) return;
    if (e.button !== 0) return;
    if (!ref) return;
    const computedStyle = window.getComputedStyle(ref.current);
    const pos = {
      top: parseInt(computedStyle.top),
      left: parseInt(computedStyle.left),
    };
    setDragging(true);
    setRel({
      x: e.pageX - pos.left,
      y: e.pageY - pos.top,
    });
    e.stopPropagation();
    e.preventDefault();
  }
  const onMouseUp = (e) => {
    setDragging(false);
    if (!dragging) return;
    updatePosition(pos.x, pos.y);
    e.stopPropagation();
    e.preventDefault();
  }
  const onMouseMove = (e) => {
    if (!limits.canDrag) return;
    if (!isDraggable) return;
    if (!dragging) return;
    const _predictX = e.pageX - rel.x;
    const _predictY = e.pageY - rel.y;
    let _x = pos.x;
    let _y = pos.y;
    switch (type) {
      case "inventory":
        if (checkX(_predictX)) {
          _x = _predictX;
        }
        if (checkY(_predictY)) {
          _y = _predictY;
        }
        setPos({
          x: _x,
          y: _y,
        });
        break;
      case "widget":
      default:
        _x = pos.x * limits.maxX / 100;
        _y = pos.y * limits.maxY / 100;
        if (checkX(_predictX)) {
          _x = _predictX;
        }
        if (checkY(_predictY)) {
          _y = _predictY;
        }
        const percentageX = _x / limits.maxX * 100;
        const percentageY = _y / limits.maxY * 100;
        setPos({
          x: percentageX,
          y: percentageY,
        });
        break;
    }
    e.stopPropagation();
    e.preventDefault();
  }

  const checkX = (x) => {
    const maxX = (limits.maxX || window.innerWidth) - ref.current.offsetWidth;
    const minX = limits.minX || 0;
    return x < maxX + 500 && x > minX - 500;
  }

  const checkY = (y) => {
    const maxY = (limits.maxY || window.innerHeight) - ref.current.offsetHeight;
    const minY = limits.minY || 0;
    return y < maxY + 500 && y > minY;
  }

  useEffect(() => {
    setInitialPos();
    initIsDraggable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('mousemove', onMouseMove);
  useEventListener('mouseup', onMouseUp);

  function getPosition() {
    let left = pos.x;
    let top = pos.y;
    switch (type) {
      case "inventory":
        left = left + 'px';
        top = top + 'px';
        break;
      case "widget":
      default:
        left = left + '%';
        top = top + '%';
        break;
    }
   return {
     left,
     top,
   }
  }

  return (
    <div
      className={CSS.wrapper}
      onMouseDown={widget.withHeader ? null : onMouseDown}
      ref={ref}
      style={{
        position: 'fixed',
        ...getPosition(),
        zIndex: dragging ? weight * 100 : weight,
        ...widget.style,
      }}
    >
      {widget.withHeader && (
        <div onMouseDown={widget.withHeader ? onMouseDown : null}>
          <div className={CSS.header}>
            <div className={CSS.headerTextBox}>
              {image && <img width="16px" height="16px" alt="header" src={image} />}
              <div style={{ paddingLeft: '5px' }}>{widget.displayName}</div>
            </div>
            {!widget.withoutExit && (
              <div className={CSS.icons}>
                <img alt="1" onClick={handlePinButton} src={isDraggable ? lockOpenIcon : lockIcon} />
                <img alt="2" onClick={handleClose} src={closeIcon} />
              </div>
            )}
          </div>
        </div>
      )}
      {children}
      {widget.withHeader && (
        <div className={CSS.bottomAssist}>Подключено</div>
      )}
    </div>
  )
}

export default Draggable;
