import React from "react";
import {
  Box,
  Button,
  Description,
  Level,
  Name,
  TextCloud,
  Wrapper,
} from "./StartView";
import get from "lodash/get";
import CSS from './StartView.module.scss';
import { isEmpty } from 'lodash/isEmpty';
import { playerHeal } from '../../../../api/socket';

function CartographerView(state, onBuy, onEnd) {

  const handleHeal = (type, data) => {
    playerHeal(type, data);
    onEnd();
  }

  return (
    <Wrapper>
      <Box>
        <img alt="catrographer" style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
          {get(state, 'messages.start', '')}
        </TextCloud>
        <div>Разведать {get(state, 'items.sector.range', '') === 'any' ? 'любой' : 'соседний'} сектор в количестве: {get(state, 'items.sector.count', 0)}</div>
        <button className={CSS.buttonEnd} onClick={() => onBuy('sector', get(state, 'items.sector'))}>Разведать сектор</button>
        <div>Излечить {get(state, 'items.wound.type', '') === 'any' ? 'любое' : 'среднее'} ранение в количестве: {get(state, 'items.wound.count', 0)}</div>
        <button className={CSS.buttonEnd} onClick={() => handleHeal(get(state, 'items.wound.type', ''), get(state, 'items.wound.count', 0))}>Вылечить</button>
      </div>
    </Wrapper>
  )
}

export default CartographerView;
