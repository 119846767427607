import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { colorByTier } from "../../utils/utils";
import {
  handleUseItem,
  handleChangeCharacteristic,
} from '../../api/socket';
import CSS from './Inventory.module.scss';

const ItemPopover = ({ anchorEl, handlePopoverClose, handleChargeClick, canEdit }) => {
    return (
        <div className={CSS.descriptionBox}>
        {get(anchorEl, 'item') && (
          <>
          <div className={CSS.itemTitle}>{anchorEl.item.name}</div>
          <div className={CSS.itemSlotType}>{anchorEl.item.slotType.name}</div>
          <div className={CSS.itemTier} style={{ color: colorByTier(anchorEl.item.tier) }}>Уровень {anchorEl.item.tier}</div>
          <div>
            {anchorEl.item.isActive && canEdit && (
              <button className={CSS.button} onClick={() => {
                handleUseItem(anchorEl.slotID, anchorEl.augmentID);
                handlePopoverClose();
              }}>Использовать</button>
            )}
            {canEdit && (
                <button className={CSS.buttonWide} onClick={() => {
                    handleChangeCharacteristic(anchorEl.slotID, anchorEl.augmentID);
                    handlePopoverClose();
                }}>Изменить свойства</button>
            )}
            {get(anchorEl, 'item.charges') !== 'none' && !canEdit && (
              <>
                <div className={CSS.descriptionTitle}>Количество зарядов: {get(anchorEl, 'item.charges')}</div>
              </>
            )}
            {anchorEl.item.isAutomated && (
              <div className={CSS.attention}>Предмет автоматизирован</div>
            )}
          </div>
          {get(anchorEl, 'item.slotType.name', '') === "Оружие" && (
            <>
              <div className={CSS.descriptionTitle}>Тип</div>
              {anchorEl.item.occupiedSlots === 1 ? 'Одноручное' : 'Двуручное'}
            </>
          )}
          {get(anchorEl, 'item.status') && (
            <>
              <div className={CSS.descriptionTitle}>Статус</div>
              {get(anchorEl, 'item.status', '')}
            </>
          )}
            {!isEmpty(get(anchorEl, 'item.characteristics', [])) && (
              <>
                <div className={CSS.descriptionTitle}>Свойства</div>
                {anchorEl.item.characteristics.sort().map(characteristic => (
                  <div style={{ color: characteristic.includes("+") ? "#28DE94" : "#F24463" }}>
                    {characteristic}
                  </div>
                ))}
              </>
            )}
            {get(anchorEl, 'item.charges') !== 'none' && canEdit && (
              <>
                <div className={CSS.descriptionTitle}>Изменить количество зарядов</div>
                <div className={CSS.chargesRow}>
                <div style={{ color: '#F24463' }} onClick={() => handleChargeClick({ slotID: anchorEl.slotID, augmentSlotID: anchorEl.augmentID, valueType: 'decreased' })}>-</div>
                <div style={{ color: '#21BE7E' }} onClick={() => handleChargeClick({ slotID: anchorEl.slotID, augmentSlotID: anchorEl.augmentID, valueType: 'increased' })}>+</div>
                </div>
              </>
            )}
            {get(anchorEl, 'item.additionalProperty') && (
              <>
                <div className={CSS.descriptionTitle}>Дополнительное свойство</div>
                {anchorEl.item.additionalProperty}
              </>
            )}
            {get(anchorEl, 'item.description') && (
              <>
                <div className={CSS.descriptionTitle}>Описание</div>
                {anchorEl.item.description}
              </>
            )}
          </>
        )}
      </div>
    )
}

export default ItemPopover;
