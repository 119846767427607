import get from "lodash/get";

const mates = (state = {}, action) => {
  switch(action.type){
    case "SET_MATES":
      return {
        ...state,
        mates: action.payload,
      };
    case "SET_ALL_MATES":
      return {
        ...state,
        allMates: action.payload,
      };
    case "REMOVE_MATE":
      const matesInRemove = [...state.mates].filter(mate => mate.id !== action.payload.id);
      const allMatesInRemove = [...state.allMates, action.payload];
      return {
        ...state,
        mates: matesInRemove,
        allMates: allMatesInRemove,
      }
    case "ADD_MATE":
      const matesInAdd = [...state.mates, action.payload];
      const allMatesInAdd = [...state.allMates].filter(mate => mate.id !== action.payload.id);
      return {
        ...state,
        mates: matesInAdd,
        allMates: allMatesInAdd,
      }
    case "SET_CURRENT_MATE":
      return {
        ...state,
        currentMate: action.payload,
      }
    case "SET_CURRENT_MATE_MESSAGES":
      return {
        ...state,
        currentMate: {
          ...state.currentMate,
          messages: action.payload,
        },
      }
    case "ADD_CURRENT_MATE_MESSAGES":
      return {
        ...state,
        currentMate: {
          ...state.currentMate,
          messages: [
            ...get(state, 'currentMate.messages', []),
            action.payload,
          ],
        },
      }
    default:
      return state
  }
}

export default mates;
