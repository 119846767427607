import React, {useCallback, useState} from "react";
import Collapse from '@material-ui/core/Collapse';
import {useDispatch, useSelector} from "react-redux";
import get from "lodash/get";
import { ReactComponent as MapDivider } from '../../../assets/styled-blocks/MapDivider.svg';
import useHover from '../../../hooks/useHover';
import {changePlayerPoints, triggerCharacter} from '../../../api/socket';
import CSS from './ControlPanel.module.scss';
import { getCookie } from "../../../utils/utils";
import LinearProgress from '@material-ui/core/LinearProgress';
import allActions from "../../../state/actions";
import SpringModal from "../../Locator/SpringModal/SpringModal";

const POINTS_NAMES = {
  MOVEMENT: 'movement',
  EXPLORE: 'explore',
  SHOP: 'shop',
  INFLUENCE: 'influence',
  TOKENS: 'tokens',
  CAPS: 'caps',
};

const POINTS_TYPE = {
  CURRENT: 'current',
  MAXIMUN: 'maximum',
};

const VALUE_TYPE = {
  INCREASED: 'increased',
  DECREASED: 'decreased',
};

const TRIGGER_COST = 300;

const ControlPanel = ({ board, showCaptured, closeCaptured }) => {
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);
  const mapState = useSelector(state => state.mapState);
  const [difficultyButtonRef, isDifficultyHover] = useHover();
  const [showCurrentStats, setShowCurrentStats] = useState(true);
  const [updateCapsValue, setUpdateCapsValue] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const sessionName = getCookie('sessionName');

  const handleSabotague = useCallback(() => {
    setOpen(false);
    if (currentPlayer.player.caps.points.current >= TRIGGER_COST) {
      triggerCharacter(
        "Диверсант",
        1,
        ({ characterData, messages, serviceData }) => {
          dispatch(allActions.dialogSwitcherActions.setCurrentCharacterInfo({ name: 'dialog', data: { ...characterData, messages, items: serviceData }}));
          dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
          dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: true }));
        }
      );

      changePlayerPoints(POINTS_NAMES.CAPS, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED, -TRIGGER_COST);
    }
  }, [currentPlayer, dispatch]);

  const renderModal = useCallback(() => {
    return (
      <div className={CSS.modal}>
        Вы уверены что хотите вызвать диверсанта?
        <div className={CSS.modalButton} onClick={handleSabotague}>Да</div>
        <div className={CSS.modalButton} onClick={() => setOpen(false)}>Отмена</div>
      </div>
    )
  }, [handleSabotague]);

  const explorePoints = get(currentPlayer, "player.map.explorePoints.current", "?");
  const maxExplorePoints = get(currentPlayer, "player.map.explorePoints.maximum", "?");
  const movePoints = get(currentPlayer, "player.map.movePoints.current", "?");
  const maxMovePoints = get(currentPlayer, "player.map.movePoints.maximum", "?");
  const buyPoints = get(currentPlayer, "player.shop.points.current", "?");
  const maxBuyPoints = get(currentPlayer, "player.shop.points.maximum", "?");
  const influencePoints = get(currentPlayer, "player.influence.points.current", "?");
  const tokensPoints = get(currentPlayer, "player.tokens.points.current", "?");
  const playersColorList = get(mapState, "content.colors.players", {});
  const playersColorsNameList = get(mapState, "content.displayText.players", {});
  const difficultiesColorList = get(mapState, "content.colors.difficulties", {});
  const difficultiesColorsNameList = get(mapState, "content.displayText.difficulties", {});

  function handleChangePlayerPoints(pointsName, pointsType, valueType) {
    changePlayerPoints(pointsName, pointsType, valueType);
  }

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={CSS.wrapper}>
      <SpringModal open={open} handleClose={handleClose}>
        {renderModal()}
      </SpringModal>
      {['player'].includes(get(currentUser, 'user.type', '')) && (
        <>
        <Collapse direction="down" in={showCurrentStats} mountOnEnter unmountOnExit>
      <div className={CSS.displayPart}>
        <div className={CSS.text}>Очки Разведки</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.EXPLORE, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.EXPLORE, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED)}>-</div>
        </div>
          <div className={CSS.points}>{explorePoints}</div>
          /
          <div className={CSS.points}>{maxExplorePoints}</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.EXPLORE, POINTS_TYPE.MAXIMUN, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.EXPLORE, POINTS_TYPE.MAXIMUN, VALUE_TYPE.DECREASED)}>-</div>
          </div>
        </div>
        <div className={CSS.displayPart}>
        <div className={CSS.text}>Очки Движения</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.MOVEMENT, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.MOVEMENT, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED)}>-</div>
        </div>
          <div className={CSS.points}>{movePoints}</div>
          /
          <div className={CSS.points}>{maxMovePoints}</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.MOVEMENT, POINTS_TYPE.MAXIMUN, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.MOVEMENT, POINTS_TYPE.MAXIMUN, VALUE_TYPE.DECREASED)}>-</div>
          </div>
        </div>
        <div className={CSS.displayPart}>
        <div className={CSS.text}>Очки Покупки</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.SHOP, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.SHOP, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED)}>-</div>
        </div>
          <div className={CSS.points}>{buyPoints}</div>
          /
          <div className={CSS.points}>{maxBuyPoints}</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.SHOP, POINTS_TYPE.MAXIMUN, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.SHOP, POINTS_TYPE.MAXIMUN, VALUE_TYPE.DECREASED)}>-</div>
          </div>
        </div>
        <div className={CSS.displayPart}>
        <div className={CSS.text} style={{ marginRight: '4px' }}>Очки Влияния</div>
          <div className={CSS.points}>{influencePoints}</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.INFLUENCE, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.INFLUENCE, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED)}>-</div>
          </div>
        </div>
        <div className={CSS.displayPart}>
        <div className={CSS.text} style={{ marginRight: '4px' }}>Жетоны</div>
          <div className={CSS.points}>{tokensPoints}</div>
          <div className={CSS.changeButtons}>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.TOKENS, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED)}>+</div>
          <div className={CSS.changeButton} onClick={() => handleChangePlayerPoints(POINTS_NAMES.TOKENS, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED)}>-</div>
          </div>
        </div>
          {sessionName?.toLowerCase().indexOf('whopg') >= 0 && currentPlayer?.player?.caps && (
            <div className={CSS.displayPart}>
            <div className={CSS.sabotagueBox}>
              <div className={CSS.level}>Возможные диверсии: {Math.floor(currentPlayer.player.caps.points.current/TRIGGER_COST)}</div>
              <div style={{ position: "relative", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <LinearProgress
                  classes={{ root: CSS.expirienceRow }}
                  variant="determinate"
                  value={Math.round(currentPlayer.player.caps.points.current/currentPlayer.player.caps.points.maximum*100)}
                />
                <div className={CSS.expirienceText}>
                  {currentPlayer.player.caps.points.current}/{currentPlayer.player.caps.points.maximum}
                </div>
              </div>
              <input className={CSS.inputValue} type="number" value={updateCapsValue} onChange={(e) => setUpdateCapsValue(e.target.value)} />
              <div className={CSS.row}>
                <div className={CSS.addValue} onClick={() => {
                  changePlayerPoints(POINTS_NAMES.CAPS, POINTS_TYPE.CURRENT, VALUE_TYPE.DECREASED, updateCapsValue);
                  setUpdateCapsValue('');
                }}>
                  Добавить
                </div>
                <div className={CSS.triggerValue} onClick={() => currentPlayer.player.caps.points.current >= TRIGGER_COST && setOpen(true)}>
                  Диверсия
                </div>
              </div>
            </div>
            </div>
          )}
      </Collapse>
      <div style={{ width: "100%", height: "4px", backgroundColor: "#141012" }} />
      <div style={{ width: "100%", marginTop: "4px" }}>
        <div className={CSS.button} style={{ width: "100%" }} onClick={() => setShowCurrentStats(prevState => !prevState)}>
          {showCurrentStats ? 'Скрыть поинты' : 'Показать поинты'}
        </div>
      </div>
        </>
      )}
      <div style={{ width: "100%", marginTop: "5px", display: "flex", position: "relative", justifyContent: "space-between" }}>
       <div>
        <div className={CSS.controllButton} ref={difficultyButtonRef} onClick={() => mapState.showCaptured ? closeCaptured() : showCaptured()}>
          {mapState.showCaptured ? 'Скрыть сложность' : 'Показать сложность'}
         </div>
         <div className={CSS.colorsWrapper}>
           <div className={CSS.colorWrapper}>
           {Object.entries(difficultiesColorList).sort(([key1, value1], [key2, value2]) => value1.order - value2.order).map(([key, { value }]) => (
             <div className={CSS.colorCard} key={key}>
               <div style={{ marginRight: "4px" }}>{difficultiesColorsNameList[key]}</div>
               <div className={CSS.colorBox} style={{ background: value }} />
             </div>
           ))}
         </div>
         <div className={CSS.colorWrapper}>
              {Object.entries(playersColorList).map(([key, value]) => (
                <div className={CSS.colorCard} key={key}>
                  <div style={{ marginRight: "4px" }}>{playersColorsNameList[key]}</div>
                  <div className={CSS.colorBox} style={{ background: value }} />
                </div>
              ))}
            </div>
           </div>
       </div>
      </div>
    </div>
  )
}

export default ControlPanel;
