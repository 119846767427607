const initWidgets = () => {
  return {
    type: "INIT_WIDGETS",
  }
}

const changePosition = (name, position) => {
  return {
    type: "CHANGE_POSITION",
    payload: {
      name,
      position,
    }
  }
}

const changeIsOpened = (name, isOpened) => {
  return {
    type: "CHANGE_IS_OPENED",
    payload: {
      name,
      isOpened,
    }
  }
}


const changeIsDraggable = (name, isDraggable) => {
  return {
    type: "CHANGE_IS_DRAGGABLE",
    payload: {
      name,
      isDraggable,
    }
  }
}

export default {
  initWidgets,
  changePosition,
  changeIsOpened,
  changeIsDraggable,
}
