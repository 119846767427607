const setMates = (mates) => {
  return {
    type: "SET_MATES",
    payload: mates,
  }
}

const setAllMates = (mates) => {
  return {
    type: "SET_ALL_MATES",
    payload: mates,
  }
}

const addMate = (mate) => {
  return {
    type: "ADD_MATE",
    payload: mate,
  }
}

const removeMate = (mate) => {
  return {
    type: "REMOVE_MATE",
    payload: mate,
  }
}

const setCurrentMate = (mate) => {
  return {
    type: "SET_CURRENT_MATE",
    payload: mate,
  }
}

const setCurrentMateMessages = (messages) => {
  return {
    type: "SET_CURRENT_MATE_MESSAGES",
    payload: messages,
  }
}

const addCurrentMateMessages = (message) => {
  return {
    type: "ADD_CURRENT_MATE_MESSAGES",
    payload: message,
  }
}

export default {
  setMates,
  setAllMates,
  addMate,
  removeMate,
  setCurrentMate,
  setCurrentMateMessages,
  addCurrentMateMessages,
}
