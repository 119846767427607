import React, { useState } from 'react';
import CSS from './Patch.module.scss';
import { ReactComponent as Divider } from './Divider.svg';
import bottomDivider from './bottomDivider.svg';
import buttonAfter from './ButtonAfter.svg';
import knifePng from './knife.png';
import detectiveEyePng from './detectiveEye.png';
import cardPng from './card.png';
import teethPng from './teeth.png';
import flyBootsPng from './flyBoots.png';
import wolverinePng from './wolverine.png';
import yayakudza from './yayakudza.png';
import Collapse from '@material-ui/core/Collapse';
import { animated } from 'react-spring';
import allActions from './../../state/actions/index';
import { useDispatch } from 'react-redux';

const patchList = {
    '1.10': '1.10',
    '1.12': '1.12',
}

const Patch = () => {

    const [currentPatch, setCurrentPatch] = useState(null);
    const dispatch = useDispatch();

    const renderPatch = (patchName) => {
        switch (patchName) {
            case patchList['1.10']:
            case patchList['1.11']:
                return (
                    <div className={CSS.patchBackHelper}>
                    <div className={CSS.patchBack}>
                        <div className={CSS.patchHeader}>Импланты</div>
                        <Divider className={CSS.dividerRed} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={knifePng} />
                            <div className={CSS.patchSubHead}>Рука «Скрытый клинок»</div>
                            <div className={CSS.patchText}>
                                Добавьте на ваше оружие +2 к двум любым характеристикам, <br/> присутствующим в графе свойства.<br/>
                                Улучшенное оружие нельзя снять по своей воле. Если оружие принудительно снимается каким-либо эффектом — наденьте его обратно при первой же возможности.<br/>
                                В случае удаления импланта сбрасывается вместе с улучшенным оружием и наоборот.<br/>
                                Удалить имплант может торговец имплантами второго уровня и выше.
                            </div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={detectiveEyePng} />
                            <div className={CSS.patchSubHead}>Детективное зрение</div>
                            <div className={CSS.patchText}>Дополнительное свойство:<br/>
Можете использовать перед проверкой на Бонусы и Штрафы.<br/>
Измените характеристику в Бонусе на нужную вам (но не ту, которая используется для проверки на Штраф). Во время этой проверки для Бонуса не будут учитываться характеристики от предметов. <br/>
После проверки получите -2 к той характеристике, на которую меняли Бонус. Эффект длится до отката кулдауна. Кулдаун 3 хода.</div>
                        </div>
                        <div className={CSS.patchHeader}>Сектор</div>
                        <Divider className={CSS.dividerRed} />
                        <div className={CSS.patchSubHead}>территория AYAYAкудза</div>
                        <div className={CSS.patchText}>Бонус: удвоение опыта за прохождение.</div>
                        <img className={CSS.fullWidthImage} alt="sector" src={yayakudza} />
                        <div className={CSS.patchHeader}>Тор</div>
                        <Divider className={CSS.dividerRed} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={cardPng} />
                            <div className={CSS.patchSubHead}>Карта взлома терминалов</div>
                            <div className={CSS.patchText}>В любой момент можете использовать карту, чтобы узнать игру,<br/>
которую сегодня необходимо пройти в Центральном секторе.<br/>
Проблема в том, что и остальные её узнают. После использования всех зарядов сбрасывается. Если у карты минимум 2 заряда, то можете продать её другому участнику инвента за влияние. Минимальная цена 6, максимальная 14.
При следующем визите к картографу получите 4 влияния отдав карту взлома,<br/>
если на ней 2 и более зарядов. Данное действие не тратит ОП.</div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={teethPng} />
                            <div className={CSS.patchSubHead}>Вставная челюсть <br/>
«Магнифисенто»</div>
                            <div className={CSS.patchText}>В начале хода, после выбора сектора, но до проверки на Бонус/Штраф, выберите персонажа. На него не будут действовать никакие эффекты от случайных встреч или от Бонуса/Штрафа. <br/>
Он однозначно будет готов с вами торговать. После прохождения игры не повышайте уровень персонажей, но воспользуйтесь услугами выбранного персонажа максимально возможного уровня на текущем секторе. Нельзя применить на Смотрителя. <br/>
После использования всех зарядов сбрасывается. Зарядов 2.</div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={flyBootsPng} />
                            <div className={CSS.patchSubHead}>Летающие ботинки «Левиоса»</div>
                            <div className={CSS.patchText}>После использования получите +2 ОД на один ход. <br />
Вы не можете захватывать нейтральные и чужие сектора. Если у вас есть штрафы на передвижение, летающие ботинки позволяют их проигнорировать. Эти штрафы не будут перенесены на следующий ход. За каждое не использованное ОД перед началом «захвата сектора» получите 1 влияние. После использования всех зарядов сбрасывается. Заряд 1.</div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <img className={CSS.patchIcon} alt="changeIcon" src={wolverinePng} />
                            <div className={CSS.patchSubHead}>Гель «Росомаха»</div>
                            <div className={CSS.patchText}>Получите 1 очко характеристик на свой выбор за каждую ячейку <br/>
с ранением. Избавьтесь от всех ранений разом: обычных и тяжелых. <br/>
После использования всех зарядов сбрасывается.
Заряд 1.</div>
                        </div>
                        <div className={CSS.patchHeader}>пресет</div>
                        <Divider className={CSS.dividerRed} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchSubHead}>Скорость</div>
                            <div className={CSS.patchText}>16 новых игр. Жанр: «Гоночные игры».</div>
                        </div>
                        <div className={CSS.patchHeader}>диверсант</div>
                        <Divider className={CSS.dividerRed} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchSubHead}>Сверхзвуковая-импульсная- <br/>
магнитная-электро-гига-турбо мина
</div>
                            <div className={CSS.patchText}>Выберите другого стримера. Он лишается любого импланта на его выбор. Если имплантов нет — приведите одну ячейку для имплантов в негодность на 4 хода. Ячейку может починить только торговец имплантами пятого уровня. Данное действие не тратит ОП.</div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchSubHead}>Полицейский беспредел</div>
                            <div className={CSS.patchText}>Выберите другого стримера. Во время следующего хода этот стример теряет все ОД и не может передвигаться по карте. Не помогут даже Летающие ботинки «Левиоса». Если стример проходит игру под этим эффектом, повторно использовать на него «Полицейский беспредел» нельзя.</div>
                        </div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchSubHead}>Деактивация</div>
                            <div className={CSS.patchText}>Выберите другого стримера. В течение следующих 2 встреч с проверкой на характеристики, стример обязан выбирать варианты без проверки на характеристики, даже если его показатели позволяют их пройти.</div>
                        </div>
                        <div className={CSS.patchHeader}>Общие изменения</div>
                        <Divider className={CSS.dividerRed} style={{ marginBottom: '35px' }} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchText}>— Исправлены незначительные ошибки;</div>
                            <div className={CSS.patchText}>— Добавлены отзывы к играм;</div>
                            <div className={CSS.patchText}>— В бинго добавлены клипы;</div>
                            <div className={CSS.patchText}>— Запущена мобильная версия сайта;</div>
                            <div className={CSS.patchText}>— Такэмура теперь работает.</div>
                        </div>
                    </div>
                </div>
                )
            case patchList['1.12']:
                return (
                    <div className={CSS.patchBackHelper}>
                    <div className={CSS.patchBack}>
                        <div className={CSS.patchHeader}>Изменения в правилах</div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchBox}>
                            <div className={CSS.patchSubHead}>Перезахват секторов</div>
                            <div className={CSS.patchText}>Один и тот же сектор может быть захвачен стримером лишь 2 раза в игровую неделю. Если стример «‎А» захватил сектор, он может проходить игры на своем секторе в соответствии с правилом о прохождении игр на своих секторах сколько угодно раз. При этом, если другой стример захватит этот сектор, то стример «А» может один раз перезахватить этот же сектор, но до конца игровой недели перезахват больше сделать не сможет. 
Если сектор, который уже под контролем стримера «А» был захвачен. он может перезахватить его и, если у него снова его захватили, перезахватить вновь. Но в третий раз перезахват в эту игровую неделю сделать он не сможет. Данное правило запрещает абуз захвата одного сектора двумя стримерами по множеству раз в течение короткого срока.</div>
                            <div className={CSS.patchSubHead}>Усиление наказания за дроп</div>
                            <div className={CSS.patchText}>Теперь при дропе игры стример теряет то количество влияния, какое должен был получить за её прохождение. 
Также для каждого сектора добавляется множитель теряемого влияния за дроп:
×1 - при дропе на своих секторах;
×2 - при дропе на нейтральных секторах;
×3 - при дропе на чужих секторах.</div>
                        <div className={CSS.patchHeader}>Предметы</div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchSubHead}>Спортивные штаны местных авторитетов</div>
                        <div className={CSS.patchText}>Теперь дают 1x(№ недели) жетонов вместо 1 жетона.</div>
                        <div className={CSS.patchSubHead}>Предметы 2 уровня</div>
                        <div className={CSS.patchText}>Почти все предметы 2 уровня теперь дают +3 характеристики вместо +4.</div>
                        <div className={CSS.patchSubHead}>Предметы 3 уровня</div>
                        <div className={CSS.patchText}>Почти все предметы 3 уровня теперь дают +5 характеристик вместо +6.</div>
                        <div className={CSS.patchHeader}>Импланты</div>
                        <Divider className={CSS.dividerYellow} style={{ marginBottom: '35px' }} />
                        <div className={CSS.patchText}>Большая часть имплантов потеряла от 30 до 50 процентов своих статов. Изменены некоторые дополнительные свойства.</div>
                        <div className={CSS.patchHeader}>Напарники</div>
                        <Divider className={CSS.dividerYellow} />
                        <div className={CSS.patchSubHead}>Ричард Чич</div>
                        <div className={CSS.patchText}>Теперь даёт +5 часов к таймеру вместо +10 часов. Теперь даёт 1×(№ недели) жетонов вместо 1 жетона. Снижены минусы от характеристик: в сумме снижает на 4 характеристики меньше, чем раньше.</div>
                        <div className={CSS.patchSubHead}>Шутница</div>
                        <div className={CSS.patchText}>Теперь даёт 1×(№ недели) жетонов вместо 1 жетона.</div>
                        <div className={CSS.patchHeader}>Общие изменения</div>
                        <Divider className={CSS.dividerYellow} style={{ marginBottom: '35px' }} />
                        <div className={CSS.patchBox} >
                            <div className={CSS.patchText}>— Такэмура теперь работает.</div>
                            <div className={CSS.patchText}>— На сложных и адских секторах отсутствует возможность реролла игры со случайной встречи.</div>
                        </div>
                        </div>
                    </div>
                </div>
                )
            default:
                return <div>Nothing</div>
        }
    }

    const patchSetter = (patch) => currentPatch === patch ? setCurrentPatch(null) : setCurrentPatch(patch);

    return (
        <>
            <div className={CSS.back}>
            <div className={CSS.closeIcon} onClick={() => dispatch(allActions.widgetsActions.changeIsOpened('patch', false))}>X</div>
            <animated.div className={CSS.button} style={{ display: currentPatch ? currentPatch === patchList['1.10'] ? 'inline' : 'none' : 'inline' }}  onClick={() => patchSetter(patchList['1.10'])}>
                <div className={CSS.buttonBox}>
                    <div className={CSS.buttonHeader}>Patch 1.10</div>
                    <div>07.02.2021</div>
                </div>
                <Divider />
                <div className={CSS.buttonBox} style={{ margin: '10px 0' }}>
                    <div>изменения вступят в силу в 00:00</div>
                    <div>week 1</div>
                </div>
            </animated.div>
            <Collapse in={currentPatch === patchList['1.10']}>
                {renderPatch(patchList['1.10'])}
            </Collapse>
            <animated.div className={CSS.button} style={{ display: currentPatch ? currentPatch === patchList['1.12'] ? 'inline' : 'none' : 'inline' }}  onClick={() => patchSetter(patchList['1.12'])}>
                <div className={CSS.buttonBox}>
                    <div className={CSS.buttonHeader}>Patch 1.12</div>
                    <div>22.02</div>
                </div>
                <Divider />
                <div className={CSS.buttonBox} style={{ margin: '10px 0' }}>
                    <div>изменения вступят в силу с 22.02 на 23.02 в 00:00</div>
                    <div>week 4</div>
                </div>
            </animated.div>
            <Collapse in={currentPatch === patchList['1.12']}>
                {renderPatch(patchList['1.12'])}
            </Collapse>
        </div>
        </>
    )
}

export default Patch;