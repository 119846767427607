import React, { useState, memo } from "react";
import RenderStats from "./RenderStats";
import {changePlayerCharacteristic, changePlayerExperience} from "../../api/socket";
import CSS from './CharacterInfo.module.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

const CharacterInfo = ({ player }) => {
  const [updateValue, setUpdateValue] = useState('');

  const updateStat = (statName, type) => {
    changePlayerCharacteristic(statName, type);
  };

  const updateExperience = (type, count) => {
    changePlayerExperience(type, count);
  };

  return (
      <div className={CSS.wrapper}>
      <div className={CSS.characterBox}>
        <img alt="person" style={{ width: "140px", height: "140px" }} src={player.image} />
        <div className={CSS.level}>Уровень {player.level}</div>
       <div style={{ position: "relative", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div className={CSS.statButton} onClick={() => updateExperience('decreased')}>-</div>
          <LinearProgress
            classes={{ root: CSS.expirienceRow }}
            variant="determinate"
            value={Math.round(player.experience.current/player.experience.maximum*100)}
          />
          <div className={CSS.expirienceText}>
              {player.experience.current}/{player.experience.maximum}
          </div>
          <div className={CSS.statButton}  onClick={() => updateExperience('increased')}>+</div>
        </div>
          <input className={CSS.inputValue} type="number" value={updateValue} onChange={(e) => setUpdateValue(e.target.value)} />
          <div>
            <div className={CSS.addValue} onClick={() => {
              updateExperience('none', updateValue);
              setUpdateValue('');
            }}>
              Добавить
            </div>
        </div>
      </div>
      <div className={CSS.statWrapper}>
        <RenderStats
          characteristics={player.characteristics}
          updateStatCallback={updateStat}
          isMutable
        />
      </div>
    </div>
  )
}

export default memo(CharacterInfo);
