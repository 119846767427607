const appState = (state = {}, action) => {
  switch(action.type){
    case "CHANGE_IS_LOCKED":
      return {
        ...state,
        isLocked: action.payload,
      };
    case "CHANGE_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}

export default appState;
