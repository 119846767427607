const saveNote = (note) => {
    return {
      type: "SAVE_ENCOUNTER_NOTE",
      payload: note,
    }
  }
  
  const deleteNote = (removeId) => {
    return {
      type: "DELETE_ENCOUNTER_NOTE",
      payload: removeId,
    }
  }
  
  export default {
    saveNote,
    deleteNote,
  }
  