import React, { useState, useEffect } from 'react';
import { getRandomFailures, sendWhellOfFailuresWinner } from '../../api/socket';
import sample from 'lodash/sample';
import './WhellOfMisfortune.scss';
import { useSpring, animated } from 'react-spring';
import ReactTurntable from "react-turntable";
import "react-turntable/assets/index.css";
import parse from 'html-react-parser';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';


const WheelOfMisfortune = () => {

    const widgets = useSelector(state => state.widgets);

    const [isReady, setIsReady] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isSpining, setIsSpining] = useState(false);
    const [result, setResult] = useState(null);
    const [variant, setVariant] = useState(false);
    const { o } = useSpring({
        from: { o: 0 },
        o: isFinished ? 1 : 0,
    });

    function handleResult(data) {
        setResult(data);
    }
  

    const getData = (data) => {
        setValues(data.list);
        setIsReady(true);
    }

    useEffect(() => {
        if (get(widgets, 'wheel.isOpened', false)) {
            getRandomFailures(getData);
        }
    }, [widgets]);
    
    const [values, setValues] = useState([]);

    const handleReroll = () => {
       setIsFinished(false);
       setResult(null);
       setVariant(null);
    }

  return (
        isReady ? (
            <div class='wrapper'>
                <ReactTurntable
                    {...{
                        prizes: values.map(value => value.name),
                        width: 700,
                        height: 700,
                        primaryColor: "rgba(111, 62, 142, 0.5)",
                        secondaryColor: "#6F3E8E",
                        fontStyle:{
                            color:"#f2f2f2",
                            size:"14px",
                            fontWeight:"bold",
                            fontFamily:"Play"
                        },
                        speed : 300,
                        duration: 25000,
                        clickText:"Крутим",
                        hiddenButton: !isReady || isFinished || isSpining,
                        onStart() {
                            setIsSpining(true);
                            return true;
                        },
                        onComplete(prize) {
                          const prizeValue = values.find((value) => value.name === prize);
                          sendWhellOfFailuresWinner(prizeValue.id, handleResult);
                          setIsSpining(false);
                          setTimeout(() => setIsFinished(true), 500);
                        }
                    }}
            />
            <animated.div className='winnerBox' style={{
                opacity: o,
                visibility: o.interpolate(o => o === 0 ? 'hidden' : 'visible'),
            }}>
                {result && !variant && (
                    <>
                        <div className='result'>{result.name}</div>
                        <div className='result-text'>{result.description}</div>
                        {result.variants && result.variants.map((variant) => (
                            <div key={variant.id} className={classNames('choice', { 'choice-isLocked': variant.isLocked })}>
                                <div onClick={() => !variant.isLocked  && setVariant(variant)}>
                                    <div className='result-text'>
                                    {variant.option.text}
                                    </div>
                                <div className='result-additional'>
                                    {variant.option.shortEffects}
                                </div>
                                <div>
                                {variant.option.condition && (
                                    <div className='result-additional'>
                                        {variant.option.condition.text}
                                    </div>
                                )}
                                </div>
                            </div>
                            </div>
                        ))}
                    </>
                )}
                {variant && (
                    <>
                        {variant.result.text && <div className='result-text'>{parse(variant.result.text)}</div>}
                        {variant.result.effects && <div className='result-effect'>{parse(variant.result.effects)}</div>}
                        <button className='button' onClick={handleReroll}>Крутить ещё</button>
                    </>
                )}
            </animated.div>
            </div>
        ) : <div>Нет данных</div>
  )
}

export default WheelOfMisfortune;