import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { MAP_STATES, TURN_STATES } from './../../../consts/map';
import CSS from './MapInterface.module.scss';
import classNames from 'classnames';
import allActions from "../../../state/actions";

const MapInterface = ({
  onMovementButtonClick, onExploreButtonClick, onOpenButtonClick, onPlayButtonClick, onPrepareCaptureSend, currentSendPlayer, setCurrentSendPlayer, findSendTiles,
}) => {
  const dispatch = useDispatch();
  const currentPlayer = useSelector(state => state.currentPlayer);
  const mapState = useSelector(state => state.mapState);
  const playersNameList = get(mapState, "content.displayText.players", {});

  function checkIsClosed(type) {
    const currentState = get(currentPlayer, "player.currentTurnState", null);
    if (currentState === TURN_STATES.IDLE) { return false; }
    const currentPoints = get(currentPlayer, `player.map.${type}.current`, 0);
    if (type === "movePoints") {
      return currentPoints === 0 || isEmpty(get(currentPlayer, 'player.map.cellsCanGo', []));
    }
    if (type === "sendCaptured") {
      return isEmpty(findSendTiles());
    }
    return currentPoints === 0;
  }

  return (
    <div className={CSS.wrapper}>
      {currentPlayer.player && (
        <div className={CSS.buttonsGrid}>
                      {get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_SEND && (
                        <div className={CSS.playersList}>
                          <div
                            onClick={() => setCurrentSendPlayer(null)}
                            className={classNames(CSS.playerCard, { [CSS.playerSelected]: !currentSendPlayer })}
                          >
                            Нейтральный
                          </div>
                        {Object.entries(playersNameList).map(([key, value]) => (
                          <div
                            onClick={() => setCurrentSendPlayer(key)}
                            className={classNames(CSS.playerCard, { [CSS.playerSelected]: currentSendPlayer === key })}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
        )}
        <div className={CSS.buttonsBlock}>
        {get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_OPEN && (
            <div className={CSS.additionalButtons}>
              <div
                className={CSS.additionalButton}
                style={{ marginRight: '6px' }}
                onClick={() => dispatch(allActions.mapActions.changeIsOpenAll(false))}
              >
                Близко
              </div>
              <div
                className={CSS.additionalButton}
                onClick={() => dispatch(allActions.mapActions.changeIsOpenAll(true))}
              >
                Всё
              </div>
            </div>
        )}
        <div
              className={classNames(CSS.open,
                 { [CSS.closed]: checkIsClosed("explorePoints"), [CSS.active]: get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_OPEN }
              )}
              onClick={!checkIsClosed("explorePoints") && onOpenButtonClick}
            >
              РАЗВЕДКА
            </div>
        <div
              className={classNames(CSS.send,
                 { [CSS.closed]: checkIsClosed("sendCaptured"), [CSS.active]: get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_SEND }
              )}
              onClick={!checkIsClosed("sendCaptured") && onPrepareCaptureSend}
            >
              ОТДАТЬ
            </div>
        </div>
         <div className={CSS.buttonsBlock}>
        <div 
          className={classNames(CSS.move, { [CSS.closed]: checkIsClosed("movePoints"), [CSS.active]: get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_MOVE })}
          onClick={!checkIsClosed("movePoints") && onMovementButtonClick}
        >
              ПЕРЕЙТИ
            </div>
        </div>
        <div className={classNames(CSS.capture)} onClick={onPlayButtonClick}>
            ОСМОТРЕТЬ СЕКТОР ПОД СОБОЙ
        </div>
        </div>
      )
      }
    </div>
  )
}

export default MapInterface;
