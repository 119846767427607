import React, { useEffect, useRef, useState } from "react";
import {
  EncounterCard,
  Field, FieldWrapper, Navigator, NavigatorMessage,
} from "./style";
import isEmpty from "lodash/isEmpty";
import sample from "lodash/sample";
import encounterBackground from "../../assets/images/encounter_background.png";
import { getRandomInt } from "../../utils/utils";
import colors from '../../consts/colors';
import Slider from "@material-ui/core/Slider";
import withStyles from "@material-ui/core/styles/withStyles";
import { isEqual, get, has } from "lodash";
import DraggableDialog from "./EncounterModal/EncounterDialog";
import { getRandomEncounters } from "../../api/socket";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../state/actions";
import CSS from './Encounter.module.scss';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';
import useComponentVisible from './../../hooks/useComponentVisible';

export const PrettoSlider = withStyles({
  root: {
    color: colors.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const navigatorMessagesPull = {
  'onAwait': {
    weight: 1,
    message: "Босс, как будешь готов, сообщи мне",
    messages: ["Босс, как будешь готов, сообщи мне"],
  },
  'onStart': {
    weight: 2,
    message: "Понял, начинаю сканирование",
    messages: ["Понял, начинаю сканирование"],
  },
  'onFail': {
    weight: 3,
    message: 'Нет, это нам не подходит',
    messages: [
      'Попробуем еще.',
      'Здесь ничего.',
      'Ты думал здесь что-то будет?',
      'Мы попались на отвлекающего Форхедера.',
      'Это нам не подходит.',
      'Повторная попытка.',
      'Здесь чисто.',
      'Датчики спокойны.',
      'Никакой активности в этом районе.',
      'Тут нет ничего интересного.',
      'Нам это не подходит.',
      'А может...',
      'Нет, не это.',
      'Что это? Показалось...',
      'Изучим другое место.',
      'Все спокойно, идем дальше.',
      'Сенсоры молчат.',
      'Кажется, мы близко.',
      'Холодно.',
      'Мы на верном пути.',
      'Все еще не то.',
      'Это не то, что мы ищем.',
      'Нам тут делать нечего.',
      'Это нам не нужно.',
    ],
  },
  'onSuccess': {
    weight: 4,
    message: 'Нам сюда, выдвигаемся!',
    messages: ['Нам сюда, выдвигаемся!'],
  }
}

const timeToMove = 1000;

const Encounter = () => {

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const dialogSwitcher = useSelector(state => state.dialogSwitcher);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const [encounters, setEncounters] = useState(Array(25).fill({ id: -1, name: '???' }));
  const [isStarted, setIsStarted] = useState(false);
  const [currentEncounter, setCurrentEncounter] = useState(null);
  const [encountersData, setEncountersData] = useState(null);
  const [navigatorMessages, setNavigatorMessages] = useState([
    {
      ...navigatorMessagesPull.onAwait,
      message: sample(navigatorMessagesPull.onAwait.messages),
    },
  ]);
  const scrollAssistRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = get(currentPlayer, 'player.encountersData', []);
    setEncountersData(data);
  }, [currentPlayer]);

  const [xPos, setXPos] = useState(0);
  const [barColor, setBarColor] = useState('#6F3E8E');
  const [barX, setBarX] = useState(0);
  const [barY, setBarY] = useState(0);

  const springState = useSpring({
    from: { x: 0, color: '#6F3E8E', barX: 0, barY: 0, },
    x: xPos,
    barColor: barColor,
    barX: barX,
    barY: barY,
});

  // x from 0 to 1200
  // y from 0 to 800
  const _secretPointsGenerator = (pointsCount) => {
    return [...Array(pointsCount)].map(() => ({
      x: getRandomInt(0, 4),
      y: getRandomInt(0, 4),
    }));
  }

  // from 10 to 20
  const _secretCountOfPointsGenerator = (winnerPosition, gameEncountersLength) => {
    const _pointsCount = getRandomInt(7, gameEncountersLength - 1);
    const arrayWithoutWinner = _secretPointsGenerator(_pointsCount)
      .filter((v, i, a) => a.findIndex(v2 => isEqual(v, v2)) === i)
      .filter((v) => !isEqual(v, winnerPosition));
    return [...arrayWithoutWinner, winnerPosition];
  }

  const handleMoveButton = () => {
    if (isStarted) return;
    setIsStarted(true);
    setNavigatorMessages([{
      ...navigatorMessagesPull.onAwait,
      message: sample(navigatorMessagesPull.onAwait.messages),
    }, {
      ...navigatorMessagesPull.onStart,
      message: sample(navigatorMessagesPull.onStart.messages),
    }]);
    getRandomEncounters(({ winnerID, list, ambient }) => {
      if (isEmpty(list)) { return; }
      const audio = new Audio(ambient);
      audio.volume = 0.07;
      const bonusList = Array(25 - list.length).fill({});
      const gameEncounters = [...list, ...bonusList].map((encounter, id) => ({
        id: encounter.id,
        name: encounter.name,
        data: encounter,
        seedX: id%5,
        seedY: Math.floor(id/5),
        isDestroyed: false,
      }));
      const gameEncountersLength = gameEncounters.length;
      if (gameEncountersLength === 0) { return; }
      setEncounters(gameEncounters);
      let winnerItem = gameEncounters.find((item) => item.id.toString() === winnerID.toString());
      let winnerPosition = { x: winnerItem.seedX, y: winnerItem.seedY }
      const randomArray = _secretCountOfPointsGenerator(winnerPosition, gameEncountersLength);
      setTimeout(() => {
        audio.play();
        animateMovementTo(randomArray, gameEncounters, audio);
      }, 1000);
    });
  }

  const recolorEncounter = (currentEncounter) => {
    setEncounters(prevState => prevState.map(prevEncounter => {
      if (prevEncounter.id === currentEncounter.id) {
        return {
          ...currentEncounter,
          isDestroyed: true,
        }
      }
      return prevEncounter;
    }));
  }

  const activateBurnoutMode = (x, y, gameEncounters) => {
    const currentEncounter = gameEncounters
      .find(encounter => encounter.seedX === x && encounter.seedY === y);
    setXPos(216);
    setTimeout(() => {
      setXPos(0);
      setBarColor(colors.red);
    }, 400);
    setTimeout(() => {
      setNavigatorMessages(prevState => [...prevState, {
        ...navigatorMessagesPull.onFail,
        message: sample(navigatorMessagesPull.onFail.messages),
      }]);
      recolorEncounter(currentEncounter);
    }, 800);
  }

  const activateCurrentEncounter = (x, y, gameEncounters, audio) => {
    const _currentEncounter = gameEncounters
      .find(encounter => encounter.seedX === x && encounter.seedY === y);
      setXPos(216);
      setTimeout(() => {
        setXPos(0);
        setBarColor(colors.green);
      }, timeToMove * 0.06);
      setTimeout(() => {
        setNavigatorMessages(prevState => [...prevState, {
          ...navigatorMessagesPull.onSuccess,
          message: sample(navigatorMessagesPull.onSuccess.messages),
        }]);

      }, timeToMove * 0.5);
      setTimeout(() => {
        if (audio) {
          audio.volume = 0.05;
        }
      }, timeToMove * 0.2);
      setTimeout(() => {
        if (audio) {
          audio.volume = 0.04;
        }
      }, timeToMove * 0.4);
      setTimeout(() => {
        if (audio) {
          audio.volume = 0.03;
        }
      }, timeToMove * 0.6);
      setTimeout(() => {
        if (audio) {
          audio.volume = 0.02;
          audio.pause();
        }
        setCurrentEncounter(_currentEncounter);
        dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'encounter', value: true }));
      }, timeToMove * 0.8);
  }


  const animateMovementTo = (array, gameEncounters, audio) => {
    if (!isEmpty(array)) {
      setBarX(232 * array[0].x);
      setBarY(152 * array[0].y);
      setTimeout(() => {
        if (array.length > 1) {
          activateBurnoutMode(array[0].x, array[0].y, gameEncounters);
        }
        if (array.length === 1) {
          activateCurrentEncounter(array[0].x, array[0].y, gameEncounters, audio);
        }
        setTimeout(() => {
          animateMovementTo(array.filter((v,i) => i !== 0), gameEncounters, audio);
        }, timeToMove * 0.8);
      }, timeToMove);
    } else {
      setIsStarted(false);
    }
  };

  useEffect(() => {
    if (scrollAssistRef.current) {
      scrollAssistRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [navigatorMessages]);

  useEffect(() => {
    dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'encounter', value: isComponentVisible }));
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'encounter', value: has(currentEncounter, 'data', false) }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible, currentEncounter]);

  useEffect(() => {
    setIsComponentVisible(dialogSwitcher.encounter.opened);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogSwitcher.encounter.opened]);

  return (
    <div className={CSS.wrapper}>
      <FieldWrapper>
        <Field image={encounterBackground}>
        <animated.div
            className={CSS.target}
            style={{ left: springState.barX, top: springState.barY, borderColor: springState.barColor, }}
            onClick={() => setIsComponentVisible(true)}
          >
            <animated.div style={{ position: 'absolute', left: springState.x, background: springState.barColor, width: '10px', height: '148px' }}>
            </animated.div>
          </animated.div>
          {encounters && encounters.map(encounter => (
            <EncounterCard isDestroyed={encounter.isDestroyed}>
              {encounter.name}
            </EncounterCard>
          ))}
        </Field>
      </FieldWrapper>
      <div className={CSS.columnFixed}>
          {encountersData && <img style={{ width: "320px", height: "220px" }} alt="character" src={encountersData.pilotImage} />}
        <Navigator>
          {
            navigatorMessages
              .sort((v1,v2)=> v1.weight - v2.weight)
              .map((navigatorMessage, id) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} key={id}>
                  <NavigatorMessage>
                    {navigatorMessage.message}
                  </NavigatorMessage>
                </div>
            ))
          }
          <div ref={scrollAssistRef} />
        </Navigator>
        {currentEncounter && (
          <div
            className={classNames(CSS.button, { [CSS.disabled]: isStarted })}
            style={{ marginBottom: '8px' }}
            color={isStarted ? colors.grey50 : colors.main}
            onClick={() => setIsComponentVisible(true)}
          >
            Изучить
          </div>
        )}
        {!currentEncounter && (
          <div className={classNames(CSS.button, { [CSS.disabled]: isStarted })}
            onClick={handleMoveButton}
          >
            Сканировать
          </div>
        )}
        <div style={{ marginTop: '4px' }}>
          Случайные встречи выполнены: {get(encountersData, 'usedCount')} раз
        </div>
      </div>
      <div>
      <DraggableDialog
        pilot={get(encountersData, 'pilotImage')}
        data={get(currentEncounter, 'data', null)}
        dialogRef={ref}
        isComponentVisible={isComponentVisible}
        handleClose={() => setIsComponentVisible(false)}
        handleOpen={() => setIsComponentVisible(true)}
        removeData={() => setCurrentEncounter(null)}
      />
      </div>
    </div>
  )
}

export default Encounter;
