import React, { useState, memo } from "react";
import Popover from '@material-ui/core/Popover';
import LinearProgress from '@material-ui/core/LinearProgress';
import get from 'lodash/get';
import CSS from './RenderStats.module.scss';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '4px',
  },
}))(Tooltip);

const RenderStats = ({ characteristics, updateStatCallback=()=>{}, isMutable=false, showMinus=true }) => {


  if (!characteristics) {
    return <div className={CSS.wrapper}></div>
  }

  return Object.entries(characteristics).map(([name, data])=> (
    <div key={name} className={CSS.wrapper}>
      <div className={CSS.displayName}>{data.displayName}</div>
      <div className={CSS.statBox}>
        {isMutable && showMinus && (
          <div className={CSS.actionButton} onClick={() => updateStatCallback(name, 'decreased')}>-</div>
        )}
        <HtmlTooltip
            placement="right"
            title={
              <React.Fragment>
                <div className={CSS.popover}>
                  <div>{Object.values(data.values).map((value) => (
                    <div>
                      {value.displayName}: {value.value}
                    </div>
                  ))}
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <div className={CSS.statLinear}> 
              <LinearProgress
                  classes={{ root: CSS.valueRow }}
                  variant="determinate"
                  value={Math.round(data.values.actual.value/20*100)}
              />
              <LinearProgress
                  classes={{ root: CSS.valueRowSecond }}
                  variant="determinate"
                  value={Math.round(data.values.manual.value/20*100)}
              />
              <div className={CSS.rowText}>
                {data.values.actual.value} ({data.values.summary.value})
              </div>
            </div>
        </HtmlTooltip>
        {isMutable && (
          <div className={CSS.actionButton} onClick={() => updateStatCallback(name, 'increased', get(data, 'values.actual.value', 0))}>+</div>
        )}
      </div>
    </div>
  ));
}

export default memo(RenderStats);
