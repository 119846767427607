const dice = (state = {}, action) => {
  switch(action.type){
    case "CHANGE_DICE_VALUE":
      return {
        ...state,
        value: action.payload,
      }
    default:
      return state
  }
}

export default dice;
