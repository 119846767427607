import React, { useState, useEffect } from "react";
import { useSpring, animated } from 'react-spring';
import CSS from './DraggableSwitcher.module.scss';

const DraggableSwitcher = ({ position, icon, callback, show }) => {

    const [pulse, setPulse] = useState(false);

    useEffect(() => {
        let interval;
        if (show) {
            interval = setInterval(() => setPulse(prevState => !prevState), 1000);
        }
        return () => clearInterval(interval);
    }, [show]);

    const styleProps = useSpring({
        opacity: show ? 1 : 0,
        height: show ? '80px' : '40px',
        width: show ? '80px' : '40px',
        left: show ? `${position * 100 + 40}px` : '500px',
        bottom: show ? '40px' : '500px',
        x: pulse ? 1 : 0,
        from: { opacity: 0, width: '40px', height: '40px', left: '50px', bottom: '50px', x: 0 },
    });
    return (
    <animated.div className={CSS.button} style={{
         ...styleProps,
        visibility: styleProps.opacity.interpolate(o => o === 0 ? 'hidden' : 'visible'),
        transform : styleProps.x.interpolate({
          range: [0, 0.25, 0.75, 1],
          output: [1, 0.97, 1.03, 1],
        })
        .interpolate(x => `scale(${x})`),
    }} onClick={callback} position={position}>
        <img src={icon} alt="icon" />
    </animated.div>
)};

export default DraggableSwitcher;
