import React, { useCallback, useEffect, useState } from 'react';
import {encounterInteraction, getEncounterData, getEncountersList} from "../../api/socket";
import Collapse from '@material-ui/core/Collapse';
import CSS from './Encounters.module.scss';
import classNames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import diceIcon from "../../assets/icons/diceIcon.svg";
import characteristicIcon from "../../assets/icons/characteristicIcon.svg";
import otherIcon from "../../assets/icons/otherIcon.svg";
import allActions from "../../state/actions";

const conditionTypeIcons = {
  dice: diceIcon,
  characteristic: characteristicIcon,
  other: otherIcon,
};

const DIFFICULTY_COLORS = {
  'Адская': 'rgb(146, 20, 12)',
  'Высокая': 'rgb(254, 74, 73)',
  'Выше среднего': 'rgb(236, 117, 5)',
  'Средняя': 'rgb(253, 202, 64)',
  'Легкая': 'rgb(10, 135, 84)',
};

const PHASES = {
  UNUSED: 'unused',
  USED: 'used',
  PICKED_VARIANT: 'picked_variant',
};

const Encounters = () => {
  const [encounters, setEncounters] = useState([]);
  const [currentEncounter, setCurrentEncounter] = useState(null);
  const [currentVariant, setCurrentVariant] = useState(null);
  const [findString, setFindString] = useState(null);
  const dispatch = useDispatch();

  const [phase, setPhase] = useState(PHASES.UNUSED);

  const handleOpenEncounter = useCallback((data) => {
    setCurrentEncounter(data);
  }, []);

  const getEncounters = useCallback(() => {
    getEncountersList((result) => setEncounters(result));
  }, []);

  const handleEncounterClick = useCallback((id) => () => {
    getEncounterData(id, handleOpenEncounter);
  }, [handleOpenEncounter]);

  const saveToNotes = (result) => {
    dispatch(allActions.encountersNotesActions.saveNote({
      title: `Случайная встреча (${currentEncounter.name})`,
      content: result.effects,
      date: Date.now(),
    }));
  };

  const sendResult = (variant) => {
    encounterInteraction(currentEncounter.id, variant.id);
    saveToNotes(variant.result);
  };

  const renderEncounterMenu = useCallback(() => {
    switch (phase) {
      case PHASES.UNUSED:
        return (
          <div className={CSS.collapseBox}>
            <div className={CSS.title}>{currentEncounter.name}</div>
            <div className={CSS.difficultyRow}>
              {currentEncounter.difficulties.map((difficult) => (
                <div
                  className={CSS.difficultyBox}
                  style={{ background: DIFFICULTY_COLORS[difficult] }}
                >
                  {difficult}
                </div>
              ))}
            </div>
            <div className={CSS.description}>{currentEncounter.description}</div>
            <div className={CSS.button} onClick={() => setPhase(PHASES.USED)}>Изучить</div>
          </div>
        );
      case PHASES.USED:
        return (
          <div className={CSS.collapseBox}>
            <div className={CSS.title}>{currentEncounter.name}</div>
            <div className={CSS.difficultyRow}>
              {currentEncounter.difficulties.map((difficult) => (
                <div
                  className={CSS.difficultyBox}
                  style={{ background: DIFFICULTY_COLORS[difficult] }}
                >
                  {difficult}
                </div>
              ))}
            </div>
            <div className={CSS.description}>{currentEncounter.description}</div>
            <div>
              {currentEncounter.variants.map((variant) => (
                <div
                  className={CSS.variantBox}
                  onClick={() => {
                    setCurrentVariant(variant);
                    setPhase(PHASES.PICKED_VARIANT);
                  }}
                >
                  {variant.option.condition && (
                    <div className={CSS.conditionBox}>
                      <img style={{ width: "20px", marginRight: "6px" }} alt="icon" src={conditionTypeIcons[variant.option.condition.type]} />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {variant.option.condition.text}
                      </div>
                    </div>
                  )}
                  <div className={CSS.optionText}>{variant.option.text}</div>
                  <div className={CSS.optionEffect}>{variant.option.shortEffects}</div>
                </div>
              ))}
            </div>
          </div>
        );
      case PHASES.PICKED_VARIANT:
        return (
          <div className={CSS.collapseBox}>
            <div className={CSS.title}>{currentEncounter.name}</div>
            <div className={CSS.difficultyRow}>
              {currentEncounter.difficulties.map((difficult) => (
                <div
                  className={CSS.difficultyBox}
                  style={{ background: DIFFICULTY_COLORS[difficult] }}
                >
                  {difficult}
                </div>
              ))}
            </div>
            <div className={CSS.description}>{currentEncounter.description}</div>
            <div>
                <div className={CSS.variantBox}>
                  {currentVariant.option.condition && (
                    <div className={CSS.conditionBox}>
                      <img style={{ width: "20px", marginRight: "6px" }} alt="icon" src={conditionTypeIcons[currentVariant.option.condition.type]} />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {currentVariant.option.condition.text}
                      </div>
                    </div>
                  )}
                  <div className={CSS.optionText}>{currentVariant.option.text}</div>
                  <div className={CSS.optionEffect}>{currentVariant.option.shortEffects}</div>
                  <Collapse direction="bottom" in={currentVariant?.id} mountOnEnter unmountOnExit>
                    <div className={CSS.optionText}>{currentVariant.result.text}</div>
                    <div className={CSS.optionEffect}>{currentVariant.result.effects}</div>
                  </Collapse>
                  <div className={CSS.button} onClick={() => {
                    setPhase(PHASES.UNUSED);
                    sendResult(currentVariant);
                  }}>Завершить</div>
                  <div className={CSS.button} onClick={() => setPhase(PHASES.USED)}>Отменить</div>
                </div>
            </div>
          </div>
        );
      default:
        break;
    }
  }, [currentEncounter, currentVariant, phase, sendResult]);

  const rerenderList = useCallback(() => {
    getEncounters();
  }, [getEncounters]);

  useEffect(() => {
    getEncounters();
  }, [getEncounters]);


  return (
    <>
    <div className={CSS.row}>
    <div className={CSS.encountersList}>
      <div className={CSS.box}>
        <input className={CSS.inputValue} onChange={(e) => setFindString(e.target.value)} />
      </div>
      {!encounters && (
        <div className={CSS.button} onClick={rerenderList}>Упс, обновить список</div>
      )}
      {encounters
        .filter((encounter) => findString ? encounter.name.toLowerCase().includes(findString.toLowerCase()) : true)
        .map((encounter) => (
        <>
          <div className={classNames(CSS.box, { [CSS.boxSelected]: encounter.id.toString() === currentEncounter?.id.toString() })} onClick={handleEncounterClick(encounter.id)}>
            {encounter.name}
          </div>
        </>
      ))}
    </div>
    <div className={CSS.encounterBox}>
      <Collapse direction="right" in={currentEncounter} mountOnEnter unmountOnExit>
        {currentEncounter && renderEncounterMenu()}
      </Collapse>
    </div>
    </div>
  </>
  )

};

export default Encounters;
