import React, {useState, useCallback, useMemo} from "react";
import { getRandomLetter } from "../../utils/randomizeWord";
import sample from "lodash/sample";
import InventoryService from "../../api/InventoryService";
import MatesService from '../../api/MatesService';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {colorByTier, getCookie} from "../../utils/utils";
import CSS from "./ShopGenerator.module.scss";
import CSSFirstBuy from '../FirstBuy/FirstBuy.module.scss';
import { addItem, addMate } from '../../api/socket';
import allWidgets from './../index';
import allActions from './../../state/actions/index';
import sound from '../../assets/sounds/misfortune.wav';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { ItemBox, ItemWrapper, ItemName, ItemIcon } from './../Locator/Dialog/components/StartView';
import Popover from '@material-ui/core/Popover';
import ItemPopover from './../Inventory/ItemPopover';
import allServices from "../../api";

const bigSizeTypes = ["Туловище", "Оружие", "Ноги"];

const defaultPull = {
    'Ноги': false,
    'Оружие': false,
    'Туловище': false,
    'Головные уборы': false,
    'Аксессуары': false,
    'Импланты': false,
    'Напарники': false,
}

const itemsPull = {
    'Ноги': true,
    'Оружие': true,
    'Туловище': true,
    'Головные уборы': true,
    'Аксессуары': true,
    'Импланты': false,
    'Напарники': false,
}

const PartOfChestPull = {
    'Ноги': true,
    'Оружие': true,
    'Туловище': true,
    'Головные уборы': true,
    'Аксессуары': false,
    'Импланты': false,
    'Напарники': false,
}

const implantPull = {
    'Ноги': false,
    'Оружие': false,
    'Туловище': false,
    'Головные уборы': false,
    'Аксессуары': false,
    'Импланты': true,
    'Напарники': false,
}

const matePull = {
    'Ноги': false,
    'Оружие': false,
    'Туловище': false,
    'Головные уборы': false,
    'Аксессуары': false,
    'Импланты': false,
    'Напарники': true,
}


const goodPull = {
    'Ноги': true,
    'Оружие': true,
    'Туловище': true,
    'Головные уборы': true,
    'Аксессуары': true,
    'Импланты': true,
    'Напарники': true,
}

function ShopGenerator() {
    const dispatch = useDispatch();
    const [isGenerate, setIsGenerate] = useState(false);
    const [word, setWord] = useState('');
    const [item, setItem] = useState({});
    const [tier1Pull, setTier1Pull] = useState(defaultPull);
    const [tier1Opened, setTier1Opened] = useState(false);
    const [tier2Pull, setTier2Pull] = useState(defaultPull);
    const [tier2Opened, setTier2Opened] = useState(false);
    const [tier3Pull, setTier3Pull] = useState(defaultPull);
    const [tier3Opened, setTier3Opened] = useState(false);
    const [findAllItems, setFindAllItems] = useState(false);
    const [findImplants, setFindImplants] = useState(false);
    const [findNotForSale, setFindNotForSale] = useState(false);
    const [findSabouter, setFindSabouter] = useState(false);
    const [fromInventory, setFromInventory] = useState(false);
    const [fromEquipedInventory, setFromEquipedInventory] = useState(false);
    const [findTor, setFindTor] = useState(false);
    const [isFindHit, setIsFindHit] = useState(false);

    const [newValue, setNewValue] = useState([]);
    const [customList, setCustomList] = useState([]);
    const [isCustomRoll, setIsCustomRoll] = useState(false);

    const buyItem = useCallback((itemID) => {
        addItem(null, itemID);
      }, []);

    const [anchorEl, setAnchorEl] = useState({});

    const handlePopoverOpen = (event, id) => {
      if (anchorEl.id === id) {
          return handlePopoverClose();
      }
      setAnchorEl({
          id: id,
          target: event.currentTarget,
      });
    };

    const handlePopoverClose = () => {
      setAnchorEl({});
    };

    let count = 0;
    let globalCount = 0;
    let canChange = false;

    const clearData = () => {
        setItem({});
        setWord('');
        setIsFindHit(false);
        setIsCustomRoll(false);
        setTier3Opened(false);
        setTier2Opened(false);
        setTier1Opened(false);
        setFromInventory(false);
        setFromEquipedInventory(false);
        setTier1Pull(defaultPull);
        setTier2Pull(defaultPull);
        setTier3Pull(defaultPull);
    };

    async function generate() {
        let result;

        if (isGenerate) { return; }
        setIsGenerate(true);
        if (isCustomRoll) {
            result = sample(customList.map((value) => ({ name: value, isCustom: true })));
        } else if (isFindHit) {
            result = sample([
                { name: 'Ноги', isHit: true },
                { name: 'Оружие (Левое)', isHit: true },
                { name: 'Оружие (Правое)', isHit: true },
                { name: 'Тело', isHit: true },
                { name: 'Головные уборы', isHit: true },
            ]);
        } else if (fromInventory || fromEquipedInventory) {
            const response = await new InventoryService().rollItem(null, null, null, null, fromEquipedInventory ? fromEquipedInventory : fromInventory, fromEquipedInventory);
            result = sample(response);
        } else {
            // items
            const searchTier1 = Object.entries(tier1Pull).filter(([key, value]) => value && key !== 'Напарники').map(([key, value]) => key);
            const searchTier1Result = isEmpty(searchTier1) ? [] : await new InventoryService().rollItem(sample(searchTier1), 1, null, ['Напарники', 'Мгновенное использование']);
            const searchTier2 = Object.entries(tier2Pull).filter(([key, value]) => value && key !== 'Напарники').map(([key, value]) => key);
            const searchTier2Result = isEmpty(searchTier2) ? [] : await new InventoryService().rollItem(sample(searchTier2), 2, null, ['Напарники', 'Мгновенное использование']);
            const searchTier3 = Object.entries(tier3Pull).filter(([key, value]) => value && key !== 'Напарники').map(([key, value]) => key);
            const searchTier3Result = isEmpty(searchTier3) ? [] : await new InventoryService().rollItem(sample(searchTier3), 3, null, ['Напарники', 'Мгновенное использование']);

            // mates
            const isMateTier1 = !!Object.entries(tier1Pull).find(([key, value]) => value && key === 'Напарники');
            const mateTier1Result = isMateTier1 ? await new MatesService().getMate({ tiers: 1, randomCount: 1, excludeAlreadyInUse: true }) : [];
            const isMateTier2 = !!Object.entries(tier2Pull).find(([key, value]) => value && key === 'Напарники');
            const mateTier2Result = isMateTier2 ? await new MatesService().getMate({ tiers: 2, randomCount: 1, excludeAlreadyInUse: true }) : [];
            const isMateTier3 = !!Object.entries(tier3Pull).find(([key, value]) => value && key === 'Напарники');
            const mateTier3Result = isMateTier3 ? await new MatesService().getMate({ tiers: 3, randomCount: 1, excludeAlreadyInUse: true }) : [];

            const sampleMates = sample([...mateTier1Result, ...mateTier2Result, ...mateTier3Result].map((mate) => ({...mate, isMate: true })));

            // all items
            const searchResultFromAllItems = findAllItems ?  await new InventoryService().rollItem(null, null, null , ['Импланты']) : [];
            // implants
            const searchResultFromImplants = findImplants ?  await new InventoryService().rollItem('Импланты') : [];

            // additional find
            let additionalFind = [];
            if (findTor) { additionalFind.push('ТОР') }
            if (findSabouter) { additionalFind.push('Диверсант') }
            if (findNotForSale) { additionalFind.push('Не для продажи') }
            const resultFromAdditionalSearch = !isEmpty(additionalFind) ?  await new InventoryService().rollItem(null, null, additionalFind) : [];

            result = sample([
                ...searchTier1Result,
                ...searchTier1Result,
                ...searchTier2Result,
                ...searchTier2Result,
                ...searchTier3Result,
                ...searchTier3Result,
                ...searchResultFromAllItems,
                ...searchResultFromAllItems,
                ...searchResultFromImplants,
                ...searchResultFromImplants,
                ...resultFromAdditionalSearch,
                sampleMates,
            ].filter((value) => !!value));
        }

        if (!result) { return; }
        const audio = new Audio(sound);
        audio.volume = 0.07;
        audio.play();
        setItem(result);
        let resultWord = result.name;
        const interval = setInterval(() => {
            let finalWord = '';
            for(var x=0; x < 24; x++) {
             if(x <= count && canChange) {
              finalWord += resultWord[x];
             } else {
              finalWord += getRandomLetter();
             }
            }
            setWord(finalWord);
            if(canChange) {
              count++;
            }
            if(globalCount >= 50) {
                canChange = true;
            }
            if(count >= resultWord.length) {
                audio && audio.pause();
                clearInterval(interval);
                setWord(resultWord);
                count = 0;
                globalCount = 0;
                canChange = false;
                setIsGenerate(false);
            }
            globalCount++;
        }, 100);
    }

    const sessionName = getCookie('sessionName');

    const renderList = () => {
        if (sessionName?.toLowerCase().indexOf('whopg') >= 0) {
            return (
              <>
                <div className={CSS.tierHeader}>Напарники</div>
                  <div className={CSS.tierRow}>
                      <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Напарники'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      />
                      <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Напарники'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      >Напарник 1 уровня</div>
                  </div>
                  <div className={CSS.tierRow}>
                      <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Напарники'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      />
                      <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Напарники'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      >Напарник 2 уровня</div>
                  </div>
                  <div className={CSS.tierRow}>
                      <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Напарники'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      />
                      <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Напарники'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                      >Напарник 3 уровня</div>
                  </div>
                <div className={CSS.tierHeader}>Дополнительно</div>
                <div className={CSS.tierRow}>
                  <div
                    className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isFindHit })}
                    onClick={() => {
                      setIsFindHit(prevState => !prevState);
                    }}
                  />
                  <div
                    className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isFindHit })}
                    onClick={() => {
                      setIsFindHit(prevState => !prevState);
                    }}
                  >
                    Ранение
                  </div>
                </div>
                <div className={CSS.tierRow}>
                  <div
                    className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: fromEquipedInventory })}
                    onClick={() => {
                      setFromEquipedInventory(prevState => !prevState);
                    }}
                  />
                  <div
                    className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: fromEquipedInventory })}
                    onClick={() => {
                      setFromEquipedInventory(prevState => !prevState);
                    }}
                  >
                    Надетые предметы в инвентаре
                  </div>
                </div>
                <div className={CSS.tierRow}>
                  <div
                    className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: fromInventory })}
                    onClick={() => {
                      setFromInventory(prevState => !prevState);
                    }}
                  />
                  <div
                    className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: fromInventory })}
                    onClick={() => {
                      setFromInventory(prevState => !prevState);
                    }}
                  >
                    Все предметы в инвентаре
                  </div>
                </div>
              </>
            )
        }
        return (
            <>
                <div className={CSS.clearData} onClick={clearData}>Удалить выбор</div>
                <div className={CSS.tierHeader}>Часть тела</div>
                <div className={CSS.tierAssist}>(Ноги, Оружие, Туловище, Голова)</div>
              <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier1Pull, PartOfChestPull) })}
                        onClick={() => setTier1Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier1Pull, PartOfChestPull) })}
                      onClick={() => setTier1Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    >
                      Часть тела 1 Уровня
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier2Pull, PartOfChestPull) })}
                        onClick={() => setTier2Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier2Pull, PartOfChestPull) })}
                      onClick={() => setTier2Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    >
                      Часть тела 2 Уровня
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier3Pull, PartOfChestPull) })}
                        onClick={() => setTier3Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier3Pull, PartOfChestPull) })}
                      onClick={() => setTier3Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : PartOfChestPull)}
                    >
                      Часть тела 3 Уровня
                    </div>
                </div>
                <div className={CSS.tierHeader}>Предмет</div>
                <div className={CSS.tierAssist}>(Ноги, Оружие, Туловище, Голова, Аксессуар)</div>
              <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier1Pull, itemsPull) })}
                        onClick={() => setTier1Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier1Pull, itemsPull) })}
                      onClick={() => setTier1Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    >
                      Предмет 1 Уровня
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier2Pull, itemsPull) })}
                        onClick={() => setTier2Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier2Pull, itemsPull) })}
                      onClick={() => setTier2Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    >
                      Предмет 2 Уровня
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isEqual(tier3Pull, itemsPull) })}
                        onClick={() => setTier3Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    />
                    <div
                      className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isEqual(tier3Pull, itemsPull) })}
                      onClick={() => setTier3Pull(prevState => Object.values(prevState).find((value) => value) ? defaultPull : itemsPull)}
                    >
                      Предмет 3 Уровня
                    </div>
                </div>

                <div className={CSS.tierHeader}>Уровень 1</div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Ноги'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Ноги'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    >Ноги</div>
                </div>
                <div className={CSS.tierRow}>

                <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Оружие'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Оружие'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    >Оружие</div>
                </div>
                     <div className={CSS.tierRow}>
                     <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Туловище'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Туловище'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    >Туловище</div>
                     </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Головные уборы'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Головные уборы'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    >Голова</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Аксессуары'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Аксессуары'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    >Аксессуар</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Напарники'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Напарники'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    >Напарник</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier1Pull['Импланты'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier1Pull['Импланты'] })}
                        onClick={() => setTier1Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    >Имплант</div>
                    </div>
                    <div className={CSS.tierHeader}>Уровень 2</div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Ноги'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Ноги'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    >Ноги</div>
                </div>
                <div className={CSS.tierRow}>

                <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Оружие'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Оружие'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    >Оружие</div>
                </div>
                     <div className={CSS.tierRow}>
                     <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Туловище'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Туловище'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    >Туловище</div>
                     </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Головные уборы'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Головные уборы'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    >Голова</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Аксессуары'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Аксессуары'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    >Аксессуар</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Напарники'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Напарники'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    >Напарник</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier2Pull['Импланты'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier2Pull['Импланты'] })}
                        onClick={() => setTier2Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    >Имплант</div>
                    </div>
                    <div className={CSS.tierHeader}>Уровень 3</div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Ноги'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Ноги'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Ноги': !prevState['Ноги'] }))}
                    >Ноги</div>
                </div>
                <div className={CSS.tierRow}>

                <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Оружие'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Оружие'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Оружие': !prevState['Оружие'] }))}
                    >Оружие</div>
                </div>
                     <div className={CSS.tierRow}>
                     <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Туловище'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Туловище'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Туловище': !prevState['Туловище'] }))}
                    >Туловище</div>
                     </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Головные уборы'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Головные уборы'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Головные уборы': !prevState['Головные уборы'] }))}
                    >Голова</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Аксессуары'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Аксессуары'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Аксессуары': !prevState['Аксессуары'] }))}
                    >Аксессуар</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Напарники'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Напарники'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Напарники': !prevState['Напарники'] }))}
                    >Напарник</div>
                    </div>
                    <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: tier3Pull['Импланты'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    ></div>
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: tier3Pull['Импланты'] })}
                        onClick={() => setTier3Pull(prevState => ({...prevState, 'Импланты': !prevState['Импланты'] }))}
                    >Имплант</div>
                    </div>
                <div className={CSS.tierHeader}>Дополнительно</div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isFindHit })}
                        onClick={() => {
                            setIsFindHit(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isFindHit })}
                        onClick={() => {
                            setIsFindHit(prevState => !prevState);
                        }}
                    >
                        Ранение
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: fromEquipedInventory })}
                        onClick={() => {
                            setFromEquipedInventory(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: fromEquipedInventory })}
                        onClick={() => {
                            setFromEquipedInventory(prevState => !prevState);
                        }}
                    >
                        Надетые предметы в инвентаре
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: fromInventory })}
                        onClick={() => {
                            setFromInventory(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: fromInventory })}
                        onClick={() => {
                            setFromInventory(prevState => !prevState);
                        }}
                    >
                        Все предметы в инвентаре
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: isCustomRoll })}
                        onClick={() => {
                            setItem({});
                            setWord('');
                            setIsFindHit(false);
                            setTier3Opened(false);
                            setTier2Opened(false);
                            setTier1Opened(false);
                            setFromInventory(false);
                            setFromEquipedInventory(false);
                            setTier1Pull(defaultPull);
                            setTier2Pull(defaultPull);
                            setTier3Pull(defaultPull);
                            setIsCustomRoll(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: isCustomRoll })}
                        onClick={() => {
                            setItem({});
                            setWord('');
                            setIsFindHit(false);
                            setTier3Opened(false);
                            setTier2Opened(false);
                            setTier1Opened(false);
                            setFromInventory(false);
                            setFromEquipedInventory(false);
                            setTier1Pull(defaultPull);
                            setTier2Pull(defaultPull);
                            setTier3Pull(defaultPull);
                            setIsCustomRoll(prevState => !prevState);
                        }}
                    >
                        Кастомный набор
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: findNotForSale })}
                        onClick={() => {
                            setFindNotForSale(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: findNotForSale })}
                        onClick={() => {
                            setFindNotForSale(prevState => !prevState);
                        }}
                    >
                        Не для продажи
                    </div>
                </div>
                <div className={CSS.tierRow}>
                    <div
                        className={classNames(CSS.tierBox, { [CSS.tierBoxSelected]: findTor })}
                        onClick={() => {
                            setFindTor(prevState => !prevState);
                        }}
                    />
                    <div
                        className={classNames(CSS.tierCard, { [CSS.tierCardSelected]: findTor })}
                        onClick={() => {
                            setFindTor(prevState => !prevState);
                        }}
                    >
                        ТОР
                    </div>
                </div>
            </>
        )
    };


    const matesService = useMemo(() => {
       return new allServices.MatesService();
    }, []);

    const updateMate = useCallback((mate) => {
        if (isEmpty(mate)) { return; }
        dispatch(allActions.matesActions.setCurrentMate(mate));
        matesService.getMessages(mate.id).then((messages) => {
            dispatch(allActions.matesActions.setCurrentMateMessages(messages));
        });
    }, [dispatch, matesService]);

    const openInfo = (mate) => {
        dispatch(allActions.matesActions.setCurrentMate(mate));
        dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.telepound.name, true));
      }

    function renderItem(item) {
        if (item.isHit) {
            return;
        }
        if (item.isCustom) {
            return;
        }
        if (item.isMate) {
         return (
           <div
             className={CSS.itemColumnBox}
             onClick={() => openInfo(item)}
             style={{
                 color: get(item, 'id') === item.id ? "#F2F2F2" : colorByTier(item.tier),
                 borderTop: `8px solid ${colorByTier(item.tier)}!important`,
                 border: `1px solid ${colorByTier(item.tier)}`,
                 padding: '8px',
             }}
           >
               <ItemIcon style={{ width: '300px', height: '300px' }} src={item.image} />
               <ItemName
                 color={colorByTier(item.tier)}
                 isSelected={anchorEl.id === item.id}
               >
                   {item.name}
               </ItemName>
               <button className={CSS.buyButton} onClick={() => addMate(item.id, null, updateMate)}>Добавить</button>
           </div>
         )
        }
        if (item.slotType) {
            return (
                <div className={CSS.itemColumnBox}>
                <ItemBox>
                    <ItemWrapper
                        color={colorByTier(item.tier)}
                        aria-describedby={item.id}
                        onClick={(e) => handlePopoverOpen(e, item.id)}
                        isSelected={anchorEl.id === item.id}
                    >
                        <ItemIcon src={item.image} />
                        <ItemName
                        color={colorByTier(item.tier)}
                        isSelected={anchorEl.id === item.id}
                        >
                        {item.name}
                        </ItemName>
                    </ItemWrapper>
                    <Popover
                            id={item.id}
                            className={CSSFirstBuy.popover}
                            open={anchorEl.id === item.id}
                            anchorEl={anchorEl.target}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                        <ItemPopover anchorEl={{ ...anchorEl, item }} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
                        </Popover>
                    </ItemBox>
                    {!fromInventory && !fromEquipedInventory && (
                            <button className={CSS.buyButton} onClick={() => buyItem(item.id)}>Добавить</button>
                        )}
                </div>
            )
        }
        return (
            <>
            </>
        )
    }

    return (
        <div className={CSS.wrapper}>
            <div className={CSS.menu}>
                {renderList()}
            </div>
            <div className={CSS.roll}>
                <div className={CSS.generatedWord}>
                {word}
                </div>
                {isCustomRoll && isEmpty(item) && !isGenerate && (
                    <div className={CSS.customBox}>
                        <div className={CSS.customList}>
                        {customList.map((customValue) => (
                            <div className={CSS.customRow}>
                                {customValue}
                                <div className={CSS.remove} onClick={() => setCustomList(prevState => prevState.filter(prevState => prevState !== customValue))}>Убрать</div>
                            </div>
                            ))}
                        </div>
                        <input className={CSS.customInput} value={newValue} onChange={e => setNewValue(e.target.value)} placeholder="Введи значение" />
                        <button className={CSS.customButton} onClick={() => {
                            setCustomList(prevState => [...prevState, newValue]);
                            setNewValue('');
                        }}>Добавить</button>
                    </div>
                )}
                {!isEmpty(item) && !isGenerate && (
                   <>
                    {renderItem(item)}
                   </>
                )}
                <button className={CSS.actionButton} onClick={generate}>Испытать удачу в Deep Net</button>
            </div>
        </div>
    )
}

export default ShopGenerator;
