const setPlayer = (playerObj) => {
  return {
    type: "SET_PLAYER",
    payload: playerObj,
  }
}

const changeCurrentTurn = (newTurn) => {
  return {
    type: "CHANGE_CURRENT_TURN",
    payload: newTurn,
  }
}

const changeMapState = (newState) => {
  return {
    type: "CHANGE_MAP_STATE",
    payload: newState,
  }
}

const changePlayerPosition = (q, r, s) => {
  return {
    type: "CHANGE_PLAYER_POSITION",
    payload: { q, r, s },
  }
}

const changePlayerLastPlace = (place) => {
  return {
    type: "CHANGE_LAST_PLACE",
    payload: place,
  }
}

const changePlayerCountOfSteps = (count) => {
  return {
    type: "CHANGE_COUNT_OF_STEPS",
    payload: count,
  }
}

const changeCellsCanGo = (cells) => {
  return {
    type: "CHANGE_CELLS_CAN_GO",
    payload: cells,
  }
}

const changeStats = (statName, type="increment") => {
  return {
    type: type === "increment" ? "INCREMENT_STAT" : "DECREMENT_STAT",
    payload: statName,
  }
}


export default {
  setPlayer,
  changeCurrentTurn,
  changeMapState,
  changePlayerPosition,
  changePlayerLastPlace,
  changePlayerCountOfSteps,
  changeCellsCanGo,
  changeStats,
}
