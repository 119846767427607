const changeDiceValue = (value) => {
  return {
    type: "CHANGE_DICE_VALUE",
    payload: value,
  }
}

export default {
  changeDiceValue,
}
