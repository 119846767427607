import React, { useState, useEffect } from "react";
import Popover from '@material-ui/core/Popover';
import {
  Box,
  Button, BuyButton,
  Description,
  ItemBox, ItemIcon, ItemName,
  ItemsBox, ItemWrapper,
  Level,
  Name,
  TextCloud,
  Wrapper
} from "./StartView";
import { colorByTier, declOfNum } from '../../../../utils/utils';
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import CSS from './StartView.module.scss';
import CSSInventory from '../../../Inventory/Inventory.module.scss';
import CSSFirstBuy from '../../../FirstBuy/FirstBuy.module.scss';
import PlayerItemsList from "../../../PlayerItemsList/PlayerItemsList";
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ItemPopover from '../../../Inventory/ItemPopover';

function ExchangeView(state, onBuy, onBuyAfterTrade, onEnd) {

  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);

  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const currentPlayer = useSelector(state => state.currentPlayer);
  const [rolledItems, setRolledItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentInTrade, setCurrentInTrade] = useState([]);
  const [lastView, setLastView] = useState(false);

  useEffect(() => {
    if (!currentPlayer.player) { return; }
    setCurrentItems(currentPlayer.player.inventory.slots.filter((slot) => slot.item).map(({ item }) => item));
}, [currentPlayer.player]);

  const handleAdd = (item) => {
    setCurrentItems(prevState => prevState.filter((prevItem) => prevItem.uniqueID !== item.uniqueID));
    setCurrentInTrade(prevState => [...prevState, item]);
  }

  const handleBack = (item) => {
    setCurrentItems(prevState => [...prevState, item]);
    setCurrentInTrade(prevState => prevState.filter((prevItem) => prevItem.uniqueID !== item.uniqueID));
  }

  const checkActive = (offer) => {
    const result = offer.map((each) => {
        const findedItems = currentInTrade.filter((item) => item.tier.toString() === each.tier.toString());
        if (!findedItems) { return false; }
        return findedItems.length >= each.count;
    });
    return result.every((value) => !!value);
  }

  const handleLastView = (data) => {
    if (Array.isArray(data)) {
      setRolledItems(data);
    } else {
      setRolledItems({ isBackpackSlot: true, text: 'Получен слот рюкзака' });
    }
    setLastView(true);
  }

  const handleBuyAfterTrade = (item) => {
    setRolledItems(prevState => prevState.filter((prevItem) => prevItem.id !== item.id));
    onBuyAfterTrade(state.name, item);
  }

  useEffect(() => {
    if (lastView && isEmpty(rolledItems)) {
      onEnd();
    }
  }, [lastView, rolledItems]);

  const renderByType = () => {
    if (get(rolledItems, 'isBackpackSlot', false)) {
      return (
        <div className={CSS.title}>{rolledItems.text}</div>
      )
    } else {
      return (
        rolledItems.map((item) => (
          <ItemBox>
            <ItemWrapper
              color={colorByTier(item.tier)}
              aria-describedby={item.id}
              onClick={(e) => handlePopoverOpen(e, item.id)}
              isSelected={get(currentSelectedItem, 'name', '') === item.name}
            >
              <ItemIcon src={item.image} />
              <ItemName
                color={colorByTier(item.tier)}
                isSelected={get(currentSelectedItem, 'name', '') === item.name}
              >
                {item.name}
              </ItemName>
            </ItemWrapper>
            <div className={CSS.buyButton}  onClick={() => handleBuyAfterTrade(item)}>Выбрать</div>
            <Popover
                  id={item.id}
                  className={CSSFirstBuy.popover}
                  open={anchorEl.id === item.id}
                  anchorEl={anchorEl.target}
                  anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                  }}
                  transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
              >
                  <ItemPopover anchorEl={{ ...anchorEl, item }} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
              </Popover>
          </ItemBox>
        ))
      )
    }
  }

  return (
    <Wrapper style={{ width: '1600px' }}>
      <Box>
        <img alt="trader" style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
        {lastView ? get(state, 'messages.end', '') : get(state, 'messages.start', '')}
        </TextCloud>
        {lastView ? (
          (
            <ItemsBox>
                {renderByType()}
              </ItemsBox>
          )
        ) : (
          <div className={CSS.columnScrollable}> 
            <div className={CSS.row}>
            <div className={CSS.column}> 
              <div className={CSS.title}>Выбери предметы на обмен</div>
            <PlayerItemsList items={currentItems} state={state} callback={handleAdd} />
            </div>
            <div className={CSS.column}> 
            <div className={CSS.title}>Выставленны на обмен</div>
            <PlayerItemsList items={currentInTrade} state={state} callback={handleBack} />
            </div>
            </div>
            <div>
            {!isEmpty(state.items) && state.items.map((offers, index) => (
              <div className={CSS.offerCard}>
              <div>
              <div className={CSS.title}>Предложение {index + 1}:</div>
              {offers.for.map((offer, index) => (
                <div key={index} className={CSS.offersWrapper}>
                <div className={CSS.offerOffset}>
                  <div
                        onClick={() => checkActive(offer) && onBuy(state.name, offers.get, currentInTrade.map((item) => item.id), handleLastView)}
                        className={classNames(CSS.buttonDisabled, { [CSS.buttonGood]: checkActive(offer) })}
                        style={{ width: '400px', height: 'auto' }}
                      >
                        {offer.map((each, index) => (
                            <span>{offer.length > 1 && offer.length - 1 === index ? 'и' : 'Отдать'} {each.count} {declOfNum(each.count, ['предмет', 'предмета', 'предметов'])} {each.tier} уровня<br/></span>
                        ))}
                        </div>
                  <div>
                  </div>
                  </div>
                </div>
              ))}
              </div>
              {
                has(offers, 'get.slot') ? (
                  <div className={CSS.result}>Вы получите {offers.get.count} {declOfNum(offers.get.count, ['ячейку', 'ячейки', 'ячеек'])} для "{offers.get.slot}"</div>
                ) : (
                  <div className={CSS.result}>Вы получите {offers.get.count} {declOfNum(offers.get.count, ['предмет', 'предмета', 'предметов'])} {offers.get.tier} уровня</div>
                )
              }
            </div>
          ))}</div>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default ExchangeView;
