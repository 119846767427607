import React, { useState, useEffect } from "react";
import { Provider as AlertProvider } from 'react-alert';
import MainScreen from './pages/MainScreen/MainScreen';
import { useSelector, useDispatch } from 'react-redux';
import allServices from './api/index';
import allActions from './state/actions/index';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { connectToSocket, subscribeToPlayerData } from "./api/socket";
import { MAP_PAINT_TYPE, TURN_STATES } from './consts/map';
import { AlertTemplate, ALERT_OPTIONS } from './utils/Alert/Alert';
import CSS from './App.module.scss';
import useLocalStorage from './hooks/useLocalStorage';
import takemuraPNG from './assets/character/takemura.png';
import blockImage from './assets/styled-blocks/blockLogin.png';
import { getCookie, setCookie } from './utils/utils';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const CURRENT_VERSION = '1.5.0';

function App() {

  const currentUser = useSelector(state => state.currentUser);
  const appState = useSelector(state => state.appState);
  const widgets = useSelector(state => state.widgets);
  const dispatch = useDispatch();
  const userService = new allServices.UserService();
  const matesService = new allServices.MatesService();
  const mapService = new allServices.MapService();
  const [hasConnectToSocket, setHasConnectToSocket] = useState(true);
  const [version, setVersion] = useLocalStorage('version');

  useEffect(() => {
    if (!version) {
      setVersion(CURRENT_VERSION);
    }
    if (version !== CURRENT_VERSION) {
      setVersion(CURRENT_VERSION);
    }
  }, [setVersion, version]);

  const setPlayerData = (data) => {
    dispatch(allActions.playerActions.setPlayer(data));
    dispatch(allActions.mapActions.setMapState({
      turnState: data.currentTurnState || TURN_STATES.IDLE,
      paintType: MAP_PAINT_TYPE.CAPTURED,
    }));
  }

  function successCb() {
    setHasConnectToSocket(true);
    fetchMapData();
  }

  function errorCb() {
    setHasConnectToSocket(false);
  }

  const fetchUser = async () => {
    userService.retrieveUser().then((data) => {
      if (get(data, "message", "").includes("No Game Sessions Found for This User") || !data) {
        dispatch(allActions.userActions.setAnonymous());
      }
      if (get(data, "message", "").includes("ERROR")) {
        return;
      }
      if (data) {
        dispatch(allActions.userActions.setUser(data));
        Notification.requestPermission().then(function(result) {
          console.log(result);
        });
        if (['player', 'deputy'].includes(get(data, 'type', ''))) {
          connectToSocket(successCb, errorCb);
          subscribeToPlayerData(setPlayerData);
        }
      }
    });
  }

  const fetchMates = async () => {
    await matesService.getMates().then((mates) => {
      dispatch(allActions.matesActions.setMates(mates));
    });
  }

  const fetchMapData = async () => {
    await mapService.retrieveMapData().then((mapData) => {
      dispatch(allActions.mapActions.setMapData(mapData));
    });
  }

  useEffect(() => {
    if (!getCookie('sessionID')) {
      setCookie('sessionID', 15);
    }
    if(isEmpty(widgets)) {
      dispatch(allActions.widgetsActions.initWidgets());
    }
    dispatch(allActions.appActions.changeIsLoading(true));
    // fetch map data if user spectator
    Promise.all([fetchMates(), fetchMapData(), fetchUser()])
      .then(() => {
        dispatch(allActions.appActions.changeIsLoading(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginButton = async () => {
    window.location.href = "https://hpg.su/api/login/steam";
  }

  if (appState.isLoading) return (
    <div className={CSS.loading}>
      Попытка подключения...
    </div>
  )

  return (
    <AlertProvider template={AlertTemplate} {...ALERT_OPTIONS}>
      <MainScreen />
      {currentUser.isAnonymous && (
        <div className={CSS.loginBox}>
          <img alt="Присоединится к твич СИТИ? aRofl" src={blockImage} />
        </div>
      )}
      {!currentUser.loggedIn && !currentUser.isAnonymous && (
        <div className={CSS.loginBox}>
          Присоединиться к Twitch City?
          <button onClick={handleLoginButton}>Да</button>
        </div>
      )}
      {!hasConnectToSocket && get(currentUser, 'user.type', '') === 'player' && (
          <div className={CSS.error}>
            <img className={CSS.takemura} alt="Мэлярукош" src={takemuraPNG} />
            <div>ВХОДЯЩИЙ ВЫЗОВ</div>
            <div className={CSS.textBorder}>У МЕНЯ ВЗОРВАЛСЯ ТАНК</div>
            <div className={CSS.errorButton}>ОТВЕТИТЬ</div>
          </div>
      )}
    </AlertProvider>
  );
}

export default App;
