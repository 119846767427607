import React from "react";
import get from "lodash/get";
import Fade from "@material-ui/core/Fade";
import Draggable from '../../hoc/DragComponent/DragComponent';
import allWidgets from './../../widgets/index';
import CSS from './WidgetZone.module.scss';

const WidgetZone = ({ widgets }) => (
  <div className={CSS.wrapper}>
    {Object.entries(allWidgets).map(([key, value]) => {
      const isVisible = get(widgets[key], 'isOpened', false);
      const weight = get(widgets[key], 'weight', 100);
      const image = get(value, 'image', null);
      return (
        <Fade key={key} in={isVisible}>
            <div>
                <Draggable
                    type="widget"
                    widgets={widgets}
                    isVisible={isVisible}
                    widget={value}
                    weight={weight}
                    image={image}
                    key={key}
                >
                    {value.component}
                </Draggable>
            </div>
        </Fade>
      )
    })}
  </div>
)

export default WidgetZone;
