const inventory = (state = {}, action) => {
  switch(action.type){
    case "SET_INVENTORY_LOCKED":
      return {
        ...state,
        isLocked: action.payload,
      }
    default:
      return state
  }
}


export default inventory;
