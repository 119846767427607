const general = (state = {}, action) => {
    switch(action.type){
      case "CHANGE_SELECTED_PLAYER":
        return {
          ...state,
          currentInfo: action.payload,
        }
      default:
        return state
    }
  }
  
  export default general;
  