import HeroIcon from "../HeroIcon/HeroIcon";
import React from "react";
import CSS from '../Locator.module.scss';

const Characters = ({ characters, handleCharacterClick, onChangeLevel, mapState, currentPlayer }) => (
  <>
    <div className={CSS.charactersTitle}>Персонажи</div>
    <div className={CSS.charactersWrapper}>
      {characters.map(character=> (
        <HeroIcon
          character={character}
          mapState={mapState}
          callback={handleCharacterClick}
          onChangeLevel={onChangeLevel}
          currentPlayer={currentPlayer}
        />
      ))}
    </div>
  </>
);

export default Characters;
