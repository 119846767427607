import io from 'socket.io-client';
import { getCookie } from "../utils/utils";
let socket;

function connectToSocket(successCb, errorCb) {
  const steamToken = getCookie("steamToken");
  if (!steamToken) { return; }
  socket = io('https://hrpg.leinstay.oitc.ch:2020/',
    {query: `steamToken=${steamToken}`, rememberTransport: false, reconnection: true, 'reconnection delay': 500, 'max reconnection attempts': 10, 'secure': true, transports: ['websocket']}
  );
  socket.on('connect', () => {
    successCb();
  });
  socket.on('reconnect_attempt', () => {
    errorCb();
  });
  socket.on('disconnect',  (reason) => console.log(reason));
}

function subscribeToPlayerData(cb) {
  if (!socket) { return; }
  socket.on('playerData', (data) => {
    cb(data);
  });
}

function subscribeToPlayersMovements(cb) {
  if (!socket) { return; }
  socket.on('player moved to', cb);
}

function subscribeToPlayersCaptured(cb) {
  if (!socket) { return; }
  socket.on('player captured cell', cb);
}

function subscribeToPlayersLostCells(cb) {
  if (!socket) { return; }
  socket.on('player lost cell', cb);
}

function subscribeToPlayersExplored(cb) {
  if (!socket) { return; }
  socket.on('player explored cell', cb);
}

function subscribeToPlayersState(cb) {
  if (!socket) { return; }
  socket.on('player updated state', cb);
}

function unsubscribeToPlayersState() {
  if (!socket) { return; }
  socket.removeListener('player updated state');
}

function subscribeToMateMessages(cb) {
  if (!socket) { return; }
  socket.on('mate update messages', cb);
}

function subscribeToSabotageMessages(cb) {
  if (!socket) { return; }
  socket.on('sabotage', cb);
  socket.emit('check notifications');
}

function subscribeToCustomMessages(cb) {
  if (!socket) { return; }
  socket.on('send notification', cb);
}

function unsubscribeToCustomMessages() {
  if (!socket) { return; }
  socket.removeListener('send notification');
}

function unsubscribeToAlerts() {
  if (!socket) { return; }
  socket.removeListener('custom alert');
}

function subscribeToAlerts(cb) {
  if (!socket) { return; }
  socket.on('custom alert', cb);
}

function unsubscribeToMateMessages() {
  if (!socket) { return; }
  socket.removeListener('mate update messages');
}

function unsubscribeToSabotageMessages() {
  if (!socket) { return; }
  socket.removeListener('sabotage');
}

function unsubscribeMapActions() {
  if (!socket) { return; }
  socket.removeListener('player moved to');
  socket.removeListener('player captured cell');
  socket.removeListener('player explored cell');
  socket.removeListener('player updated state');
  socket.removeListener('player lost cell');
}

function changeMapState(newState) {
  if (!socket) { return; }
  socket.emit('set map state', newState);
}

function playerMove(cellID) {
  if (!socket) { return; }
  socket.emit('player move to cell', cellID);
}

function playerOpen(cellID) {
  if (!socket) { return; }
  socket.emit('player open cell', cellID);
}

function playerCompleteQuest(questID) {
  if (!socket) { return; }
  socket.emit('complete quest', { questID });
}

function playerComplete(review) {
  if (!socket) { return; }
  socket.emit('player complete game', { review });
}

function playerDrop(review) {
  if (!socket) { return; }
  socket.emit('player drop game', { review });
}

function changePlayerCharacteristic(characteristic, type = "increased") {
  if (!socket) { return; }
  socket.emit('change player characteristic', {
    characteristic,
    type,
  });
}

function changePlayerExperience(type = "increased", count) {
  if (!socket) { return; }
  socket.emit('change player experience', count ? count : type);
}

function changeSlotPosition(slotID, coordinates) {
  if (!socket) { return; }
  socket.emit('change slot coordinate', {
    slotID,
    coordinates, // { x: 1, y: 2 }
  });
}

function removeItem(slotID) {
  if (!socket) { return; }
  socket.emit('remove item', { slotID });
}

function removeEffect(effectID) {
  if (!socket) { return; }
  socket.emit('remove effect', { effectID });
}

function addItem(slotID, itemID) {
  if (!socket) { return; }
  socket.emit('add item', { slotID, itemID });
}

function addAugmentItem(slotID, augmentSlotID, itemID) {
  if (!socket) { return; }
  socket.emit('add augment item', { slotID, augmentSlotID, itemID });
}

function removeAugmentItem(slotID, augmentSlotID) {
  if (!socket) { return; }
  socket.emit('remove augment item', { slotID, augmentSlotID });
}

function equipToAnoterSlot(toSlotID, fromSlotID, toAugmentSlotID, fromAugmentSlotID) {
  if (!socket) { return; }
  socket.emit('equip to another slot', { fromSlotID, toSlotID, toAugmentSlotID, fromAugmentSlotID });
}

function addMate(mateID, slotID=null, cb) {
  if (!socket) { return; }
  socket.emit('add mate', { mateID, slotID }, cb);
}

function removeMate(slotID) {
  if (!socket) { return; }
  socket.emit('remove mate', { slotID });
}

function sendMessage(mateID, message) {
  if (!socket) { return; }
  socket.emit('send message to mate', { mateID, message });
}

function changeSlotNumber(slotName, type="add") {
  if (!socket) { return; }
  socket.emit('change slots number', { slotName, type });
}

function changeWound(slotID, type="none") {
  if (!socket) { return; }
  socket.emit('change slot wound', { slotID, type });
}

function activateCharacter(characterName, callback) {
  if (!socket) { return; }
  socket.emit('use character', characterName, callback);
}

function triggerCharacter(characterName, level, callback) {
  if (!socket) { return; }
  socket.emit('trigger character', characterName, level, callback);
}

function buyItem(characterName, itemID) {
  if (!socket) { return; }
  const cb = (data) => console.log(data);
  socket.emit('character interaction', { characterName, itemID }, cb);
}

function recruitMate(characterName, mateID) {
  if (!socket) { return; }
  const cb = (data) => console.log(data);
  socket.emit('character interaction', { characterName, mateID }, cb);
}

function saboteurEnemy(characterName, playerID, itemID) {
  if (!socket) { return; }
  const cb = (data) => console.log(data);
  socket.emit('character interaction', { characterName, itemID, playerID }, cb);
}

function buyItemFromTrade(characterName, get, items, cb) {
  if (!socket) { return; }
  socket.emit('character interaction', { characterName, get, for: items }, cb);
}

function getRandomEncounters(cb) {
  if (!socket) { return; }
  socket.emit('get random encounters', cb);
}

function  getEncountersList(cb) {
  if (!socket) { return; }
  socket.emit('get encounters list', cb);
}

function  getEncounterData(encounterID, cb) {
  if (!socket) { return; }
  socket.emit('get encounter data', encounterID, cb);
}

function getRandomFailures(cb) {
  if (!socket) { return; }
  socket.emit('get wheel of failures', cb);
}

function getPilotData(cb) {
  if (!socket) { return; }
  socket.emit('get pilot data', cb);
}

function encounterInteraction(encounterID, variantID) {
  if (!socket) { return; }
  socket.emit('encounter interaction', { encounterID, variantID });
}

function changePlayerPoints(pointsName, pointsType, valueType, value) {
  if (!socket) { return; }
  socket.emit('change player points', { pointsName, pointsType, valueType, value });
}

function changeCharacterLevel(valueType, characterName, cb) {
  if (!socket) { return; }
  socket.emit('change character level', { valueType, characterName }, cb);
}

function changeMatesSlotsNumber(type="add") {
  if (!socket) { return; }
  socket.emit('change mates slots number', { type });
}

function joinToCongress() {
  if (!socket) { return; }
  socket.emit('join to congress');
}

function leaveFromCongress() {
  if (!socket) { return; }
  socket.emit('leave from congress');
}

function nominateAct(id) {
  if (!socket) { return; }
  socket.emit('nominate act', id);
}

function readyInCongress() {
  if (!socket) { return; }
  socket.emit('ready');
}

function rejectAct() {
  if (!socket) { return; }
  socket.emit('reject act');
}

function passedAct() {
  if (!socket) { return; }
  socket.emit('pass act');
}

function voteInCongress(actID, voteType, count) {
  if (!socket) { return; }
  socket.emit('vote', { actID, voteType, count });
}

function subscribeToCongressData(cb) {
  if (!socket) { return; }
  socket.on('congressData', cb);
}

function subscribeToCongressTimer(cb) {
  if (!socket) { return; }
  socket.on('congress vote timer', cb);
}

function playerUncaptureCell(cellID, playerID) {
  if (!socket) { return; }
  let sendData = { cellID }
  if (playerID) {
    sendData = { ...sendData, playerID }
  }
  socket.emit('player uncapture cell', sendData);
}

function getAllPlayersState(cb) {
  if (!socket) { return; }
  socket.emit('get all players state', cb);
}

function changeItemCharges({ slotID, augmentSlotID, valueType }) {
  if (!socket) { return; }
  socket.emit('change item charges', { slotID, augmentSlotID, valueType });
}

function getCustomWidgetInfo(cb) {
  if (!socket) { return; }
  socket.on('custom widget', cb);
}

function removeCustomWidgetInfo(cb) {
  if (!socket) { return; }
  socket.removeListener('custom widget', cb);
}

function sendCustomWidgetData({ id, data }) {
  if (!socket) { return; }
  socket.emit(`custom widget${id}`, data);
}

function handleUseItem(slotID, augmentSlotID) {
  if (!socket) { return; }
  socket.emit('use item', { slotID, augmentSlotID });
}

function handleChangeCharacteristic(slotID, augmentSlotID) {
  if (!socket) { return; }
  socket.emit('change item characteristics', { slotID, augmentSlotID });
}

function sendWhellOfFailuresWinner(winnerID, handleResult) {
  if (!socket) { return; }
  socket.emit('wheel of failures winner', { winnerID }, handleResult);
}

function playerHeal(type, count) {
  if (!socket) { return; }
  socket.emit('heal wound', { type, count });
}

export {
  playerHeal,
  removeCustomWidgetInfo,
  sendWhellOfFailuresWinner,
  handleChangeCharacteristic,
  handleUseItem,
  getCustomWidgetInfo,
  sendCustomWidgetData,
  unsubscribeToPlayersState,
  changeItemCharges,
  getAllPlayersState,
  playerUncaptureCell,
  subscribeToCongressTimer,
  nominateAct,
  joinToCongress,
  subscribeToCongressData,
  readyInCongress,
  voteInCongress,
  changeCharacterLevel,
  connectToSocket,
  subscribeToPlayerData,
  subscribeToPlayersMovements,
  subscribeToPlayersCaptured,
  subscribeToPlayersExplored,
  subscribeToPlayersState,
  subscribeToMateMessages,
  changeMapState,
  changePlayerCharacteristic,
  changeSlotPosition,
  removeItem,
  addItem,
  addAugmentItem,
  removeAugmentItem,
  sendMessage,
  addMate,
  removeMate,
  unsubscribeToMateMessages,
  playerMove,
  playerComplete,
  playerDrop,
  playerOpen,
  changeSlotNumber,
  unsubscribeMapActions,
  activateCharacter,
  buyItem,
  recruitMate,
  saboteurEnemy,
  subscribeToSabotageMessages,
  unsubscribeToSabotageMessages,
  getRandomEncounters,
  getPilotData,
  equipToAnoterSlot,
  encounterInteraction,
  changePlayerPoints,
  changePlayerExperience,
  changeMatesSlotsNumber,
  changeWound,
  buyItemFromTrade,
  subscribeToPlayersLostCells,
  triggerCharacter,
  leaveFromCongress,
  rejectAct,
  passedAct,
  getRandomFailures,
  removeEffect,
  subscribeToAlerts,
  unsubscribeToAlerts,
  subscribeToCustomMessages,
  unsubscribeToCustomMessages,
  getEncountersList,
  getEncounterData,
  playerCompleteQuest,
}
