import get from "lodash/get";
import allWidgets from "../../widgets/index";

const widgets = (state = {}, action) => {
  switch(action.type){
    case "INIT_WIDGETS":
      return loadWidgets(state);
    case "CHANGE_POSITION":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          x: action.payload.position.x,
          y: action.payload.position.y,
        }
      };
    case "CHANGE_IS_OPENED":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          isOpened: action.payload.isOpened,
          weight: action.payload.isOpened ? Math.max(...Object.values(state).map((value) => parseInt(value.weight || 100, 10))) + 2 : 100,
        }
      };
    case "CHANGE_IS_DRAGGABLE":
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          isDraggable: action.payload.isDraggable,
        }
      };
    default:
      return state
  }
}

const loadWidgets = (state) => {
  const newWidgets = {};
  Object.entries(allWidgets).forEach(([key, value]) => {
    newWidgets[key] = {
      'weight': get(state[key], 'weight', value.weight),
      'isDraggable': get(state[key], 'draggable', value.isDraggable),
      'isOpened': get(state[key], 'isOpened', false),
      'x': get(widgets[key], 'x', value.initialPos.x),
      'y': get(widgets[key], 'y', value.initialPos.y),
    }
  });
  return newWidgets;
}

export default widgets;
