const switcherState = {
    encounter: {
        opened: false,
        hasData: false,
        data: {},
    },
    dialog: {
        opened: false,
        hasData: false,
        data: {},
    },
}

const dialogSwitcher = (state = switcherState, action) => {
    switch(action.type){
      case "CHANGE_DIALOG_OPENED":
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            opened: action.payload.value,
            },
        }
      case "CHANGE_DIALOG_HAS_DATA":
            return {
              ...state,
              [action.payload.name]: {
                  ...state[action.payload.name],
                  hasData: action.payload.value,
              },
        }
      case "SET_CURRENT_DIALOG_INFO":
          return {
            ...state,
            [action.payload.name]: {
                ...state[action.payload.name],
                data: action.payload.data,
            },
      }
      default:
        return state
    }
  }
  
  export default dialogSwitcher;
  