class Configuration {
  LOGIN = "/login/steam";
  USER_URL = "/user_data";
  PLAYER_URL = "/player";
  MAP_URL = "/map";
  SECTOR_INFO = "/sector";
  MATES = "/mates";
  MESSENGER = "/messenger";
  ITEMS = "/inventory/items";
  ITEM_TYPES = "/inventory/slot_types";
  CHARACTERS = "/characters";
  INFO = "/info";
  LOGS = "/logs";
  TROPHIES = "/trophies";
  SOCIAL_GROUPS = "/social_groups";
  RULES = "/rules";
  RULES = "/session_list";
}

export default Configuration;
