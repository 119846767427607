export const DIFFICULTY_HEX_COLORS = {
    99: 0x92140C,
    5: 0xFE4A49,
    4: 0xEC7505,
    3: 0xFDCA40,
    2: 0x0A8754,
    1: 0xFFCF99,
    0: 0xDBBEA1,
  }
  
  export const DIFFICULTY_COLORS = {
    99: '#92140C',
    5: '#FE4A49',
    4: '#EC7505',
    3: '#FDCA40',
    2: '#0A8754',
    1: '#FFCF99',
    0: '#DBBEA1',
  }
  
  export const DEFAULT_CLOSE_TILE_COLOR = 0x27292d;
  export const DEFAULT_OPEN_TILE_COLOR = 0x8f8f8f;
  
  export const MAP_PAINT_TYPE = {
    DIFFICULTY: 'difficulty',
    CAPTURED: 'captured',
  }
  
  export const TURN_STATES = {
    IDLE: 'idle',
    MOVE: 'move',
    PLAY: 'play',
    SHOP: 'shop',
    START: 'start',
  }
  
  export const MAP_STATES = {
    IDLE: 'idle',
    PREPARE_OPEN: 'prepare open',
    PREPARE_EXPLORE: 'prepare explore',
    PREPARE_MOVE: 'prepare move',
    PREPARE_SEND: 'prepare send capture',
    OPEN: 'open',
    EXPLORE: 'explore',
    DROP: 'drop',
  }
