import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class SectorService {

  constructor() {
    this.config = new Configuration();
    this.sectorInfo = {};
  }

  async getSectorInfo(id) {
    return API.get(this.config.SECTOR_INFO, {
      params: {
        'cellID': id,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.sectorInfo = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default SectorService;
