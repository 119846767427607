import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CSS from './MobileScreen.module.scss';
import DataService from './../../api/DataService';
import InventoryService from './../../api/InventoryService';
import MatesService from './../../api/MatesService';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import takemuraPng from '../../assets/images/takemura.png';
import { colorByTier } from "../../utils/utils";
import parse from 'react-html-parser';
import { useSelector } from 'react-redux';
import Map3D from './../../widgets/Map3D/Map3D';
import moment from 'moment';
import InventorySlot, { smallSlotPack } from './../../widgets/Inventory/InventorySlot/InventorySlot';

const cardsNames = {
    'trophies': 'Трофеи',
    'items': 'Предметы',
    'mates': 'Напарники',
    'social': 'Соц. Группы',
    'map': 'Карта',
    'rules': 'Правила',
}

const useStyles = makeStyles({
    button: {
        background: '#121212',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 2px 2px 1px rgba(18, 18, 18, 0.5)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        background: '#151719',
        color: '#f2f2f2',
    },
    image: {
        height: '80px',
        width: 'auto',
    },
    trophieBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
        padding: '8px',
        background: '#121212',
    },
    trophieName: {
        fontSize: '24px',
    },
    trophiePlayerName: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        textAlign: 'center',
        fontSize: '18px',
        textShadow: '2px 2px #121212',
    },
    trophieDescription: {
        fontSize: '16px',
        textAlign: 'center',
    },
    trophieBoxSmall: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
        padding: '8px',
        background: '#1C1E20',
    },
    trophieLeaderboardName: {
        fontSize: '24px',
        textAlign: 'center',
        margin: '8px 0',
    },
    trophieNumber: {
        fontFamily: 'Play',
        fontSize: '24px',
    },
    input: {
        input: {
            color: '#f2f2f2!important',
        },



    }
  });


  const bigSizeTypes = ["Туловище", "Оружие", "Ноги"];

function MobileScreen() {

    const classes = useStyles();
    const mapState = useSelector(state => state.mapState);
    const difficultiesColorList = get(mapState, "content.colors.difficulties", {});
    const dataService = useMemo(() => new DataService(), []);
    const inventoryService = useMemo(() => new InventoryService(), []);
    const matesService = useMemo(() => new MatesService(), []);
    const [currentOpenCard, setCurrentOpenCard] = useState(null);
    const [currentTrophie, setCurrentTrophie] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentMate, setCurrentMate] = useState(null);
    const [currentSocial, setCurrentSocial] = useState(null);
    const [currentGlobalCard, setCurrentGlobalCard] = useState(null);
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [openedReview, setOpenedReview] = useState(null);
    const [allInfo, setAllInfo] = useState({});
    const [searchItemString, setSearchItemString] = useState('');
    const [searchMateString, setSearchMateString] = useState('');

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const renderAugment = useCallback((slot) => {
        if (!slot.augments) { return; }
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {
              Array(slot.augments.maxCount)
                .fill(null)
                .map((_, i) => i)
                .map(augmentId => {
                  if (!isEmpty(slot.augments.items[augmentId])) {
                    return (
                    <div
                        style={{ position: "relative", width: "36px"}}
                        onClick={() => {}}
                      >
                        <img alt="contain" width="100%" src={smallSlotPack.contain} />
                        <img
                          alt="item"
                          style={{
                            position: "absolute",
                            width: !bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                              && "36px",
                            height: bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                              && "36px",
                            margin: "auto",
                            left: 0,
                            right: 0,
                          }}
                          src={slot.augments.items[augmentId].image}
                        />
                        {slot.augments.items[augmentId].charges !== 'none' && <div className={CSS.augmentChargesRow}>{slot.augments.items[augmentId].charges}</div>}
                      </div>
                    )
                  }
                  return (
                    <img alt="empty" width="36px" style={{
                        boxSizing: 'border-box',
                      }} src={smallSlotPack.empty} />
                  )
                })
            }
          </div>
        )
      }, []);
    
      const renderHead = useCallback((headSlot) => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Голова</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <InventorySlot
                  onItemClick={() => {}}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                  slot={headSlot}
                />
                {has(headSlot, 'augments') && renderAugment(headSlot)}
              </div>
        </div>
      ), [renderAugment]);
    
      const renderBody = useCallback((bodySlot) => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Тело</div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <InventorySlot
                  onItemClick={() => {}}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                  slot={bodySlot}
                />
                {has(bodySlot, 'augments') && renderAugment(bodySlot)}
              </div>
        </div>
      ), [renderAugment]);
    
      const renderLegs = useCallback((legsSlot) => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Ноги</div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <InventorySlot
                  onItemClick={() => {}}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                  slot={legsSlot}
                />
                {has(legsSlot, 'augments') && renderAugment(legsSlot)}
              </div>
        </div>
      ), [renderAugment]);
    
      const renderWeapon = useCallback((weaponSlots) => (
        <div className={CSS.weaponWrapper}>
          <div className={CSS.slotTitle}>Оружие</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {weaponSlots.map((weapon, index) => (
                  <InventorySlot
                    key={index}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                    onItemClick={() => {}}
                    slot={weapon}
                  />
                ))}
                {!isEmpty(weaponSlots) && weaponSlots[0].augments && renderAugment(weaponSlots[0])}
              </div>
        </div>
      ), [renderAugment]);

    const renderImplants = useCallback((implantsSlots) => (
        <div>
          <div className={CSS.slotTitle}>
            Импланты
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {implantsSlots.map((implant, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                onItemClick={() => {}} 
                slot={implant}
              />
            ))}
            {!isEmpty(implantsSlots) && implantsSlots[0].augments && renderAugment(implantsSlots[0])}
          </div>
        </div>
  ), [renderAugment]);

  const renderAccessories = useCallback((accessoriesSlots) => (
    <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={CSS.slotTitle}>
              Аксессуары
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {accessoriesSlots.map((accessories, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                onItemClick={() => {}}
                slot={accessories}
              />
            ))}
          </div>
        </div>
  ), []);

  const renderBackpack = useCallback((bagSlots) => (
    <div>
          <div className={CSS.slotTitle}>
            Рюкзак
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {bagSlots.map((bag, index) => (
              <InventorySlot
                key={index}
                onItemClick={() => {}}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                slot={bag}
              />
            ))}
          </div>
        </div>
  ), []);

  const renderCurrentMates = useCallback((maxCount, mates) => (
    <div style={{ height: "225px", display: 'flex', flexDirection: 'row' }}>
        {Array(Math.max(maxCount - mates.length, 0)).fill(null).map((d, index) => (
          <div key={index} style={{ marginRight: '8px' }}>
            <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column', lineHeight: '160px', borderRadius: '6px' }}>
              Свободно
            </div>
          </div>
        ))}
        {mates.map((mate, index) => (
            has(mate, 'image') ? (
                <div key={mate.id} style={{ marginRight: '8px' }}>
                  <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img className={CSS.icon} alt="mate" src={mate.image} />
                    <div className={CSS.title} style={{ color: colorByTier(mate.tier) }}>{mate.name}</div>
                  </div>
                </div>
            ) : (
              <div key={index} style={{ marginRight: '8px' }}>
                <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column', lineHeight: '160px', borderRadius: '6px' }}>
                  Свободно
                </div>
              </div>
            )
        ))}
    </div>
), []);

    const renderTrophies = () => {
        return (
            <Paper className={classes.paper}>
                    {allInfo.trophies.map((trophie) => (
                        <>
                            <div className={classes.trophieBox} onClick={() => setCurrentTrophie((prevState) => prevState === trophie ? null : trophie)}>
                                <div className={classes.trophieName}>{trophie.name}</div>
                                {trophie.completeBy ? (
                                    <div style={{ position: 'relative' }}>
                                        <img className={classes.image} alt="hero" src={allInfo.data.find((player)=>player.name === trophie.completeBy).image} />
                                        <div className={classes.trophiePlayerName}>{trophie.completeBy}</div>
                                    </div>
                                ) : (
                                    <div style={{ position: 'relative' }}>
                                        <img className={classes.image} alt="hero" src={takemuraPng} />
                                        <div className={classes.trophiePlayerName}>Такэмура</div>
                                    </div>
                                )}
                            </div>
                            <Collapse in={get(currentTrophie, 'name', '') === trophie.name}>
                                <div className={classes.trophieDescription}>{trophie.description}</div>
                                <div>
                                <div className={classes.trophieLeaderboardName}>{trophie.leaderboard.title}</div>
                                <div>{trophie.leaderboard.list.map((item) => (
                                    <div className={classes.trophieBoxSmall} style={{ padding: '8px 24px' }}>
                                        <div style={{ position: 'relative' }}>
                                            <img className={classes.image} alt="hero" src={allInfo.data.find((player)=>player.name === item.playerName).image} />
                                            <div className={classes.trophiePlayerName}>{item.playerName}</div>
                                        </div>
                                        <div className={classes.trophieNumber}>{item.value}</div>
                                    </div>
                                ))}</div>
                                </div>
                            </Collapse>
                        </>
                    ))}
                </Paper>
        )
    }

    const renderItems = () => {
        return (
            <Paper className={classes.paper}>
                <TextField
                    id="searchString"
                    label="Поиск"
                    className={classes.input}
                    value={searchItemString}
                    fullWidth
                    color="secondary"
                    onChange={(e) => setSearchItemString(e.target.value)}
                    variant="filled"
                />
                {allInfo.items.filter((item) => searchItemString ? item.name.toLowerCase().includes(searchItemString.toLowerCase()) : true).map((item) => (
                        <>
                            <div key={item.name} className={classes.trophieBox} onClick={() => setCurrentItem((prevState) => prevState === item ? null : item)}>
                                <div className={classes.trophieName} style={{ color: colorByTier(item.tier) }}>{item.name}</div>
                                <img className={classes.image} alt="hero" src={item.image} />
                            </div>
                            <Collapse in={get(currentItem, 'name', '') === item.name}>
                                <div style={{ padding: '8px' }}>
                                <div className={CSS.itemSlotType}>{item.slotType.name}</div>
                                <div className={CSS.itemTier} style={{ color: colorByTier(item.tier) }}>Уровень {item.tier}</div>
                                <div>
                                    {item.isAutomated && (
                                    <div className={CSS.attention}>Предмет автоматизирован</div>
                                    )}
                                </div>
                                {item.slotType.name === "Оружие" && (
                                    <>
                                    <div className={CSS.descriptionTitle}>Тип</div>
                                    {item.occupiedSlots === 1 ? 'Одноручное' : 'Двуручное'}
                                    </>
                                )}
                                    {!isEmpty(get(item, 'characteristics', [])) && (
                                    <>
                                        <div className={CSS.descriptionTitle}>Свойства</div>
                                        {item.characteristics.sort().map(characteristic => (
                                        <div style={{ color: characteristic.includes("+") ? "#28DE94" : "#F24463" }}>
                                            {characteristic}
                                        </div>
                                        ))}
                                    </>
                                    )}
                                    {get(item, 'charges') !== 'none' && (
                                    <>
                                        <div className={CSS.descriptionTitle}>Количество зарядов</div>
                                        {item.charges}
                                    </>
                                    )}
                                    {get(item, 'additionalProperty') && (
                                    <>
                                        <div className={CSS.descriptionTitle}>Дополнительное свойство</div>
                                        {item.additionalProperty}
                                    </>
                                    )}
                                    {get(item, 'description') && (
                                    <>
                                        <div className={CSS.descriptionTitle}>Описание</div>
                                        {item.description}
                                    </>
                                    )}
                                </div>
                            </Collapse>
                        </>
                    ))}
            </Paper>
        )
    }

    const renderMates = () => {
        return (
            <Paper className={classes.paper}>
                <TextField
                    id="searchString"
                    label="Поиск"
                    className={classes.input}
                    value={searchMateString}
                    fullWidth
                    color="secondary"
                    onChange={(e) => setSearchMateString(e.target.value)}
                    variant="filled"
                />
                {allInfo.mates.filter((mate) => searchMateString ? mate.name.toLowerCase().includes(searchMateString.toLowerCase()) : true).map((mate) => (
                        <>
                            <div key={mate.name} className={classes.trophieBox} onClick={() => setCurrentMate((prevState) => get(prevState, 'name', '') === mate.name ? null : mate)}>
                                <div className={classes.trophieName} style={{ color: colorByTier(mate.tier) }}>{mate.name}</div>
                                <img className={classes.image} alt="hero" src={mate.image} />
                            </div>
                            <Collapse in={get(currentMate, 'name', '') === mate.name}>
                                {parse(mate.description)}
                            </Collapse>
                        </>
                    ))}
            </Paper>
        )
    }

    const renderSocialGroups = () => {
        return (
            <Paper className={classes.paper}>
                {allInfo.socialGroups.map((group) => (
                        <>
                            <div key={group.name} className={classes.trophieBox} onClick={() => setCurrentSocial((prevState) => get(prevState, 'name', '') === group.name ? null : group)}>
                                <div className={classes.trophieName}  style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value }}>{group.name}</div>
                                <img className={classes.image} alt="hero" src={group.image} />
                            </div>
                            <Collapse in={get(currentSocial, 'name', '') === group.name}>
                            <div>
                                <div className={CSS.popoverGroupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value }}>{group.name}</div>
                                <div className={CSS.description}>{group.description}</div>
                                <div className={CSS.popoverRow}>
                                    <div>
                                    <div>Бонус от характеристик:</div>
                                <div>{group.buffs_from_stats.map((stat) => (
                                    <div key={stat} className={CSS.buffs}>{stat}</div>
                                ))}</div>
                                    </div>
                                    <div>
                                    <div>Штраф от характеристик:</div>
                                <div>{group.debuffs_from_stats.map((stat) => (
                                    <div key={stat} className={CSS.debuffs}>{stat}</div>
                                ))}</div>
                                    </div>
                                </div>
                            </div>
                            </Collapse>
                        </>
                    ))}
            </Paper>
        )
    }

    const renderMap = () => {
        return <Map3D isMobile closeCallback={() => setCurrentOpenCard(null)} />
    }

    const renderGlobalInfo = (player) => {
        const explorePoints = get(player, "map.explorePoints.current", "?");
        const maxExplorePoints = get(player, "map.explorePoints.maximum", "?");
        const movePoints = get(player, "map.movePoints.current", "?");
        const maxMovePoints = get(player, "map.movePoints.maximum", "?");
        const buyPoints = get(player, "shop.points.current", "?");
        const maxBuyPoints = get(player, "shop.points.maximum", "?");
        const influencePoints = get(player, "influence.points.current", "?");
        const tokensPoints = get(player, "tokens.points.current", "?");
        const timePlayed = get(player, "timer", "?");
        const headSlot = get(player, 'inventory.slots', []).find(slot => slot.name === "Головные уборы")
        const bodySlot = get(player, 'inventory.slots', []).find(slot => slot.name === "Туловище")
        const legsSlot = get(player, 'inventory.slots', []).find(slot => slot.name === "Ноги")
        const weaponSlots = get(player, 'inventory.slots', []).filter(slot => slot.name === "Оружие")
        const accessoriesSlots = get(player, 'inventory.slots', []).filter(slot => slot.name === "Аксессуары")
        const implantsSlots = get(player, 'inventory.slots', []).filter(slot => slot.name === "Импланты")
        const bagSlots = get(player, 'inventory.slots', []).filter(slot => slot.name === "Рюкзак")
        const mates = get(player, 'mates.list', [])
        const maxCount = get(player, 'mates.slotsMaxCount', [])
        return (
            <Paper className={classes.paper}>
                <div className={CSS.infoColumn}>
                <img alt="hero" className={CSS.playerImage} src={player.image} />
                        <div className={CSS.playerName} style={{ color: get(player, 'map.color') }}>{player.name}</div>
                        <div className={CSS.statCount}>Уровень {player.level}</div>
                        <div className={CSS.statCount}>{player.experience.current}/{player.experience.maximum}</div>
                        {Object.entries(player.characteristics).map(([name, data])=> (
                            <div key={name} className={CSS.statRow}>
                                <div className={CSS.statName}>{data.displayName}</div>
                                <div className={CSS.statCount}>{get(data, 'values.actual.value')}({get(data, 'values.summary.value')})</div>
                            </div>
                        ))}
                        <div className={CSS.divider} />
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Разведки</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{explorePoints}</div>
                                /
                                <div className={CSS.statCount}>{maxExplorePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Движения</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{movePoints}</div>
                                /
                                <div className={CSS.statCount}>{maxMovePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Покупки</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{buyPoints}</div>
                                /
                                <div className={CSS.statCount}>{maxBuyPoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Влияния</div>
                            <div>
                                <div className={CSS.statCount}>{influencePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Жетоны</div>
                            <div>
                                <div className={CSS.statCount}>{tokensPoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Время</div>
                            <div>
                                <div className={CSS.statCount}>{timePlayed}</div>
                            </div>
                        </div>
                </div>
                <Button fullWidth className={classes.button} variant="contained" onClick={() => setCurrentGlobalCard((prevState) => prevState === 'Инвентарь' ? null : 'Инвентарь')}>Инвентарь</Button>
                    <Collapse in={currentGlobalCard === 'Инвентарь'}>
                        <div style={{ width: '100%', overflowY: 'auto' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {renderHead(headSlot)}
                                {renderBody(bodySlot)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {renderLegs(legsSlot)}
                                {renderWeapon(weaponSlots)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {renderImplants(implantsSlots)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {renderAccessories(accessoriesSlots)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              {renderBackpack(bagSlots)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {renderCurrentMates(maxCount, mates)}
                            </div>
                        </div>
                    </Collapse>
                    <Button fullWidth className={classes.button} variant="contained" onClick={() => setCurrentGlobalCard((prevState) => prevState === 'Игры' ? null : 'Игры')}>Игры</Button>
                    <Collapse in={currentGlobalCard === 'Игры'}>
                    <div className={CSS.gameColumn}>
                        {player.games.map((game, index) => (
                            <div  style={{ width: '100%'}}>
                                <div key={index} className={CSS.gameCard}>
                                <div className={CSS.gameBox} style={{ width: '100%', textAlign: 'center' }}>
                                    {parse(game.gameName)}
                                <div style={{ cursor: 'pointer', opacity: 0.2 }} onClick={() => setOpenedReview((prevState) => game.gameName === prevState ? '' : game.gameName)}>Открыть описание</div>
                                </div>
                            </div>
                            <Collapse direction="down" in={openedReview === game.gameName} mountOnEnter unmountOnExit>
                            <div className={CSS.gameCard} style={{ flexDirection: 'column' }}>
                            <div className={CSS.gameBox} style={{ textAlign: 'center' }}>
                                {game.review ? parse(game.review) : 'Отзыва нет'}
                            </div>
                            <div className={CSS.gameBox} style={{ textAlign: 'center' }}>
                                    {parse(game.difficulty)}
                                </div>
                                <div className={CSS.gameBox} style={{ textAlign: 'center' }}>
                                    {parse(game.status)}
                                </div>
                                <div className={CSS.gameBox} style={{textAlign: 'center' }}>
                                {parse(game.sector)}
                                </div>
                                <div className={CSS.gameBox} style={{ textAlign: 'center' }}>
                                 GGP: {parse(game.ggp)}
                                </div>
                            </div>
                            </Collapse>
                            </div>
                            ))}
                        </div>
                    </Collapse>
                    <Button fullWidth className={classes.button} variant="contained" onClick={() => setCurrentGlobalCard((prevState) => prevState === 'Случайные встречи' ? null : 'Случайные встречи')}>Случайные встречи</Button>
                    <Collapse in={currentGlobalCard === 'Случайные встречи'}>
                        <div className={CSS.encounterColumn}>
                            {player.encounters.map((encounter, index) => (
                                <div key={index} className={CSS.gameCard} style={{ fontSize: '24px' }}>
                                <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                                    {index + 1}
                                </div>
                                <div className={CSS.gameBoxDefault}>
                                    {parse(encounter.content)}
                                    <div className={CSS.time}>{moment(encounter.timestamp).format('LLL')}</div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </Collapse>
            </Paper>
        )
    }

    const renderAllPlayers = () => {
        return allInfo.data.map((player) => {
            return (
                <Paper className={classes.paper}>
                    <Button  style={{ background: get(player, 'map.color') }} fullWidth className={classes.button} variant="contained" onClick={() => setCurrentPlayer((prevState) => get(prevState, 'name', '') === player.name ? null : player)}>{player.name}</Button>
                    <Collapse in={get(currentPlayer, 'name', '') === player.name}>
                        {renderGlobalInfo(player)}
                    </Collapse>
                </Paper>
            )
        })
    }

    const fetchData = async () => {
        // трофеи
        const _trophies = await dataService.retrieveTrophies();
        // общая инфа + логи
        const _data = await dataService.retrieveData();
        const _logs = await dataService.retrieveLogs();
        // социальные группы
        const _socialGroups = await dataService.retrieveSocialGroup();
        // предметы + типы
        const _items = await inventoryService.findItems(null, 'Мгновенное использование');
        const _types = await inventoryService.getTypes();
        // напаники
        const _mates = await matesService.getMates();

        setAllInfo({
           trophies: get(_trophies, 'trophies', []),
           data: _data,
           logs: _logs,
           socialGroups: _socialGroups,
           items: _items,
           types: _types,
           mates: _mates,
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (isEmpty(allInfo)) {
        return <Container style={{ textAlign: 'center' }}>Загрузочка...</Container>
    }

    if (get(allInfo, 'data.message', "").includes("ERROR:")) {
        return <Container style={{ textAlign: 'center' }}>SadCat</Container>
    }
 
    return (
        <Container className={classes.column}>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.global ? null : cardsNames.global)}>Общая информация</Button>
            <Collapse in={currentOpenCard === cardsNames.global}>
                {renderAllPlayers()}
            </Collapse>
            <Collapse>
            </Collapse>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.items ? null : cardsNames.items)}>Предметы</Button>
            <Collapse in={currentOpenCard === cardsNames.items}>
                {renderItems()}
            </Collapse>
            <Button className={classes.button} variant="contained"  onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.mates ? null : cardsNames.mates)}>Напарники</Button>
            <Collapse in={currentOpenCard === cardsNames.mates}>
                {renderMates()}
            </Collapse>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.trophies ? null : cardsNames.trophies)}>Трофеи</Button>
            <Collapse in={currentOpenCard === cardsNames.trophies}>
                {renderTrophies()}
            </Collapse>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.rules ? null : cardsNames.rules)}>Правила</Button>
            <Collapse in={currentOpenCard === cardsNames.rules}>
            <div className={CSS.rulesBox}>
                            <div className={CSS.headerBig}>Общие правила HPG 3</div>
                            <div className={CSS.textBox}>1. Этот сезон, в отличии от предыдущих является аналогом ролевой настольной игры, где стримеры должны на карте вымышленного города захватывать сектора путем прохождения игр. Победа представляет из себя комплекс кубков (или трофеев), которые выдаются стримерам за достижения разных целей.</div>
                            <div className={CSS.textBox}>2. Во время прохождения ХПГ, стримеры будут активировать случайные встречи, одна активация которой стоит <b>2000 рублей</b>. <br/> Всего за ход можно активировать 5 случайных встреч за донат и 1 случайную встречу за поинты (100 000 поинтов - ролл колеса). <br/> В отличие от предыдущих сезонов, подарочные или новые подписки никак не влияют на активацию случайных встреч. <br/> О более понятном принципе работы случайных встреч можно почитать в <b className={CSS.link}>Главном мануале</b>. </div>
                            <div className={CSS.header}>3. Правила реролла игр.</div>
                            <div className={CSS.textBox}>Легитимным рероллом считается тот случай, когда:</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не имеет концовки (ММО, песочница, бесконечный тайкун без сюжета, контурные карты по типу Цивилизации)</div>
                            <div className={CSS.textBoxWithOffset}>- VR-игра</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не захватывается ОБСом или Хсплитом и\или ломает экраны (чаще всего происходит с играми 90ых\начала 00ых годов, где нет выбора разрешения, запуска в оконном режиме и т.д.)</div>
                            <div className={CSS.textBoxWithOffset}>- Игра представляет из себя ДЛС к основе и требует установки этой самой основы</div>
                            <div className={CSS.textBoxWithOffset}>- Некорректная стоимость игры (возникает, когда игра оказывается в каком-то бандле и показывается стоимость всего бандла, а не отдельной игры, или показывает подписку в Ориджин, виндоус сторе и т.д.). Данное правило реролла работает только в случае, если в особенностях игры на секторе есть показатель "Стоимость".</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не может быть транслирована на твич или не имеет русского, украинского или английского интерфейса</div>
                            <div className={CSS.textBoxWithOffset}>- Если игра у конкретного стримера выпадает второй раз, учитывая первый сезон, или это ремастер уже выпадавшей игры, или это оригинал ремастера, который уже выпадал. Рероллы и дропы с первого сезона не учитываются, только пройденные игры. Данное правило не работает на секторах с Пресетами.</div>
                            <div className={CSS.textBoxWithOffset}>- Обязательным рероллом являются визуальны новеллы, текстовые РПГ, Тривии и подобные викторины. Визуальной новеллой считается игра с практически полным отсутствием геймплея (исключения редкие мини-игры в таких ВН как Doki Doki), кроме чтения текста и принятия каких-то выборов. Текстовой РПГ считается любая игра, где кроме текста нет ничего.</div>
                            <div className={CSS.textBoxWithOffset}>- Игры в раннем доступе могут быть реролльнуты в течение первых 30 минут геймплея по усмотрению стримера. Если по истечении 30 минут стример не реролльнул игру, то даже если она впоследствии окажется непроходимой, ее нужно будет дропать.</div>
                            <div className={CSS.textBoxWithOffset}>- Стример может рероллить сборник игр (2 в 1, 3 в 1 и т.д.) по своему усмотрению. У стримера так же есть опция пройти весь сборник, но он будет засчитан как 1 игра. Данное решение нужно принять до того, как стример зашел в сборник.</div>
                            <div className={CSS.textBoxWithOffset}>- Стример имеет право рероллить любой спортивный или гоночный симулятор по своему усмотрению. Если стример видит, что у игры есть прохождение, он так же в праве пройти этот симулятор. Но если по ходу прохождения выяснится, что стример ошибся, то игру реролльнуть уже нельзя.</div>
                            <div className={CSS.header}>4. Игра считается пройденной, если:</div>
                            <div className={CSS.textBoxWithOffset}>- Стример дошел до финала игры. Увидеть титры не считается дойти до финала игры, т.к. не в каждой игре есть титры, а в некоторых титры можно вызывать на первых минутах игры (Far Cry 5, South Park: FBW), во время Bad Ending (По сути Game Over) или если это фишка игры (MGS V после каждой миссии вызывались титры, nier:automata где игра поделена на несколько актов, и после каждого из них появляются титры).</div>
                            <div className={CSS.textBoxWithOffset}>- Стример прошел игру на Альтернативную концовку. Порой у игр бывает несколько концовок, некоторые из которых могут произойти раньше других, но при этом не считаются плохим окончанием игры, а представляют из себя альтернативный вариант исхода истории. В таком случае стример может засчитать игру пройденной, но лучше посоветоваться с другими участниками, дабы не вызвать разбирательств в дальнейшем.</div>
                            <div className={CSS.textBoxWithOffset}>- В игре без сюжета, при наличии уровней, необходимо разблокировать все основные уровни (бонус или экстра уровни проходить необязательно). Если все уровни разблокированы изначально, нужно пройти каждый из них.</div>
                            <div className={CSS.textBoxWithOffset}>- Если в игре нет сюжета, или уровней, но есть программный High Score (Тот, который установил именно разработчик, а не игроки в онлайне), то прохождением игры будет считаться, если этот ХС будет побит. В отдельных случаях есть игры с несколькими уровнями, где единственным показателем прохождение уровня является ХайСкор. В таком случае этот ХайСкор нужно побить на каждом уровне.</div>
                            <div className={CSS.textBoxWithOffset}>- В играх жанра rogue-like без ярко-выраженной цели требуется созвониться с другими участниками и определить что будет являться прохождением игры.</div>
                            <div className={CSS.textBoxWithOffset}>- В Стратегиях с кампаниями необходимо пройти все кампании. Если выпадает стратегия, в которой нет кампании, но есть исторические сборки военных операций, миссиий и прочего, следует посовещаться с остальными участниками на предмет того, что будет являться прохождением.</div>
                            <div className={CSS.textBoxWithOffset}>- Если выпадает игра, где есть несколько историй , которые открыты одновременно (Resident Evil 2 Remake), или другие истории открываются лишь после прохождения основной сюжетки (Resident Evil 4) необходимо посовещаться с остальными участниками на предмет того, как будет засчитываться прохождение в подобного типа игр.</div>
                            <div className={CSS.textBoxWithOffset}>- Если выпадает версия игры в стиле GOTY Edition со всеми включенными ДЛС, для зачета прохождения игры стример должен пройти ТОЛЬКО основную игру, без побочных ДЛС и прочего контента.</div>
                            <div className={CSS.textBoxWithOffset}><i><b>И самое важное!</b><br/> Если вы не уверены в том, что игра пройдена честно и хотите ее засчитать, хоть даже на подсознательном уровне думаете, что здесь может быть что-то не чисто, лучше сразу обратиться к другим участникам и решить этот вопрос, чтобы не возникало неприятных конфузов, когда спустя время к вам приходят другие стримеры, и говорят что вы где-то накосячили.</i></div>
                            <div className={CSS.textBox}>5. Если так получилось, что стример прошел игру нелегитимно и реальное прохождение игры заключается в другом, то другие стримеры могут заставить его или перепроходить или допроходить данную игру. Однако, во избежании слишком поздней мошны, сделать они это могут в течение 24 часов после нелегитимного прохождения. Если за это время другие стримеры не захотели мошнить или не уследили за нелегитимным прохождением, то потом к этому вопросу вернуться будет уже нельзя, ровно как и использовать аргументы в дальнейших спорных ситуациях из серии "А вот этот стример месяц назад нелегитимно прошел игру". Тоже самое касается и всех рероллов. Поднять вопрос о нелегитимности реролла можно только в течение первых 24 часов с момента этого реролла.</div>
                            <div className={CSS.textBox}>6. Запрещено использование чит-кодов, трейнеров и сторонних программ, облегчающих прохождение. Баги и гличи на скип уровней разрешены.</div>
                            <div className={CSS.textBox}>7. Уровень сложности прохождения игры – стандартный, предлагаемый игрой, только если игра не требует от стримера выбора сложности. В таком случае стример оставляет за собой право назначить сложность игры (но не изи). Если в игре нет сложности, но есть разные режимы, то стример выбирает его аналогично уровню сложности. Если игровых режима только два, стример вправе выбирать более простой. Это же касается и сложности. Запрещены настройки, которые влияют на сложность прохождения игры. Чтобы понять, какая настройка влияет на уровень сложности, каждый такой случай рассматривается отдельно.</div>
                            <div className={CSS.textBox}>8. При дропе игры происходит сразу несколько манипуляций с прогрессом стримера в ивенте, о которых более подробно написано в <a className={CSS.link} href="https://docs.google.com/document/d/1vyPe-b51N0L8ppISpCmvCvKm54ig2Gu-c1EzQXHLBZY/edit?usp=sharing" rel="noreferrer" target="_blank">
                                    <b>главном мануале</b>
                                </a> или его <b className={CSS.link}>которкой выжимке</b>.</div>
                            <div className={CSS.textBox}>9. Смотреть прохождение игры запрещено. Проследить за этим почти невозможно, но этот пункт остается на совести стримера. Разрешены подсказки из чата, если: </div>
                            <div className={CSS.textBoxWithOffset}>- Это не копипаста прохождения игры с какого-либо сайта.</div>
                            <div className={CSS.textBoxWithOffset}>- Если эти подсказки не связаны со спидранами, багами и абузами, позволяющими сократить бОльшую часть времени, проведенное в игре."</div>
                            <div className={CSS.textBox}>10. Победителем становится тот, у кого по окончании ивента больше всего Кубков (или трофеев). О том, что такое трофеи, сколько их и как они получаются, более подробно написано в <a className={CSS.link} href="https://docs.google.com/document/d/1vyPe-b51N0L8ppISpCmvCvKm54ig2Gu-c1EzQXHLBZY/edit?usp=sharing" rel="noreferrer" target="_blank">
                                    <b>главном мануале</b>
                                </a> или его <b className={CSS.link}>которкой выжимке</b>.</div>
                            <div className={CSS.header}>11. Награды:</div>
                            <div className={CSS.textBoxWithOffset}>Первое место получает по 50 сабов от каждого другого участника ивента.</div>
                            <div className={CSS.textBoxWithOffset}>Второе место получает 2 недели автохоста + авторейд от всех других участников, кроме первого места.</div>
                            <div className={CSS.textBoxWithOffset}>Третье и четвертое место не получают ни приза, ни наказания.</div>
                            <div className={CSS.textBoxWithOffset}>Пятое место в течение целого стрима, который должен длиться не меньше 5 часов, должен слушить по кругу рэп от стримера, занявшего последнее место.</div>
                            <div className={CSS.textBoxWithOffset}>Шестое место записывает рэп в вольном исполнении минимум на три минуты, связанный с ХПГ. Желательно разнообразие текста, а не одна фраза, повторяющаяся в течение всего трека, но это уже на совести проигравшего стримера. После записи рэпа, в течение следующих 15 стримов, каждый зритель может заказать этот трек за 300 рублей. Стример не имеет право отказаться.</div>
                            <div className={CSS.textBox}>12. Любые непредвиденные моменты, не прописанные в правилах, решаются стримером по его усмотрению при отсутствии свидетелей.</div>
                            <div className={CSS.textBox}>13. Если один из стримеров дропает челленж, то он пожизненно исключается из ХПГ и не сможет принимать участие в следующих ивентах, а также получит официальную приставку "Чм-" к своему нику (только если это не коллективный дроп всеми участниками ивента).</div>
                            <div className={CSS.headerBig} >Правила работы с сайтом</div>
                            <div className={CSS.header} >Порядок хода</div>
                            <div className={CSS.textBox} ><b>Фаза движения:</b><br /> Возможно провести разведку сектора <i>(по умолчанию 1 сектор каждый ход)</i>. <br /> Совершить движение по секторам <i>(по умолчанию 3 ОД каждый ход)</i>. <br /> В случае движения на вражеский или нейтральный сектор все оставшиеся ОД тратятся. <br /> ОД не тратится при передвижении на свой стартовый сектор.</div>
                            <div className={CSS.textBox} ><b>Фаза прохождения игры:</b><br /> Производится ролл колеса в соответствии с условиями текущего сектора с учетом бонусов или штрафов этого сектора <b className={CSS.link}>(см. Бонусы и штрафы)</b>, которые влияют на ролл. <br/> Далее игрок либо <u style={{ color: ' #32aa0c' }}>проходит игру</u>, либо <u style={{ color: '#f1dd4f' }}>рероллит</u>, либо <u style={{ color: '#F24463' }}>дропает</u>.</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#32aa0c' }}>Прохождение игры:</b><br/> Этап непосредственно прохождения игры может занимать не более 30 часов в неделю, если не применены какие-либо модификаторы. <br /> При успешном <b className={CSS.link}>(см. Условия)</b> прохождении игры сектор считается захваченным, если нигде не указано иного. <br />Во время прохождения игры возможна активация Случайных встреч. <br /> После этого наступает фаза получения награды.</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#f1dd4f' }}>Реролл:</b><br/> Происходит еще один ролл колеса с текущими условиями до тех пор, пока игра не будет удовлетворять условиям прохождения <b className={CSS.link}>(см. Условия)</b></div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#F24463' }}>Дроп:</b><br/> При дропе игры происходит потеря <b>2/4/6</b> очков влияния, если игрок находится на <b>своем/нейтральном/чужом секторе</b>; <br/> В случае <b>нейтрального/чужого</b> сектора попытка захвата сектора считается проваленной и игрок делает шаг на последний сектор под своим контролем, если сектор не занят другим стримером (в противном случае стример возвращается на последний СВОБОДНЫЙ сектор под своим контролем, либо на стартовый сектор, если таковых нет); <br/> В случае дропа стример не может захватывать <b>нейтральные/чужие</b> сектора сложностью <b>выше среднего/сложный (высокая)/адский</b> в следующем ходу; <br /> В случае дропа ход немедленно заканчивается. </div>
                            <div className={CSS.textBox} ><b>Фаза получения награды:</b><br /> После успешного прохождения игры на <b>нейтральном/вражеском</b> секторе игрок получает награду в соответствии с описанием сектора с учетом возможных бонусов или штрафов <b className={CSS.link}>(см. Бонусы и штрафы)</b>. <br /> Награда в виде влияния не получается при повторном прохождении игры на <b>своем</b> секторе. <br /> В случае прохождения игры на <b>своем</b> секторе, игрок получает +1 ОП на текущий ход вместо влияния <b className={CSS.link}>(см. Фазу покупок)</b>. <br /> Также, за прохождение на любом секторе игрок получает опыт: <b>25/50/100/200/500</b> опыта за <b>легкий/средний/выше среднего/сложный/адский сектор</b>. <i>Однако, если сектор уже принадлежит игроку, получаемый опыт делится на 2.</i> <br/> За прохождение игры на <b>своем легком</b> секторе опыт не дается. <br/> Плюс ко всему вышеперечисленному, после прохождения каждой игры, перед фазой покупок, стример может увеличить уровень персонажа в секторе на 1, за исключением Смотрящего сектора.</div>
                            <div className={CSS.textBox} ><b>Фаза покупок:</b><br /> После получения награды игрок может воспользоваться услугами персонажей сектора, в котором он находится. Одно любое обращение к любому персонажу стоит 1 ОП <i>(по умолчанию у игрока 1 ОП каждый ход)</i>, если не указано обратного. <br/> Игрок не обязан тратить все ОП, но они <b>не перенесутся</b> на следующий ход. <br/> Также, при каждом прохождении игры на секторе, игрок может увеличить уровень любого персонажа, кроме Смотрящего сектора, на 1, пока они не достигнут максимально допустимого для сектора. <i>Механика повышения уровня персонажа за прохождение игры не работает на секторах с пресетами</i>. <br/> После этой фазы наступает конец хода.</div>
                            <div className={CSS.header} >Бонусы и штрафы:</div>
                            <div className={CSS.textBox}>Бонусы/штрафы могут влиять как на особенности игры, так и на последующее получение награды. <br/> Получение бонуса или штрафа проходит путем проверки на конкретную характеристику. <br/> Игрок не может получить одновременно и бонус, и штраф от сектора.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#f1dd4f' }}>Характеристик игрока не хватает чтобы пройти проверку на штраф или бонус – ничего не происходит.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#32aa0c' }}>Характеристик игрока не хватает чтобы пройти проверку на штраф, но хватает чтобы пройти проверку на бонус – игрок по желанию применяет бонус.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#F24463' }}>Характеристик игрока хватает чтобы пройти проверку на штраф, но не хватает чтобы пройти проверку на бонус – игрок обязан применить штраф.</div>
                            <div className={CSS.textBoxWithOffset}>Характеристик игрока хватает чтобы пройти проверку на штраф и на бонус – игрок смотрит на показатели своих характеристик, участвующих в проверке. Тот показатель характеристики, который больше, побеждает. Если характеристики имеют одинаковый показатель, то ничего не происходит. </div>
                            <div className={CSS.header}>Экипировка:</div>
                            <div className={CSS.textBox}>Экипировка – предметы, импланты или напарники, которые игрок получает в процессе игры. <br/> Можно иметь несколько одинаковых предметов, но нельзя иметь одинаковые импланты или напарников. <br/> После установки импланта его можно заменить или снять только у торговца имплантами без трат очков ОП. <br/> Напарника нельзя на время <i>«снять»</i>, только выгнать насовсем.</div>
                            <div className={CSS.textBox}>Предметы нельзя надеть на соответствующую им часть тела, если эта часть тела <b>ранена</b>. <br/> В результате какого-то события игрок может получить ранение. Ранение бывает легким и тяжелым:</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#32aa0c' }}>Обычное:</b> <br/> Длится <b>Х</b> ходов <i>(указано в описании каждого ранения)</i>. <br/> <b>Излечивается:</b><br/> <i>- Cамо после <b>Х</b> ходов.</i> <br /><i>- У картографа 1 уровня или выше.</i> <br/><i>- С помощью специальных предметов.</i></div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#F24463' }}>Тяжелое:</b> <br/> Длится до излечения. <br/><b>Излечивается:</b><br/> <i>- У картографа 2 уровня или выше.</i><br/> <i>- C помощью специальных предметов.</i></div>
                            <div className={CSS.textBox}>Если игрок получает ранение на часть тела, в которой у него находится предмет, этот предмет можно переместить в рюкзак, если дополнительным условием получения ранения не был сброс надетого предмета.</div>
                            <div className={CSS.textBox}>Игроки не имеют права обмениваться любой экипировкой, если где-то не указано иного.</div>
                            <div className={CSS.textBox}>Игрок может менять свои предметы (переодеваться) в определенные этапы:</div>
                            <div className={CSS.textBoxWithOffset}><i>- В фазу движения, до того, как произведено открытие сектора или сделан первый шаг;</i></div>
                            <div className={CSS.textBoxWithOffset}><i>- Сразу после того, как произошла случайная встреча <b>(не во время!)</b>;</i></div>
                            <div className={CSS.textBoxWithOffset}><i>- Во время и после фазы покупок.</i></div>
                            <div className={CSS.header}>Персонажи:</div>
                            <div className={CSS.textBox}><b>Торговец оружием</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/> Торговец оружием продает предметы на голову, туловище, ноги и оружие.</div>
                            <div className={CSS.textBox}><b>Барахольщик</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/>  Барахольщик продает аксессуары.</div>
                            <div className={CSS.textBox}><b>Торговец имплантами</b> <br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/> Торговец имплантами продает импланты и аугментации, которые необходимы для разблокирования дополнительных ячеек под конкретные импланты или части тела.</div>
                            <div className={CSS.textBox}><b>Рекрутер</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/>  Рекрутер предлагает нанять напарников.</div>
                            <div className={CSS.textBox}><b>Картограф</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/> Картограф позволяет провести разведку секторов и вылечить раны игрока.</div>
                            <div className={CSS.textBox}><b>Трейдер</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/>  Трейдер обменивает ваши вещи на свои. При обмене с трейдером вы всегда будете получать случайный предмет.</div>
                            <div className={CSS.textBox}><b>Смотрящий сектора</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/>  После того, как игрок решит воспользоваться услугами смотрящего сектора, тот исчезает из сектора вместе с остальными персонажами на секторе. Вместо них появляются новые персонажи. Вне зависимости от того, каких персонажей сгенерировал Смотрящий Сектора, их уровни не могут превышать верхний лимит, поставленный им сложностью сектора.</div>
                            <div className={CSS.textBox}><b>ТОР</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 1</i><br/>  ТОР продает уникальные предметы, которые занимают ячейки аксессуаров. После того, как игрок решает воспользоваться услугами ТОРа, он получает предмет случайным образом. </div>
                            <div className={CSS.textBox}><b>Диверсант</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 1</i><br/>  Диверсант мешает жить другим игрокам. После того, как игрок решает воспользоваться услугами Диверсанта, он получает случайную диверсию, которую может применить на любого другого игрока. </div>
                            <div className={CSS.textBox}>Если какой-то из персонажей в секторе (или все) отказываются с вами сотрудничать до конца хода после получения штрафа или эффекта от случайных встреч, предметов и т.д., вы не можете увеличить уровень этих персонажей. </div>
                            <div className={CSS.textBox}>Если уровень одного или нескольких персонажей на обычном (не пресетовском) секторе стал равен 0, он покидает сектор до конца хода. Во время следующего хода он вернется на сектор, а его уровень будет равен 1.</div>
                            <div className={CSS.header}>Случайные встречи:</div>
                            <div className={CSS.textBox}>Случайные встречи могут быть активированы только во время прохождения игры и никак иначе. Когда будет набрана необходимая сумма донатов, будет активироваться колесо случайных встреч.</div>
                            <div className={CSS.textBox}>Случайные встречи делятся на несколько типов и механик: </div>
                            <div className={CSS.textBoxWithOffset}><b>Однозначные</b><br/>Это встречи, которые имеют лишь один эффект.</div>
                            <div className={CSS.textBoxWithOffset}><b>Бросок кубика</b><br/>Это встречи, при выпадении которого игроку необходимо подбросить кубик d12 и сопоставить полученный результат с вариантом исхода случайной встречи.</div>
                            <div className={CSS.textBoxWithOffset}><b>Проверка на характеристику</b><br/>Это встречи, которые имеют несколько вариантов развития событий, некоторые из которых могут потребовать пройти проверку на какую-либо характеристику. </div>
                            <div className={CSS.textBoxWithOffset}><b>Смешанные</b><br/>Это встречи, которые могут одновременно содержать в себе как бросок кубика, так и проверку на какую-то характеристику. </div>
                            <div className={CSS.textBoxWithOffset}><b>Простой выбор</b><br/>Это встречи, которые имеют несколько вариантов развития событий, но не требуют никаких дополнительных механик (бросок кубика или проверка на характеристики).</div>
                            <div className={CSS.textBox}>Если выбранный игроком эффект встречи оказался негативным и не может быть применен по каким-либо причинам, производится ролл из колеса неудач. <br/> <b>Колесо неудач</b> – это 8 событий, которые имеют негативный эффект, усиливающийся с повышением сложности секторов, на которых находится игрок. <br/> Тем не менее, если негативных эффектов несколько, и хотя бы один может быть выполнен, колесо неудач крутить не нужно – достаточно выполнить этот эффект. Тоже самое касается и смешанных эффектов случайных встреч. Если не может быть выполнен негативный эффект, но может быть выполнен позитивный – вам повезло, колесо крутить не нужно. </div>
                            <div className={CSS.textBox}>Если выбранный игроком эффект встречи оказался позитивным и не может быть применен по каким-либо причинам, или же игрок не хочет его применять, он может получить вместо этого <b>15\легкий, 25\средний, 35\Выше среднего, 50\сложный\адский опыта</b>.</div>
                            <div className={CSS.header}>Особенности механик:</div>
                            <div className={CSS.textBox}>Предметы (ноги, туловище, голова, оружие, аксессуары) можно спокойно перемещать, переодевать, убирать в рюкзак в дозволенные фазы переодевания. <br/> Импланты, после их установки, нельзя самостоятельно снять или переместить в другие ячейки. Их может заменить или снять только торговец имплантами или эффект случайной встречи (получение\сброс импланта). Некоторые импланты имеют описание, где сказано что лишь торговец конкретного уровня и выше может их снять. Это значит, что замену этого импланта вы можете произвести лишь оказавшись у торговца соответствующего уровня. Удаление импланта также проводится у него или же в следствии эффекта случайной встречи, который требует от вас снять имплант. Удаление импланта не требует траты ОП. </div>
                            <div className={CSS.textBox}>Когда вы получаете новый уровень, можете увеличить любую характеристику на +1. </div>
                            <div className={CSS.textBox}>Несмотря на базовый максимальный уровень у персонажей, он также зависит от сложности сектора, в котором находится персонаж. К примеру, торговец оружием при номинальном максимальном уровне 5, может иметь лишь 3 уровень максимум на секторах легкого уровня сложности. </div>
                            <div className={CSS.textBox}>На одном секторе может находиться максимум один стример. <i>Исключение - центральный сектор "Ядро"</i>.</div>
                            <div className={CSS.textBox}>Если в какой-то момент времени вы оказались зажаты со всех сторон другими стримерами, вы имеете возможность перешагнуть через них на любой соседний от них свободный сектор. </div>
                            <div className={CSS.textBox}>Во время увеличение уровней персонажам после прохождения игры, вместо этого вы можете понизить любое количество уровней у Барахольщика или Рекрутера, т.к. почти все аксессуары и напарники имеют уникальные способности, которые могут пригодиться и на поздних этапах ивента. Если вы воспользовались понижением уровней, после фазы покупок уровни этих персонажей вернутся к значению, которое у них было до понижения.</div>
                            <div className={CSS.header}>Правила прохождение игр из пресета "Рогалики"</div>
                            <div className={CSS.gameTextBox}>NECROPOLIS: BRUTAL EDITION</div>
                            <div className={CSS.textBoxWithOffset}>Убить босса на самом нижнем этаже и войти в дверь</div>
                            <div className={CSS.gameTextBox}>Hades</div>
                            <div className={CSS.textBoxWithOffset}>Убить в первый раз Аида</div>
                            <div className={CSS.gameTextBox}>Moonlighter</div>
                            <div className={CSS.textBoxWithOffset}>Получить титры после убийства босса на корабле</div>
                            <div className={CSS.gameTextBox}>The Binding of Isaac: Rebirth</div>
                            <div className={CSS.textBoxWithOffset}>The Binding of Isaac: Rebirth</div>
                            <div className={CSS.gameTextBox}>Noita</div>
                            <div className={CSS.textBoxWithOffset}>Убийство последнего босса (глаз-паук)</div>
                            <div className={CSS.gameTextBox}>Meteorfall: Krumit's Tale</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все 7 локаций</div>
                            <div className={CSS.gameTextBox}>Hand of Fate</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все главы</div>
                            <div className={CSS.gameTextBox}>Hand of Fate 2</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все главы</div>
                            <div className={CSS.gameTextBox}>Dicey Dungeons</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все миссии на одном из персонажей. <br/> <i>Примечание: чтобы открыть миссии надо пройти первую миссию за конкретного персонажа, на котором хотите проходить.</i></div>
                            <div className={CSS.gameTextBox}>Slay the Spire</div>
                            <div className={CSS.textBoxWithOffset}>Убить третьго босса</div>
                            <div className={CSS.gameTextBox}>Dead Cells</div>
                            <div className={CSS.textBoxWithOffset}>Убить the hand of the king</div>
                            <div className={CSS.gameTextBox}>Iratus: Lord of the Dead</div>
                            <div className={CSS.textBoxWithOffset}>После убийства Великого магистра, выбраться из подземелья</div>
                            <div className={CSS.gameTextBox}>Enter the Gungeon</div>
                            <div className={CSS.textBoxWithOffset}>Убить дракона</div>
                            <div className={CSS.gameTextBox}>Darkest Dungeon®</div>
                            <div className={CSS.textBoxWithOffset}>Убить Сердце Тьмы</div>
                            <div className={CSS.gameTextBox}>Dungeon Souls</div>
                            <div className={CSS.textBoxWithOffset}>Убить selene</div>
                            <div className={CSS.gameTextBox}>Neon Abyss</div>
                            <div className={CSS.textBoxWithOffset}>Убить 5 боссов, финальный Аргус</div>
                        </div>
            </Collapse>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.map ? null : cardsNames.map)}>Карта</Button>
            <Collapse in={currentOpenCard === cardsNames.map}>
                {renderMap()}
            </Collapse>
            <Button className={classes.button} variant="contained" onClick={() => setCurrentOpenCard((prevState) => prevState === cardsNames.social ? null : cardsNames.social)}>Соц. Группы</Button>
            <Collapse in={currentOpenCard === cardsNames.social}>
                {renderSocialGroups()}
            </Collapse>
            <div className={CSS.scrollButton} onClick={handleScrollToTop}>^</div>
        </Container>
    )
}

export default MobileScreen;