import React from "react";
import CSS from "./BottomMenu.module.scss";
import Tooltip from '@material-ui/core/Tooltip';
import allWidgets from './../../widgets/index';
import get from 'lodash/get';
import classNames from 'classnames';

const BottomMenu = ({ widgets, changeWidgetState, isViewer, isWhopg }) => (
  <div className={CSS.box} style={{ zIndex: get(widgets, 'map.weight', 0) + 1 }}>
    {!isViewer && (
      <a className={CSS.link} style={{ textDecoration: 'none' }} href="https://who-pe.ga/" rel="noreferrer" target="_blank">
        Колёсико
      </a>
    )}
      {Object.values(allWidgets)
        .filter((widget) => !widget.isSmall)
        .filter((widget) => isViewer ? widget.showInViewerMode : true)
        .filter((widget) => isWhopg ? !widget.disabledInWhopg : true)
        .map((widget) => (
          <Tooltip key={widget.name} title={widget.displayName} placement="top" arrow>
            <div className={classNames(CSS.icon, { [CSS.iconActive]: widgets[widget.name]?.isOpened })}>
                <img
                    alt="widget"
                    style={{ hegiht: '42px', width: '42px' }}
                    onClick={() => changeWidgetState(widget.name)}
                    src={widget.image}
                />
            </div>
          </Tooltip>
      ))}
  </div>
);

export default BottomMenu;
