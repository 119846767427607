const addCompareItem = (item) => {
  return {
    type: "ADD_COMPARE_ITEM",
    payload: item,
  }
}

const deleteCompareItem = (item) => {
  return {
    type: "DELETE_COMPARE_ITEM",
    payload: item.id,
  }
}

export default {
  addCompareItem,
  deleteCompareItem,
}
