const changeCurrentSelectedPlayer = (value) => {
    return {
      type: "CHANGE_SELECTED_PLAYER",
      payload: value,
    }
  }
  
  export default {
    changeCurrentSelectedPlayer,
  }
  