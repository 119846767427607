import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames';
import inventoryDefault from '../../assets/images/inventoryDefault.png';
import antifunPNG from '../../assets/icons/antifun.png';
import InventorySlot, { smallSlotPack } from "./InventorySlot/InventorySlot";
import InventoryService from "../../api/InventoryService";
import { colorByTier } from "../../utils/utils";
import ItemPopover from './ItemPopover';
import {
  addAugmentItem,
  changeSlotNumber,
  changeWound,
  equipToAnoterSlot,
  removeAugmentItem,
  removeItem,
  changeItemCharges,
  handleUseItem,
  handleChangeCharacteristic,
} from '../../api/socket';
import closedSlot from "../../assets/styled-blocks/closedSlot.png";
import DragItem from "./InventorySlot/Drag-n-Drop/DragItem";
import DropItem from "./InventorySlot/Drag-n-Drop/DropItem";
import CSS from './Inventory.module.scss';
import allActions from './../../state/actions/index';
import allWidgets from './../index';
import Tooltip from "@material-ui/core/Tooltip";


const maxAccessoriesSlots = 6;
const maxImplantsSlots = 5;
const maxBackpackSlots = 8;

const Inventory = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const [currentInventory, setCurrentInventory] = useState({});
  const [currentTypes, setCurrentTypes] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [isOpenedCompare, setIsOpenedCompare] = useState(false);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);

  const [currentDraggedItem, setCurrentDraggedItem] = useState(false);

  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, slotID, item, augmentID) => {
    if (anchorEl.slotID === slotID) {
        return handlePopoverClose();
    }
    setAnchorEl({
        slotID,
        augmentID,
        item: item,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  useEffect(() => {
    new InventoryService().findItems(null, 'Мгновенное использование').then((items) => {
      if (get(items, 'message', []).includes('ERROR')) {
        setCurrentItems([]);
      } else {
        setCurrentItems(items);
      }
    });
    new InventoryService().getTypes().then((types) => {
      if (get(types, 'message', []).includes('ERROR')) {
        setAllTypes([]);
      } else {
        setAllTypes(types);
      }
    });
  }, []);

  useEffect(() => {
    if (!currentPlayer.player) { return; }
    setCurrentInventory({
      image: get(currentPlayer, 'player.inventory.image', ''),
      slots: get(currentPlayer, 'player.inventory.slots', []),
    });
  }, [currentPlayer]);

  useEffect(() => {
    if (!currentItem) { return; }
    const currentElement = document.getElementById("current");
    if (!currentElement) { return; }
    currentElement.scrollIntoView({ behavior: 'smooth' });
  }, [currentItem]);

  const bigSizeTypes = useMemo(() => ["Туловище", "Оружие", "Ноги"], []);

  const headSlot = get(currentInventory, 'slots', []).find(slot => slot.name === "Головные уборы");
  const bodySlot = get(currentInventory, 'slots', []).find(slot => slot.name === "Туловище");
  const legsSlot = get(currentInventory, 'slots', []).find(slot => slot.name === "Ноги");
  const weaponSlots = get(currentInventory, 'slots', []).filter(slot => slot.name === "Оружие");
  const accessoriesSlots = get(currentInventory, 'slots', []).filter(slot => slot.name === "Аксессуары");
  const implantsSlots = get(currentInventory, 'slots', []).filter(slot => slot.name === "Импланты");
  const bagSlots = get(currentInventory, 'slots', []).filter(slot => slot.name === "Рюкзак");

  function handleSlotClick(e, slot) {
    if (!slot.item) { return; }
    handlePopoverOpen(e, slot.id, slot.item);
  }

  function handleAugmentSlotClick(e, slotID, item, augmentId) {
    handlePopoverOpen(e, slotID, item, augmentId);
  }

  function handleAddSlot(name) {
    changeSlotNumber(name);
  }

  function handleRemoveSlot(name) {
    changeSlotNumber(name, "remove");
  }

  function handleWoundClick(slotId, type) {
    changeWound(slotId, type);
  }

  function handleChargeClick({ slotID, augmentSlotID, valueType }) {
    changeItemCharges({ slotID, augmentSlotID, valueType });
  }

  const byName = useCallback((item) => {
    if (searchString && item.name) {
      return item.name.toString().toLowerCase().includes(searchString.toLowerCase());
    }
    return true;
  }, [searchString]);

  const byType = useCallback((item) => {
    if (!isEmpty(currentTypes)) {
      return currentTypes.includes(item.slotType.name);
    }
    return true;
  }, [currentTypes]);

  const updateCurrentTypes = useCallback((newType) => {
    if (currentTypes.includes(newType)) {
      return setCurrentTypes(prevState => prevState.filter((type) => type !== newType));
    }
    return setCurrentTypes(prevState => [...prevState, newType]);
  }, [currentTypes]);

  function setCurrentType(newType) {
    setCurrentTypes([newType]);
  }

  function addToCompare(item) {
    if (!item) { return; }
    dispatch(allActions.compareActions.addCompareItem(item));
  }

  function hasDrop(value, currentSlotName) {
    const parsedValue = JSON.parse(value);
    if (has(parsedValue, 'item.slotType')) {
      return currentSlotName === parsedValue.item.slotType.name;
    }
  }

  const renderAugment = useCallback((slot) => {
    if (!slot.augments) { return; }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {
          Array(slot.augments.maxCount)
            .fill(null)
            .map((_, i) => i)
            .map(augmentId => {
              if (!isEmpty(slot.augments.items[augmentId])) {
                const id = JSON.stringify({
                  slotId: slot.id,
                  item: slot.augments.items[augmentId],
                  augmentId: augmentId,
                  type: 'augment',
                  dragFrom: 'augment',
                });
                return (
                  <DropItem
                    key={augmentId}
                    onDrop={data => {
                      const parsedData = JSON.parse(data);
                      if (parsedData.dragFrom === 'augment') {
                        return equipToAnoterSlot(slot.id, parsedData.slotId, augmentId, parsedData.augmentId);
                      }
                      if (parsedData.dragFrom === 'inventory') {
                        return equipToAnoterSlot(slot.id, parsedData.slotId, augmentId);
                      }
                      return addAugmentItem(slot.id, augmentId, parsedData.item.id);
                    }}
                  >
                    <DragItem
                      id={id}
                      key={slot.id}
                      type={slot.name}
                      callback={(e) => e ? setCurrentDraggedItem(id) : setCurrentDraggedItem(null)}
                      data={(
                        <div
                          style={{ position: "relative", width: "36px"}}
                          onClick={(e) => handleAugmentSlotClick(e, slot.id, slot.augments.items[augmentId], augmentId)}
                        >
                          <img alt="contain" width="100%" src={smallSlotPack.contain} />
                          <img
                            alt="item"
                            style={{
                              position: "absolute",
                              width: !bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                                && "36px",
                              height: bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                                && "36px",
                              margin: "auto",
                              left: 0,
                              right: 0,
                              opacity: hasDrop(currentDraggedItem, get(slot, "name")) ? 0.2 : 1,
                            }}
                            src={slot.augments.items[augmentId].image}
                          />
                          {slot.augments.items[augmentId].charges !== 'none' && <div className={CSS.augmentChargesRow}>{slot.augments.items[augmentId].charges}</div>}
                        </div>
                      )}
                    />
                  </DropItem>
                )
              }
              return (
                <DropItem
                  key={augmentId}
                  onDrop={data => {
                    const parsedData = JSON.parse(data);
                    if (parsedData.dragFrom === 'augment') {
                      return equipToAnoterSlot(slot.id, parsedData.slotId, augmentId, parsedData.augmentId);
                    }
                    if (parsedData.dragFrom === 'inventory') {
                      return equipToAnoterSlot(slot.id, parsedData.slotId, augmentId);
                    }
                    return addAugmentItem(slot.id, augmentId, parsedData.item.id);
                  }}
                >
                  <img alt="empty" width="36px" style={{
                    padding: hasDrop(currentDraggedItem, get(slot, "name")) && "2px",
                    border: hasDrop(currentDraggedItem, get(slot, "name")) && "2px dashed #baaefe",
                    boxSizing: 'border-box',
                  }} src={smallSlotPack.empty} />
                </DropItem>
              )
            })
        }
      </div>
    )
  }, [currentDraggedItem, bigSizeTypes]);

  const renderHead = useCallback(() => (
    <div>
      <div className={CSS.slotTitle}>Голова</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <InventorySlot
              onEmptySlotClick={setCurrentType}
              onItemClick={handleSlotClick}
              onWoundClick={handleWoundClick}
              onChargeClick={handleChargeClick}
              dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
              slot={headSlot}
            />
            {has(headSlot, 'augments') && renderAugment(headSlot)}
          </div>
    </div>
  ), [currentDraggedItem, headSlot, renderAugment]);

  const renderBody = useCallback(() => (
    <div>
      <div className={CSS.slotTitle}>Тело</div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            <InventorySlot
              onEmptySlotClick={setCurrentType}
              onItemClick={handleSlotClick}
              onWoundClick={handleWoundClick}
              onChargeClick={handleChargeClick}
              dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
              slot={bodySlot}
            />
            {has(bodySlot, 'augments') && renderAugment(bodySlot)}
          </div>
    </div>
  ), [bodySlot, currentDraggedItem, renderAugment]);

  const renderLegs = useCallback(() => (
    <div>
      <div className={CSS.slotTitle}>Ноги</div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            <InventorySlot
              onEmptySlotClick={setCurrentType}
              onItemClick={handleSlotClick}
              onWoundClick={handleWoundClick}
              onChargeClick={handleChargeClick}
              dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
              slot={legsSlot}
            />
            {has(legsSlot, 'augments') && renderAugment(legsSlot)}
          </div>
    </div>
  ), [legsSlot, currentDraggedItem, renderAugment]);

  const renderWeapon = useCallback(() => (
    <div className={CSS.weaponWrapper}>
      <div className={CSS.slotTitle}>Оружие</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {weaponSlots.map((weapon, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={setCurrentType}
                onItemClick={handleSlotClick}
                onWoundClick={handleWoundClick}
                onChargeClick={handleChargeClick}
                dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
                slot={weapon}
              />
            ))}
            {!isEmpty(weaponSlots) && weaponSlots[0].augments && renderAugment(weaponSlots[0])}
          </div>
    </div>
  ), [weaponSlots, renderAugment, currentDraggedItem]);

  const renderImage = useCallback(() => (
    <div style={{ marginLeft: '-75px', width: "455px" }}>
        <img
          alt="inventory"
          style={{ height: "550px", position: "absolute" }}
          src={currentInventory.image || inventoryDefault}
        />
      </div>
  ), [currentInventory.image]);

  const renderImplants = useCallback(() => (
        <div>
          <div className={CSS.slotTitle}>
            Импланты
            <div
              className={CSS.buttonTitle}
              onClick={() => handleRemoveSlot("Импланты")}
            >
              {` - `}
            </div>
            <div
              className={CSS.buttonTitle}
              onClick={() => handleAddSlot("Импланты")}>
              {` + `}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {implantsSlots.map((implant, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={setCurrentType}
                onItemClick={handleSlotClick}
                onWoundClick={handleWoundClick}
                onChargeClick={handleChargeClick}
                dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
                slot={implant}
              />
            ))}
            {
              Array(maxImplantsSlots - implantsSlots.length).fill(null).map((_, index) => (
                <img key={index} className={CSS.closedImage} alt="closed" src={closedSlot} />
              ))
            }
            {!isEmpty(implantsSlots) && implantsSlots[0].augments && renderAugment(implantsSlots[0])}
          </div>
        </div>
  ), [currentDraggedItem, implantsSlots, renderAugment]);

  const renderAccessories = useCallback(() => (
    <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={CSS.slotTitle}>
              Аксессуары
              <div
                className={CSS.buttonTitle}
                onClick={() => handleRemoveSlot("Аксессуары")}
              >
                {` - `}
              </div>
              <div
                className={CSS.buttonTitle}
                onClick={() => handleAddSlot("Аксессуары")}>
                {` + `}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {accessoriesSlots.map((accessories, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={setCurrentType}
                onItemClick={handleSlotClick}
                onWoundClick={handleWoundClick}
                onChargeClick={handleChargeClick}
                dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
                slot={accessories}
              />
            ))}
            {
              Array(maxAccessoriesSlots-accessoriesSlots.length).fill(null).map((_, index) => (
                <img key={index} className={CSS.closedImage} alt="closed" src={closedSlot} />
              ))
            }
          </div>
        </div>
  ), [accessoriesSlots, currentDraggedItem]);

  const renderBackpack = useCallback(() => (
    <div>
          <div className={CSS.slotTitle}>
            Рюкзак
            <div
              className={CSS.buttonTitle}
              onClick={() => handleRemoveSlot("Рюкзак")}
            >
              {` - `}
            </div>
            <div
              className={CSS.buttonTitle}
              onClick={() => handleAddSlot("Рюкзак")}>
              {` + `}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
            {bagSlots.map((bag, index) => (
              <InventorySlot
                key={index}
                onEmptySlotClick={() => setCurrentTypes([])}
                onItemClick={handleSlotClick}
                onWoundClick={handleWoundClick}
                onChargeClick={handleChargeClick}
                dragAssist={[currentDraggedItem, setCurrentDraggedItem]}
                slot={bag}
              />
            ))}
            {
              Array(maxBackpackSlots-bagSlots.length).fill(null).map((_, index) => (
                <img key={index} className={CSS.closedImage} alt="closed" src={closedSlot} />
              ))
            }
          </div>
        </div>
  ), [bagSlots, currentDraggedItem]);

  const renderSearch = useCallback(() => (
    <>
    <div style={{ position: "relative", marginTop: "8px" }}>
          <input
            className={CSS.searchInput}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder="Поиск..."
          />
          {allTypes && allTypes.filter((type) => type.image).map((type, index) => (
            <Tooltip placement="top" arrow title={type.name}>
              <img
              key={type.name}
              className={CSS.searchIcon}
              alt="search"
              onClick={() => updateCurrentTypes(type.name)}
              style={{
                right: `${7 + index*35}px`,
                opacity: currentTypes.includes(type.name) ? 1 : 0.1,
              }}
              src={type.image}
            />
            </Tooltip>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={CSS.itemsBox}>
            {searchString === 'antifun' && ['viewer'].includes(get(currentUser, 'user.type', '')) && (
              <div
                    className={CSS.itemBox}
                    style={{
                      color: '#f2f2f2',
                      background: '#b50b19',
                      borderTop: `8px solid #b50b19!important`,
                      border: `1px solid #b50b19`,
                    }}
                    id="antifun"
                  >
                    <img
                      alt="item"
                      style={{
                        height: '120px',
                        marginRight: "4px",
                      }}
                      src={antifunPNG}
                    />
                    <div style={{ width: "260px" }}>
                      Антифан
                    </div>
                  </div>
            )}
            {currentItems && currentItems.filter(byName).filter(byType).map(item => {
              const id = JSON.stringify({
                item: item,
                type: 'item',
                dragFrom: 'pull',
              });
              return (
              <DragItem
                id={id}
                key={item.id}
                data={(
                  <div
                    className={CSS.itemBox}
                    style={{
                      color: get(currentItem, 'id') === item.id ? "#F2F2F2" : colorByTier(item.tier),
                      background: get(currentItem, 'id') === item.id ? colorByTier(item.tier) : "none",
                      borderTop: `8px solid ${colorByTier(item.tier)}!important`,
                      border: `1px solid ${colorByTier(item.tier)}`,
                    }}
                    id={get(currentItem, 'id') === item.id ? "current" : null}
                    onClick={() => setCurrentItem(item)}
                  >
                    <img
                      alt="item"
                      style={{
                        height: bigSizeTypes.includes(item.slotType.name) ? '120px' : "80px",
                        marginRight: "4px",
                      }}
                      src={item.image}
                    />
                    <div style={{ width: "260px" }}>
                      {item.name}
                    </div>
                  </div>
                )}
                type={item.slotType.name}
                callback={(e) => e ? setCurrentDraggedItem(id) : setCurrentDraggedItem(null)}
              />
            )})}
          </div>
          <div className={CSS.descriptionBox}>
            {searchString === 'antifun' && ['viewer'].includes(get(currentUser, 'user.type', '')) && (
              <>
                <div className={CSS.itemTitle}>
                  {"Антифан <3"}
                </div>
                <div className={CSS.itemSlotType}>
                  {"Вот он, мой любимый антифан, слева на права ->"}
                </div>
                <div className={CSS.descriptionTitle} style={{ margin: '8px 0' }}>
                  {"Крабецк, Торбыч, Албанц, Эланта, Фуа, Хейнтари, ХаниРамонка, Мелхахёмушка, Йах_Мик, Калабочек(EZY), Лизуня, МэдЭнеми (или МэдЙенами?), Нимферночка, Сигалыч(EZY?), Славладинчик, БугимэнЛул"}
                </div>
                <div className={CSS.descriptionTitle}>
                  {"Ну и остальных ребяток не забывает: Хроник, Лёшечка(без трусиков), Лобстерочек, Пету4, Ананасик, Колайчик, МешокМасла(осуждаю), БраунДи, Калейда, Привет Турутутсик, Гиииду0"}
                </div>
                <div className={CSS.descriptionTitle}>
                  {"Приветы ребяткам стримерам: СлексБой, Свити..(шизов)"}
                </div>
                <div className={CSS.descriptionTitle} style={{ margin: '8px 0' }}>
                  {"Всех чатерсов Антифана, Кастомщиков, Дотеров, Рыбаков. Крепко обнял, поцеловал (не по гейски)"}
                </div>
                <div className={CSS.descriptionTitle}>
                  {"Если кого забыл, простите, пепаПат <3"}
                </div>
                <div className={CSS.descriptionTitle}>
                  {"Пуф и все все все пушистики(и другие) домашние любимцы, вам самый большой чмок"}
                </div>
              </>
            )}
            {currentItem && (
              <>
              <div className={CSS.itemTitle}>{currentItem.name}</div>
              <div className={CSS.itemSlotType}>{currentItem.slotType.name}</div>
              <div className={CSS.itemTier} style={{ color: colorByTier(currentItem.tier) }}>Уровень {currentItem.tier}</div>
              <div>
                {currentItem.isAutomated && (
                  <div className={CSS.attention}>Предмет автоматизирован</div>
                )}
              </div>
              {currentItem.slotType.name === "Оружие" && (
                <>
                  <div className={CSS.descriptionTitle}>Тип</div>
                  {currentItem.occupiedSlots === 1 ? 'Одноручное' : 'Двуручное'}
                </>
              )}
                {!isEmpty(get(currentItem, 'characteristics', [])) && (
                  <>
                    <div className={CSS.descriptionTitle}>Свойства</div>
                    {currentItem.characteristics.sort().map(characteristic => (
                      <div style={{ color: characteristic.includes("+") ? "#28DE94" : "#F24463" }}>
                        {characteristic}
                      </div>
                    ))}
                  </>
                )}
                {get(currentItem, 'charges') !== 'none' && (
                  <>
                    <div className={CSS.descriptionTitle}>Количество зарядов</div>
                    {currentItem.charges}
                  </>
                )}
                {get(currentItem, 'additionalProperty') && (
                  <>
                    <div className={CSS.descriptionTitle}>Дополнительное свойство</div>
                    {currentItem.additionalProperty}
                  </>
                )}
                {get(currentItem, 'description') && (
                  <>
                    <div className={CSS.descriptionTitle}>Описание</div>
                    {currentItem.description}
                  </>
                )}
              </>
            )}
          </div>
        </div>
    </>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [allTypes, bigSizeTypes, byName, byType, currentItem, currentItems, currentTypes, updateCurrentTypes, currentTypes, searchString]);

  if (['viewer', 'deputy'].includes(get(currentUser, 'user.type', ''))) {
    return (
      <div className={CSS.viewerWrapper}>
         {renderSearch()}
      </div>
    )
  }

  return (
    <>
      <div className={CSS.wrapper}>
        <div style={{ zIndex: 1, padding: "6px 0 0 6px" }}>
          {renderHead()}
          {renderBody()}
          {renderLegs()}
          {renderWeapon()}
        </div>
          <div style={{ position: 'relative' }}>
            {renderImage()}
            <div className={CSS.bottomButtons}>
            <DropItem
              onDrop={data => {
                const parsedData = JSON.parse(data);
                addToCompare(parsedData.item);
              }}
            >
              <button
                className={classNames(CSS.compareButton, { [CSS.compareButtonActive]: isOpenedCompare })}
                onClick={() => dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.compare.name, true))}
              >
                Сравнение
              </button>
            </DropItem>
            <DropItem
                onDrop={(data) => {
                  const parsedData = JSON.parse(data);
                  if (parsedData.type === "augment") {
                    return removeAugmentItem(parsedData.slotId, parsedData.augmentId);
                  }
                  return removeItem(parsedData.slotId);
                }}
              >
                <button
                  className={classNames(CSS.deleteButton)}
                >
                  Выбросить
                </button>
              </DropItem>
          </div>
          </div>
      <div style={{ zIndex: 1, padding: "6px 6px 0 0" }}>
          {renderImplants()}
          {renderAccessories()}
          {renderBackpack()}
          {renderSearch()}
      </div>
      <Popover
        className={CSS.popover}
        open={anchorEl.item}
        anchorEl={anchorEl.target}
        anchorOrigin={{
                    vertical: 'right',
                    horizontal: 'right',
        }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
        >
          <ItemPopover anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} handleChargeClick={handleChargeClick} canEdit />
      </Popover>
      </div>
    </>
  )
}

export default Inventory;
