import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class DataService {

  constructor() {
    this.config = new Configuration();
    this.data = {}
    this.logs = {}
    this.trophies = []
    this.groups = []
    this.sessions = []
    this.rules = ''
  }

  async retrieveData(playerID, tab) {
    return API.get(this.config.INFO, {params: { playerID, tab, steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') }})
      .then(response => {
        return this.data = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async retrieveLogs() {
    return API.get(this.config.LOGS, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.logs = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async retrieveTrophies() {
    return API.get(this.config.TROPHIES, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.trophies = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async retrieveSocialGroup() {
    return API.get(this.config.SOCIAL_GROUPS, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.groups = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async retrieveRules() {
    return API.get(this.config.RULES, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.rules = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async retrieveSessions() {
    return API.get(this.config.RULES, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.sessions = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default DataService;
