import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Draggable from 'react-draggable';
import styled from "styled-components";
import { encounterInteraction } from '../../../api/socket';
import diceIcon from "../../../assets/icons/diceIcon.svg";
import characteristicIcon from "../../../assets/icons/characteristicIcon.svg";
import otherIcon from "../../../assets/icons/otherIcon.svg";
import allActions from "../../../state/actions";
import { useDispatch, useSelector } from "react-redux";
import {useSpring, animated} from 'react-spring';
import parse from 'html-react-parser';
import CSS from '../Encounter.module.scss';
import Popover from '@material-ui/core/Popover';
import RenderStats from '../../CharacterInfo/RenderStats';

const HeadWrapper = styled.div`
    padding: 18px;
    background: #212D39;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    box-shadow: 4px 0 0 0 rgba(0, 0, 0, 0.25);
`;

const MessageBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    overflow-y: auto;
    max-height: 600px;
`;

const Message = styled.div`
    padding: 10px;
    background: #14141C;
    border-radius: 5px;
    margin-bottom: 8px;
    white-space: break-spaces;
    font-size: ${props => props.isEffect && '24px'};
    font-weight: ${props => props.isEffect && 'bold'};

`;

const BodyGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 16px 8px;
`;

const DialogBlock = styled.div`
    grid-area: text;
    cursor: pointer;
    padding: 4px;
    margin: 0 0 4px 4px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    border-left: 4px solid rgb(33, 45, 57);
    :hover {
        background: rgb(33, 45, 57);
        box-shadow: 2px 2px 0 0 rgba(33, 45, 57, 0.5);
    }
`;
const DialogText = styled.div`
    font-weight: normal;
    opacity: 0.5;
    font-style: italic;
`;
const DialogCondition = styled.div`
    grid-area: condition;
    display: flex;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    background: rgb(33, 45, 57);
    font-weight: bold;
    font-size: 12px;
    padding: 4px;
    margin-bottom: 4px;
    box-shadow: 2px 2px 0 0 rgba(33, 45, 57, 0.5);
`;

const DialogWrapper = styled.div`
    display: grid;
    grid-template-areas: "condition text";
    grid-template-columns: 30% 70%;
    width: 100%;
`;

const conditionTypeIcons = {
    dice: diceIcon,
    characteristic: characteristicIcon,
    other: otherIcon,
}

export default function DraggableDialog({ pilot, data, dialogRef, isComponentVisible, handleClose, removeData }) {

    const { o, xyscale } = useSpring({
        from: { o: 0, xyscale: [0, 0] },
        o: isComponentVisible ? 1 : 0,
        xyscale: isComponentVisible ? [0, 0] : [-500, 400],
    });
    const [variant, setVariant] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const currentPlayer = useSelector(state => state.currentPlayer);
    const dispatch = useDispatch();

    // const [anchorEl, setAnchorEl] = useState({});

    // const handlePopoverOpen = (event, id) => {
    //     console.log(id);
    //   if (anchorEl.id === id) {
    //       return handlePopoverClose();
    //   }
    //   setAnchorEl({
    //       id: id,
    //       target: event.currentTarget,
    //   });
    // };
  
    // const handlePopoverClose = () => {
    //   setAnchorEl({});
    // };

    if (!pilot || !data) { return <></>; }

    function sendResult(variant) {
        encounterInteraction(data.id, variant.id);
        saveToNotes(variant.result);
    }

    function close(variant) {
        setVariant(null);
        setIsComplete(false);
        sendResult(variant);
        removeData();
        handleClose();
    }

  function renderHead() {
      return (
          <HeadWrapper id="draggable-dialog-title">
              <img style={{ width: "320px", height: "220px" }} alt="character" src={pilot} />
              <MessageBlock className={CSS.scrollbar}>
                    <Message>Мы прибыли на место</Message>
                    <Message><b>{data.name}</b><br/>{data.description}</Message>
                    {variant && (
                        <>
                            {variant.result.text && <Message style={{ fontSize: '18px!important' }}>{parse(variant.result.text)}</Message>}
                            {variant.result.effects && <Message isEffect>{parse(variant.result.effects)}</Message>}
                            <div className={CSS.messageEnd} onClick={() => setVariant(null)}>Отменить</div>
                            {!isComplete && <div className={CSS.messageEnd} onClick={() => setIsComplete(true)}>Да, я прочитал Лор</div>}
                            {isComplete && (
                                <div className={CSS.messageEnd} onClick={() => close(variant)}>Закончить</div>
                            )}
                        </>
                    )}
              </MessageBlock>
          </HeadWrapper>
      )
  }

    function saveToNotes(result) {
        dispatch(allActions.encountersNotesActions.saveNote({
        title: `Случайная встреча (${data.name})`,
        content: result.effects,
        date: Date.now(),
        }));
    }

    function openDicer() {
        handleClose();
        dispatch(allActions.widgetsActions.changeIsOpened('dicer', true));
    }

  function renderBody() {
      return (
          <BodyGroup>
              {data.variants && data.variants.map((variant) => (
                  <DialogWrapper key={variant.id}>
                    {variant.option.condition && (
                        <DialogCondition>
                            <img style={{ width: "20px", marginRight: "6px" }} alt="icon" src={conditionTypeIcons[variant.option.condition.type]} />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {variant.option.condition.text}
                                {variant.option.condition.type === "dice" && <div onClick={openDicer} style={{ fontStyle: 'italic' }}>Бросить кубик</div>}
                            </div>
                        </DialogCondition>
                    )}
                    <DialogBlock onClick={() => setVariant(variant)}>
                      {variant.option.text}
                      <DialogText>
                        {variant.option.shortEffects}
                      </DialogText>
                  </DialogBlock>
                  </DialogWrapper>
              ))}
              {/* <Popover
                className={CSS.popover}
                open={!!anchorEl.id}
                anchorEl={anchorEl.target}
                anchorOrigin={{
                            vertical: 'right',
                            horizontal: 'right',
                }}
                            transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                >
                <div>
                <RenderStats characteristics={currentPlayer.player.characteristics} />
                </div>
                </Popover> */}
          </BodyGroup>
      )
  }

  return (
    <animated.div style={{
        opacity: o,
        visibility: o.interpolate(o => o === 0 ? 'hidden' : 'visible'),
        transform: xyscale.interpolate((x, y, scale) => `translate(${x}px, ${y}px)`),
    }} className={CSS.dialog} ref={dialogRef}>
        {renderHead()}
        <Collapse direction="down" in={!variant} mountOnEnter unmountOnExit>
            {renderBody()}
        </Collapse>
    </animated.div>
  );
}
