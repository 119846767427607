const changeDialogIsOpened = (data) => {
    return {
      type: "CHANGE_DIALOG_OPENED",
      payload: data,
    }
  }

const changeDialogHasData = (data) => {
    return {
      type: "CHANGE_DIALOG_HAS_DATA",
      payload: data,
    }
  }
  
const setCurrentCharacterInfo = (data) => {
  return {
    type: "SET_CURRENT_DIALOG_INFO",
    payload: data,
  }
}

  export default {
    changeDialogIsOpened,
    changeDialogHasData,
    setCurrentCharacterInfo,
  }
  