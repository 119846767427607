import React, { useMemo, useState, useCallback, useEffect } from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import NotesList from "./NotesList/NotesList";
import AddNote from "./AddNote/AddNote";
import allActions from "../../state/actions";
import CSS from './Notes.module.scss';
import isEmpty from 'lodash/isEmpty';

const Notes = () => {

  const notes = useSelector(state => state.notes);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [editedNote, setEditedNote] = useState({});

  const handleSaveNote = () => {
    const _defaultTitle = notes.length > 0
      ? `Заметка-${notes.length+1}`
      : 'Заметка';
    const _title = title || _defaultTitle;
    const _content = content || 'Ничего нет';

    if (!isEmpty(editedNote)) {
      dispatch(allActions.notesActions.editNote({
        title: _title,
        content: _content,
        id: editedNote.id,
      }));
    } else {
      dispatch(allActions.notesActions.saveNote({
        title: _title,
        content: _content,
        date: Date.now(),
      }));
    }
    setTimeout(() => {
      setTitle("");
      setContent("");
      setEditedNote({});
    }, 100);
  }

  const handleDeleteNote = useCallback((id) => {
    dispatch(allActions.notesActions.deleteNote(id));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(editedNote)) {
        setTitle(editedNote.title);
        setContent(editedNote.content);
    }
  }, [editedNote]);

  const noData = useMemo(() => (
    <div className={CSS.notesListEmpty}>
      У вас ещё нет заметок
    </div>
  ), []);

  const notesList = useMemo(
    () => <NotesList notes={notes} setEditedNote={setEditedNote} hasEdit deleteCallback={handleDeleteNote} />,
    [notes, handleDeleteNote]
  );

  return (
    <div className={CSS.wrapper}>
      {_.isEmpty(notes) ? noData : notesList}
      <AddNote
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        saveCallback={handleSaveNote}
        setEditedNote={setEditedNote}
        hasEditedNote={!isEmpty(editedNote)}
      />
    </div>
  )
}

export default Notes;
