import React, { useState, useEffect } from 'react';
import CustomSelect from './components/Select';
import get from 'lodash/get';
import CSS from './CustomWidget.module.scss';
import CustomInput from './components/Input';
import CustomNumber from './components/Number';
import { getCustomWidgetInfo, sendCustomWidgetData, removeCustomWidgetInfo } from '../../api/socket';
import { useSelector } from 'react-redux';

const CUSTOM_WIDGET_TYPES = {
    FIELDS: 'fields',
    MAP: 'map',
}

const FIELD_TYPES = {
    SELECT: 'select',
    INPUT: 'input',
    NUMBER: 'number',
}

const CustomWidget = () => {


    const currentPlayer = useSelector(state => state.currentPlayer);
    const [data, setData] = useState(null);
    const [widgetId, setWidgetId] = useState(null);
    const [result, setResult] = useState({});

    const updateCustomWidget = (response) => {
        setWidgetId(response.id);
        setData(response.data);
    }

    useEffect(() => {
        if (get(currentPlayer, 'player.id')) {
            removeCustomWidgetInfo();
            getCustomWidgetInfo(updateCustomWidget);
        }
        return () => {
            removeCustomWidgetInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get(currentPlayer, 'player.id')]);

    const renderField = (field) => {
        switch (field.type) {
            case FIELD_TYPES.SELECT:
                return <CustomSelect data={field} setResult={setResult} />
            case FIELD_TYPES.INPUT:
                return <CustomInput data={field} setResult={setResult} />
            case FIELD_TYPES.NUMBER:
                return <CustomNumber data={field} setResult={setResult} />
            default:
                <div>Ничего</div>
        }
    }

    const renderByType = () => {
        switch (get(data, 'type', null)) {
            case CUSTOM_WIDGET_TYPES.FIELDS:
                return get(data, 'data') && get(data, 'data', []).map((field) => renderField(field));
            case CUSTOM_WIDGET_TYPES.MAP:
            default:
                <div>Ничего</div>
        }
    }

    const handleEnd = () => {
        sendCustomWidgetData({ id: widgetId });
        setWidgetId(null);
        setResult(null);
        setData(null);
    }

    const handleAccept = () => {
        sendCustomWidgetData({ id: widgetId, data: result });
        setWidgetId(null);
        setResult(null);
        setData(null);
    }

    if (!widgetId) return <></>;

    return (
        <div className={CSS.wrapper}>
            <div className={CSS.header}>{get(data, 'name')}</div>
            {renderByType()}
            <button className={CSS.button} onClick={handleAccept}>Принять</button>
            <button className={CSS.button} onClick={handleEnd}>Отменить</button>
        </div>
    )
}

export default CustomWidget;