import React, {useMemo, useRef} from 'react';
import CSS from './Rules.module.scss';
import {getCookie} from "../../utils/utils";

const Rules = () => {
    const sessionName = getCookie('sessionName');

    const hpgRulesRef = useRef(null);
    const rerollRules = useRef(null);
    const completeRules = useRef(null);

    const siteRulesRef = useRef(null);
    const moveOrderRef = useRef(null);
    const movePhaseRef = useRef(null);
    const playPhaseRef = useRef(null);
    const copmletePhaseRef = useRef(null);
    const buyPhaseRef = useRef(null);

    const bonusesRef = useRef(null);
    const equipRef = useRef(null);
    const characterRef = useRef(null);
    const weaponRef = useRef(null);
    const accessoriesRef = useRef(null);
    const implantRef = useRef(null);
    const recruitRef = useRef(null);
    const mapRef = useRef(null);
    const traderRef = useRef(null);
    const sectorRef = useRef(null);
    const torRef = useRef(null);
    const saboteurRef = useRef(null);

    const randomRef = useRef(null);
    const mechanicsRef = useRef(null);
    const rogueLikePresetsRef = useRef(null);

    const isWhopg = useMemo(() => sessionName?.toLowerCase().indexOf('whopg') >= 0, [sessionName]);

    return (
        <div className={CSS.wrapper}>
            <div className={CSS.pageWrapper}>
                        <div className={CSS.menu}>
                            <div className={CSS.globalLink} onClick={() => hpgRulesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Общие правила {isWhopg ? 'WhoPG 2' : 'HPG 3'}</div>
                            <div className={CSS.menuBigLink} onClick={() => rerollRules.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Правила реролла</div>
                            <div className={CSS.menuBigLink} onClick={() => completeRules.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Правила прохождения</div>
                            <div className={CSS.globalLink} onClick={() => siteRulesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Правила работы с сайтом</div>
                            <div className={CSS.menuBigLink} onClick={() => moveOrderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Порядок хода</div>
                            <div className={CSS.menuLink} onClick={() => movePhaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Фаза движения</div>
                            <div className={CSS.menuLink} onClick={() => playPhaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Фаза прохождения игры</div>
                            <div className={CSS.menuLink} onClick={() => copmletePhaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Фаза получения награды</div>
                            <div className={CSS.menuLink} onClick={() => buyPhaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Фаза покупок</div>
                            <div  className={CSS.menuBigLink} onClick={() => bonusesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Бонусы и штрафы</div>
                            <div  className={CSS.menuBigLink} onClick={() => equipRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Экипировка</div>
                            <div  className={CSS.menuBigLink} onClick={() => characterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Персонажи</div>
                            <div  className={CSS.menuLink} onClick={() => weaponRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Торговец оружием</div>
                            <div  className={CSS.menuLink} onClick={() => accessoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Барахольщик</div>
                            <div  className={CSS.menuLink} onClick={() => implantRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Торговец имплантами</div>
                            <div  className={CSS.menuLink} onClick={() => recruitRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Рекрутер</div>
                            <div  className={CSS.menuLink} onClick={() => mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Картограф</div>
                            <div  className={CSS.menuLink} onClick={() => traderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Трейдер</div>
                            <div  className={CSS.menuLink} onClick={() => sectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Смотрящий сектора</div>
                            <div  className={CSS.menuLink} onClick={() => torRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>ТОР</div>
                            <div  className={CSS.menuLink} onClick={() => saboteurRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Диверсант</div>
                            <div  className={CSS.menuBigLink} onClick={() => randomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Случайные встречи</div>
                            <div  className={CSS.menuBigLink} onClick={() => mechanicsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Особенности механик</div>
                            <div  className={CSS.menuBigLink} onClick={() => rogueLikePresetsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Правила пресета "Рогалики"</div>
                            <div  className={CSS.globalLink}>
                                <a className={CSS.link} style={{ textDecoration: 'none' }} href={isWhopg ? "https://docs.google.com/document/d/1FXxiTaQRGrHUkODoBk7C33p5Luo3ibpk3Uar5H0Lruo/edit#" : "https://docs.google.com/document/d/1vyPe-b51N0L8ppISpCmvCvKm54ig2Gu-c1EzQXHLBZY/edit?usp=sharing"} rel="noreferrer" target="_blank">
                                    Главный мануал
                                </a>
                            </div>
                        </div>
                        <div className={CSS.rulesBox}>
                            <div className={CSS.headerBig} ref={hpgRulesRef}>Общие правила {isWhopg ? 'WhoPG 2' : 'HPG 3'}</div>
                            <div className={CSS.textBox}>1. Этот сезон, в отличии от предыдущих является аналогом ролевой настольной игры, где стримеры должны на карте вымышленного города захватывать сектора путем прохождения игр. Победа представляет из себя комплекс кубков (или трофеев), которые выдаются стримерам за достижения разных целей.</div>
                            <div className={CSS.textBox}>2. Во время прохождения ХПГ, стримеры будут активировать случайные встречи, одна активация которой стоит <b>{isWhopg ? '800' : '2000'} рублей</b>. <br/> Всего за ход можно активировать {isWhopg ? '6' : '5'} случайных встреч за донат и 1 случайную встречу за поинты ({isWhopg ? '50 000' : '100 000'} поинтов - ролл колеса). <br/>{isWhopg ? '' : 'В отличие от предыдущих сезонов, подарочные или новые подписки никак не влияют на активацию случайных встреч.'} <br/> О более понятном принципе работы случайных встреч можно почитать в <b className={CSS.link} onClick={() => siteRulesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Главном мануале</b>. </div>
                            <div className={CSS.header} ref={rerollRules}>3. Правила реролла игр.</div>
                            <div className={CSS.textBox}>Легитимным рероллом считается тот слуай, когда:</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не имеет концовки (ММО, песочница, бесконечный тайкун без сюжета, контурные карты по типу Цивилизации)</div>
                            <div className={CSS.textBoxWithOffset}>- VR-игра</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не захватывается ОБСом или Хсплитом и\или ломает экраны (чаще всего происходит с играми 90ых\начала 00ых годов, где нет выбора разрешения, запуска в оконном режиме и т.д.)</div>
                            <div className={CSS.textBoxWithOffset}>- Игра представляет из себя ДЛС к основе и требует установки этой самой основы</div>
                            <div className={CSS.textBoxWithOffset}>- Некорректная стоимость игры (возникает, когда игра оказывается в каком-то бандле и показывается стоимость всего бандла, а не отдельной игры, или показывает подписку в Ориджин, виндоус сторе и т.д.). Данное правило реролла работает только в случае, если в особенностях игры на секторе есть показатель "Стоимость".</div>
                            <div className={CSS.textBoxWithOffset}>- Игра не может быть транслирована на твич или не имеет русского, украинского или английского интерфейса</div>
                            <div className={CSS.textBoxWithOffset}>- Если игра у конкретного стримера выпадает второй раз, учитывая первый сезон, или это ремастер уже выпадавшей игры, или это оригинал ремастера, который уже выпадал. Рероллы и дропы с первого сезона не учитываются, только пройденные игры. Данное правило не работает на секторах с Пресетами.</div>
                            <div className={CSS.textBoxWithOffset}>- Обязательным рероллом являются визуальны новеллы, текстовые РПГ, Тривии и подобные викторины. Визуальной новеллой считается игра с практически полным отсутствием геймплея (исключения редкие мини-игры в таких ВН как Doki Doki), кроме чтения текста и принятия каких-то выборов. Текстовой РПГ считается любая игра, где кроме текста нет ничего.</div>
                            <div className={CSS.textBoxWithOffset}>- Игры в раннем доступе могут быть реролльнуты в течение первых 30 минут геймплея по усмотрению стримера. Если по истечении 30 минут стример не реролльнул игру, то даже если она впоследствии окажется непроходимой, ее нужно будет дропать.</div>
                            <div className={CSS.textBoxWithOffset}>- Стример может рероллить сборник игр (2 в 1, 3 в 1 и т.д.) по своему усмотрению. У стримера так же есть опция пройти весь сборник, но он будет засчитан как 1 игра. Данное решение нужно принять до того, как стример зашел в сборник.</div>
                            <div className={CSS.textBoxWithOffset}>- Стример имеет право рероллить любой спортивный или гоночный симулятор по своему усмотрению. Если стример видит, что у игры есть прохождение, он так же в праве пройти этот симулятор. Но если по ходу прохождения выяснится, что стример ошибся, то игру реролльнуть уже нельзя.</div>
                            <div className={CSS.textBoxWithOffset}>- Производится обязательный реролл, когда игра не соответствует условиям сектора, на которой она зароллена. Некорректная стоимость или Дата Выхода, а так же объективно неверная информация относительно длительности игры (когда колесо ссылается на другую игру, а не ту, которая выпала на колесе)</div>
                            <div className={CSS.header} ref={completeRules}>4. Игра считается пройденной, если:</div>
                            <div className={CSS.textBoxWithOffset}>- Стример дошел до финала игры. Увидеть титры не считается дойти до финала игры, т.к. не в каждой игре есть титры, а в некоторых титры можно вызывать на первых минутах игры (Far Cry 5, South Park: FBW), во время Bad Ending (По сути Game Over) или если это фишка игры (MGS V после каждой миссии вызывались титры, nier:automata где игра поделена на несколько актов, и после каждого из них появляются титры).</div>
                            <div className={CSS.textBoxWithOffset}>- Стример прошел игру на Альтернативную концовку. Порой у игр бывает несколько концовок, некоторые из которых могут произойти раньше других, но при этом не считаются плохим окончанием игры, а представляют из себя альтернативный вариант исхода истории. В таком случае стример может засчитать игру пройденной, но лучше посоветоваться с другими участниками, дабы не вызвать разбирательств в дальнейшем.</div>
                            <div className={CSS.textBoxWithOffset}>- В игре без сюжета, при наличии уровней, необходимо разблокировать все основные уровни (бонус или экстра уровни проходить необязательно). Если все уровни разблокированы изначально, нужно пройти каждый из них.</div>
                            <div className={CSS.textBoxWithOffset}>- Если в игре нет сюжета, или уровней, но есть программный High Score (Тот, который установил именно разработчик, а не игроки в онлайне), то прохождением игры будет считаться, если этот ХС будет побит. В отдельных случаях есть игры с несколькими уровнями, где единственным показателем прохождение уровня является ХайСкор. В таком случае этот ХайСкор нужно побить на каждом уровне.</div>
                            <div className={CSS.textBoxWithOffset}>- В играх жанра rogue-like без ярко-выраженной цели требуется созвониться с другими участниками и определить что будет являться прохождением игры.</div>
                            <div className={CSS.textBoxWithOffset}>- В Стратегиях с кампаниями необходимо пройти все кампании. Если выпадает стратегия, в которой нет кампании, но есть исторические сборки военных операций, миссиий и прочего, следует посовещаться с остальными участниками на предмет того, что будет являться прохождением.</div>
                            <div className={CSS.textBoxWithOffset}>- Если выпадает игра, где есть несколько историй , которые открыты одновременно (Resident Evil 2 Remake), или другие истории открываются лишь после прохождения основной сюжетки (Resident Evil 4) необходимо посовещаться с остальными участниками на предмет того, как будет засчитываться прохождение в подобного типа игр.</div>
                            <div className={CSS.textBoxWithOffset}>- Если выпадает версия игры в стиле GOTY Edition со всеми включенными ДЛС, для зачета прохождения игры стример должен пройти ТОЛЬКО основную игру, без побочных ДЛС и прочего контента.</div>
                            <div className={CSS.textBoxWithOffset}><i><b>И самое важное!</b><br/> Если вы не уверены в том, что игра пройдена честно и хотите ее засчитать, хоть даже на подсознательном уровне думаете, что здесь может быть что-то не чисто, лучше сразу обратиться к другим участникам и решить этот вопрос, чтобы не возникало неприятных конфузов, когда спустя время к вам приходят другие стримеры, и говорят что вы где-то накосячили.</i></div>
                            <div className={CSS.textBox}>5. Если так получилось, что стример прошел игру нелегитимно и реальное прохождение игры заключается в другом, то другие стримеры могут заставить его или перепроходить или допроходить данную игру. Однако, во избежании слишком поздней мошны, сделать они это могут в течение 24 часов после нелегитимного прохождения. Если за это время другие стримеры не захотели мошнить или не уследили за нелегитимным прохождением, то потом к этому вопросу вернуться будет уже нельзя, ровно как и использовать аргументы в дальнейших спорных ситуациях из серии "А вот этот стример месяц назад нелегитимно прошел игру". Тоже самое касается и всех рероллов. Поднять вопрос о нелегитимности реролла можно только в течение первых 24 часов с момента этого реролла.</div>
                            <div className={CSS.textBox}>6. Запрещено использование чит-кодов, трейнеров и сторонних программ, облегчающих прохождение. Баги и гличи на скип уровней разрешены.</div>
                            <div className={CSS.textBox}>7. Уровень сложности прохождения игры – стандартный, предлагаемый игрой, только если игра не требует от стримера выбора сложности. В таком случае стример оставляет за собой право назначить сложность игры (но не изи). Если в игре нет сложности, но есть разные режимы, то стример выбирает его аналогично уровню сложности. Если игровых режима только два, стример вправе выбирать более простой. Это же касается и сложности. Запрещены настройки, которые влияют на сложность прохождения игры. Чтобы понять, какая настройка влияет на уровень сложности, каждый такой случай рассматривается отдельно.</div>
                            <div className={CSS.textBox}>8. При дропе игры происходит сразу несколько манипуляций с прогрессом стримера в ивенте, о которых более подробно написано в <a className={CSS.link} href="https://docs.google.com/document/d/1vyPe-b51N0L8ppISpCmvCvKm54ig2Gu-c1EzQXHLBZY/edit?usp=sharing" rel="noreferrer" target="_blank">
                                    <b>главном мануале</b>
                                </a> или его <b className={CSS.link} onClick={() => siteRulesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>которкой выжимке</b>.</div>
                            <div className={CSS.textBox}>9. Смотреть прохождение игры запрещено. Проследить за этим почти невозможно, но этот пункт остается на совести стримера. Разрешены подсказки из чата, если: </div>
                            <div className={CSS.textBoxWithOffset}>- Это не копипаста прохождения игры с какого-либо сайта.</div>
                            <div className={CSS.textBoxWithOffset}>- Если эти подсказки не связаны со спидранами, багами и абузами, позволяющими сократить бОльшую часть времени, проведенное в игре."</div>
                            <div className={CSS.textBox}>10. {isWhopg ? 'Победителем становится тот, у кого по окончанию ивента наибольший результат в кубке “Триумфатор”.' : 'Победителем становится тот, у кого по окончании ивента больше всего Кубков (или трофеев).'} О том, что такое трофеи, сколько их и как они получаются, более подробно написано в <a className={CSS.link} href={isWhopg ? 'https://docs.google.com/document/d/1FXxiTaQRGrHUkODoBk7C33p5Luo3ibpk3Uar5H0Lruo/edit#' : '"https://docs.google.com/document/d/1vyPe-b51N0L8ppISpCmvCvKm54ig2Gu-c1EzQXHLBZY/edit?usp=sharing"'} rel="noreferrer" target="_blank">
                                    <b>главном мануале</b>
                                </a> или его <b className={CSS.link} onClick={() => siteRulesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>которкой выжимке</b>.</div>
                            <div className={CSS.header}>11. Награды:</div>
                            <div className={CSS.textBoxWithOffset}>
                                {isWhopg ? 'Первое место получает по 30 сабов от каждого участника ивента и кубок победителя.' : 'Первое место получает по 50 сабов от каждого другого участника ивента.'}
                            </div>
                            <div className={CSS.textBoxWithOffset}>
                                {isWhopg ? 'Второе место получает месяц автохостов + рейдов от всех других участников, кроме первого места.' : 'Второе место получает 2 недели автохоста + авторейд от всех других участников, кроме первого места.'}
                            </div>
                            <div className={CSS.textBoxWithOffset}>
                                {isWhopg ? 'Третьему месту все остальные участники заказывают любую еду на дом, под прием которой он должен совершить просмотр аниме от четвертого и пятого места.' : 'Третье и четвертое место не получают ни приза, ни наказания.'}
                            </div>
                            <div className={CSS.textBoxWithOffset}>
                                {isWhopg ? 'Четвертое и пятое место должны записать двухголосую озвучку аниме (хронометраж которого должен составлять минимум 1 час или 3 серии). Что именно озвучивать выбирает второе место.' : 'Пятое место в течение целого стрима, который должен длиться не меньше 5 часов, должен слушить по кругу рэп от стримера, занявшего последнее место.'}
                            </div>
                            <div className={CSS.textBoxWithOffset}>
                                {isWhopg ? 'Шестое место в течении целого стрима, который должен длиться не меньше 5 часов, должен ходить по улицам города и собирать бутылки.' : 'Шестое место записывает рэп в вольном исполнении минимум на три минуты, связанный с ХПГ. Желательно разнообразие текста, а не одна фраза, повторяющаяся в течение всего трека, но это уже на совести проигравшего стримера. После записи рэпа, в течение следующих 15 стримов, каждый зритель может заказать этот трек за 300 рублей. Стример не имеет право отказаться.'}
                            </div>
                            {isWhopg && (
                              <>
                              <div className={CSS.textBoxWithOffset}>
                                  На выполнение условий согласно занятым местам - дается 3 месяца после окончания ивента. Если условия не были выполнены в данный срок - участник навсегда исключается из WhoPG!
                              </div>
                              <div className={CSS.textBoxWithOffset}>
                                  Если наказания были исполнены на «похуй», то на следующий сезон вам попросту может не прийти приглашение.
                              </div>
                              </>
                            )}
                            <div className={CSS.textBox}>12. Любые непредвиденные моменты, не прописанные в правилах, решаются стримером по его усмотрению при отсутствии свидетелей.</div>
                            <div className={CSS.textBox}>13. Если один из стримеров дропает челленж, то он пожизненно исключается из ХПГ и не сможет принимать участие в следующих ивентах, а также получит официальную приставку "Чм-" к своему нику (только если это не коллективный дроп всеми участниками ивента).</div>
                            <div className={CSS.headerBig} ref={siteRulesRef}>Правила работы с сайтом</div>
                            <div className={CSS.header} ref={moveOrderRef}>Порядок хода</div>
                            <div className={CSS.textBox} ref={movePhaseRef}><b>Фаза движения:</b><br /> Возможно провести разведку сектора <i>(по умолчанию 1 сектор каждый ход)</i>. <br /> Совершить движение по секторам <i>(по умолчанию 3 ОД каждый ход)</i>. <br /> В случае движения на вражеский или нейтральный сектор все оставшиеся ОД тратятся. <br /> ОД не тратится при передвижении на свой стартовый сектор.</div>
                            <div className={CSS.textBox} ref={playPhaseRef}><b>Фаза прохождения игры:</b><br /> Производится ролл колеса в соответствии с условиями текущего сектора с учетом бонусов или штрафов этого сектора <b className={CSS.link} onClick={() => bonusesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>(см. Бонусы и штрафы)</b>, которые влияют на ролл. <br/> Далее игрок либо <u style={{ color: ' #32aa0c' }}>проходит игру</u>, либо <u style={{ color: '#f1dd4f' }}>рероллит</u>, либо <u style={{ color: '#F24463' }}>дропает</u>.</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#32aa0c' }}>Прохождение игры:</b><br/> Этап непосредственно прохождения игры может занимать не более 30 часов в неделю, если не применены какие-либо модификаторы. <br /> При успешном <b className={CSS.link} onClick={() => completeRules.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>(см. Условия)</b> прохождении игры сектор считается захваченным, если нигде не указано иного. <br />Во время прохождения игры возможна активация Случайных встреч. <br /> После этого наступает фаза получения награды.</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#f1dd4f' }}>Реролл:</b><br/> Происходит еще один ролл колеса с текущими условиями до тех пор, пока игра не будет удовлетворять условиям прохождения <b className={CSS.link} onClick={() => rerollRules.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>(см. Условия)</b></div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#F24463' }}>Дроп:</b><br/> При дропе игры происходит потеря <b>х1\х2\х3 от того кол-ва влияния, которое можно получить за первое прохождение игры на секторе</b>, если игрок находится на <b>своем/нейтральном/чужом секторе</b>; <br/> В случае <b>нейтрального/чужого</b> сектора попытка захвата сектора считается проваленной и игрок делает шаг на последний сектор под своим контролем, если сектор не занят другим стримером (в противном случае стример возвращается на последний СВОБОДНЫЙ сектор под своим контролем, либо на стартовый сектор, если таковых нет); <br/> В случае дропа стример не может захватывать <b>нейтральные/чужие</b> сектора сложностью <b>выше среднего/сложный (высокая)/адский</b> в следующем ходу; <br /> В случае дропа ход немедленно заканчивается. </div>
                            <div className={CSS.textBox} ref={copmletePhaseRef}><b>Фаза получения награды:</b><br /> После успешного прохождения игры на <b>нейтральном/вражеском</b> секторе игрок получает награду в соответствии с описанием сектора с учетом возможных бонусов или штрафов <b className={CSS.link} onClick={() => bonusesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>(см. Бонусы и штрафы)</b>. <br /> Награда в виде влияния не получается при повторном прохождении игры на <b>своем</b> секторе. <br /> В случае прохождения игры на <b>своем</b> секторе, игрок получает +1 ОП на текущий ход вместо влияния <b className={CSS.link} onClick={() => buyPhaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>(см. Фазу покупок)</b>. <br /> Также, за прохождение на любом секторе игрок получает опыт: <b>25/50/100/200/500</b> опыта за <b>легкий/средний/выше среднего/сложный/адский сектор</b>. <i>Однако, если сектор уже принадлежит игроку, получаемый опыт делится на 2.</i> <br/> За прохождение игры на <b>своем легком</b> секторе опыт не дается. <br/> Плюс ко всему вышеперечисленному, после прохождения каждой игры, перед фазой покупок, стример может увеличить уровень персонажа в секторе на 1, за исключением Смотрящего сектора.</div>
                            <div className={CSS.textBox} ref={buyPhaseRef}><b>Фаза покупок:</b><br /> После получения награды игрок может воспользоваться услугами персонажей сектора, в котором он находится. Одно любое обращение к любому персонажу стоит 1 ОП <i>(по умолчанию у игрока 1 ОП каждый ход)</i>, если не указано обратного. <br/> Игрок не обязан тратить все ОП, но они <b>не перенесутся</b> на следующий ход. <br/> Также, при каждом прохождении игры на секторе, игрок может увеличить уровень любого персонажа, кроме Смотрящего сектора, на 1, пока они не достигнут максимально допустимого для сектора. <i>Механика повышения уровня персонажа за прохождение игры не работает на секторах с пресетами</i>. <br/> После этой фазы наступает конец хода.</div>
                            <div className={CSS.header} ref={bonusesRef}>Бонусы и штрафы:</div>
                            <div className={CSS.textBox}>Бонусы/штрафы могут влиять как на особенности игры, так и на последующее получение награды. <br/> Получение бонуса или штрафа проходит путем проверки на конкретную характеристику. <br/> Игрок не может получить одновременно и бонус, и штраф от сектора.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#f1dd4f' }}>Характеристик игрока не хватает чтобы пройти проверку на штраф или бонус – ничего не происходит.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#32aa0c' }}>Характеристик игрока не хватает чтобы пройти проверку на штраф, но хватает чтобы пройти проверку на бонус – игрок по желанию применяет бонус.</div>
                            <div className={CSS.textBoxWithOffset} style={{ color: '#F24463' }}>Характеристик игрока хватает чтобы пройти проверку на штраф, но не хватает чтобы пройти проверку на бонус – игрок обязан применить штраф.</div>
                            <div className={CSS.textBoxWithOffset}>Характеристик игрока хватает чтобы пройти проверку на штраф и на бонус – игрок смотрит на показатели своих характеристик, участвующих в проверке. Тот показатель характеристики, который больше, побеждает. Если характеристики имеют одинаковый показатель, то ничего не происходит. </div>
                            <div className={CSS.header} ref={equipRef}>Экипировка:</div>
                            <div className={CSS.textBox}>Экипировка – предметы, импланты или напарники, которые игрок получает в процессе игры. <br/> Можно иметь несколько одинаковых предметов, но нельзя иметь одинаковые импланты или напарников. <br/> После установки импланта его можно заменить или снять только у торговца имплантами без трат очков ОП. <br/> Напарника нельзя на время <i>«снять»</i>, только выгнать насовсем.</div>
                            <div className={CSS.textBox}>Предметы нельзя надеть на соответствующую им часть тела, если эта часть тела <b>ранена</b>. <br/> В результате какого-то события игрок может получить ранение. Ранение бывает легким и тяжелым:</div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#32aa0c' }}>Обычное:</b> <br/> Длится <b>Х</b> ходов <i>(указано в описании каждого ранения)</i>. <br/> <b>Излечивается:</b><br/> <i>- Cамо после <b>Х</b> ходов.</i> <br /><i>- У картографа 1 уровня или выше.</i> <br/><i>- С помощью специальных предметов.</i></div>
                            <div className={CSS.textBoxWithOffset}><b style={{ color: '#F24463' }}>Тяжелое:</b> <br/> Длится до излечения. <br/><b>Излечивается:</b><br/> <i>- У картографа 2 уровня или выше.</i><br/> <i>- C помощью специальных предметов.</i></div>
                            <div className={CSS.textBox}>Если игрок получает ранение на часть тела, в которой у него находится предмет, этот предмет можно переместить в рюкзак, если дополнительным условием получения ранения не был сброс надетого предмета.</div>
                            <div className={CSS.textBox}>Игроки не имеют права обмениваться любой экипировкой, если где-то не указано иного.</div>
                            <div className={CSS.textBox}>Игрок может менять свои предметы (переодеваться) в определенные этапы:</div>
                            <div className={CSS.textBoxWithOffset}><i>- В фазу движения, до того, как произведено открытие сектора или сделан первый шаг;</i></div>
                            <div className={CSS.textBoxWithOffset}><i>- Сразу после того, как произошла случайная встреча <b>(не во время!)</b>;</i></div>
                            <div className={CSS.textBoxWithOffset}><i>- Во время и после фазы покупок.</i></div>
                            <div className={CSS.header} ref={characterRef}>Персонажи:</div>
                            <div className={CSS.textBox} ref={weaponRef}><b>Торговец оружием</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/> Торговец оружием продает предметы на голову, туловище, ноги и оружие.</div>
                            <div className={CSS.textBox} ref={accessoriesRef}><b>Барахольщик</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/>  Барахольщик продает аксессуары.</div>
                            <div className={CSS.textBox} ref={implantRef}><b>Торговец имплантами</b> <br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/> Торговец имплантами продает импланты и аугментации, которые необходимы для разблокирования дополнительных ячеек под конкретные импланты или части тела.</div>
                            <div className={CSS.textBox} ref={recruitRef}><b>Рекрутер</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 5</i><br/>  Рекрутер предлагает нанять напарников.</div>
                            <div className={CSS.textBox} ref={mapRef}><b>Картограф</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/> Картограф позволяет провести разведку секторов и вылечить раны игрока.</div>
                            <div className={CSS.textBox} ref={traderRef}><b>Трейдер</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/>  Трейдер обменивает ваши вещи на свои. При обмене с трейдером вы всегда будете получать случайный предмет.</div>
                            <div className={CSS.textBox} ref={sectorRef}><b>Смотрящий сектора</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 3</i><br/>  После того, как игрок решит воспользоваться услугами смотрящего сектора, тот исчезает из сектора вместе с остальными персонажами на секторе. Вместо них появляются новые персонажи. Вне зависимости от того, каких персонажей сгенерировал Смотрящий Сектора, их уровни не могут превышать верхний лимит, поставленный им сложностью сектора.</div>
                            <div className={CSS.textBox} ref={torRef}><b>ТОР</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 1</i><br/>  ТОР продает уникальные предметы, которые занимают ячейки аксессуаров. После того, как игрок решает воспользоваться услугами ТОРа, он получает предмет случайным образом. </div>
                            <div className={CSS.textBox} ref={saboteurRef}><b>Диверсант</b><br/> <i style={{ opacity: 0.5 }}>максимальный уровень 1</i><br/> {isWhopg ? 'Диверсант мешает жить другим стримерам. После того, как стример решает воспользоваться услугами Диверсанта, он тратит 300 крышечек и получает случайную диверсию, которую может применить на любого ДРУГОГО игрока. Нельзя пользоваться Диверсантом два раза за один ход, даже если крышечек хватает на больше. Нельзя два раза подряд использовать диверсию на одного стримера. Это касается всех участников. Если один стример бросил в другого диверсию, то остальные участники не могут бросить в этого стримера диверсию до тех пор, пока диверсию не получит кто-то еще.' : 'Диверсант мешает жить другим игрокам. После того, как игрок решает воспользоваться услугами Диверсанта, он получает случайную диверсию, которую может применить на любого другого игрока. '}</div>
                            <div className={CSS.textBox}>Если какой-то из персонажей в секторе (или все) отказываются с вами сотрудничать до конца хода после получения штрафа или эффекта от случайных встреч, предметов и т.д., вы не можете увеличить уровень этих персонажей. </div>
                            <div className={CSS.textBox}>Если уровень одного или нескольких персонажей на обычном (не пресетовском) секторе стал равен 0, он покидает сектор до конца хода. Во время следующего хода он вернется на сектор, а его уровень будет равен 1.</div>
                            <div className={CSS.header} ref={randomRef}>Случайные встречи:</div>
                            <div className={CSS.textBox}>Случайные встречи могут быть активированы только во время прохождения игры и никак иначе. Когда будет набрана необходимая сумма донатов, будет активироваться колесо случайных встреч.</div>
                            <div className={CSS.textBox}>Случайные встречи делятся на несколько типов и механик: </div>
                            <div className={CSS.textBoxWithOffset}><b>Однозначные</b><br/>Это встречи, которые имеют лишь один эффект.</div>
                            <div className={CSS.textBoxWithOffset}><b>Бросок кубика</b><br/>Это встречи, при выпадении которого игроку необходимо подбросить кубик d12 и сопоставить полученный результат с вариантом исхода случайной встречи.</div>
                            <div className={CSS.textBoxWithOffset}><b>Проверка на характеристику</b><br/>Это встречи, которые имеют несколько вариантов развития событий, некоторые из которых могут потребовать пройти проверку на какую-либо характеристику. </div>
                            <div className={CSS.textBoxWithOffset}><b>Смешанные</b><br/>Это встречи, которые могут одновременно содержать в себе как бросок кубика, так и проверку на какую-то характеристику. </div>
                            <div className={CSS.textBoxWithOffset}><b>Простой выбор</b><br/>Это встречи, которые имеют несколько вариантов развития событий, но не требуют никаких дополнительных механик (бросок кубика или проверка на характеристики).</div>
                            <div className={CSS.textBox}>Если выбранный игроком эффект встречи оказался негативным и не может быть применен по каким-либо причинам, производится ролл из колеса неудач. <br/> <b>Колесо неудач</b> – это 8 событий, которые имеют негативный эффект, усиливающийся с повышением сложности секторов, на которых находится игрок. <br/> Тем не менее, если негативных эффектов несколько, и хотя бы один может быть выполнен, колесо неудач крутить не нужно – достаточно выполнить этот эффект. Тоже самое касается и смешанных эффектов случайных встреч. Если не может быть выполнен негативный эффект, но может быть выполнен позитивный – вам повезло, колесо крутить не нужно. </div>
                            <div className={CSS.textBox}>Если выбранный игроком эффект встречи оказался позитивным и не может быть применен по каким-либо причинам, или же игрок не хочет его применять, он может получить вместо этого <b>15\легкий, 25\средний, 35\Выше среднего, 50\сложный\адский опыта</b>.</div>
                            <div className={CSS.header} ref={mechanicsRef}>Особенности механик:</div>
                            <div className={CSS.textBox}>Предметы (ноги, туловище, голова, оружие, аксессуары) можно спокойно перемещать, переодевать, убирать в рюкзак в дозволенные фазы переодевания. <br/> Импланты, после их установки, нельзя самостоятельно снять или переместить в другие ячейки. Их может заменить или снять только торговец имплантами или эффект случайной встречи (получение\сброс импланта). Некоторые импланты имеют описание, где сказано что лишь торговец конкретного уровня и выше может их снять. Это значит, что замену этого импланта вы можете произвести лишь оказавшись у торговца соответствующего уровня. Удаление импланта также проводится у него или же в следствии эффекта случайной встречи, который требует от вас снять имплант. Удаление импланта не требует траты ОП. <br/> Стример не может иметь больше 5 одновременно надетых легендарных предмета, импланта и напарника. Легендарные аксессуары и предметы в рюкзаке не считаются. </div>
                            <div className={CSS.textBox}>Когда вы получаете новый уровень, можете увеличить любую характеристику на +1. </div>
                            <div className={CSS.textBox}>Несмотря на базовый максимальный уровень у персонажей, он также зависит от сложности сектора, в котором находится персонаж. К примеру, торговец оружием при номинальном максимальном уровне 5, может иметь лишь 3 уровень максимум на секторах легкого уровня сложности. </div>
                            <div className={CSS.textBox}>На одном секторе может находиться максимум один стример. <i>Исключение - центральный сектор "Ядро"</i>.</div>
                            <div className={CSS.textBox}>Если в какой-то момент времени вы оказались зажаты со всех сторон другими стримерами, вы имеете возможность перешагнуть через них на любой соседний от них свободный сектор. </div>
                            <div className={CSS.textBox}>Во время увеличение уровней персонажам после прохождения игры, вместо этого вы можете понизить любое количество уровней у Барахольщика или Рекрутера, т.к. почти все аксессуары и напарники имеют уникальные способности, которые могут пригодиться и на поздних этапах ивента. Если вы воспользовались понижением уровней, после фазы покупок уровни этих персонажей вернутся к значению, которое у них было до понижения.</div>
                            <div className={CSS.textBox}><div style={{ color: 'red' }}>NEW</div>Стример не может добровольно находиться на своих секторах больше одного хода. Исключением могут стать лишь эффекты, ограничивающие передвижение стримера.
                                <br/> - Дроп на своем секторе считается за <u>ХОД</u> - следующим захватом должен быть нейтральный/чужой сектор.
                                <br/> - Дроп на нейтральном/чужом секторе - <u>не считается</u> за прохождение и следующим захватом должен быть нейтральный/чужой сектор.
                            </div>
                            <div className={CSS.header} ref={rogueLikePresetsRef}>Правила прохождение игр из пресета "Рогалики"</div>
                            <div className={CSS.gameTextBox}>NECROPOLIS: BRUTAL EDITION</div>
                            <div className={CSS.textBoxWithOffset}>Убить босса на самом нижнем этаже и войти в дверь</div>
                            <div className={CSS.gameTextBox}>Hades</div>
                            <div className={CSS.textBoxWithOffset}>Убить в первый раз Аида</div>
                            <div className={CSS.gameTextBox}>Moonlighter</div>
                            <div className={CSS.textBoxWithOffset}>Получить титры после убийства босса на корабле</div>
                            <div className={CSS.gameTextBox}>The Binding of Isaac: Rebirth</div>
                            <div className={CSS.textBoxWithOffset}>Убить Мать</div>
                            <div className={CSS.gameTextBox}>Noita</div>
                            <div className={CSS.textBoxWithOffset}>Убийство последнего босса (глаз-паук)</div>
                            <div className={CSS.gameTextBox}>Meteorfall: Krumit's Tale</div>
                            <div className={CSS.textBoxWithOffset}>Пройти 9 локаций</div>
                            <div className={CSS.gameTextBox}>Hand of Fate</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все главы</div>
                            <div className={CSS.gameTextBox}>Hand of Fate 2</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все главы</div>
                            <div className={CSS.gameTextBox}>Dicey Dungeons</div>
                            <div className={CSS.textBoxWithOffset}>Пройти все миссии на одном из персонажей. <br/> <i>Примечание: чтобы открыть миссии надо пройти первую миссию за конкретного персонажа, на котором хотите проходить.</i></div>
                            <div className={CSS.gameTextBox}>Slay the Spire</div>
                            <div className={CSS.textBoxWithOffset}>Убить третьго босса</div>
                            <div className={CSS.gameTextBox}>Dead Cells</div>
                            <div className={CSS.textBoxWithOffset}>Убить the hand of the king</div>
                            <div className={CSS.gameTextBox}>Iratus: Lord of the Dead</div>
                            <div className={CSS.textBoxWithOffset}>Пройти 5 этаж</div>
                            <div className={CSS.gameTextBox}>Enter the Gungeon</div>
                            <div className={CSS.textBoxWithOffset}>Убить дракона</div>
                            <div className={CSS.gameTextBox}>Darkest Dungeon®</div>
                            <div className={CSS.textBoxWithOffset}>Убить Сердце Тьмы</div>
                            <div className={CSS.gameTextBox}>Dungeon Souls</div>
                            <div className={CSS.textBoxWithOffset}>Убить selene</div>
                            <div className={CSS.gameTextBox}>Neon Abyss</div>
                            <div className={CSS.textBoxWithOffset}>Убить 5 боссов, финальный Аргус</div>
                        </div>
                    </div>
        </div>
    )
}

export default Rules;
