import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colorByTier } from "../../utils/utils";
import CSSInventory from '../Inventory/Inventory.module.scss';
import allActions from './../../state/actions/index';

function Compare() {

    const dispatch = useDispatch();
    const compareItems = useSelector(state => state.compare);
    
      const addNoteAboutItem = (item) => {
        const _title = `Хочу: ${item.name}`;
        const _content = 'сохранено из "Сравнение"';
        dispatch(allActions.notesActions.saveNote({
          title: _title,
          content: _content,
          date: Date.now(),
        }));
      }
    
      const removeFromCompare = (item) => {
        dispatch(allActions.compareActions.deleteCompareItem(item));
      }

    return (
        <div className={CSSInventory.bottomMenu}>
          {compareItems && compareItems.map((compareItem) => (
            <div className={CSSInventory.compareItem}>
            <div
              style={{ 
                display: 'flex',
                flexDirection: 'column',
                color: colorByTier(compareItem.tier),
                background: "none",
                borderTop: `8px solid ${colorByTier(compareItem.tier)}!important`,
                border: `1px solid ${colorByTier(compareItem.tier)}`,



              }}
            >
              {compareItem.name}
              <div style={{ display: 'flex', flexDirection: 'column', width: '240px' }}>
              <div className={CSSInventory.compareItemProperty}>
                <div className={CSSInventory.compareItemHeader}>Свойства</div>
              {compareItem.characteristics.sort().map(characteristic => (
                  <div style={{ color: characteristic.includes("+") ? "#28DE94" : "#F24463" }}>
                    {characteristic}
                  </div>
                ))}
              </div>
              <div className={CSSInventory.compareItemProperty}>
                <div className={CSSInventory.compareItemHeader}>Доп.Свойство</div>
              {compareItem.additionalProperty}
              </div>
            </div>
              </div>
              <button className={CSSInventory.buttonInCompare} onClick={() => addNoteAboutItem(compareItem)}>
              Запомнить
            </button>
            <button className={CSSInventory.deleteButtonInCompare} onClick={() => removeFromCompare(compareItem)}>
              Удалить
            </button>
            </div>
          ))}
        </div>
    )
}

export default Compare;