import React, { useEffect, useState, useMemo, useCallback } from 'react';
import vg from "hex-grid/src/vg";
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import TWEEN from '@tweenjs/tween.js';
import useEventListener from '../../hooks/useEventListener';
import {useDispatch, useSelector} from "react-redux";
import * as THREE from "three";
import { animateVector3 } from "./Config";
import useDidMountEffect from '../../hooks/useDidMountEffect';
import MapInterface from "./MapIntarface/MapIntarface";
import allActions from '../../state/actions';
import { toHex } from "../../utils/utils";
import Sprite from "hex-grid/src/Sprite";
import {
  changeMapState, playerMove, playerOpen,
  subscribeToPlayersCaptured,
  subscribeToPlayersExplored,
  subscribeToPlayersMovements, unsubscribeMapActions,
  playerUncaptureCell,
  subscribeToPlayersLostCells,
} from '../../api/socket';
import ControlPanel from "./ControlPanel/ControlPanel";
import { MAP_STATES, MAP_PAINT_TYPE, TURN_STATES } from './../../consts/map';
import allWidgets from './../index';
import DataService from './../../api/DataService';

const Map3D = ({ isMobile, closeCallback }) => {

  const dataService = useMemo(() => new DataService(), []);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);
  const mapState = useSelector(state => state.mapState);
  const widgets = useSelector(state => state.widgets);
  const dispatch = useDispatch();

  const [config, setConfig] = useState({});
  const [currentSendPlayer, setCurrentSendPlayer] = useState(null);

  // MAP LOAD
  const loadMapData = () => {
    if (isEmpty(mapState.content)) { return; }
    if(get(mapState, 'content.message', []).includes('ERROR')) { return; }
    config.grid.fromJSON(mapState.content);
    config.board.generateTilemap();
    config.sprites = drawSprites(config.board);
    config.board.generateTilemap({ tileScale: 0.98 });
    // var loader = new RGBELoader();
    // loader.load( skyboxHDR, function ( texture ) {
    //   var pmremGenerator = new THREE.PMREMGenerator( config.scene.renderer );
    //   var envMap = pmremGenerator.fromEquirectangular( texture );
    //   config.scene.container.background = envMap.texture;
    //   config.scene.container.environment = envMap.texture;
    //   texture.dispose();
    //   pmremGenerator.dispose();
    // });
    config.controlledMeshs = [];
    const sceneElement = document.getElementById('view');
    sceneElement.style.display = "block";
  }

  // DRAW MAP
  function drawSprites(board) {
    const { icons } = mapState.content;
    let i = 0;
    let sprites = [];
    board.tiles.forEach(tile => {
      const { gameData } = tile.cell;
      //  const offset = get(gameData, 'players.hereNow', []).length * 15;
      if (!isEmpty(gameData.players.hereNow)) {
        gameData.players.hereNow.forEach((hereNow, index) => {
          sprites[i] = new Sprite({
            container: board.group,
            url: icons.players[hereNow],
            scale: 16,
            heightOffset: 6,
          });
          sprites[i].activate(tile.position.x, 12 + index * 15, tile.position.z);
          sprites[i].tile = tile.cell;
          sprites[i].userId = hereNow;
          sprites[i].hereNow = !!gameData.players.hereNow;
          i++;
        });
      }
    });
    return sprites;
  }

  function drawMap() {
    const sceneElement = document.getElementById('view');
    const scene = new vg.Scene({
        element: sceneElement,
        cameraPosition: {x: 0, y: 150, z: 150},
      }, true);
    const grid = new vg.HexGrid();
    const board = new vg.Board(grid);
    const mouse = new vg.MouseCaster(scene.container, scene.camera);
    const sprites = [];
    const tile = null;

    mouse.active = false;
    scene.container.background = new THREE.Color(0x1B1D1E);
    scene.add(board.group);

    const canvas = scene.renderer.domElement;
    canvas.id = "mapCanvas";

    setConfig({
      scene,
      grid,
      board,
      mouse,
      sprites,
      tile,
      // controlledMeshs,
    });
  }

  // SOCKET SUBSCRIBES
  function subscribeToMapUpdates() {
    subscribeToPlayersMovements(updatePlayerPosition);
    subscribeToPlayersCaptured(updateMapCaptured);
    subscribeToPlayersLostCells(updateUncaptured);
    subscribeToPlayersExplored(updateMapExplored);
  }

  // SOCKET CALLBACKS
  function updatePlayerPosition(response) {
    // {id: 1, value: 322}
    const userId = response.id;
    const newPosition = response.value;
    const start = get(config, 'sprites', []).filter(sprite => sprite.userId.toString() === userId.toString())[0].position;
    Object.values(config.grid.cells)
      .forEach((cell) => {
        cell.gameData.players.hereNow = cell.gameData.players.hereNow.filter((hereId) => hereId !== userId );
      });
    const target = findCellById(newPosition);
    const offset = get(target, 'hexData.difficulty', '') === 'core' ? get(target, 'gameData.players.hereNow', []).length * 15 : 0;
    target.gameData.players.hereNow = [...target.gameData.players.hereNow, userId];
    const targetVector3 = new THREE.Vector3(target.tile.position.x, 12 + offset, target.tile.position.z);
    if (!targetVector3) return;
    animateVector3(start, targetVector3, {
      duration: 3000,
      easing : TWEEN.Easing.Quadratic.InOut,
      update: function(_) {
        console.log("Updating");
      },
      callback : function() {}
    });
  }

  function updateMapExplored(response) {
    // {id: 1, value: 322}
    const userId = response.id;
    const newPosition = response.value;
    const target = findCellById(newPosition);
    target.gameData.players.exploredBy = [...target.gameData.players.exploredBy, userId];
    target.tile.material.opacity = 1;
    setByCaptured(config.board);
    if (mapState.showCaptured) {
      showCaptured();
    }
    if (currentPlayer.player.mapState === MAP_STATES.PREPARE_OPEN) {
      paintOpenTiles();
    }
    if (currentPlayer.player.mapState === MAP_STATES.PREPARE_EXPLORE) {
      paintExploreTiles();
    }
  }

  function updateMapCaptured(response) {
    // {id: 1, value: 322}
    const userId = response.id;
    const newPosition = response.value;
    const target = findCellById(newPosition);
    target.gameData.players.controlledBy = [userId];
    target.gameData.players.exploredBy = [...target.gameData.players.exploredBy, userId];
    setByCaptured(config.board);
    if (mapState.showCaptured) {
      showCaptured();
    }
    if (currentPlayer.player.mapState === MAP_STATES.PREPARE_MOVE) {
      paintMovementTiles();
    }
  }

  function updateUncaptured(response) {
    // {playerID: 1, cellID: 322}
    const cellID = response.cellID;
    const target = findCellById(cellID);
    target.gameData.players.controlledBy = [];
    setByCaptured(config.board);
    if (mapState.showCaptured) {
      showCaptured();
    }
    if (currentPlayer.player.mapState === MAP_STATES.PREPARE_MOVE) {
      paintMovementTiles();
    }
  }
  // USE EFFECTS AND UPDATES
  function update() {
    config.mouse.update();
    config.scene.render();
    TWEEN.update();
    requestAnimationFrame(update);
  }

  useEffect(() => {
    drawMap();
  }, []);

  useDidMountEffect(() => {
    loadMapData();
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    update();
  }, [config]);

  useEffect(() => {
    if (get(currentPlayer, 'player.id')) {
      unsubscribeMapActions();
      subscribeToMapUpdates();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(currentPlayer, 'player.id'), config]);

  useDidMountEffect(() => {
    config.mouse.signal.removeAll();
    config.mouse.signal.add(handleTileClick);
    return () => {
      config.mouse.signal.removeAll();
    };
  }, [config, currentPlayer.player, currentUser, currentSendPlayer, mapState.isOpenAll]);

  // EVENTS LISTENERS
  function onWindowResize() {
    config.scene.camera.aspect = window.innerWidth / window.innerHeight;
    config.scene.camera.updateProjectionMatrix();
    config.scene.renderer.setSize(window.innerWidth, window.innerHeight);

  }
  function updateCurrentMouseState(e) {
    if (config.mouse) {
      config.mouse.active = e.target.id === "mapCanvas";
    }
  }

  useEventListener('resize', onWindowResize);
  useEventListener('mousemove', updateCurrentMouseState);

  const fetchData = useCallback(async (playerID, tab) => {
    const result = await dataService.retrieveData(playerID,  tab);
    return result;
  }, [dataService]);

    const fetchOverallData = useCallback(async (playerID=null) => {
      const result = await fetchData(playerID, 'overall');
      return result && result[0];
  }, [fetchData]);

  const fetchInventoryData = useCallback(async (playerID=null) => {
    const result = await fetchData(playerID, 'inventory');
    return result;
  }, [fetchData]);

  const fetchGamesData = useCallback(async (playerID=null) => {
    const result = await fetchData(playerID, 'games');
    return result;
  }, [fetchData]);

  const fetchEncountersData = useCallback(async (playerID=null) => {
    const result = await fetchData(playerID, 'encounters');
    return result;
  }, [fetchData]);

  const fetchInfo = useCallback(async (id) => {
    const encounters = await fetchEncountersData(id);
    const overall = await fetchOverallData(id);
    const games = await fetchGamesData(id);
    const inventory = await fetchInventoryData(id);
    return {
      ...overall,
      encounters: encounters[0].encounters,
      games: games[0].games,
      inventory: inventory[0].inventory,
      mates: inventory[0].mates,
    }
  }, [fetchEncountersData, fetchGamesData, fetchInventoryData, fetchOverallData]);

  const handleTileClick = async (event, obj) => {
    if (isEmpty(obj)) return;
    const _cell = obj.cell || obj.tile.cell || obj.tile;
    if (!obj.userId) {
      highlightObject(_cell, event);
    }
    if (event === vg.MouseCaster.CLICK) {
      // handle sprite clicks
      if (obj.userId) {
        if (currentPlayer.player && obj.userId === currentPlayer.player.id) {
          openCurrentPlayerTile();
          return;
        }
        console.log(`click on player ${obj.userId}`);
        const newInfo = await fetchInfo(obj.userId);
        dispatch(allActions.generalActions.changeCurrentSelectedPlayer(newInfo));
        dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.generalBlock.name, true));
        return;
      }
      if (_cell) {
        const { gameData: { players: { exploredBy } }, hexData: { sectorID } } = _cell;
        if (['viewer', 'deputy'].includes(get(currentUser, 'user.type', '')) && !isEmpty(exploredBy)) {
          config.tile = _cell;
          paintCurrentTile();
          exploreEvent();
        }
        if (!currentPlayer.player) { return; }
        if (get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_MOVE
          && currentPlayer.player.map.cellsCanGo.includes(sectorID)) {
          config.tile = _cell;
          config.nearTile = currentPlayer.player.map.cellsCanGo.includes(sectorID);
          paintCurrentTile();
          movementEvent();
        }
        if (get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_OPEN
          && (mapState.isOpenAll || currentPlayer.player.map.cellsCanOpen.includes(sectorID))) {
          config.tile = _cell;
          paintCurrentTile();
          openEvent();
          dispatch(allActions.playerActions.changeMapState(MAP_STATES.IDLE));
        }
        if (get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_SEND
          && _cell.gameData.players.controlledBy.includes(currentPlayer.player.id)) {
            sendCaptureEvent(_cell.hexData.sectorID);
            dispatch(allActions.playerActions.changeMapState(MAP_STATES.IDLE));
        }
        if (![MAP_STATES.PREPARE_MOVE, MAP_STATES.PREPARE_SEND, MAP_STATES.PREPARE_OPEN].includes(get(currentPlayer, 'player.mapState'))
        && !isEmpty(exploredBy)) {
          config.tile = _cell;
          paintCurrentTile();
          exploreEvent();
        }
      }
    }
  }

  function movementEvent() {
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    playerMove(config.tile.hexData.sectorID);
  }

  function exploreEvent() {
    dispatch(allActions.mapActions.changeSector(null));
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    const currentSectorId = get(config, 'tile.hexData.sectorID', get(currentPlayer, 'player.map.position', null));
    if (!currentSectorId) { return; }
    dispatch(allActions.mapActions.changeSector(parseInt(currentSectorId)));
    openLocator();
  }

  function sendCaptureEvent(cellId) {
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    playerUncaptureCell(cellId, currentSendPlayer);
  }

  function openEvent() {
    dispatch(allActions.mapActions.changeSector(null));
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    playerOpen(config.tile.hexData.sectorID);
    const currentSectorId = get(config, 'tile.hexData.sectorID', get(currentPlayer, 'player.map.position', null));
    if (!currentSectorId) { return; }
    dispatch(allActions.mapActions.changeSector(parseInt(currentSectorId)));
    openLocator();
  }

  // MAP CHANGE STATES HANDLERS
  const handleIdlePhase = () => {
    console.log(TURN_STATES.IDLE);
    dispatch(allActions.mapActions.changeSector(null));
    dispatch(allActions.sectorActions.setSectorInfo(null));
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
  }

  const handleMapIdleStatePhase = () => {
    console.log(MAP_STATES.IDLE);
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
  }

  const handlePrepareMovePhase = () => {
    console.log(MAP_STATES.PREPARE_MOVE);
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    paintMovementTiles();
  }
  const handlePrepareOpenPhase = () => {
    console.log(MAP_STATES.PREPARE_OPEN);
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    paintOpenTiles();
  }
  const handlePrepareExplorePhase = () => {
    console.log(MAP_STATES.PREPARE_EXPLORE);
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    paintExploreTiles();
  }

  const handleSendPhase = () => {
    console.log(MAP_STATES.PREPARE_SEND);
    paintMap(mapState.paintType);
    if (mapState.showCaptured) {
      showCaptured();
    }
    paintSendTiles();
  }

  const handlePlayPhase = () => {
    console.log(TURN_STATES.PLAY);
    dispatch(allActions.mapActions.changeSector(null));
    paintMap(mapState.paintType);
    const currentSectorId = get(currentPlayer, 'player.map.position', null);
    if (!currentSectorId) { return; }
    dispatch(allActions.mapActions.changeSector(parseInt(currentSectorId)));
    openLocator();
  }

  const handleDropGamePhase = () => {
    console.log(MAP_STATES.DROP);
    closeLocator();
    dispatch(allActions.mapActions.changeSector(null));
    dispatch(allActions.sectorActions.setSectorInfo(null));
    changeMapState(TURN_STATES.IDLE);
  }

  const handleShopPhase = () => {
    console.log(TURN_STATES.SHOP);
  }

  useEffect(() => {
    if (!config.scene) return;
    if (!currentPlayer.player) return;
    switch (currentPlayer.player.currentTurnState) {
      case TURN_STATES.IDLE:
        return handleIdlePhase();
      case TURN_STATES.PLAY:
        return handlePlayPhase();
      case TURN_STATES.SHOP:
        return handleShopPhase();
      default:
        return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer.player?.currentTurnState]);

  useEffect(() => {
    if (!config.scene) return;
    if (!currentPlayer.player) return;
    switch (currentPlayer.player.mapState) {
      case MAP_STATES.IDLE:
        return handleMapIdleStatePhase();
      case MAP_STATES.PREPARE_MOVE:
        return handlePrepareMovePhase();
      case MAP_STATES.PREPARE_OPEN:
        return handlePrepareOpenPhase();
      case MAP_STATES.PREPARE_EXPLORE:
        return handlePrepareExplorePhase();
      case MAP_STATES.PREPARE_SEND:
        return handleSendPhase();
      case MAP_STATES.DROP:
        return handleDropGamePhase();
      default:
        return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer.player?.mapState]);

  useEffect(() => {
    if (!config.scene) return;
    if (!currentPlayer.player) return;
    if (currentPlayer.player.mapState === MAP_STATES.PREPARE_OPEN) {
      handlePrepareOpenPhase();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer.player?.mapState, mapState.isOpenAll]);


  useEffect(() => {
    const movePoints = get(currentPlayer, "player.map.movePoints.current", 0);
    const currentState = get(currentPlayer, "player.currentTurnState", null);
    if (!movePoints && currentState === TURN_STATES.IDLE) {
      return changeMapState(TURN_STATES.PLAY);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer.player]);

  // PAINT FUNCTIONS
  function paintMap(type) {
    switch (type) {
      case MAP_PAINT_TYPE.CAPTURED:
        console.log(`painted by ${MAP_PAINT_TYPE.CAPTURED}`);
        setByCaptured(config.board);
        break;
      case MAP_PAINT_TYPE.DIFFICULTY:
        console.log(`painted by ${MAP_PAINT_TYPE.DIFFICULTY}`);
        setByCaptured(config.board);
        break;
      default:
        console.log(`paint error, paint by default`);
        setByCaptured(config.board);
        break;
    }
    dispatch(allActions.mapActions.changePaintType(type));
  }

  function showCaptured() {
    const { tiles } = config.board;
    const { colors } = mapState.content;
    dispatch(allActions.mapActions.changeShowCaptured(true));
    config.controlledMeshs.forEach((mesh) => config.scene.remove(mesh));
    config.controlledMeshs = [];
    tiles.forEach((tile) => {
      const { hexData, gameData } = tile.cell;
      var controlledMeshMaterial = new THREE.MeshPhongMaterial( { color: colors.difficulties[hexData.difficulty].value, side: THREE.DoubleSide } );
      var geom = new THREE.Geometry();
      var v1 = new THREE.Vector3(-1.5,0,0);
      var v2 = new THREE.Vector3(5,0,0);
      var v3 = new THREE.Vector3(7.6,5,0);

      geom.vertices.push(v1);
      geom.vertices.push(v2);
      geom.vertices.push(v3);

      geom.faces.push(new THREE.Face3( 0, 1, 2 ));
      geom.computeFaceNormals();
      var controlledMesh = new THREE.Mesh(geom, controlledMeshMaterial);
      controlledMesh.name = gameData.id;
      controlledMesh.position.x = tile.position.x;
      controlledMesh.position.y = tile.position.y + 2.6;
      controlledMesh.position.z = tile.position.z - 8.7;
      controlledMesh.rotateX(90 * vg.DEG_TO_RAD);
      controlledMesh.scale.multiplyScalar(1);
      config.scene.add(controlledMesh);
      config.controlledMeshs = [...config.controlledMeshs, controlledMesh];
    });
  }

  function closeCaptured() {
    dispatch(allActions.mapActions.changeShowCaptured(false));
    config.controlledMeshs.forEach((mesh) => config.scene.remove(mesh));
  }

  function setByCaptured(board) {
    const { tiles } = board;
    tiles.forEach((tile) => {
      const { gameData } = tile.cell;
      const { colors } = mapState.content;
      const sectorCapturedPlayerId = get(gameData, 'players.controlledBy[0]');
      tile.material.color.setHex(toHex(colors.players[sectorCapturedPlayerId] || '#1C1E20'));
      tile.material.transparent = true;
      tile.material.opacity = isEmpty(tile.cell.gameData.players.exploredBy) ? 0.6 : 1;
    });
  }

  function paintCurrentTile(color=0x53396F) {
    const currentCell = config.tile;
    currentCell.tile.prevColor = currentCell.tile.material.color.getHex();
    currentCell.tile.material.color.setHex(color);
  }

  function paintMovementTiles() {
    const { cellsCanGo } = currentPlayer.player.map;
    const allCanGoTiles = Object.values(config.grid.cells)
      .filter((cell) => cellsCanGo.includes(cell.hexData.sectorID));
    allCanGoTiles.forEach((neighbor) => {
      neighbor.tile.prevColor = neighbor.tile.material.color.getHex();
      neighbor.tile.material.color.setHex(0x52ACFF);
    });
  }

  function paintOpenTiles() {
    const { cellsCanOpen } = currentPlayer.player.map;
    let allCanOpenTiles = Object.values(config.grid.cells);
    if (!mapState.isOpenAll) {
      allCanOpenTiles = allCanOpenTiles.filter((cell) => cellsCanOpen.includes(cell.hexData.sectorID));
    } else {
      allCanOpenTiles = allCanOpenTiles.filter((cell) => isEmpty(cell.gameData.players.exploredBy));
    }
    allCanOpenTiles.forEach((neighbor) => {
      neighbor.tile.prevColor = neighbor.tile.material.color.getHex();
      neighbor.tile.material.color.setHex(0xF24463);
    });
  }

  function paintExploreTiles() {
    const allCanExploreTiles = Object.values(config.grid.cells)
      .filter((cell) => !isEmpty(cell.gameData.players.exploredBy));
    allCanExploreTiles.forEach((neighbor) => {
      neighbor.tile.prevColor = neighbor.tile.material.color.getHex();
      neighbor.tile.material.color.setHex(0xF252AC);
    });
  }

  function findSendTiles() {
    if (!config.grid) { return; }
    return Object.values(config.grid.cells)
      .filter((cell) => cell.gameData.players.controlledBy.includes(currentPlayer.player.id));
  }

  function paintSendTiles() {
    const allCanSendTiles = findSendTiles();
    allCanSendTiles.forEach((neighbor) => {
      neighbor.tile.prevColor = neighbor.tile.material.color.getHex();
      neighbor.tile.material.color.setHex(0x701922);
    });
  }

  function highlightObject(obj, event) {
    if (obj && event === vg.MouseCaster.OVER) {
      obj.tile.position.y = 2;
    }
    if (event === vg.MouseCaster.OUT) {
      obj.tile.position.y = 0;
    }
  }

  // PREPARE ACTIONS
  function handleMovementButtonClick() {
    const newMapState = get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_MOVE ?
      MAP_STATES.IDLE
      :
      MAP_STATES.PREPARE_MOVE;
    dispatch(allActions.playerActions.changeMapState(newMapState));
  }

  function handleExploreButtonClick() {
    const newMapState = get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_EXPLORE ?
      MAP_STATES.IDLE
      :
      MAP_STATES.PREPARE_EXPLORE;
    dispatch(allActions.playerActions.changeMapState(newMapState));
  }

  function handleOpenButtonClick() {
    if (!currentPlayer.player) { return; }
    if (get(currentPlayer, "player.map.explorePoints.current", 0) === 0) { return; }
    const newMapState = get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_OPEN ?
      MAP_STATES.IDLE
      :
      MAP_STATES.PREPARE_OPEN;
    dispatch(allActions.playerActions.changeMapState(newMapState));
  }

  function handlePrepareCaptureSend() {
    if (!currentPlayer.player) { return; }
    const newMapState = get(currentPlayer, 'player.mapState') === MAP_STATES.PREPARE_SEND ?
      MAP_STATES.IDLE
      :
      MAP_STATES.PREPARE_SEND;
    dispatch(allActions.playerActions.changeMapState(newMapState));
  }

  function openCurrentPlayerTile() {
    const currentSectorId = get(currentPlayer, 'player.map.position', null);
    if (!currentSectorId) { return; }
    dispatch(allActions.mapActions.changeSector(parseInt(currentSectorId)));
    dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.locator.name, true));
  }

  function handlePlayButtonClick() {
    const isLocatorOpened = get(widgets, 'locator.isOpened', false);
    const currentSectorId = get(currentPlayer, 'player.map.position', null);
    if (!currentSectorId) { return; }
    dispatch(allActions.mapActions.changeSector(parseInt(currentSectorId)));
    !isLocatorOpened && openLocator();
  }

  // HELPERS
  function findCellById(id) {
    const foundCell = Object.values(config.grid.cells)
      .find((cell) => cell.hexData.sectorID.toString() === id.toString());
    return foundCell;
  }

  // LOCATOR
  function openLocator() {
    const _locatorName = allWidgets.locator.name;
    dispatch(allActions.widgetsActions.changeIsOpened(_locatorName, true));
  }

  function closeLocator() {
    const _locatorName = allWidgets.locator.name;
    if (widgets[_locatorName].isOpened) {
      dispatch(allActions.widgetsActions.changeIsOpened(_locatorName, false));
    }
  }

  return (
    <div>
      {!isMobile && ['player'].includes(get(currentUser, 'user.type', '')) && (
        <>
         <MapInterface
          onMovementButtonClick={handleMovementButtonClick}
          onExploreButtonClick={handleExploreButtonClick}
          onOpenButtonClick={handleOpenButtonClick}
          onPlayButtonClick={handlePlayButtonClick}
          onPrepareCaptureSend={handlePrepareCaptureSend}
          setCurrentSendPlayer={setCurrentSendPlayer}
          findSendTiles={findSendTiles}
          currentSendPlayer={currentSendPlayer}
        />
        </>
      )}
      {!isMobile && (<ControlPanel showCaptured={showCaptured} closeCaptured={closeCaptured} board={config.board} />)}
      {isMobile && <div style={{ zIndex: '9999', position: 'fixed', right: '10px', top: '10px', fontSize: '24px' }} onClick={closeCallback}>Назад</div>}
      <div id="view" />
    </div>
  );
}


export default Map3D;
