import React, { useEffect, useState } from 'react';
import { unsubscribeToPlayersState, subscribeToPlayersState, getAllPlayersState } from '../../api/socket';
import CSS from './PlayerStates.module.scss';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useSpring, animated } from 'react-spring';
import { TURN_STATES } from '../../consts/map';

const STATE_DISPLAY_NAMES = {
    [TURN_STATES.START]: 'Конфигурируется',
    [TURN_STATES.IDLE]: 'Взаимодействует с картой',
    [TURN_STATES.PLAY]: 'Захватывает сектор',
    [TURN_STATES.SHOP]: 'Взаимодействует с персонажами',
    'none': 'peepoRIP',
}

const STATE_DISPLAY_NAMES_FOR_PLAYER = {
    [TURN_STATES.START]: 'Конфигурация',
    [TURN_STATES.IDLE]: 'Взаимодействие с картой',
    [TURN_STATES.PLAY]: 'Захват сектора',
    [TURN_STATES.SHOP]: 'Взаимодействие с персонажами',
    'none': 'peepoRIP',
}

function PlayerStates() {

    const currentPlayer = useSelector(state => state.currentPlayer);
    const widgets = useSelector(state => state.widgets);
    const mapState = useSelector(state => state.mapState);
    const playersNameList = get(mapState, "content.displayText.players", {});
    const playersColorList = get(mapState, "content.colors.players", {});
    const [playersData, setPlayersData] = useState({});
    const [stateUpdated, setStateUpdated] = useState(null);
    const { o} = useSpring({
        from: { o: 0 },
        o: stateUpdated ? 1 : 0,
    });

    function updateStates(result) {
        if (currentPlayer.player.id == result.playerID) {
            setStateUpdated(STATE_DISPLAY_NAMES_FOR_PLAYER[result.stateName]);
            setTimeout(() => setStateUpdated(null), 1000);
        }
        setPlayersData((prevState) => ({ ...prevState, [result.playerID]: { stateName: result.stateName, turnCount: result.turnCount } }));
    }

    function setStats(result) {
        result.map((player) => setPlayersData((prevState) => ({ ...prevState, [player.playerID]: { stateName: player.stateName, turnCount: player.turnCount } })))
    }

    useEffect(() => {
        getAllPlayersState(setStats);
    }, []);

    useEffect(() => {
        if (get(currentPlayer, 'player.id')) {
            unsubscribeToPlayersState();
            subscribeToPlayersState(updateStates);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get(currentPlayer, 'player.id')]);

    return (
        <>
        <div className={CSS.wrapper} style={{ zIndex: get(widgets, 'map.weight', 100) + 1 }}>
            {Object.entries(playersNameList).map(([key, value]) => (
                <div
                    className={CSS.playerCard}
                >
                    <div style={{ color: playersColorList[key] }}>{value}</div>
                    <div>Ход № {get(playersData, `${key}.turnCount`, '0') || '0'}</div>
                    <div>{STATE_DISPLAY_NAMES[get(playersData, `${key}.stateName`, 'none')]}</div>
                </div>
            ))}
        </div>
        <animated.div className={CSS.newState} style={{ opacity: o, visibility: o.interpolate(o => o === 0 ? 'hidden' : 'visible') }}>{stateUpdated}</animated.div>
        </>
    )
}

export default PlayerStates;