import React, { useMemo, useState, useCallback } from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import NotesList from "./NotesList/NotesList";
import allActions from "../../state/actions";
import CSS from './Notes.module.scss';
import classNames from 'classnames';
import Effects from '../Effects/Effects';

const EcounterNotes = () => {

  const encountersNotes = useSelector(state => state.encountersNotes);
  const dispatch = useDispatch();
  const [isEffects, setIsEffects] = useState(false);

  const handleDeleteNote = useCallback((id) => {
    dispatch(allActions.encountersNotesActions.deleteNote(id));
  }, [dispatch]);

  const noData = useMemo(() => (
    <div className={CSS.centerBox}>
      <div  className={CSS.centerText}>
        У вас ещё нет случайных встреч
      </div>
    </div>
  ), []);

  const notesList = useMemo(
    () => <NotesList notes={encountersNotes} deleteCallback={handleDeleteNote} />,
    [encountersNotes, handleDeleteNote]
  );

  return (
    <div className={CSS.smallWrapper}>
      <div className={CSS.buttons}>
        <div className={classNames(CSS.button, { [CSS.buttonDisabled]: !isEffects })} onClick={() => setIsEffects(false)}>Случайные встречи</div>
        <div className={classNames(CSS.button, { [CSS.buttonDisabled]: isEffects })} onClick={() => setIsEffects(true)}>Долгосрочные эффекты</div>
      </div>
      {isEffects ? (
        <Effects />
      ): _.isEmpty(encountersNotes) ? noData : notesList}
      
    </div>
  )
}

export default EcounterNotes;
