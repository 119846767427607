import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import classNames from 'classnames';
import tokenPng from "../../assets/icons/tokenPng.svg";
import CSS from './Convention.module.scss';
import { subscribeToCongressData, joinToCongress, nominateAct, readyInCongress, subscribeToCongressTimer, voteInCongress, rejectAct, passedAct, leaveFromCongress } from '../../api/socket';
import { useSelector, useDispatch } from 'react-redux';
import allActions from './../../state/actions/index';
import allWidgets from "..";

function Convention() {

    const dispatch = useDispatch();
    const currentPlayer = useSelector(state => state.currentPlayer);
    const [currentAct, setCurrentAct] = useState({});
    const [data, setData] = useState({});
    const [playerState, setPlayerState] = useState("idle");
    const [acts, setActs] = useState([]);
    const [passedActs, setPassedActs] = useState([]);
    const [rejectedActs, setRejectedActs] = useState([]);
    const [nominatedActs, setNominatedActs] = useState([]);
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState({});
    const [isJoin, setIsJoin] = useState(false);
    const [time, setTime] = useState(null);
    const [streamMode, setStreamMode] = useState(false);
    const [pointsValue, setPointsValue] = useState("");

    const handleEnd = () => {
        dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.convention.name, false));
        leaveFromCongress();
        setIsJoin(false);
    }

    const updateCurrentData = (data) => {
        setData(data);
        setActs(data.acts.all);
        setNominatedActs(data.acts.nominated);
        setPassedActs(data.acts.nominated.filter((nominatedAct) => nominatedAct.status === "passed"));
        setRejectedActs(data.acts.nominated.filter((nominatedAct) => nominatedAct.status === "rejected"));
        setPlayerState(data.state);
        setPlayers(data.players);
        setPlayer(data.players.find((player) => player.id.toString() === get(currentPlayer, 'player.id', -1).toString()));
    }

    const updateTimer = (data) => {
        setTime(data);
    }

    useEffect(() => {
        if (isJoin) {
            joinToCongress();
            subscribeToCongressData(updateCurrentData);
            subscribeToCongressTimer(updateTimer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJoin]);

    const vote = (id, type, count) => {
        voteInCongress(id, type, count);
        setPointsValue("");
    }

    const getCurrentInPorcessAct = () => {
        const actInfo = acts.find((act) => act.id === get(data, 'acts.turn', -1));
        if (!actInfo) { return; }
        return { ...nominatedActs.find((act) => act.id === get(data, 'acts.turn', -1)), name :actInfo.name, description: actInfo.description };
    }

    function renderPlayers() {
        return players.map((player) => (
            <div className={CSS.playerCard} key={player.position}>
                <div className={CSS.statRow}> 
                    {player.id === get(data, 'turn', 0) && <img alt="token" className={CSS.token} src={tokenPng} />}
                    <div className={CSS.playerPoints}>{player.tokens}</div>
                </div>
                <img alt="player" className={classNames(CSS.playerImage, { [CSS.playerImageReady]: get(player, 'status', 'pending') === 'ready' })} src={player.image} />
                <div className={CSS.playerName}>{player.name}</div>
            </div>
        ))
    }

    function getHeader() {
        // // idle, nominate, discussion, vote, results
        switch(playerState) {
            case "idle":
                return "Ожидание";
            case "nominate":
                return "Выбор закона";
            case "discussion":
                return "Обсуждение";
            case "vote":
                return "Голосование";
            case "results":
                return "Результат";
            case "end":
                return "Результаты";
            default:
                return "Не определенно";
        }
    }

    function getActBody() {
        const currentInPorcessAct = getCurrentInPorcessAct();
        switch(playerState) {
            case "idle":
                return (
                    <div className={CSS.block}>
                        <div className={CSS.actName}>
                            Пока можно заварить чаёк и угадать, кто опаздывает.
                        </div>
                        {!streamMode && (
                           <div className={CSS.modeButton}>
                           <div className={CSS.button} style={{ width: '400px' }} onClick={() => {
                                setStreamMode(true);
                                window.open('https://hpg.su');
                            }}>Активировать стример мод</div>
                            <div className={CSS.desriptionStreamerMode}>После нажатия на кнопку зайди в съезд и не показывай новую вкладку чатерсам.</div>
                            <div className={CSS.desriptionStreamerMode}>Активировать мод нужно до обсуждения законов.</div>
                            <div className={CSS.desriptionStreamerMode}>Не забудь нажать кнопку "Скрыть поинты" на вкладке для зрителей.</div>
                            <div className={CSS.desriptionStreamerMode}>Красный круг справа сверху указывает на то, что это стримерская вкладка.</div>
                           </div>
                       )}
                       {streamMode && (
                          <div className={CSS.desriptionStreamerMode}>Показывайте эту вкладку Чатерсам</div>
                       )}
                    </div>
                )
            case "nominate":
                return isEmpty(currentAct) ? (
                    <div className={CSS.block}>
                        <div className={CSS.actName}>
                            Нужно выбрать закон
                        </div>
                    </div>
                ) : (
                    <div className={CSS.block}>
                       <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                       <div className={CSS.actName}>
                            {currentAct.name}
                        </div>
                        <div style={{ textAlign: "left"}}>
                            {currentAct.description}
                        </div>
                       </div>
                       {!nominatedActs.find((nominatedAct) => nominatedAct.id === currentAct.id) && (
                       <div
                            className={classNames(CSS.button, { [CSS.disabled]: get(player, 'id', -1) !== get(data, 'turn', 0) })}
                            style={{ marginTop: "50px", marginBottom: "8px" }}
                            onClick={() => get(player, 'id', -1) === get(data, 'turn', 0) && nominateAct(currentAct.id)}
                        >
                            Выбрать
                        </div>
                       )}
                       {!streamMode && (
                           <div className={CSS.modeButton}>
                           <div className={CSS.button} style={{ width: '400px' }} onClick={() => {
                                setStreamMode(true);
                                window.open('https://hpg.su');
                            }}>Активировать стример мод</div>
                            <div className={CSS.desriptionStreamerMode}>После нажатия на кнопку зайди в съезд и не показывай новую вкладку чатерсам.</div>
                            <div className={CSS.desriptionStreamerMode}>Активировать мод нужно до обсуждения законов.</div>
                            <div className={CSS.desriptionStreamerMode}>Не забудь нажать кнопку "Скрыть поинты" на вкладке для зрителей.</div>
                            <div className={CSS.desriptionStreamerMode}>Красный круг справа сверху указывает на то, что это стримерская вкладка.</div>
                           </div>
                       )}
                       {streamMode && (
                          <div className={CSS.desriptionStreamerMode}>Показывайте эту вкладку Чатерсам</div>
                       )}
                    </div>
                );
            case "discussion":
                return (
                    <div className={CSS.block}>
                       <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                       <div className={CSS.actName}>
                            {currentInPorcessAct.name}
                        </div>
                        <div style={{ textAlign: "left"}}>
                            {currentInPorcessAct.description}
                        </div>
                       </div>
                       <button
                            className={classNames(CSS.readyButton, { [CSS.disabled]: get(player, 'status', 'pending') === 'ready' })}
                            onClick={() => readyInCongress()}
                        >
                            Готов
                        </button>
                    </div>
                );
            case "vote":
                return (
                    <div className={CSS.block}>
                       <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                       <div className={classNames(CSS.timer, { [CSS.timerLast]: time <= 5 })}>
                           {time}
                        </div>
                       <div className={CSS.actName}>
                            {currentInPorcessAct.name}
                        </div>
                        <div style={{ textAlign: "left"}}>
                            {currentInPorcessAct.description}
                        </div>
                       </div>
                       {!streamMode && (
                           <>
                            <input placeholder="Введите поинты" className={CSS.inputPoints} value={pointsValue} onChange={(e) => setPointsValue(e.target.value)} />
                            <button
                                    className={classNames(CSS.buttonGood, { [CSS.disabled]: get(currentPlayer, "player.tokens.points.current", "?") === 0 })}
                                    onClick={() => vote(currentInPorcessAct.id, 'for', pointsValue)}
                                    >
                                    За
                                </button>
                            <button
                                className={classNames(CSS.buttonBad, { [CSS.disabled]: get(currentPlayer, "player.tokens.points.current", "?") === 0 })}
                                onClick={() => vote(currentInPorcessAct.id, 'against', pointsValue)}
                                >
                                    Против
                                </button>
                            <div className={CSS.tokenAssist}>
                                У вас осталось {get(currentPlayer, "player.tokens.points.current", "?")} токена.
                            </div>
                           </>
                       )}
                    </div>
                );
            case "results":
                return (
                    <div className={CSS.block}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                       <div className={CSS.actName}>
                            {currentInPorcessAct.name}
                        </div>
                        <div style={{ textAlign: "left"}}>
                            {currentInPorcessAct.description}
                        </div>
                       </div>
                       <div className={CSS.voteCount}>
                           <span className={CSS.voteGood}>ЗА:</span> {currentInPorcessAct.votes.filter((vote) => vote.type === "for").reduce((r, vote) => r + vote.tokens, 0)}
                       </div>
                       <div className={CSS.voteCount}>
                        <span className={CSS.voteBad}>ПРОТИВ:</span> {currentInPorcessAct.votes.filter((vote) => vote.type === "against").reduce((r, vote,) => r + vote.tokens, 0)}
                       </div>
                       <div className={CSS.voteCount}>
                        <span className={currentInPorcessAct.status === 'passed' ? CSS.voteGood : CSS.voteBad}>{currentInPorcessAct.status === 'passed' ? 'Принят' : 'Отклонён'}</span>
                       </div>
                       {
                           currentInPorcessAct.status === 'passed' && (
                            <button
                                className={classNames(CSS.changeStatusButton)}
                                onClick={() => rejectAct()}
                            >
                                Отменить закон
                            </button>
                           )
                       }
                                              {
                           currentInPorcessAct.status === 'rejected' && (
                            <button
                                className={classNames(CSS.changeStatusButton)}
                                onClick={() => passedAct()}
                            >
                                Принять закон
                            </button>
                           )
                       }
                       <button
                            className={classNames(CSS.readyButton, { [CSS.disabled]: get(player, 'status', 'pending') === 'ready' })}
                            onClick={() => readyInCongress()}
                        >
                            Готов
                        </button>
                    </div>
                );
            case "end":
                return (
                    <div className={CSS.block}>
                        <button
                            className={classNames(CSS.readyButton)}
                            style={{ margin: '16px' }}
                            onClick={() => handleEnd()}
                        >
                            Выйти
                        </button>
                        {
                            [ ...passedActs, ...rejectedActs].map((act) => { 
                                const actInfo = acts.find((actInfo) => actInfo.id.toString() === act.id.toString());
                                return (
                                <div  className={CSS.endView}>
                                <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div className={CSS.actName}>
                                        {actInfo.name}
                                    </div>
                                    <div style={{ textAlign: "left"}}>
                                        {actInfo.description}
                                    </div>
                                </div>
                                <div className={CSS.resultBox}>
                                    <div className={CSS.voteCount}>
                                        <span className={CSS.voteGood}>ЗА:</span> {act.votes.filter((vote) => vote.type === "for").reduce((r, vote) => r + vote.tokens, 0)}
                                    </div>
                                    <div className={CSS.voteCount}>
                                        <span className={CSS.voteBad}>ПРОТИВ:</span> {act.votes.filter((vote) => vote.type === "against").reduce((r, vote,) => r + vote.tokens, 0)}
                                    </div>
                                    <div className={CSS.voteCount}>
                                        <span className={act.status === 'passed' ? CSS.voteGood : CSS.voteBad}>{act.status === 'passed' ? 'Принят' : 'Отклонён'}</span>
                                    </div>
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                );
            default:
                return (
                    <div className={CSS.block}>
                        Ничего не найдено
                    </div>
                );
        }
    }

    function renderPassedActs() {
        return passedActs.map((act) => { 
            const actInfo = acts.find((actInfo) => actInfo.id.toString() === act.id.toString());
            return (
            <div className={CSS.passedActBox} key={actInfo.name} onClick={() => setCurrentAct(actInfo)}>
                <div className={CSS.actName}>
                    {actInfo.name}
                </div>
                {actInfo.description}
            </div>
        )})
    }

    function renderRejectedActs() {
        return rejectedActs.map((act) => { 
            const actInfo = acts.find((actInfo) => actInfo.id.toString() === act.id.toString());
            return (
            <div className={CSS.rejectedActBox} key={actInfo.name} onClick={() => setCurrentAct(actInfo)}>
                <div className={CSS.actName}>
                    {actInfo.name}
                </div>
                {actInfo.description}
            </div>
        )})
    }

    function renderNominatedActs() {
        return nominatedActs
            .filter((actInfo) => !rejectedActs.find((rejectedAct) => rejectedAct.id === actInfo.id) && !passedActs.find((passedAct) => passedAct.id === actInfo.id))
            .map((act) => { 
            const actInfo = acts.find((actInfo) => actInfo.id.toString() === act.id.toString());
            return (
            <div className={CSS.nominatedActBox} key={actInfo.name} onClick={() => setCurrentAct(actInfo)}>
                <div className={CSS.actName}>
                    {actInfo.name}
                </div>
                {actInfo.description}
            </div>
        )})
    }

    function renderActs() {
        return acts.filter((actInfo) => !nominatedActs.find((nominatedAct) => nominatedAct.id === actInfo.id)).map((act) => (
            <div className={CSS.actBox} key={act.name} onClick={() => setCurrentAct(act)}>
                <div className={CSS.actName}>
                    {act.name}
                </div>
                {act.description}
            </div>
        ))
    }

    if (!isJoin) {
        return (
            <div className={CSS.conventionJoinWrapper}>
                <button className={CSS.conventionJoinButton} onClick={() => setIsJoin(true)}>Присоединится к съезду</button>
            </div>
        )
    }

    return (
        <div className={CSS.wrapper}>
            {!streamMode && (
                <div className={CSS.streamerCirce}></div>
            )}
            <div className={CSS.players}>
                <div className={CSS.header}>Участники съезда</div>
                <div className={CSS.playersBody}>
                {renderPlayers()}
                </div>
            </div>
            <div className={CSS.actsAll}>
                <div className={CSS.header}>Законы</div>
                <div className={CSS.actsBody}>
                    {!isEmpty(passedActs) && (
                        <>
                            <div className={CSS.actsHeader}>Принятые</div>
                            {renderPassedActs()}
                        </>
                    )}
                    {!isEmpty(nominatedActs.filter((actInfo) => !rejectedActs.find((rejectedAct) => rejectedAct.id === actInfo.id) && !passedActs.find((passedAct) => passedAct.id === actInfo.id))) && (
                        <>
                            <div className={CSS.actsHeader}>Номинированные</div>
                            {renderNominatedActs()}
                        </>
                    )}
                    {!isEmpty(rejectedActs) && (
                        <>
                            <div className={CSS.actsHeader}>Отмененные</div>
                            {renderRejectedActs()}
                        </>
                    )}
                    {(!isEmpty(rejectedActs) || !isEmpty(nominatedActs) || !isEmpty(passedActs)) && (
                        <div className={CSS.actsHeader}>Все</div>
                    )}
                    {renderActs()}
                </div>
            </div>
            <div className={CSS.actsCurrent}>
                <div className={CSS.header}>{getHeader()}</div>
                {getActBody()}
            </div>
        </div>
    )
}

export default Convention;