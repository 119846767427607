import React, { useCallback, memo } from "react";
import { useSelector } from 'react-redux';
import { colorByTier } from "../../utils/utils";
import get from 'lodash/get';
import { changeMatesSlotsNumber } from '../../api/socket';
import CSS from './MatesGallery.module.scss';
import allWidgets from './../index';


const MatesGallery = ({ mates, callback, changeWidgetState }) => {
  const currentPlayer = useSelector(state => state.currentPlayer);
  const slotsMaxCount = get(currentPlayer, 'player.mates.slotsMaxCount', []);
  const emptySlots = slotsMaxCount - mates.length;

  const updateSlots = useCallback((type) => {
    changeMatesSlotsNumber(type);
  }, []);

  return (
  <div className={CSS.wrapper}>
    {mates.map((mate) => (
      <div key={mate.id} style={{ marginRight: '8px' }}>
        <div style={{ width: "60px", height: "60px", cursor: "pointer", background: '#121212' }} onClick={() => callback(mate)}>
          <img className={CSS.icon} alt="mate" style={{ borderColor: colorByTier(mate.tier) }} src={mate.image} />
        </div>
      </div>
    ))}
    {Array(emptySlots).fill(null).map((_, id) => (
      <div key={id} style={{ marginRight: '8px' }}>
      <div className={CSS.addIcon} onClick={() => changeWidgetState(allWidgets.telepound.name)}>
        +
      </div>
    </div>
    ))}
    <div>
      <div className={CSS.changeButton} onClick={() => updateSlots('add')}>
        +
      </div>
      <div className={CSS.changeButton} onClick={() => updateSlots('remove')}>
        -
      </div>
    </div>
  </div>
)}

export default memo(MatesGallery);
