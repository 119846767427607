import React, { useMemo, useState, useCallback } from 'react';
import CSS from './Trophies.module.scss';
import DataService from '../../api/DataService';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useSprings, useSpring, animated } from 'react-spring';
import { useSelector } from 'react-redux';

function Trophies() {

    const dataService = useMemo(() => new DataService(), []);
    const mapState = useSelector(state => state.mapState);
    const widgets = useSelector(state => state.widgets);
    const [data, setData] = useState([]);
    const [showTrophie, setShowTrophie] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { o } = useSpring({
        from: { o: 0 },
        o: showTrophie ? 1 : 0,
    });

    const fetchData = async () => {
        const response = await dataService.retrieveTrophies();
        if (!response) { return; }
        setData(response.trophies);
        setIsLoading(false);
    }

    useEffect(() => {
        if (get(widgets, 'trpohies.isOpened', false)) {
            fetchData();
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgets]);

    if (isLoading) {
        return (
            <div className={CSS.wrapperLoader}>Загрузка...</div>
        )
    }

    return (
        <div className={CSS.wrapper}>
            {data && data.map((value) => (
                <div className={classNames(CSS.trophieBox)} style={{ background: value.color }} onClick={() => setShowTrophie(prevState => !prevState)}>
                    <div className={CSS.title}>{value.name}</div>
                    {value.completeBy && (
                        <div className={CSS.getBy}>
                            Держатель трофея: {value.completeBy}
                        </div>
                    )}
                    <div>{value.description}</div>
                    {showTrophie && (
                      <animated.div className={CSS.leaderboardBox} style={{ background: value.color, opacity: o, visibility: o.interpolate(o => o === 0 ? 'hidden' : 'visible') }}>
                          {value.leaderboard && (
                            <div className={CSS.leaderbordList}>
                                <div className={CSS.leaderbordItemTitle}>{value.leaderboard.title}</div>
                                <div>{value.leaderboard.list.map((item) => (
                                  <div className={CSS.leaderbordItem}>
                                      <div>{item.playerName}</div> <div className={CSS.leaderboardPoints}>{item.value}</div>
                                  </div>
                                ))}</div>
                                {!get(value, 'leaderboard.title') && (
                                  <div className={CSS.centerText}>У данного трофея нет списка лидеров</div>
                                )}
                            </div>
                          )}
                      </animated.div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Trophies;
