import React from "react";
import { TURN_STATES } from '../../../consts/map';
import CSS from './HeroIcon.module.scss';
import get from 'lodash/get';


const HeroIcon = ({ character, mapState, callback, onChangeLevel, currentPlayer }) => (
  <div
    className={CSS.character}
    active={mapState.turnState === TURN_STATES.SHOP && get(currentPlayer, 'player.map.position', -1) == get(mapState, 'currentSector', 0)}
    onClick={() => {
      if (mapState.turnState === TURN_STATES.SHOP && get(currentPlayer, 'player.map.position', -1) == get(mapState, 'currentSector', 0)) {
        callback(character);
      }
    }}
  >
    <div className={CSS.characterImageWrapper} title={character.description}>
      <img alt="character" className={CSS.characterImage} style={{ border: character.level.current.toString() === character.level.maximum.toString() ? '2px solid #FF8000' : 'none' }} src={character.image} />
    </div>
    <div className={CSS.characterName}>{character.name}</div>
    <div className={CSS.characterLevel}>
      {[TURN_STATES.SHOP, TURN_STATES.PLAY].includes(mapState.turnState) && get(currentPlayer, 'player.map.position', -1) == get(mapState, 'currentSector', 0) ? (
        <div className={CSS.changeLevelButton} onClick={(e) => onChangeLevel(e, 'decreased', character.name)}>-</div>
      ) : (
        <div className={CSS.changeLevelButton}></div>
      )}
      <div className={CSS.currentLevel} style={character.level.current.toString() === character.level.maximum.toString() ? { color: "#FF8000" } : {}}>
        {character.level.current.toString() === character.level.maximum.toString() ?
          character.level.current
            :
          `${character.level.current}/${character.level.maximum}`
        }
      </div>
      {[TURN_STATES.SHOP, TURN_STATES.PLAY].includes(mapState.turnState) && get(currentPlayer, 'player.map.position', -1) == get(mapState, 'currentSector', 0) ? (
        <div className={CSS.changeLevelButton} onClick={(e) => onChangeLevel(e, 'increased', character.name)}>+</div>
      ) : (
        <div className={CSS.changeLevelButton}></div>
      )}
    </div>
  </div>
)

export default HeroIcon;
