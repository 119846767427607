import React, {useState} from "react";
import {
  Box,
  Button, BuyButton,
  Description,
  ItemBox, ItemIcon, ItemName,
  ItemsBox, ItemWrapper,
  Level,
  Name,
  TextCloud,
  Wrapper
} from "./StartView";
import Popover from '@material-ui/core/Popover';
import get from "lodash/get";
import {useSelector} from "react-redux";
import { saboteurEnemy } from '../../../../api/socket';
import { colorByTier } from "../../../../utils/utils";
import CSS from './StartView.module.scss';
import CSSFirstBuy from '../../../FirstBuy/FirstBuy.module.scss';

function SaboteurView(state, onSaboteur, onEnd) {

  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
  const [currentSelectedSaboutage, setCurrentSelectedSaboutage] = useState(null);
  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const mapState = useSelector(state => state.mapState);
  const playersIconsList = get(mapState, "content.icons.players", {});
  const playersColorList = get(mapState, "content.colors.players", {});
  const playersColorsNameList = get(mapState, "content.displayText.players", {});

  return (
    <Wrapper>
      <Box>
        <img style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
          {get(state, 'messages.start', '')}
        </TextCloud>
        <ItemsBox>
        {state.items && state.items.map((item) => (
            <ItemBox>
              <ItemWrapper
                height="100px"
                color="#b81514"
                aria-describedby={item.id}
                onClick={(e) => handlePopoverOpen(e, item.id)}
                isSelected={currentSelectedSaboutage === item.id}
              >
                <ItemName
                  color="#b81514"
                  isSelected={currentSelectedSaboutage === item.id}
                >
                  {item.name}
                </ItemName>
              </ItemWrapper>
              <div className={CSS.buyButton} onClick={() => setCurrentSelectedSaboutage(item.id)}>Выбрать</div>
              <Popover
                    id={item.id}
                    className={CSSFirstBuy.popover}
                    open={anchorEl.id === item.id}
                    anchorEl={anchorEl.target}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                  <div className={CSS.sabouterView}>
                    <div className={CSS.sabouterTextHeader}>{item.name}</div>
                    <div className={CSS.sabouterSmallHeader}>Описание</div>
                    <div>{item.description}</div>
                    <div className={CSS.sabouterSmallHeader}>Действие</div>
                    <div className={CSS.sabouterEffect}>{item.additionalProperty}</div>
                  </div>
                </Popover>
            </ItemBox>
          ))}
        </ItemsBox>
        <ItemsBox>
          {Object.entries(playersIconsList).map(([key, value]) => (
            <ItemBox key={key}>
              <ItemWrapper
                height="100px"
                color={playersColorList[key]}
                onClick={() => setCurrentSelectedItem(key)}
                isSelected={currentSelectedItem === key}
              >
                <ItemIcon src={value} />
                <ItemName
                  color={playersColorList[key]}
                  isSelected={currentSelectedItem === key}
                >
                  {playersColorsNameList[key]}
                </ItemName>
              </ItemWrapper>
              <div className={CSS.buyButton} style={{ marginBottom: '8px' }} onClick={() => currentSelectedSaboutage && onSaboteur(state.name, key, currentSelectedSaboutage)}>Отправить</div>
            </ItemBox>
          ))}
        </ItemsBox>
      </div>
    </Wrapper>
  )
}

export default SaboteurView;
