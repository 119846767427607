import React, { useEffect, useRef, useMemo, useCallback } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import get from "lodash/get";
import parse from 'html-react-parser';
import classNames from 'classnames';
import infoIcon from "../../../assets/icons/info.svg";
import arrowBack from "../../../assets/icons/arrow_back.svg";
import { addMate, removeMate, sendMessage } from "../../../api/socket";
import {colorByTier} from '../../../utils/utils';
import allActions from './../../../state/actions/index';
import CSS from './Chat.module.scss';
import allServices from './../../../api/index';
import isEmpty from 'lodash/isEmpty';

const Chat = () => {

  const currentPlayer = useSelector(state => state.currentPlayer);
  const mates = useSelector(state => state.mates);
  const dispatch = useDispatch();
  const matesService = useMemo(() => new allServices.MatesService(), []);
  const scrollAssistRef = useRef(null);

  useEffect(() => {
    if (scrollAssistRef.current) {
      scrollAssistRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mates.currentMate.messages]);

  const removeCurrentMate = () => {
    dispatch(allActions.matesActions.setCurrentMate(null));
  }

  // useEffect(() => {
  //   const mateFromPlayerList = get(currentPlayer, 'player.mates.list', []).filter((m) => m && m.id === mates.currentMate.id);
  //   if (!mateFromPlayerList) { return; }
  //   dispatch(allActions.matesActions.setCurrentMate(!isEmpty(mateFromPlayerList) ? mateFromPlayerList[0] : mates.currentMate));
  //   matesService.getMessages(mates.currentMate.id).then((messages) => {
  //     dispatch(allActions.matesActions.setCurrentMateMessages(messages));
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mates.currentMate.id]);

  const updateMate = useCallback((mate) => {
    if (isEmpty(mate)) { return; }
    dispatch(allActions.matesActions.setCurrentMate(mate));
    matesService.getMessages(mate.id).then((messages) => {
      dispatch(allActions.matesActions.setCurrentMateMessages(messages));
    });
  }, [dispatch, matesService]);

  const handleAddMate = (mate) => {
    addMate(mate.id, null, updateMate);
  }

  const handleRemoveMate = (removedMate) => {
    removeMate(get(currentPlayer, 'player.mates.list', []).findIndex(mate => removedMate.id === get(mate, 'id', null)));
  }

  const isTeammate = (soughtMate=mates.currentMate) => {
    return get(currentPlayer, 'player.mates.list', []).find(mate => soughtMate.id === get(mate, 'id', null));
  }

  const renderActionButton = () => {
    return isTeammate() ? (
      <button className={classNames(CSS.button, CSS.badAction)} onClick={() => handleRemoveMate(mates.currentMate)}>
        Выгнать
      </button>
    ) : (
      <button className={CSS.button} onClick={() => handleAddMate(mates.currentMate)}>
        Пригласить
      </button>
    )
  }

  useEffect(() => {
    matesService.getMessages(mates.currentMate.id).then((messages) => {
      dispatch(allActions.matesActions.setCurrentMateMessages(messages));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mates.currentMate.id]);

  async function handleGetInformation() {
    sendMessage(mates.currentMate.id, 'show description');
  }

  async function handleGetAbilityStatus() {
    sendMessage(mates.currentMate.id, 'show abilities status');
  }

  async function handleUseAbility() {
    sendMessage(mates.currentMate.id, 'use ability');
  }


  const renderBottomPanel = () => {
    return (
      <div className={CSS.bottomPanel}>
        <img alt="mate" style={{ height: '80px', backgrond: '#121212', transform: 'rotateY(180deg)', alignSelf: 'flex-start' }} src={mates.currentMate.image} />
        {mates.currentMate.hasActiveAbilities && isTeammate() && (
          <>
            <button
              className={CSS.bottomButton}
              onClick={() => handleUseAbility()}
            >
              <b>Использовать способность</b>
            </button>
            <button
              className={CSS.bottomButton}
              onClick={() => handleGetAbilityStatus()}
            >
              <b>Статус способности</b>
            </button>
          </>
        )}
        <button
          className={CSS.bottomButton}
          onClick={() => handleGetInformation()}
        >
          <b>Показать информацию</b>
        </button>
      </div>
    )
  }


  return (
    <div className={CSS.wrapper}>
      <div className={CSS.header}>
        <img alt="back" className={CSS.headerButton} onClick={removeCurrentMate} src={arrowBack} />
        <div className={CSS.headerNameWrapper}>
          <div className={CSS.headerName} style={{ color: colorByTier(mates.currentMate.tier) }}>
            {mates.currentMate.name}
          </div>
          {isTeammate() && (
            <div className={CSS.headerStatus}>
              online
            </div>
          )}
        </div>
        {currentPlayer.player && renderActionButton()}
      </div>
      <div className={CSS.messageWrapper} style={{ backgroundImage: `url(${mates.currentMate.image})`}}>
        {mates.currentMate.messages && mates.currentMate.messages.map((message, index) => (
          message.type === "from" ? (
            <div key={index} className={CSS.message}>
              {parse(message.message)}
              <div className={CSS.messageTime}>
                {message.dateTime}
              </div>
            </div>
          ) : (
            <div key={index} className={CSS.playerMessage}>
              {parse(message.message)}
              <div className={CSS.messageTime}>
                {message.dateTime}
              </div>
            </div>
          )
        ))}
        <div ref={scrollAssistRef} />
      </div>
      {currentPlayer.player && renderBottomPanel()}
    </div>
  )
}

export default Chat;
