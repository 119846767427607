import styled from "styled-components";
import colors from '../../consts/colors';

const FieldWrapper = styled.div`
`;

const Field = styled.div`
  background-image: url(${props=>props.image});
  position: relative;
  width: 1160px;
  height: 760px;
  display: grid;
  grid-template-rows: repeat(5,152px);
  grid-template-columns: repeat(5,232px);
`;

const EncounterCard = styled.div`
  width: 232px;
  height: 152px;
  padding: 6px;
  border: 2px solid #6F3E8E;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 28px;
  color: ${props=> props.isDestroyed ? colors.red : colors.white};
  display: flex;
  text-decoration: ${props=> props.isDestroyed ? 'line-through' : 'none'};
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ActivateButton = styled.div`
  cursor: pointer;
  width: 275px;
  height: 72px;
  background: ${props=>props.color};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  line-height: 72px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColumnFixed = styled.div`
  width: 400px;
  height: 100%;
  padding: 35px 0;
  background: #212D39;
  display: flex;
  justify-content: space-between;
`;

const Navigator = styled.div`
  width: 400px;
  height: 398px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

const NavigatorHead = styled.div`
  font-size: 16px;
  color: ${colors.white};
  align-self: start;
  margin-bottom: 8px;
`;

const NavigatorMessage = styled.div`
  width: 320px;
  height: 40px;
  padding: 8px;
  background: #14141C;
  font-size: 16px;
  text-align: center;
  color: ${colors.white};
  margin-bottom: 8px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
`;

const ControlPanel = styled.div`
  width: 360px;
  background: #14141C;
  padding: 20px;
  border-radius: 4px;
`;

const Target = styled.div`
  position: absolute;
  width: 222px;
  height: 144px;
  border: 4px solid ${colors.red};
`;

const DestroyBar = styled.div`
  width: 10px;
  height: 148px;
  background: ${colors.red};
`;

export {
  Field,
  EncounterCard,
  ActivateButton,
  ColumnFixed,
  Target,
  DestroyBar,
  Navigator,
  NavigatorHead,
  NavigatorMessage,
  ControlPanel,
  FieldWrapper,
}
