import React, { useEffect, useState, useCallback, useMemo } from 'react';
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { useDispatch, useSelector } from "react-redux";
import InventoryService from "../../api/InventoryService";
import CSS from './PlayerItemsList.module.scss';
import { colorByTier } from '../../utils/utils';

const PlayerItemsList = ({ items, state, callback }) => {
    const bigSizeTypes = useMemo(() => ["Туловище", "Оружие", "Ноги"], []);

    return (
        <div className={CSS.wrapper}>
            {uniqBy(items, 'uniqueID').filter((item) => item.slotType.name !== 'Импланты').map((item) => (
                <div className={CSS.itemWrapper} key={item.id} onClick={() => callback(item)}>
                    <img className={CSS.image} style={{ height: bigSizeTypes.includes(item.slotType.name) ? '120px' : '80px'}} alt="item" src={item.image} />
                    <div style={{ color: colorByTier(item.tier)}}>{item.name}</div>
                </div>
            ))}
        </div>
    );
}

export default PlayerItemsList;