import { transitions, positions } from 'react-alert';
import closeIcon from '../../assets/icons/clear.svg';
import CSS from './Alert.module.scss';
import parse from 'html-react-parser';
import { colorByTier } from "../../utils/utils";
import get from 'lodash/get';

export const ALERT_TYPES = {
    MATE_MESSAGE: 'mate message',
    SABOTAGE_MESSAGE: 'sabotage message',
    CUSTOM_NOTIFICATION: 'custom notification',
}

export const ALERT_OPTIONS = {
    position: positions.BOTTOM_RIGHT,
    timeout: 600000,
    offset: '8px',
    containerStyle: {
      zIndex: 99999
    },
    transition: transitions.SCALE,
}

// TODO: work with styles
export const AlertTemplate = ({ style, message, close }) => {
    switch(message.type) {
      case ALERT_TYPES.CUSTOM_NOTIFICATION:
        return (
          <div style={{ ...style, borderColor: get(message, 'messageData.color', '#f2f2f2') }} className={CSS.wrapper}>
            <div className={CSS.defaultBox}>
              <div style={{ margin: '0 0 16px 8px', width: '300px' }}>
                <div className={CSS.messageHeader}>
                  {get(message, 'messageData.title', '')}
                </div>
                {parse(get(message, 'messageData.content', ''))}
              </div>
            </div>
            <img
              style={{ position: "absolute", top: '4px', right: '4px', width: "16px", cursor: "pointer" }}
              onClick={close}
              src={closeIcon}
              alt="close"
            />
          </div>
        );
      case ALERT_TYPES.SABOTAGE_MESSAGE:
        return (
          <div style={{ ...style }} className={CSS.wrapper}>
            <div className={CSS.alertBox}>
              <div style={{ margin: '0 0 16px 8px', width: '300px' }}>
                <div className={CSS.messageHeader} style={{ color: colorByTier(message.message.tier), marginBottom: '4px' }}>
                  {message.message.name}
                </div>
                <div className={CSS.messageFrom} style={{ color: colorByTier(message.message.tier), marginBottom: '4px' }}>
                  От {message.message.playerName}
                </div>
                {parse(message.message.description)}
                <br />
                {message.message.additionalProperty}
              </div>
            </div>
            <img
              style={{ position: "absolute", top: '4px', right: '4px', width: "16px", cursor: "pointer" }}
              onClick={close}
              src={closeIcon}
              alt="close"
            />
          </div>
        );
      case ALERT_TYPES.MATE_MESSAGE:
      default:
        return (
          <div style={{
            ...style,
            position: "relative",
            width: "400px",
            background: "#4C4C50",
            border: "2px solid #E4E7F0",
            boxSizing: "border-box",
            borderRadius: "4px",
            padding: "12px 16px 12px 8px",
            zIndex: 99999,
          }}>
            <div style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "16px",
              color: "#E4E7F0",
              display: "flex",
              flexDirection: "row",
            }}>
              <div className={CSS.image} size="64">
                <img alt="icon" src={message.mate.image} />
              </div>
              <div style={{ margin: '0 0 16px 8px', width: '300px' }}>
                <div className={CSS.messageHeader} style={{ color: colorByTier(message.mate.tier) }}>
                  {message.mate.name}
                </div>
                {parse(message.messageData.message)}
              </div>
            </div>
            <div className={CSS.messageTime}>{message.messageData.dateTime}</div>
            <img
              style={{ position: "absolute", top: '4px', right: '4px', width: "16px", cursor: "pointer" }}
              onClick={close}
              src={closeIcon}
              alt="close"
            />
          </div>
        );
    }
  }