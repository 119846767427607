import React, { useState, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import Popover from '@material-ui/core/Popover';
import CSS from "./FirstBuy.module.scss";
import CSSInventory from '../Inventory/Inventory.module.scss';
import InventoryService from './../../api/InventoryService';
import MatesService from './../../api/MatesService';
import { colorByTier } from '../../utils/utils';
import { addItem, addMate, changePlayerCharacteristic } from '../../api/socket';
import allActions from "../../state/actions";
import { MAP_STATES } from '../../consts/map';
import RenderStats from '../CharacterInfo/RenderStats';
import { changePlayerPoints } from '../../api/socket';
import allServices from './../../api/index';
import allWidgets from './../../widgets/index';
import ItemPopover from '../Inventory/ItemPopover';

const bigSizeTypes = ["Туловище", "Оружие", "Ноги"];

function FirstBuy() {

  const currentPlayer = useSelector(state => state.currentPlayer);
  const startPointsCurrent = get(currentPlayer, 'player.start.points.current', 0);
  const startPointsMaximun = get(currentPlayer, 'player.start.points.maximum', 0);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [mates, setMates] = useState([]);

  const openSector = useCallback(() => {
    changePlayerPoints('start', 'current', null, -3);
    changePlayerPoints('explore', 'current', 'increased');
  }, []);

  const rollItem = useCallback(async ({ type, excludeTypes }) => {
    const items = await new InventoryService().buyItem({ type, excludeTypes });
    if (!items) { return; }
    setItems(prevState => [...prevState, ...items]);
    const count = type ? -2 : -1;
    changePlayerPoints('start', 'current', null, count);
  }, []);

  const rollMate = useCallback(async () => {
    const mates = await new MatesService().buyMate({ excludeAlreadyInUse: true });
    if (!mates) { return; }
    setMates(prevState => [...prevState, ...mates]);
    changePlayerPoints('start', 'current', null, -3);
  }, []);

  const buyItem = useCallback((itemID) => {
    addItem(null, itemID);
    setItems((prevState) => prevState.filter((item) => item.id !== itemID));
  }, []);

  const buyMate = useCallback((mateID) => {
    addMate(mateID);
    setMates((prevState) => prevState.filter((mate) => mate.id !== mateID));
  }, []);

  const updateStat = (statName, type, value) => {
    changePlayerCharacteristic(statName, type);
    changePlayerPoints('start', 'current', null, -1 * value);
  }

  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const openChatWithMate = useCallback((mate) => {
    const matesService = new allServices.MatesService();
    if (get(mates, 'currentMate.id', null) !== mate.id) {
      dispatch(allActions.matesActions.setCurrentMate(mate));
      setTimeout(() => {
        matesService.getMessages(mate.id).then((messages) => {
          dispatch(allActions.matesActions.setCurrentMateMessages(messages));
        });
      }, 100);
    }
    dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.telepound.name, true));
  }, [dispatch, mates]);

  return (
    <div className={CSS.wrapper}>
        <div className={CSS.itemsWrapper}>
            <div className={CSS.pointText}>Количество очков: {startPointsCurrent}/{startPointsMaximun}</div>
            {startPointsCurrent === 0 && (
            <div className={CSS.pointText}>Закупка закончена!</div>
            )}
            {items.map((item) => (
                <div 
                key={item.id}
                    className={CSS.itemBox}
                style={{   
                  color: get(item, 'id') === item.id ? "#F2F2F2" : colorByTier(item.tier),
                  borderTop: `8px solid ${colorByTier(item.tier)}!important`,
                  border: `1px solid ${colorByTier(item.tier)}`,

                }}
                aria-describedby={item.id}
                onClick={(e) => handlePopoverOpen(e, item.id)}
              >
                <img
                  alt="item"
                  style={{
                    height: bigSizeTypes.includes(item.slotType.name) ? '120px' : "80px",
                    marginRight: "4px",
                  }}
                  src={item.image}
                />
                <div style={{ width: "315px" }}>
                  {item.name}
                </div>
                <Popover
                    id={item.id}
                    className={CSS.popover}
                    open={anchorEl.id === item.id}
                    anchorEl={anchorEl.target}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                  <ItemPopover anchorEl={{ ...anchorEl, item }} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
                </Popover>
                <button className={CSS.buyButton} onClick={() => buyItem(item.id)}>Добавить</button>
              </div>
            ))}
            {mates.map((mate) => (
                <div key={mate.id}
                  className={CSS.itemBox}
                  onClick={() => openChatWithMate(mate)}
                   style={{
                    color: "#F2F2F2",
                    height: "120px",
                    borderTop: `8px solid ${colorByTier(mate.tier)}!important`,
                    border: `1px solid ${colorByTier(mate.tier)}`,

                  }}>
                   <img className={CSS.icon} alt="mate" style={{ borderColor: colorByTier(mate.tier) }} src={mate.image} />
                   <div style={{ width: "275px" }}>
                    {mate.name}
                   </div>
                   <button className={CSS.buyButton} onClick={() => buyMate(mate.id)}>Добавить</button>
               </div>
            ))}
        </div>
        {startPointsCurrent !== 0 && (
          <>
          <div className={CSS.buttonsWrapper}>
        <div className={CSS.actionItem} onClick={() => rollItem({ excludeTypes: "Импланты" })}>
            Получить предмет
            <div className={CSS.valueText}>(-1)</div>
        </div>
        <div className={CSS.actionItem} onClick={() => rollItem({ type: 'Туловище' })}>
            Получить туловище
            <div className={CSS.valueText}>(-2)</div>
        </div>
        <div className={CSS.actionItem} onClick={() => rollItem({ type: 'Импланты' })}>
            Получить имплант
            <div className={CSS.valueText}>(-2)</div>
        </div>
        </div>
        <div className={CSS.buttonsWrapper}>
        <div className={CSS.row}>
        <div className={CSS.actionItem} style={{ height: '248px', width: '150px' }} onClick={openSector}>
                Получить 1 ОР
                <div className={CSS.valueText}>(-3)</div>
            </div>
            <div className={CSS.smallItem} style={{ height: '248px' }}>
            {currentPlayer.player && (
                <div style={{ transform: 'scale(0.9)' }}>
                        <RenderStats
                        characteristics={currentPlayer.player.characteristics}
                        updateStatCallback={updateStat}
                        isMutable
                        showMinus={false}
                    />
                </div>
            )}
        </div>
        </div>  
        <div className={CSS.row}>
            <div className={CSS.smallItem} onClick={() => rollItem({ type: 'Оружие' })}>
                Оружие
                <div className={CSS.valueText}>(-2)</div>
            </div>
            <div className={CSS.smallItem} onClick={() => rollItem({ type: 'Аксессуары' })}>
                Акксесуар
                <div className={CSS.valueText}>(-2)</div>
            </div>
        </div>
        <div className={CSS.row}>
            <div className={CSS.smallItem} onClick={() => rollItem({ type: 'Головные уборы' })}>
                Голова
                <div className={CSS.valueText}>(-2)</div>
            </div>
            <div className={CSS.smallItem} onClick={() => rollItem({ type: 'Ноги' })}>
                Ноги
                <div className={CSS.valueText}>(-2)</div>
            </div>
        </div>
        </div>
          </>
        )}
    </div>
  )
}

export default memo(FirstBuy);
