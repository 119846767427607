const changeIsLocked = (isLocked) => {
  return {
    type: "CHANGE_IS_LOCKED",
    payload: isLocked,
  }
}

const changeIsLoading = (isLoading) => {
  return {
    type: "CHANGE_IS_LOADING",
    payload: isLoading,
  }
}

export default {
  changeIsLocked,
  changeIsLoading,
}
