import MapService from "./MapService";
import MatesService from "./MatesService";
import PlayerService from "./PlayerService";
import UserService from "./UserService";
import SectorService from "./SectorService";
import CharacterService from "./LocatorService";

const allServices = {
  MapService,
  MatesService,
  PlayerService,
  UserService,
  SectorService,
  CharacterService,
}

export default allServices;
