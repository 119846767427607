import React, { useState } from 'react';
import CSS from '../CustomWidget.module.scss';
import classNames from 'classnames';
import get from 'lodash/get';
import Collapse from '@material-ui/core/Collapse';


const CustomSelect = ({ data, setResult }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isOpened, setIsOpened] = useState(false);

    const handleChange = (value, text) => {
        setSelectedValue({ value, text });
        setResult(prevState => ({ ...prevState, [data.name]: value }));
        setIsOpened(false);
    }

    return (
        <div>
            <div className={CSS.inputHeader}>{data.displayName}</div>
            <div className={CSS.selectResult} onClick={() => setIsOpened(prevState => !prevState)}>
                {get(selectedValue, 'text', data.placeholder  || 'Выберите значение')}
            </div>
            <Collapse direction="down" in={isOpened} mountOnEnter unmountOnExit>
                {data.data.map(({value, text}) => (
                    <div key={value} className={classNames(CSS.option, { [CSS.optionActive]: value === get(selectedValue, 'value', null) })} onClick={() => handleChange(value, text)}>{text}</div>
                ))}
            </Collapse>
            {data.helperText && (
                <div>{data.helperText}</div>
            )}
        </div>
    )
}

export default CustomSelect;