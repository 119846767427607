import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { positions, useAlert } from "react-alert";
import { useSpring, animated } from 'react-spring';
import parse from 'html-react-parser';
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import CSS from "./MainScreen.module.scss";
import LeftWidgets from './../../widgets/LeftWidgets/LeftWidgets';
import allActions from './../../state/actions/index';
import allServices from './../../api/index';
import WidgetZone from './../../components/WidgetZone/WidgetZone';
import { ALERT_TYPES } from './../../utils/Alert/Alert';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  subscribeToMateMessages,
  subscribeToSabotageMessages,
  unsubscribeToSabotageMessages,
  unsubscribeToMateMessages,
  subscribeToCustomMessages,
  unsubscribeToCustomMessages,
  activateCharacter,
  buyItem,
  recruitMate,
  saboteurEnemy,
  buyItemFromTrade,
  changePlayerPoints,
  leaveFromCongress,
  unsubscribeToAlerts,
  subscribeToAlerts,
  } from '../../api/socket';
import { TURN_STATES } from "../../consts/map";
import allWidgets from './../../widgets/index';
import switcherIcon from "../../assets/icons/encounterDialog.svg";
import DraggableSwitcher from './../../widgets/DraggableSwitcher/DraggableSwitcher';
import { POINTS_TYPE, POINTS_NAMES, VALUE_TYPE } from '../../consts/traders';
import useComponentVisible from './../../hooks/useComponentVisible';
import DraggableDialog from './../../widgets/Locator/Dialog/DraggableDialog';
import PlayerStates from '../../widgets/PlayerStates/PlayerStates';
import CustomWidget from './../../widgets/CustomWidget/CustomWidget';
import { isEmpty } from 'lodash/isEmpty';
import MobileScreen from './../Mobile/MobileScreen';
import {getCookie} from "../../utils/utils";


var isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

const MainScreen = () => {

  const widgets = useSelector(state => state.widgets);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);
  const mapState = useSelector(state => state.mapState);
  const currentState = get(currentPlayer, "player.currentTurnState", null);
  const [isDialogEnd, setIsDialogEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const sectorService = useMemo(() => new allServices.SectorService(), []);
  const mates = useSelector(state => state.mates);
  const dialogSwitcher = useSelector(state => state.dialogSwitcher);
  const dispatch = useDispatch();
  const matesService = new allServices.MatesService();
  const alert = useAlert();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [hasAlert, setHasAlers] = useState(false);
  const [backendAlert, setBackendAlert] = useState({});
  const { o } = useSpring({
      from: { o: 0 },
      o: hasAlert ? 1 : 0,
  });
  const sessionName = getCookie('sessionName');

  function notifyMe(title, body, icon) {
    const options = {
      body: body,
    };
    if (icon) {
      options.icon = icon;
    }
    const n = new Notification(title, options);
    n.onclick = function(x) {
      window.focus();
      this.close();
     };
  }

  function showCustomNotification(data) {
    alert.show({ messageData: data, type: ALERT_TYPES.CUSTOM_NOTIFICATION }, {
      onOpen: () => {
        notifyMe(data.title, 'На сайте важная информация!');
      }
    });
  }

  function showNotification({ messageData, mateID }) {
    if (!messageData) { return; }
      if (mates.currentMate && mates.currentMate.id === mateID && get(widgets, ['telepound', 'isOpened'])) {
        dispatch(allActions.matesActions.addCurrentMateMessages(messageData));
        return;
      }
      const currentMate = mates.mates.find(mate => mate.id === mateID);
      if (messageData.type === 'from') {
        alert.show({ messageData, mate: currentMate, type: ALERT_TYPES.MATE_MESSAGE }, {
          onOpen: () => {
            notifyMe(
              `Новое сообщение от ${currentMate.name}`,
              'Посмотри сообщение в месенджере',
              currentMate.image,
              );
          }
        });
      }
  }

  function saveToNotes(data) {
    dispatch(allActions.notesActions.saveNote({
      title: 'Саботаж',
      content: data.name + '\n' + data.description,
      date: Date.now(),
    }));
}

  function showSabotageNotification({ itemData, playerName }) {
    alert.show({ message: { ...itemData, playerName }, type: ALERT_TYPES.SABOTAGE_MESSAGE }, {
      position: positions.TOP_RIGHT,
      onOpen: () => {
        notifyMe(
          `${itemData.name} от ${playerName}`,
          itemData.description,
        );
        saveToNotes(itemData);
      }
    });
  }

  function showAlert(alert) {
    setBackendAlert(alert);
    setHasAlers(true);
    setTimeout(() => {
      setHasAlers(false);
      setBackendAlert({});
    }, 5000);
  }

  useEffect(() => {
    unsubscribeToMateMessages();
    subscribeToMateMessages(showNotification);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets, mates.currentMate]);

  useEffect(() => {
    if (get(currentPlayer, 'player.id')) {
      unsubscribeToSabotageMessages();
      subscribeToSabotageMessages(showSabotageNotification);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(currentPlayer, 'player.id')]);

  useEffect(() => {
    if (get(currentPlayer, 'player.id')) {
      unsubscribeToCustomMessages();
      subscribeToCustomMessages(showCustomNotification);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(currentPlayer, 'player.id')]);

  useEffect(() => {
    if (get(currentPlayer, 'player.id')) {
      unsubscribeToAlerts();
      subscribeToAlerts(showAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(currentPlayer, 'player.id')]);

  useEffect(() => {
    if (currentState === TURN_STATES.START) {
      dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.firstBuy.name, true));
    }
  }, [currentState, dispatch]);

  const changeWidgetState = useCallback((widgetName) => {
    if (get(widgets, [widgetName, 'isOpened'])) {
      dispatch(allActions.widgetsActions.changeIsOpened(widgetName, false));
    } else {
      dispatch(allActions.widgetsActions.changeIsOpened(widgetName, true));
    }
  }, [dispatch, widgets]);

  async function handleBuy(name, item) {
    buyItem(name, item.id);
    setIsDialogEnd(true);
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: false }));
  }

  async function handleRecruit(name, mate) {
    recruitMate(name, mate.id);
    setIsDialogEnd(true);
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
  }

  async function handleSaboteur(name, enemyID, itemID) {
    saboteurEnemy(name, enemyID, itemID);
    setIsDialogEnd(true);
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
  }

  async function handleTradeItems(name, get, items, cb) {
    buyItemFromTrade(name, get, items, cb);
  }

  async function handleBuyAfterTrade(name, item) {
    buyItem(name, item.id);
  }

  async function handleCartographerAction(type, data) {
    if (type === 'sector') {
      dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
      dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: false }));
      changePlayerPoints(POINTS_NAMES.EXPLORE, POINTS_TYPE.CURRENT, VALUE_TYPE.INCREASED, data.count);
    }
  }

  async function handleActivate() {
    activateCharacter(
      dialogSwitcher.dialog.data.name,
      ({ messages, serviceData }) => {
        dispatch(allActions.dialogSwitcherActions.setCurrentCharacterInfo({ name: 'dialog', data: { ...dialogSwitcher.dialog.data, messages, items: serviceData }}));
        if (dialogSwitcher.dialog.data.name === "Смотрящий сектора") {
          setIsLoading(true);
          sectorService.getSectorInfo(mapState.currentSector).then((sectorInfo) => {
            if (!sectorInfo) { return; }
            dispatch(allActions.sectorActions.setSectorInfo(sectorInfo));
            setIsLoading(false);
            handleEnd();
          });
        }
      }
    );
  }

  const handleCloseDialog = () => {
    setIsDialogEnd(false);
    dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: false }));
  };

  const handleEnd = () => {
    setIsDialogEnd(false);
    dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: false }));
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: false }));
    dispatch(allActions.dialogSwitcherActions.setCurrentCharacterInfo({ name: 'dialog', data: {} }));
  };

  const isWhopg = useMemo(() => sessionName?.toLowerCase().indexOf('whopg') >= 0, [sessionName]);

  if(isMobile.any()) {
    return <MobileScreen />
  }

  return (
    <div className={CSS.wrapper}>
      <WidgetZone widgets={widgets} />
      <LeftWidgets changeWidgetState={changeWidgetState} />
      <animated.div className={CSS.alert} style={{ opacity: o, visibility: o.interpolate(o => o === 0 ? 'hidden' : 'visible') }}>
        <Alert variant="filled" severity={backendAlert.type} onClick={() => {
          setHasAlers(false);
          setBackendAlert({});
        }}>
          {parse(get(backendAlert, 'text', ''))}
        </Alert>
      </animated.div>
      {['player'].includes(get(currentUser, 'user.type', '')) && (
        <PlayerStates />
      )}
      <DraggableDialog
        currentCharacterState={dialogSwitcher.dialog.data}
        open={dialogSwitcher.dialog.opened}
        dialogRef={ref}
        isDialogEnd={isDialogEnd}
        onBuy={handleBuy}
        onActivate={handleActivate}
        onRecruit={handleRecruit}
        onSaboteur={handleSaboteur}
        handleClose={handleCloseDialog}
        onTradeItems={handleTradeItems}
        onBuyAfterTrade={handleBuyAfterTrade}
        onCartographerAction={handleCartographerAction}
        handleEnd={handleEnd}
      />
      <CustomWidget />
      <DraggableSwitcher position={0} icon={switcherIcon} show={dialogSwitcher.encounter.hasData && !dialogSwitcher.encounter.opened} callback={() => {
        dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.encounter.name, true));
          dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'encounter', value: true }));
      }} />
      <DraggableSwitcher position={1} icon={switcherIcon} show={dialogSwitcher.dialog.hasData && !dialogSwitcher.dialog.opened} callback={() => {
          dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: true }));
        }} />
      <BottomMenu
        widgets={widgets}
        changeWidgetState={changeWidgetState}
        isViewer={['viewer', 'deputy'].includes(get(currentUser, 'user.type', ''))}
        isWhopg={isWhopg}
      />
    </div>
  )
}

export default MainScreen;
