import React, {useEffect, useState} from "react";
import * as THREE from "three";
import * as CANNON from "cannon";
import {DiceD12, DiceManager} from "threejs-dice";
import isEmpty from 'lodash/isEmpty';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {getRandomInt} from "../../utils/utils";
import colors from '../../consts/colors';
import useDidMountEffect from "../../hooks/useDidMountEffect";
import {useDispatch} from "react-redux";
import allActions from "../../state/actions";
import CSS from './Dicer.module.scss';

const Dicer = () => {

  const [config, setConfig] = useState({});
  const [number, setNumber] = useState(null);
  const [isFinished, setIsFinished] = useState('throw');

  function updatePhysics() {
    config.world.step(1.0 / 60.0);

    for (var i in config.dice) {
      config.dice[i].updateMeshFromBody();
    }
  }

  useDidMountEffect(() => {
    animate();
  }, [config]);

  function animate() {
    requestAnimationFrame(animate);
    updatePhysics();
    render();
    update();
  }

  function update() {
    if (!isEmpty(config.dice)) {
      setIsFinished(config.dice[0].isFinished());
    }
  }

  function render() {
    config.renderer.render(config.scene, config.camera);
  }

  useEffect(() => {

    const dice = [];
    // SCENE
    const scene = new THREE.Scene();
    // CAMERA
    const SCREEN_WIDTH = 600, SCREEN_HEIGHT = 400;
    const VIEW_ANGLE = 45, ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT, NEAR = 0.01, FAR = 20000;
    const camera = new THREE.PerspectiveCamera( VIEW_ANGLE, ASPECT, NEAR, FAR);
    scene.add(camera);
    camera.position.set(0,30,30);
    // RENDERER
    const renderer = new THREE.WebGLRenderer({antialias:true});
    renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const container = document.getElementById('diceField');
    container.appendChild( renderer.domElement );
    // EVENTS
    // CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement);

    let ambient = new THREE.AmbientLight('#ffffff', 0.1);
    scene.add(ambient);

    let directionalLight = new THREE.DirectionalLight('#ffffff', 0.3);
    directionalLight.position.x = -1000;
    directionalLight.position.y = 1000;
    directionalLight.position.z = 1000;
    scene.add(directionalLight);

    let light = new THREE.SpotLight(0xefdfd5, 1.3);
    light.position.y = 200;
    light.target.position.set(0, 0, 0);
    light.castShadow = true;
    light.shadow.camera.near = 50;
    light.shadow.camera.far = 110;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);


    // FLOOR
    var floorMaterial = new THREE.MeshPhongMaterial( { color: colors.black14, side: THREE.DoubleSide } );
    var floorGeometry = new THREE.PlaneGeometry(30, 30, 10, 10);
    var floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.receiveShadow  = true;
    floor.rotation.x = Math.PI / 2;
    scene.add(floor);
    // SKYBOX/FOG
    scene.fog = new THREE.FogExp2( 0x9999ff, 0.0015 );

    ////////////
    // CUSTOM //
    ////////////
    const world = new CANNON.World();

    world.gravity.set(0, -9.82 * 20, 0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 16;

    DiceManager.setWorld(world);

    //Floor
    let floorBody = new CANNON.Body({mass: 0, shape: new CANNON.Plane(), material: DiceManager.floorBodyMaterial});
    floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    world.add(floorBody);

    for (let i = 0; i < 1; i++) {
      const die = new DiceD12({size: 4, backColor: colors.main});
      scene.add(die.getObject());
      dice.push(die);
    }
    setConfig({
      scene,
      camera,
      dice,
      world,
      controls,
      container,
      renderer,
    });
    }, []);

  function randomDiceThrow() {
    const { dice } = config;
    let _diceValues = [];

    for (let i = 0; i < dice.length; i++) {
      let yRand = Math.random() * 20;
      dice[i].getObject().position.x = -15 - (i % 3) * 1.5;
      dice[i].getObject().position.y = 2 + Math.floor(i / 3) * 1.5;
      dice[i].getObject().position.z = -15 + (i % 3) * 1.5;
      dice[i].getObject().quaternion.x = (Math.random()*90-45) * Math.PI / 180;
      dice[i].getObject().quaternion.z = (Math.random()*90-45) * Math.PI / 180;
      dice[i].updateBodyFromMesh();
      let rand = Math.random() * 5;
      dice[i].getObject().body.velocity.set(25 + rand, 40 + yRand, 15 + rand);
      dice[i].getObject().body.angularVelocity.set(20 * Math.random() -10, 20 * Math.random() -10, 20 * Math.random() -10);

      _diceValues = [..._diceValues, {dice: dice[i], value: getRandomInt(1, 12)}];
    }
    DiceManager.prepareValues(_diceValues);
    setNumber(_diceValues[0].value);
  }

  return (
    <div className={CSS.wrapper} id="diceField">
      {isFinished && number && (
        <div className={CSS.number}>{number}</div>
      )}
      <div className={CSS.button} onClick={randomDiceThrow}>
        Ролл
      </div>
    </div>
  )
}

export default Dicer;
