const sortDate = (dateA, dateB) => {
  return new Date(dateB.date) - new Date(dateA.date);
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

function setCookie(name, value) {
  document.cookie = `${name}=${value}`;
}

function toHex(str) {
  if (!str) return null;
  return `0x${str.slice(1, str.length)}`;
}

function colorByTier(tier) {
  switch (tier) {
    case 1:
      return "#0070DD";
    case 2:
      return "#A335EE";
    case 3:
      return "#ff8000";
    case 0:
    default:
      return "#f2f2f2";
  }
}

function declOfNum(number, titles) {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
  }
  return titles[1];
}

export {
  sortDate,
  getRandomInt,
  getCookie,
  toHex,
  colorByTier,
  declOfNum,
  setCookie,
}
