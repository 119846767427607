const currentPlayer = (state = {}, action) => {
  switch(action.type){
    case "SET_PLAYER":
      return {
        ...state,
        player: action.payload,
        dataIsFetched: true,
      }
    case "CHANGE_CURRENT_TURN":
      return {
        ...state,
        player: {
          ...state.player,
          currentTurn: action.payload,
        },
      }
      case "CHANGE_MAP_STATE":
        return {
          ...state,
          player: {
            ...state.player,
            mapState: action.payload,
          },
        }
    case "CHANGE_PLAYER_POSITION":
      return {
        ...state,
        player: {
          ...state.player,
          position: action.payload,
        },
      }
    case "CHANGE_LAST_PLACE":
      return {
        ...state,
        player: {
          ...state.player,
          lastPlace: action.payload,
        },
      }
    case "CHANGE_COUNT_OF_STEPS":
      return {
        ...state,
        player: {
          ...state.player,
          numberOfSteps: action.payload,
        },
      }
    case "CHANGE_CELLS_CAN_GO":
      return {
        ...state,
        player: {
          ...state.player,
          cellsCanGo: action.payload,
        },
      }
    case "INCREMENT_STAT":
      if (state.player.stats[action.payload] === 20) {
        return state;
      }
      return {
        ...state,
        player: {
          ...state.player,
          stats: {
            ...state.player.stats,
            [action.payload]: state.player.stats[action.payload] + 1
          },
        },
      }
    case "DECREMENT_STAT":
      if (state.player.stats[action.payload] === 0) {
        return state;
      }
      return {
        ...state,
        player: {
          ...state.player,
          stats: {
            ...state.player.stats,
            [action.payload]: state.player.stats[action.payload] - 1
          },
        },
      }
    default:
      return state
  }
}

export default currentPlayer;
