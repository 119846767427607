import React, { memo } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import CSS from "./Effects.module.scss";
import { declOfNum } from '../../utils/utils';
import { removeEffect } from '../../api/socket';
import isEmpty from 'lodash/isEmpty';

function Effects() {

  const currentPlayer = useSelector(state => state.currentPlayer);
  const playerEffects = get(currentPlayer, 'player.effects.self', []);

  const handleRemoveEffect = (id) => () => {
    removeEffect(id);
  }

  return (
    <div className={CSS.wrapper}>
        {isEmpty(playerEffects) ? (
            <div className={CSS.centerText}>
                Нет активных эффектов
            </div>
        ) : (
            <div>
                {playerEffects.map((effect) => (
                <div className={CSS.effectBox}>
                    <div className={CSS.effectTitle}>{effect.name}</div>
                    <div>{effect.description}</div>
                    <div className={CSS.duration}>{effect.duration ? `Пропадёт через ${effect.duration} ${declOfNum(effect.duration, ['ход', 'хода', 'ходов'])}` : 'Бессрочный эффект'}</div>
                    <div className={CSS.button} onClick={handleRemoveEffect(effect.id)}>Отменить эффект</div>
                </div>
            ))}
            </div>
        )}
    </div>
  )
}

export default memo(Effects);
