const encountersNotes = (state = [], action) => {
    switch(action.type){
      case "SAVE_ENCOUNTER_NOTE":
        return [
          ...state,
          {
            ...action.payload,
            id: state.length,
          },
        ];
      case "DELETE_ENCOUNTER_NOTE":
        return [...state].filter((v) => v.id !== action.payload);
      default:
        return state
    }
  }
  
  export default encountersNotes;
  