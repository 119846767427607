import React, { useMemo, memo } from "react";
import { sortDate } from '../../../utils/utils';
import CSS from './SmallNotes.module.scss';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import parse from 'html-react-parser';

const SmallNotes = ({ notes }) => {
  const lastNote = useMemo(() => notes && !isEmpty(notes) && notes.sort(sortDate).slice(-1)[0], [notes]);
  return (
    <div className={CSS.wrapper}>
    {lastNote ? (
      <div className={CSS.note}>
      <div className={CSS.title}>
          {lastNote.title}
        </div>
        {parse(get(lastNote, 'content', ''))}
      </div>
    ) : (
      <div className={CSS.note}>
        <div className={CSS.title}>Нет заметок</div>
      </div>
    )}
  </div>
  )
}

export default memo(SmallNotes);
