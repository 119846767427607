import React from "react";
import moment from "moment";
import CSS from '../Notes.module.scss';
import deleteIcon from '../../../assets/icons/clear.svg';
import { sortDate } from "../../../utils/utils";
import parse from 'html-react-parser';

const NotesList = ({ notes, deleteCallback, setEditedNote, hasEdit }) => (
  <div className={CSS.notesListWrapper}>
    {notes.sort(sortDate).map((note) => (
      <div className={CSS.note} key={note.id}>
        <div className={CSS.noteText}>
          <div className={CSS.noteTitle}>
            {note.title}
          </div>
          <div className={CSS.noteContent}>
            {note.content && parse(note.content)}
          </div>
        </div>
        <img alt="delete" className={CSS.icon} onClick={() => deleteCallback(note.id)} src={deleteIcon} />
        <div className={CSS.date}>
          {hasEdit && <i className={CSS.changeButton} onClick={() => setEditedNote(note)}>Изменить</i>}
          {moment(note.date).format('HH:mm')}
        </div>
      </div>
    ))}
  </div>
);

export default NotesList;
