import React from "react";
import Telepound from "./Telepound/Telepound";
import Inventory from "./Inventory/Inventory";
import locatorIcon from '../assets/icons/track_changes.svg';
import mapIcon from '../assets/icons/public.svg';
import inventoryIcon from '../assets/icons/1work.svg';
import contactsIcon from '../assets/icons/contacts.svg';
import rulesIcon from '../assets/icons/local_library.svg';
import dicerIcon from '../assets/icons/casino.svg';
import encounterIcon from '../assets/icons/my_location.svg';
import encountersIcon from '../assets/icons/encounters.svg';
import firstBuyIcon from '../assets/icons/star.svg';
import darkWebIcon from '../assets/icons/dark-web.svg';
import triggerIcon from '../assets/icons/audience.svg';
import wheelIcon from '../assets/icons/wheel.svg';
import networkIcon from '../assets/icons/network.svg';
import trophyIcon from '../assets/icons/trophy.svg';
import bingoIcon from '../assets/icons/bingo.svg';
import updateIcon from '../assets/icons/update.svg';
import Map3D from './Map3D/Map3D';
import Locator from './Locator/Locator';
import Notes from './Notes/Notes';
import Encounter from './Encounter/Encounter';
import Dicer from './Dicer/Dicer';
import FirstBuy from "./FirstBuy/FirstBuy";
import Convention from './Convention/Convention';
import ShopGenerator from './ShopGenerator/ShopGenerator';
import Compare from './Compare/Compare';
import Triggers from "./Triggers/Triggers";
import GlobalInformation from './GlobalInformation/GlobalInformation';
import WheelOfMisfortune from './WheelOfMisfortune/WheelOfMisfortune';
import EncounterNotes from './Notes/EncounterNotes';
import Trophies from './Trophies/Trophies';
import Rules from './Rules/Rules';
import SocialGroups from "./SocialGroups/SocialGroups";
import Patch from "./Patch/Patch";
import Bingo from './Bingo/Bingo';
import Encounters from "./Encounters/Encounters";

const allWidgets = {
  locator: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'locator',
    displayName: 'Сканер',
    component: <Locator />,
    initialPos: {x: 65, y: 10},
    style: {},
    image: locatorIcon,
    showInViewerMode: true,
  },
  notes: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'notes',
    displayName: 'Заметки',
    component: <Notes />,
    initialPos: {x: 18, y: 14},
    style: {},
    isSmall: true,
    showInViewerMode: true,
  },
  encountersNotes: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'encountersNotes',
    displayName: 'Эффекты',
    component: <EncounterNotes />,
    initialPos: {x: 18, y: 14},
    style: {},
    isSmall: true,
  },
  telepound: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'telepound',
    displayName: 'Напарники',
    component: <Telepound />,
    initialPos: {x: 18, y: 14},
    style: {},
    isSmall: true,
    showInViewerMode: true,
  },
  // TODO: need complete
  map: {
    weight: 50,
    isDraggable: false,
    withHeader: false,
    name: 'map',
    displayName: 'Карта',
    component: <Map3D />,
    initialPos: {x: 0, y: 0},
    style: {},
    image: mapIcon,
    showInViewerMode: true,
  },
  inventory: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'inventory',
    displayName: 'Инвентарь',
    component: <Inventory />,
    initialPos: {x: 25, y: 10},
    image: inventoryIcon,
    showInViewerMode: true,
  },
  generalBlock: {
    weight: 100,
    isDraggable: false,
    withHeader: true,
    name: 'generalBlock',
    displayName: 'Общая информация',
    component: <GlobalInformation />,
    initialPos: {x: 0, y: 0},
    image: contactsIcon,
    showInViewerMode: true,
  },
  // TODO: need complete
  rules: {
    weight: 100,
    isDraggable: false,
    withHeader: true,
    name: 'rules',
    displayName: 'Правила',
    component: <Rules />,
    initialPos: {x: 0, y: 0},
    image: rulesIcon,
    showInViewerMode: true,
  },
  encounter: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'encounter',
    displayName: 'Случайные встречи',
    component: <Encounter />,
    initialPos: {x: 2, y: 2},
    image: encounterIcon,
    disabledInWhopg: true,
  },
  dicer: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'dicer',
    displayName: 'Кубик',
    component: <Dicer />,
    initialPos: {x: 45, y: 10},
    image: dicerIcon,
    showInViewerMode: true,
  },
  convention: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    withoutExit: true,
    name: 'convention',
    displayName: 'Съезд',
    component: <Convention />,
    initialPos: {x: 10, y: 10},
    isSmall: true,
  },
  shopGenerator: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    name: 'shopGenerator',
    displayName: 'Генератор',
    component: <ShopGenerator />,
    initialPos: {x: 10, y: 10},
    image: darkWebIcon,
  },
  firstBuy: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: true,
    name: 'firstBuy',
    displayName: 'Стартовый закуп',
    component: <FirstBuy />,
    initialPos: {x: 10, y: 10},
    image: firstBuyIcon,
  },
  compare: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: true,
    name: 'compare',
    displayName: 'Сравнение',
    component: <Compare />,
    initialPos: {x: 10, y: 10},
    showInViewerMode: true,
  },
  trigger: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: false,
    name: 'trigger',
    displayName: 'Персонажи',
    component: <Triggers />,
    initialPos: {x: 10, y: 10},
    image: triggerIcon,
  },
  wheel: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: false,
    name: 'wheel',
    displayName: 'Колесо неудач',
    component: <WheelOfMisfortune />,
    initialPos: {x: 10, y: 10},
    image: wheelIcon,
  },
  trpohies: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: false,
    name: 'trpohies',
    displayName: 'Трофеи',
    component: <Trophies />,
    initialPos: {x: 10, y: 10},
    image: trophyIcon,
    showInViewerMode: true,
  },
  socialGroups: {
    weight: 100,
    isDraggable: false,
    withHeader: true,
    isSmall: false,
    name: 'socialGroups',
    displayName: 'Социальные группы',
    component: <SocialGroups />,
    initialPos: {x: 0, y: 0},
    image: networkIcon,
    showInViewerMode: true,
  },
  bingo: {
    weight: 100,
    isDraggable: true,
    withHeader: true,
    isSmall: false,
    name: 'bingo',
    displayName: 'Бинго',
    component: <Bingo />,
    initialPos: {x: 0, y: 0},
    image: bingoIcon,
    showInViewerMode: true,
    disabledInWhopg: true,
  },
  patch: {
    weight: 55,
    isDraggable: false,
    withHeader: false,
    isSmall: false,
    name: 'patch',
    displayName: 'Обновления',
    component: <Patch />,
    initialPos: {x: 0, y: 0},
    image: updateIcon,
    showInViewerMode: true,
    disabledInWhopg: true,
  },
  encounters: {
    weight: 45,
    isDraggable: true,
    withHeader: true,
    isSmall: false,
    name: 'encounters',
    displayName: 'Случайные встречи',
    component: <Encounters />,
    initialPos: {x: 0, y: 0},
    image: encountersIcon,
    showInViewerMode: false,
  }
};

export default allWidgets;
