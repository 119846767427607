import React, { useState } from 'react';
import CSS from '../CustomWidget.module.scss';

const CustomNumber = ({ data, setResult }) => {

    const [value, setValue] = useState(null);

    const handleChange = (e) => {
        setValue(e.target.value);
        setResult(prevState => ({ ...prevState, [data.name]: e.target.value }));
    }

    return (
        <>
            <div className={CSS.inputHeader}>{data.name}</div>
            <input id={data.name} value={value} type="number" onChange={handleChange} className={CSS.inputValue} />
            {data.helperText && (
                <div>{data.helperText}</div>
            )}
        </>
    )
}

export default CustomNumber;