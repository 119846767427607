export const POINTS_NAMES = {
    MOVEMENT: 'movement',
    EXPLORE: 'explore',
    SHOP: 'shop',
    INFLUENCE: 'influence',
    TOKENS: 'tokens',
}
  
export const POINTS_TYPE = {
    CURRENT: 'current',
    MAXIMUN: 'maximum',
}
  
export const VALUE_TYPE = {
    INCREASED: 'increased',
    DECREASED: 'decreased',
}