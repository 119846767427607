import Configuration from "./_config";
import API from "./_api";
import { getCookie } from "../utils/utils";

class InventoryService {

  constructor() {
    this.config = new Configuration();
    this.items = [];
  }

  async rollItem(type, tiers, receivingMethods, excludeTypes, inventory, inventoryEquiped) {
    return API.get(this.config.ITEMS, {
      params: {
        'type': type,
        'tiers': tiers,
        'receivingMethods': receivingMethods,
        'excludeTypes': excludeTypes,
        'inventory': inventory,
        'inventoryEquiped': inventoryEquiped,
        'randomCount': 1,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.items = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async buyItem({ type, excludeTypes }) {
    return API.get(this.config.ITEMS, {
      params: {
        'type': type,
        'tiers': 1,
        'randomCount': 1,
        'excludeTypes': excludeTypes,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.items = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async findItems(type, excludeTypes) {
    return API.get(this.config.ITEMS, {
      params: {
        'type': type,
        'excludeTypes': excludeTypes,
        steamToken: getCookie('steamToken'),
        sessionID: getCookie('sessionID')
      }
    })
      .then(response => {
        return this.items = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getTypes() {
    return API.get(this.config.ITEM_TYPES, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.types = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default InventoryService;
