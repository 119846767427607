import { combineReducers } from 'redux';
import currentUser from './currentUser';
import currentPlayer from "./currentPlayer";
import notes from "./notes";
import compare from "./compare";
import mates from "./mates";
import mapState from "./map";
import widgets from "./widgets";
import appState from "./appState";
import dice from "./dice";
import sectorState from "./sector";
import inventory from "./inventory";
import dialogSwitcher from "./dialogSwitcher";
import encountersNotes from "./encountersNotes";
import general from "./general";

const rootReducer = combineReducers({
  currentUser,
  currentPlayer,
  notes,
  compare,
  mates,
  mapState,
  widgets,
  appState,
  dice,
  sectorState,
  inventory,
  dialogSwitcher,
  encountersNotes,
  general,
})

export default rootReducer;
