const setUser = (userObj) => {
  return {
    type: "SET_USER",
    payload: userObj
  }
}

const setAnonymous = () => {
  return {
    type: "SET_ANONYMOUS",
  }
}

const logOut = () => {
  return {
    type: "LOG_OUT"
  }
}

export default {
  setUser,
  logOut,
  setAnonymous,
}
