import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class MapService {

  constructor() {
    this.config = new Configuration();
    this.mapData = {}
  }

  async retrieveMapData() {
    return API.get(this.config.MAP_URL, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.mapData = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default MapService;
