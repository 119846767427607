import { MAP_PAINT_TYPE } from './../../consts/map';

const defaultMapState = {
  currentSector: null,
  paintType: MAP_PAINT_TYPE.CAPTURED,
}

const mapState = (state = defaultMapState, action) => {
  switch(action.type){
    case "SET_MAP":
      return {
        ...state,
        content: {...action.payload},
        dataIsFetched: true,
      }
    case "SET_MAP_STATE":
      return {
        ...state,
        ...action.payload,
      }
    case "CHANGE_SECTOR":
      return {
        ...state,
        currentSector: action.payload,
      }
    case "CHANGE_PAINT_TYPE":
      return {
        ...state,
        paintType: action.payload,
      }
    case "CHANGE_IS_OPEN_ALL":
      return {
        ...state,
        isOpenAll: action.payload,
      }
    case "CHANGE_SHOW_CAPTURED":
        return {
          ...state,
          showCaptured: action.payload,
        }
    default:
      return state
  }
}

export default mapState;
