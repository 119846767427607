import React, { useMemo, useState, useCallback } from 'react';
import CSS from './GlobalInformation.module.scss';
import CSSInventory from '../Inventory/Inventory.module.scss';
import DataService from '../../api/DataService';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import InventorySlot, { smallSlotPack } from './../Inventory/InventorySlot/InventorySlot';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import moment from "moment";
import ItemPopover from '../Inventory/ItemPopover';
import { colorByTier } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../state/actions';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '4px',
  },
}))(Tooltip);

function GlobalInformation() {

    const dataService = useMemo(() => new DataService(), []);
    const dispatch = useDispatch();
    const widgets = useSelector(state => state.widgets);
    const general = useSelector(state => state.general);
    const [overallData, setOverallData] = useState([]);
    const [logs, setLogs] = useState([]);
    const [openedReview, setOpenedReview] = useState([]);
    const [currentLog, setCurrentLog] = useState([]);
    const [currentMenuSelected, setCurrentMenuSelected] = useState('Инвентарь');
    const [currentInventory, setCurrentInventory] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [needUpdate, setNeedUpdate] = useState(false);

    useEffect(() => {
        if (isEmpty(get(general, 'currentInfo'))) { return; }
        setCurrentInventory(get(general, 'currentInfo.inventory'));
        const log = logs.find((log) => log.playerID.toString() === general.currentInfo.id.toString());
        if (log) {
          setCurrentLog(log.list);
        }
    }, [general, logs]);

    const fetchData = useCallback(async (playerID, tab) => {
      setIsLoading(true);
      const result = await dataService.retrieveData(playerID,  tab);
      return result;
    }, [dataService]);

      const fetchOverallData = useCallback(async (playerID=null) => {
        const result = await fetchData(playerID, 'overall');
        setOverallData(result);
        setIsLoading(false);
        return result && result[0];
    }, [fetchData]);

    const fetchInventoryData = useCallback(async (playerID=null) => {
      const result = await fetchData(playerID, 'inventory');
      return result;
    }, [fetchData]);

    const fetchGamesData = useCallback(async (playerID=null) => {
      const result = await fetchData(playerID, 'games');
      return result;
    }, [fetchData]);

    const fetchEncountersData = useCallback(async (playerID=null) => {
      const result = await fetchData(playerID, 'encounters');
      return result;
    }, [fetchData]);

    const fetchLogsData = useCallback(async () => {
      setIsLoading(true);
      const logs = await dataService.retrieveLogs();
      setLogs(logs);
    }, [dataService]);

    const fetchInfo = useCallback(async (id) => {
      const encounters = await fetchEncountersData(id);
      const overall = await fetchOverallData(id);
      const games = await fetchGamesData(id);
      const inventory = await fetchInventoryData(id);
      await fetchLogsData(id);
      return {
        ...overall,
        encounters: encounters[0].encounters,
        games: games[0].games,
        inventory: inventory[0].inventory,
        mates: inventory[0].mates,
      }
    }, [fetchEncountersData, fetchGamesData, fetchInventoryData, fetchLogsData, fetchOverallData]);

    const headSlot = useMemo(() => get(currentInventory, 'slots', []).find(slot => slot.name === "Головные уборы"), [currentInventory]);
    const bodySlot = useMemo(() => get(currentInventory, 'slots', []).find(slot => slot.name === "Туловище"), [currentInventory]);
    const legsSlot = useMemo(() => get(currentInventory, 'slots', []).find(slot => slot.name === "Ноги"), [currentInventory]);
    const weaponSlots = useMemo(() => get(currentInventory, 'slots', []).filter(slot => slot.name === "Оружие"), [currentInventory]);
    const accessoriesSlots = useMemo(() => get(currentInventory, 'slots', []).filter(slot => slot.name === "Аксессуары"), [currentInventory]);
    const implantsSlots = useMemo(() => get(currentInventory, 'slots', []).filter(slot => slot.name === "Импланты"), [currentInventory]);
    const bagSlots = useMemo(() => get(currentInventory, 'slots', []).filter(slot => slot.name === "Рюкзак"), [currentInventory]);
    const mates = useMemo(() => get(general, 'currentInfo.mates.list', []), [general]);
    const maxCount = useMemo(() => get(general, 'currentInfo.mates.slotsMaxCount', []), [general]);
    const bigSizeTypes = useMemo(() => ["Туловище", "Оружие", "Ноги"], []);

    const [anchorEl, setAnchorEl] = useState({});

    const handlePopoverOpen = useCallback((event, slotID, item, augmentID) => {
      if (anchorEl.slotID === slotID) {
          return handlePopoverClose();
      }
      setAnchorEl({
          slotID,
          augmentID,
          item: item,
          target: event.currentTarget,
      });
  }, [anchorEl.slotID]);

    const handlePopoverClose = () => {
        setAnchorEl({});
    };

    const handleSlotClick = useCallback((e, slot) => {
      if (!slot.item) { return; }
      handlePopoverOpen(e, slot.id, slot.item);
    }, [handlePopoverOpen]);

    const handleAugmentSlotClick = useCallback((e, slotID, item, augmentId) => {
      handlePopoverOpen(e, slotID, item, augmentId);
    }, [handlePopoverOpen]);

    const renderAugment = useCallback((slot) => {
        if (!slot.augments) { return; }
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {
              Array(slot.augments.maxCount)
                .fill(null)
                .map((_, i) => i)
                .map(augmentId => {
                  if (!isEmpty(slot.augments.items[augmentId])) {
                    return (
                    <div
                        style={{ position: "relative", width: "36px"}}
                        onClick={(e) => handleAugmentSlotClick(e, slot.id, slot.augments.items[augmentId], augmentId)}
                      >
                        <img alt="contain" width="100%" src={smallSlotPack.contain} />
                        <img
                          alt="item"
                          style={{
                            position: "absolute",
                            width: !bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                              && "36px",
                            height: bigSizeTypes.includes(slot.augments.items[augmentId].slotType.name)
                              && "36px",
                            margin: "auto",
                            left: 0,
                            right: 0,
                          }}
                          src={slot.augments.items[augmentId].image}
                        />
                        {slot.augments.items[augmentId].charges !== 'none' && <div className={CSS.augmentChargesRow}>{slot.augments.items[augmentId].charges}</div>}
                      </div>
                    )
                  }
                  return (
                    <img alt="empty" width="36px" style={{
                        boxSizing: 'border-box',
                      }} src={smallSlotPack.empty} />
                  )
                })
            }
          </div>
        )
      }, [bigSizeTypes, handleAugmentSlotClick]);

      const renderHead = useCallback(() => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Голова</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <InventorySlot
                  onItemClick={handleSlotClick}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                  slot={headSlot}
                />
                {has(headSlot, 'augments') && renderAugment(headSlot)}
              </div>
        </div>
      ), [handleSlotClick, headSlot, renderAugment]);

      const renderBody = useCallback(() => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Тело</div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <InventorySlot
                  onItemClick={handleSlotClick}
                onEmptySlotClick={() => {}}
                onWoundClick={() => {}}
                onChargeClick={() => {}}
                dragAssist={[null, () => {}]}
                  slot={bodySlot}
                />
                {has(bodySlot, 'augments') && renderAugment(bodySlot)}
              </div>
        </div>
      ), [bodySlot, handleSlotClick, renderAugment]);

      const renderLegs = useCallback(() => (
        <div className={CSS.marginRight}>
          <div className={CSS.slotTitle}>Ноги</div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <InventorySlot
                  onItemClick={handleSlotClick}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                  slot={legsSlot}
                />
                {has(legsSlot, 'augments') && renderAugment(legsSlot)}
              </div>
        </div>
      ), [handleSlotClick, legsSlot, renderAugment]);

      const renderWeapon = useCallback(() => (
        <div className={CSS.weaponWrapper}>
          <div className={CSS.slotTitle}>Оружие</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {weaponSlots.map((weapon, index) => (
                  <InventorySlot
                    key={index}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                    onItemClick={handleSlotClick}
                    slot={weapon}
                  />
                ))}
                {!isEmpty(weaponSlots) && weaponSlots[0].augments && renderAugment(weaponSlots[0])}
              </div>
        </div>
      ), [weaponSlots, renderAugment, handleSlotClick]);

      const renderImage = useCallback(() => (
        <div style={{ width: "455px" }}>
            <img
              alt="inventory"
              style={{ height: "450px" }}
              src={currentInventory && currentInventory.image}
            />
          </div>
      ), [currentInventory]);

      const renderImplants = useCallback(() => (
            <div>
              <div className={CSS.slotTitle}>
                Импланты
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                {implantsSlots.map((implant, index) => (
                  <InventorySlot
                    key={index}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                    onItemClick={handleSlotClick}
                    slot={implant}
                  />
                ))}
                {!isEmpty(implantsSlots) && implantsSlots[0].augments && renderAugment(implantsSlots[0])}
              </div>
            </div>
      ), [handleSlotClick, implantsSlots, renderAugment]);

      const renderAccessories = useCallback(() => (
        <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className={CSS.slotTitle}>
                  Аксессуары
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                {accessoriesSlots.map((accessories, index) => (
                  <InventorySlot
                    key={index}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                    onItemClick={handleSlotClick}
                    slot={accessories}
                  />
                ))}
              </div>
            </div>
      ), [accessoriesSlots, handleSlotClick]);

      const renderBackpack = useCallback(() => (
        <div>
              <div className={CSS.slotTitle}>
                Рюкзак
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                {bagSlots.map((bag, index) => (
                  <InventorySlot
                    key={index}
                    onItemClick={handleSlotClick}
                    onEmptySlotClick={() => {}}
                    onWoundClick={() => {}}
                    onChargeClick={() => {}}
                    dragAssist={[null, () => {}]}
                    slot={bag}
                  />
                ))}
              </div>
            </div>
      ), [bagSlots, handleSlotClick]);

      const renderMates = useCallback(() => (
          <div style={{ height: "225px", display: 'flex', flexDirection: 'row' }}>
              {Array(Math.max(maxCount - mates.length, 0)).fill(null).map((d, index) => (
                <div key={index} style={{ marginRight: '8px' }}>
                  <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column', lineHeight: '160px', borderRadius: '6px' }}>
                    Свободно
                  </div>
                </div>
              ))}
              {mates.map((mate, index) => (
                  has(mate, 'image') ? (
                    <HtmlTooltip placement="top-end" title={
                      <React.Fragment>
                        <div className={CSS.tooltip}>
                          {parse(mate.description)}
                        </div>
                      </React.Fragment>
                    }>
                      <div key={mate.id} style={{ marginRight: '8px' }}>
                        <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                          <img className={CSS.icon} alt="mate" src={mate.image} />
                          <div className={CSS.title} style={{ color: colorByTier(mate.tier) }}>{mate.name}</div>
                        </div>
                      </div>
                    </HtmlTooltip>
                  ) : (
                    <div key={index} style={{ marginRight: '8px' }}>
                      <div style={{ width: "160px", height: "160px", background: '#14141C', display: 'flex', alignItems: 'center', flexDirection: 'column', lineHeight: '160px', borderRadius: '6px' }}>
                        Свободно
                      </div>
                    </div>
                  )
              ))}
          </div>
      ), [mates, maxCount]);

    useEffect(() => {
      if (get(widgets, 'generalBlock.isOpened', false)) {
        fetchOverallData();
      }
    }, [widgets, fetchOverallData]);

    const fetchGeneralData = useCallback(async () => {
      if (!isEmpty(get(general, 'currentInfo', {}))) {
        const newInfo = await fetchInfo(general.currentInfo.id);
        dispatch(allActions.generalActions.changeCurrentSelectedPlayer(newInfo));
        setIsLoading(false);
      } else {
        fetchOverallData();
      }
    }, [dispatch, fetchInfo, fetchOverallData, general]);

    useEffect(() => {
      if (needUpdate) {
        setNeedUpdate(false);
        fetchGeneralData();
      }
    }, [fetchGeneralData, needUpdate]);

    function renderCurrentPage() {
        switch(currentMenuSelected) {
            case 'Основная':
                return (
                    <div className={CSS.bandCard}>
                        <img alt="band" src={general.currentInfo.band.image} className={CSS.bandImage} />
                        <div className={CSS.bandText}>
                          <div className={CSS.bandName}>{general.currentInfo.band.name}</div>
                          <div>{general.currentInfo.band.description}</div>
                        </div>
                    </div>
                )
            case 'Инвентарь':
                return (
                  <div className={CSS.rowInventory}>
                      <div className={CSS.columnInventory}>
                      {renderImage()}
                      {renderMates()}
                      </div>
                    <div className={CSS.columnInventory}>
                      <div className={CSS.rowItems}>
                        {renderHead()}
                        {renderBody()}
                        {renderLegs()}
                        {renderWeapon()}
                      </div>
                      {renderImplants()}
                      {renderAccessories()}
                      {renderBackpack()}
                      <Popover
                        className={CSSInventory.popover}
                        open={anchorEl.item}
                        anchorEl={anchorEl.target}
                        anchorOrigin={{
                                    vertical: 'right',
                                    horizontal: 'right',
                        }}
                                    transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                        >
                        <ItemPopover anchorEl={anchorEl} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
                    </Popover>
                  </div>
                  </div>
                )
            case 'Игры':
                return (
                    <div className={CSS.gameColumn}>
                      <div className={CSS.gameCard}>
                            <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                              №
                            </div>
                            <div className={CSS.gameBox}  style={{ width: '500px', textAlign: 'center' }}>
                              Игра
                            </div>
                            <div className={CSS.gameBox} style={{ width: '120px', textAlign: 'center' }}>
                            Сложность
                            </div>
                            <div className={CSS.gameBox} style={{ width: '120px', textAlign: 'center' }}>
                            Статус
                            </div>
                            <div className={CSS.gameBox} style={{ width: '400px', textAlign: 'center' }}>
                            Сектор
                            </div>
                            <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                            GGP
                            </div>
                      </div>
                        {!isEmpty(general.currentInfo.games) && general.currentInfo.games.map((game, index) => (
                          <div onClick={() => setOpenedReview((prevState) => game.gameName === prevState ? '' : game.gameName)}>
                            <div key={index} style={{ cursor: 'pointer' }} className={CSS.gameCard}>
                            <div className={CSS.gameBox}  style={{ width: '50px', textAlign: 'center' }}>
                                {general.currentInfo.games.length - index}
                            </div>
                            <div className={CSS.gameBox} style={{ width: '500px', textAlign: 'center' }}>
                                {parse(game.gameName)}
                            </div>
                            <div className={CSS.gameBox} style={{ width: '120px', textAlign: 'center' }}>
                                {parse(game.difficulty)}
                            </div>
                            <div className={CSS.gameBox} style={{ width: '120px', textAlign: 'center' }}>
                                {parse(game.status)}
                            </div>
                            <div className={CSS.gameBox} style={{ width: '400px', textAlign: 'center' }}>
                              {parse(game.sector)}
                            </div>
                            <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                              {parse(game.ggp)}
                            </div>
                          </div>
                          <Collapse direction="down" in={openedReview === game.gameName} mountOnEnter unmountOnExit>
                          <div className={CSS.gameCard} style={{ background: '#121212' }}>
                          <div
                            className={CSS.gameBox}
                            style={{
                              background: 'transparent',
                              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', maxWidth: 'fit-content', textAlign: 'start' }}
                          >
                            {game.review ? parse(game.review) : 'Отзыва нет'}
                          </div>
                          </div>
                        </Collapse>
                          </div>
                        ))}
                    </div>
                )
                case 'Случайные встречи':
                    return (
                        <div className={CSS.encounterColumn}>
                            {general.currentInfo.encounters.map((encounter, index) => (
                                <div key={index} className={CSS.gameCard} style={{ fontSize: '24px' }}>
                                <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                                    {index + 1}
                                </div>
                                <div className={CSS.gameBoxDefault} style={{ width: '1300px' }}>
                                    {parse(encounter.content)}
                                    <div className={CSS.time}>{moment(encounter.timestamp).format('LLL')}</div>
                                </div>
                            </div>
                            ))}
                        </div>
                    )
            case 'Лог':
              return (
                <div className={CSS.encounterColumn}>
                    {currentLog.map((info, index) => (
                        <div key={index} className={CSS.gameCard} style={{ fontSize: '24px' }}>
                        <div className={CSS.gameBox} style={{ width: '50px', textAlign: 'center' }}>
                            {index + 1}
                        </div>
                        <div className={CSS.gameBoxLeftAlign} style={{ width: '1300px' }}>
                            {parse(info.text)}
                            <div className={CSS.time}>{moment(info.timestamp).format('LLL')}</div>
                        </div>
                    </div>
                    ))}
                </div>
            )
            default:
                    return <div></div>
        }
    }

    function renderInfo() {
        return (
            <div className={CSS.infoWrapper}>
                <div className={CSS.smallMenu}>
                   <div
                      className={classNames(CSS.smallMenuItem, { [CSS.smallMenuItemSelected]: currentMenuSelected === 'Основная' })}
                      onClick={() => setCurrentMenuSelected('Основная')}
                    >
                        Основная
                    </div>
                    <div
                      className={classNames(CSS.smallMenuItem, { [CSS.smallMenuItemSelected]: currentMenuSelected === 'Инвентарь'})}
                      onClick={() => setCurrentMenuSelected('Инвентарь')}
                    >
                        Инвентарь
                    </div>
                    <div
                      className={classNames(CSS.smallMenuItem, { [CSS.smallMenuItemSelected]: currentMenuSelected === 'Игры' })}
                      onClick={() => setCurrentMenuSelected('Игры')}
                    >
                        Игры
                    </div>
                    <div
                      className={classNames(CSS.smallMenuItem, { [CSS.smallMenuItemSelected]: currentMenuSelected === 'Случайные встречи' })}
                      onClick={() => setCurrentMenuSelected('Случайные встречи')}
                    >
                        Случайные встречи
                    </div>
                    <div
                      className={classNames(CSS.smallMenuItem, { [CSS.smallMenuItemSelected]: currentMenuSelected === 'Лог' })}
                      onClick={() => setCurrentMenuSelected('Лог')}
                    >
                        Лог
                    </div>
                </div>
                <div className={CSS.infoCard}>
                    {renderCurrentPage()}
                </div>
            </div>
        )
    }

    if (isLoading) {
      return (
        <div className={CSS.wrapperLoader}>Загрузка...</div>
      )
    }

    return (
        <div className={CSS.wrapper}>
            {
                overallData && !get(overallData, 'message', "").includes("ERROR:") && overallData.filter((player) => !isEmpty(general.currentInfo) ? player.id === general.currentInfo.id : true).map((player) => {
                    const explorePoints = get(player, "map.explorePoints.current", "?");
                    const maxExplorePoints = get(player, "map.explorePoints.maximum", "?");
                    const movePoints = get(player, "map.movePoints.current", "?");
                    const maxMovePoints = get(player, "map.movePoints.maximum", "?");
                    const buyPoints = get(player, "shop.points.current", "?");
                    const maxBuyPoints = get(player, "shop.points.maximum", "?");
                    const influencePoints = get(player, "influence.points.current", "?");
                    const tokensPoints = get(player, "tokens.points.current", "?");
                    const capsPoints = get(player, "caps.points.current", "?");
                    const timePlayed = get(player, "timer", "?");
                 return (
                    <div key={player.id} className={CSS.infoColumn}>
                        <img alt="hero" className={CSS.playerImage} src={player.image} />
                        <div className={CSS.playerName} style={{ color: get(player, 'map.color') }}>{player.name}</div>
                        <div className={CSS.statCount}>Уровень {player.level}</div>
                        <div className={CSS.statCount}>{player.experience.current}/{player.experience.maximum}</div>
                        {Object.entries(player.characteristics).map(([name, data])=> (
                            <div key={name} className={CSS.statRow}>
                                <div className={CSS.statName}>{data.displayName}</div>
                                <div className={CSS.statCount}>{get(data, 'values.actual.value')}({get(data, 'values.summary.value')})</div>
                            </div>
                        ))}
                        <div className={CSS.divider} />
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Разведки</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{explorePoints}</div>
                                /
                                <div className={CSS.statCount}>{maxExplorePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Движения</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{movePoints}</div>
                                /
                                <div className={CSS.statCount}>{maxMovePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Покупки</div>
                            <div className={CSS.row}>
                                <div className={CSS.statCount}>{buyPoints}</div>
                                /
                                <div className={CSS.statCount}>{maxBuyPoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Очки Влияния</div>
                            <div>
                                <div className={CSS.statCount}>{influencePoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Жетоны</div>
                            <div>
                                <div className={CSS.statCount}>{tokensPoints}</div>
                            </div>
                        </div>
                        <div className={CSS.statRow}>
                          <div className={CSS.statName}>Крышечки</div>
                          <div>
                            <div className={CSS.statCount}>{capsPoints}</div>
                          </div>
                        </div>
                        <div className={CSS.statRow}>
                            <div className={CSS.statName}>Время</div>
                            <div>
                                <div className={CSS.statCount}>{timePlayed}</div>
                            </div>
                        </div>
                        <button className={CSS.button} onClick={async () => {
                          dispatch(allActions.generalActions.changeCurrentSelectedPlayer(isEmpty(get(general, 'currentInfo', {})) ? player : {}));
                          setNeedUpdate(true);
                        }}>{isEmpty(get(general, 'currentInfo', {})) ? 'Открыть' : 'Вернуться'}</button>
                    </div>
                )})
            }
            {!isEmpty(get(general, 'currentInfo', {})) && renderInfo()}
        </div>
    )
}

export default GlobalInformation;
