import React from "react";
import styled from "styled-components";
import CSS from './StartView.module.scss';

export const Box = styled.div`
  width: 320px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Name = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 8px;
`;

export const Level = styled.div`
  text-align: flex-start;
  color: #FF8000;
  width: 100%;
  border-bottom: 2px solid #9B9B9B;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  color: #F2F2F2;
`;

export const Button = styled.div`
  width: 100%;
  height: 45px;
  background: ${props=>props.color};
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  color: #F2F2F2;
  margin-top: 8px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: row;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const TextCloud = styled.div`
  padding: 20px;
  margin: 8px;
  position:relative;
  width: 650px;
  color: #D4D4D4;
  background: #121212;
  border-radius: 30px 30px 30px 0;
  ::before {
    content:"\\A";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent #121212 transparent;
    bottom: 0;
    left: -17px;
  }
`;

export const ItemsBox = styled.div`
  width: 740px;
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const ItemBox = styled.div`
  width: 140px;
  margin: 4px 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemWrapper = styled.div`
  height: ${props=> props.height ? props.height : '180px'};
  background-color: ${props=> props.isSelected ? props.color : "transparent"};
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemIcon = styled.img`
  width: 80px;
`;

export const ItemName = styled.div`
  font-family: Cyberpunk;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  padding: 2px;
  word-break: break-word;
  color: ${props=> props.isSelected ? "#F2F2F2" : props.color};
`;

export const BuyButton = styled.div`
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #F2F2F2;
  background: #BAAEFE;
  cursor: pointer;
`;

function StartView(state, onActivate, onEnd) {
  return (
    <Box>
      <img alt="character" style={{ width: "250px", height: "250px" }} src={state.image} />
      <Name>{state.name}</Name>
      <Level>Уровень {state.level.current}</Level>
      <Description>{state.description}</Description>
      <div className={CSS.buttonGood} onClick={onActivate}>Воспользоватся</div>
      <div className={CSS.buttonBad} onClick={onEnd}>Уйти</div>
    </Box>
  )
}

export default StartView;
