import React, { useState, useMemo, useCallback, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Collapse from '@material-ui/core/Collapse';
import moment from "moment";
import eventIcon from "../../assets/images/event.svg";
import notesIcon from "../../assets/icons/insert_drive_file.svg";
import playerStateIcon from "../../assets/icons/heart.svg";
import matesIcon from "../../assets/icons/people.svg";
import allWidgets from "../../widgets/index";
import LeftWidget from './../../components/LeftWidget/LeftWidget';
import CSS from "./LeftWidgets.module.scss";
import CharacterInfo from './../CharacterInfo/CharacterInfo';
import allServices from './../../api/index';
import allActions from './../../state/actions/index';
import MatesGallery from './../MatesGallery/MatesGallery';
import SmallNotes from './../Notes/SmallNotes/SmallNotes';
import DataService from './../../api/DataService';
import {setCookie, getCookie} from '../../utils/utils';
import classNames from 'classnames';
import {playerCompleteQuest} from "../../api/socket";
import SpringModal from "../Locator/SpringModal/SpringModal";

const DateComponent = ({ now }) => (
  <>
    <div className={CSS.time}>
      {now.format('HH:mm')}
    </div>
    <div className={CSS.date}>
      {now.format('dddd, MMMM D')}
    </div>
  </>
)

const SelectWidget = ({ list, handleSelect }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleChange = (id, name) => {
      setSelectedValue({ id, name });
      handleSelect(id, name);
      setIsOpened(false);
  }

  useEffect(() => {
    setSelectedValue({ id: getCookie("sessionID"), name: getCookie("sessionName") });
  }, []);

  return (
      <div>
          <div className={CSS.selectResult} onClick={() => setIsOpened(prevState => !prevState)}>
              {get(selectedValue, 'name', 'Выберите сессию')}
          </div>
          <Collapse direction="down" in={isOpened} mountOnEnter unmountOnExit>
              {list.map(({ id, name }) => (
                  <div key={id} className={classNames(CSS.option, { [CSS.optionActive]: id === get(selectedValue, 'id', null) })} onClick={() => handleChange(id, name)}>{name}</div>
              ))}
          </Collapse>
      </div>
  )
}

function LeftWidgets({ changeWidgetState }) {

  const dataService = useMemo(() => new DataService(), []);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);
  const mates = useSelector(state => state.mates);
  const notes = useSelector(state => state.notes);
  const widgets = useSelector(state => state.widgets);
  const dispatch = useDispatch();
  const sessionName = getCookie('sessionName');

  const [isOpenWidgets, setIsOpenWidgets] = useState(true);
  const [now, setNow] = useState(moment());
  const [currentSessions, setCurrentSessions] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentQuest, setCurrentQuest] = useState(null);

  const openChatWithMate = useCallback((mate) => {
    const matesService = new allServices.MatesService();
    if (get(mates, 'currentMate.id', null) !== mate.id) {
      dispatch(allActions.matesActions.setCurrentMate(mate));
      setTimeout(() => {
        matesService.getMessages(mate.id).then((messages) => {
          dispatch(allActions.matesActions.setCurrentMateMessages(messages));
        });
      }, 100);
    }
    dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.telepound.name, true));
  }, [dispatch, mates]);

  const handleCompleteQuest = useCallback((questId)  => () => {
    playerCompleteQuest(questId);
    setOpen(false);
  }, []);

  const currentQuests = () => {
    const data = get(currentPlayer, 'player.quests', []);
    if (!data) { return; }
    return (
      <div className={CSS.lawsBox}>
        {data.map((quest) => (
          <div className={CSS.questBox}>
            {quest.name}
            <div className={CSS.lawDescription}>{quest.description}</div>
            <div className={CSS.button} onClick={() => {
              setCurrentQuest(quest);
              setOpen(true);
            }}>
              Завершить квест
            </div>
          </div>
        ))}
      </div>
    );
  };

  const currentLaws = () => {
    const data = get(currentPlayer, 'player.effects.global', []);
    if (!data) { return; }
    return (
      <div className={CSS.lawsBox}>
        {data.filter((effect) => effect.type === 'act').map((effect) => (
        <div className={CSS.lawBox}>
          {effect.name}
          <div className={CSS.lawDescription}>{effect.description}</div>
        </div>
      ))}
      </div>
    );
  };

  const smallNotesWidget = useMemo(() => <SmallNotes notes={notes} />, [notes]);
  const statsWidget = useMemo(() => (
    currentPlayer.player && (
      <CharacterInfo player={currentPlayer.player} />
    )
  ), [currentPlayer]);
  const matesWidget = useMemo(() => (
    <MatesGallery
      mates={get(currentPlayer, 'player.mates.list', []).filter((mate) => !isEmpty(mate))}
      callback={openChatWithMate}
      changeWidgetState={changeWidgetState}
    />
  ), [changeWidgetState, currentPlayer, openChatWithMate]);

  const dateComponent = useMemo(() => <DateComponent now={now} />, [now]);

  useEffect(() => {
    const interval = setInterval(() => setNow(moment()), 20000);
    return () => clearInterval(interval);
  }, [now]);

  const handleSessionSelect = useCallback((value, name) => {
    setCookie("sessionID", value);
    setCookie("sessionName", name);
    document.location.reload();
  }, []);

  const fetchSessions = useCallback(async () => {
    const response = await dataService.retrieveSessions();
    if (!response || get(response, 'message', "").includes("ERROR:")) { return; }
    setCurrentSessions(response);
  }, [dataService]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderModal = useCallback(() => {
    return (
      <div className={CSS.modal}>
        Вы уверены что выполнили все условия квеста?
        <div className={CSS.modalButton} onClick={handleCompleteQuest(currentQuest?.id)}>Выполнил</div>
        <div className={CSS.modalButton} onClick={() => setOpen(false)}>Отмена</div>
      </div>
    )
  }, [handleCompleteQuest, currentQuest]);

  return (
    <div className={CSS.wrapper} style={{ zIndex: get(widgets, 'map.weight', 100) + 1 }}>
      <SpringModal open={open} handleClose={handleClose}>
        {renderModal()}
      </SpringModal>
      {dateComponent}
      <Collapse direction="down" in={isOpenWidgets} mountOnEnter unmountOnExit>
        {['player', 'deputy'].includes(get(currentUser, 'user.type', ''))  && sessionName?.toLowerCase().indexOf('whopg') >= 0 && (
          <LeftWidget
            icon={eventIcon}
            title="Квест"
          >
            {currentQuests()}
          </LeftWidget>
        )}
        {['player', 'deputy'].includes(get(currentUser, 'user.type', '')) && (
          <LeftWidget
            icon={eventIcon}
            title="Текущие законы"
            callback={() => ['player', 'deputy'].includes(get(currentUser, 'user.type', '')) && changeWidgetState(allWidgets.convention.name)}
          >
            {currentLaws()}
          </LeftWidget>
        )}
        <LeftWidget
          icon={notesIcon}
          title="Заметки"
          callback={() => changeWidgetState(allWidgets.notes.name)}
        >
          {smallNotesWidget}
        </LeftWidget>
        {get(currentUser, 'user.type', '') === 'player' && (
          <LeftWidget
            icon={notesIcon}
            title="Эффекты"
            callback={() => changeWidgetState(allWidgets.encountersNotes.name)}
          >
          </LeftWidget>
        )}
        {get(currentUser, 'user.type', '') === 'player' && (
                  <LeftWidget
                  icon={playerStateIcon}
                  title="Состояние"
                  callback={() => changeWidgetState(allWidgets.effects.name)}
                >
                  {statsWidget}
                </LeftWidget>
        )}
        <LeftWidget
          icon={matesIcon}
          title="Напарники"
          callback={() => changeWidgetState(allWidgets.telepound.name)}
        >
           {get(currentUser, 'user.type', '') === 'player' && matesWidget}
        </LeftWidget>
        <LeftWidget
          icon={matesIcon}
          title="Сессии"
        >
           <SelectWidget list={currentSessions} handleSelect={handleSessionSelect} />
        </LeftWidget>
      </Collapse>
      <LeftWidget fat>
        <div
          onClick={() => setIsOpenWidgets(prevState => !prevState)}
          className={CSS.buttonText}
        >
          {isOpenWidgets ? "Свернуть" : "Развернуть"}
        </div>
      </LeftWidget>
    </div>
  )
}

export default memo(LeftWidgets);
