import React, { useState } from "react";
import Popover from '@material-ui/core/Popover';
import {
  Box,
  Description,
  ItemBox, ItemIcon, ItemName,
  ItemsBox, ItemWrapper,
  Level,
  Name,
  TextCloud,
  Wrapper,
} from "./StartView";
import { colorByTier } from '../../../../utils/utils';
import get from "lodash/get";
import CSS from './StartView.module.scss';
import CSSInventory from '../../../Inventory/Inventory.module.scss';
import CSSFirstBuy from '../../../FirstBuy/FirstBuy.module.scss';
import ItemPopover from '../../../Inventory/ItemPopover';


function ImplantView(state, onBuy, onEnd) {

  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);

  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  function renderItems(items) {
    return (
      <ItemsBox>
        {items && items.map((item) => (
          <ItemBox>
            <ItemWrapper
              color={colorByTier(item.tier)}
              aria-describedby={item.id}
              onClick={(e) => handlePopoverOpen(e, item.id)}
              isSelected={get(currentSelectedItem, 'name', '') === item.name}
            >
              <ItemIcon src={item.image} />
              <ItemName
                color={colorByTier(item.tier)}
                isSelected={get(currentSelectedItem, 'name', '') === item.name}
              >
                {item.name}
              </ItemName>
            </ItemWrapper>
            <div className={CSS.buyButton} onClick={() => onBuy(state.name, item)}>Выбрать</div>
            <Popover
                    id={item.id}
                    className={CSSFirstBuy.popover}
                    open={anchorEl.id === item.id}
                    anchorEl={anchorEl.target}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                  <ItemPopover anchorEl={{ ...anchorEl, item }} handlePopoverClose={handlePopoverOpen} handleChargeClick={() => {}} />
                </Popover>
          </ItemBox>
        ))}
      </ItemsBox>
    )
  }

  return (
    <Wrapper>
      <Box>
        <img style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
        {get(state, 'messages.start', '')}
        </TextCloud>
        {renderItems(state.items.commons)}
        {renderItems(state.items.augmentators)}
      </div>
    </Wrapper>
  )
}

export default ImplantView;
