const notes = (state = [], action) => {
  switch(action.type){
    case "SAVE_NOTE":
      return [
        ...state,
        {
          ...action.payload,
          id: state.length,
        },
      ];
    case "DELETE_NOTE":
      return [...state].filter((v) => v.id !== action.payload);
    case "EDIT_NOTE":
      return [
        ...state.map((item) => {
          if (item.id === action.payload.id) {
            return { ...action.payload, date: item.date }
          }
          return item;
        }),
      ];
    default:
      return state
  }
}

export default notes;
