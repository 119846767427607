import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from './../../state/actions/index';
import { triggerCharacter } from '../../api/socket';
import CSS from './Triggers.module.scss';
import allWidgets from '..';
import allServices from './../../api/index';

const CHARACTERS = [
    {
      name: "Торговец оружием",
      maxLevel: 5,
    },
    {
      name: "Барахольщик",
      maxLevel: 5,
    },
    {
      name: "Торговец имплантами",
      maxLevel: 5,
    },
    {
      name: "Рекрутер",
      maxLevel: 5,
    },
    {
      name: "Картограф",
      maxLevel: 3,
    },
    {
      name: "Трейдер",
      maxLevel: 3,
    },
    {
      name: "Смотрящий сектора",
      maxLevel: 3,
    },
    {
      name: "ТОР",
      maxLevel: 1,
    },
    {
      name: "Диверсант",
      maxLevel: 1,
    }
  ];

function Triggers() {

    const dispatch = useDispatch();
    const mapState = useSelector(state => state.mapState);
    const sectorService = useMemo(() => new allServices.SectorService(), []);

    function handleActivateCharacter(name, level) {
        triggerCharacter(
          name,
          level,
          ({ characterData, messages, serviceData }) => {
            if (name === "Смотрящий сектора") {
                sectorService.getSectorInfo(mapState.currentSector).then((sectorInfo) => {
                  if (!sectorInfo) { return; }
                  dispatch(allActions.sectorActions.setSectorInfo(sectorInfo));
                });
            } else {
                dispatch(allActions.dialogSwitcherActions.setCurrentCharacterInfo({ name: 'dialog', data: { ...characterData, messages, items: serviceData }}));
                dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
                dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: true }));
            }
          }
        );
      }

    return (
        <div className={CSS.characters}>
        {CHARACTERS.map((character) => (
          <div className={CSS.character} key={character.name}>
            {character.name}
            <div className={CSS.characterButtons}>
              {Array(character.maxLevel).fill(null).map((_, index) => (
                <div className={CSS.characterButton} onClick={() => handleActivateCharacter(character.name, index + 1)}>{index + 1}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
}

export default Triggers;