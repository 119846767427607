import React from "react";
import get from "lodash/get";
import has from 'lodash/has';
import smallEmptySlot from "../../../assets/styled-blocks/smallEmptySlot.svg";
import smallSlot from "../../../assets/styled-blocks/smallSlot.png";
import bigEmptySlot from "../../../assets/styled-blocks/bigEmptySlot.png";
import bigSlot from "../../../assets/styled-blocks/bigSlot.png";
import tier1Img from "../../../assets/styled-blocks/tier1.png";
import tier2Img from "../../../assets/styled-blocks/tier2.png";
import tier3Img from "../../../assets/styled-blocks/tier3.png";
import tier0Img from "../../../assets/styled-blocks/tier0.png";
import averageSmallWound from "../../../assets/styled-blocks/smallWound1.png";
import heavySmallWound from "../../../assets/styled-blocks/smallWound2.png";
import averageBigWound from "../../../assets/styled-blocks/bigWound1.png";
import heavyBigWound from "../../../assets/styled-blocks/bigWound2.png";
import DropItem from "./Drag-n-Drop/DropItem";
import { addItem, equipToAnoterSlot } from "../../../api/socket";
import DragItem from "./Drag-n-Drop/DragItem";
import CSS from '../Inventory.module.scss';

export const smallSlotPack = {
  averageWound: averageSmallWound,
  heavyWound: heavySmallWound,
  empty: smallEmptySlot,
  contain: smallSlot,
}

const bigSlotPack = {
  averageWound: averageBigWound,
  heavyWound: heavyBigWound,
  empty: bigEmptySlot,
  contain: bigSlot,
}

export const bigSizeTypes = ["Туловище", "Оружие", "Ноги"];
const unbreakableSlotTypes = ['Аксессуары', 'Рюкзак'];

function getEmptySlotIcon(imagePack, wound) {
  switch (wound) {
    case "average":
      return imagePack.averageWound;
    case "heavy":
      return imagePack.heavyWound;
    case "none":
    default:
      return imagePack.empty;
  }
}

function getTierImage(tier) {
  switch (tier) {
    case 1:
      return tier1Img;
    case 2:
      return tier2Img;
    case 3:
      return tier3Img;
    case 0:
    default:
      return tier0Img;
  }
}

function hasDrop(value, currentSlotName) {
  const parsedValue = JSON.parse(value);
  if (has(parsedValue, 'item.slotType')) {
    return currentSlotName === parsedValue.item.slotType.name || currentSlotName === 'Рюкзак';
  }
}

const InventorySlot = ({ slot, onItemClick, onEmptySlotClick, onWoundClick, onChargeClick, dragAssist }) => {

  function renderSlot(imagePack) {
    if (get(slot, 'item')) {
      const id = JSON.stringify({
        slotId: slot.id,
        item: slot.item,
        type: 'item',
        dragFrom: 'inventory',
      });
      return (
        <DropItem
          key={slot.id}
          onDrop={(data) => {
            if (!hasDrop(dragAssist[0], get(slot, "name"))) { return; }
            if (get(slot, 'woundData.type', "none") !== "none") { return; }
            const parsedData = JSON.parse(data);
            if (parsedData.dragFrom === 'augment') {
              return equipToAnoterSlot(slot.id, parsedData.slotId, null, parsedData.augmentId);
            }
            if (parsedData.dragFrom !== 'pull') {
              equipToAnoterSlot(slot.id, parsedData.slotId);
            } else {
              addItem(slot.id, parsedData.item.id);
            }
          }}
        >
        <DragItem
          id={id}
          key={slot.item.id}
          type={slot.name}
          callback={(e) => e ? dragAssist[1](id) : dragAssist[1](null)}
          data={(
            <div
              onClick={(e) => onItemClick(e, slot)}
              style={{ position: "relative", width: "80px", height: bigSizeTypes.includes(get(slot, 'name')) ? "120px" : "80px", display: "flex", flexDirection: 'column', justifyContent: 'center' }}
            >
              <img
                alt="contain"
                width="100%"
                style={{ opacity: 0.5 }}
                height={bigSizeTypes.includes(get(slot, 'name')) ? "120px" : "80px"}
                src={imagePack.contain}
              />
              <img
                alt="item"
                style={{
                  position: "absolute",
                  width: get(slot, 'name') === 'Рюкзак' && bigSizeTypes.includes(get(slot, 'item.slotType.name')) ? "60px" : "80px",
                  // height: bigSizeTypes.includes(get(slot, 'name')) ? "120px" : "80px",
                  maxHeight: '100%',
                  maxWidth: '100%',
                  margin: "auto",
                  right: 0,
                  left: 0,
                  opacity: hasDrop(dragAssist[0], get(slot, "name")) ? 0.2 : 1,
                }}
                src={slot.item.image}
              />
              <img
                alt="tier"
                style={{ position: "absolute", left: "-1px", bottom: "-1px" }}
                src={getTierImage(slot.item.tier)}
              />
              {slot.item.charges !== 'none' && (
                <>
              <div
                className={CSS.chargeAction}
                style={{
                  position: "absolute",
                  margin: 'auto',
                  right: '4px',
                  bottom: 0,
                }}
              >
                {slot.item.charges}
              </div>
                </>
              )}
            </div>
          )}
        />
      </DropItem>
      )
    }
    return (
      <DropItem
        onDrop={data => {
          if (!hasDrop(dragAssist[0], get(slot, "name"))) { return; }
          if (get(slot, 'woundData.type', "none") !== "none") { return; }
          const parsedData = JSON.parse(data);

            if (parsedData.dragFrom === 'augment') {
              return equipToAnoterSlot(slot.id, parsedData.slotId, null, parsedData.augmentId);
             }
            if (parsedData.dragFrom !== 'pull') {
              equipToAnoterSlot(slot.id, parsedData.slotId);
            } else {
              addItem(slot.id, parsedData.item.id);
            }
        }}
      >

        <div className={CSS.woundBar}>
      {!unbreakableSlotTypes.includes(get(slot, 'name')) && (
        <>
        <div
          alt="wound"
          style={{
            cursor: 'pointer',
            position: "absolute",
            top: '5px',
            left: '4px',
            height: '10px',
            width: '10px',
          }}
          onClick={() => onWoundClick(slot.id, 'none')}
        >
          O
        </div>
        <div
          alt="wound"
          style={{
            cursor: 'pointer',
            position: "absolute",
            top: '5px',
            margin: 'auto',
            left: 0,
            right: 0,
            height: '10px',
            width: '10px',
          }}
          onClick={() => onWoundClick(slot.id, 'average')}
        >
          |
        </div>
        <div
          alt="wound"
          style={{
            cursor: 'pointer',
            position: "absolute",
            top: '5px',
            right: '4px',
            height: '10px',
            width: '10px',
          }}
          onClick={() => onWoundClick(slot.id, 'heavy')}
        >
          X
        </div>
        </>
      )}
        <img
          alt="empty"
          style={{
            boxSizing: 'border-box',
            padding: hasDrop(dragAssist[0], get(slot, "name")) && "2px",
            border: hasDrop(dragAssist[0], get(slot, "name")) && "2px dashed #baaefe",
          }}
          onClick={() => {
            if (get(slot, 'wound', "none") !== "none") { return; }
            onEmptySlotClick(get(slot, "name"));
          }}
          width="80px"
          height={bigSizeTypes.includes(get(slot, 'name')) ? "120px" : "80px"}
          src={getEmptySlotIcon(imagePack, get(slot, 'woundData.type'))}
        />
        {get(slot, 'woundData.type') && (
          <div
            className={CSS.chargeAction}
            style={{
              position: "absolute",
              margin: 'auto',
              right: '4px',
              bottom: 0,
              color: '#701821',
              fontSize: '34px',
              fontFamily: 'Play',
            }}
          >
            {get(slot, 'woundData.duration')}
          </div>
        )}
        </div>
      </DropItem>
    )
  }

  function renderSlotByType() {
    const imagePack = bigSizeTypes.includes(get(slot, 'name')) ? bigSlotPack : smallSlotPack;
    return renderSlot(imagePack);
  }

  return (
    <div style={{ marginRight: "4px" }}>
      {renderSlotByType()}
    </div>
  );
}

export default InventorySlot;
