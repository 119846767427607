import React, { useEffect, useMemo, useState, useCallback } from "react";
import noConnectionIcon from "../../assets/icons/signal_wifi_off.svg";
import { TURN_STATES, MAP_STATES } from '../../consts/map';
import Characters from "./Characters/Characters";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../state/actions";
import {
  changeMapState,
  playerComplete,
  playerDrop,
  changeCharacterLevel,
} from "../../api/socket";
import allServices from '../../api';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Collapse from "@material-ui/core/Collapse";
import parse from 'html-react-parser';
import CSS from './Locator.module.scss';
import allWidgets from './../index';
import SpringModal from './SpringModal/SpringModal';
import {getCookie} from "../../utils/utils";

const Locator = () => {

  const currentPlayer = useSelector(state => state.currentPlayer);
  const mapState = useSelector(state => state.mapState);
  const widgets = useSelector(state => state.widgets);
  const sectorState = useSelector(state => state.sectorState);
  const dispatch = useDispatch();
  const sectorService = useMemo(() => new allServices.SectorService(), []);
  const difficultiesColorList = get(mapState, "content.colors.difficulties", {});
  const currentSectorId = get(currentPlayer, 'player.map.position', null);
  const sessionName = getCookie('sessionName');

  const [isOpenedDescriptions, setIsOpenedDescriptions] = useState(true);
  const [isOpenedBuffs, setIsOpenedBuffs] = useState(false);
  const [isOpenedFeatures, setIsOpenedFeatures] = useState(false);
  const [isOpenedReward, setIsOpenedReward] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useState('');

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(false);

  const handleOpen = (data) => {
    setOpen(true);
    setModalType(data);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setModalType(null), 500);
  }

  useEffect(() => {
    if (get(widgets, 'locator.isOpened', false) || mapState.currentSector) {
      if (!mapState.currentSector) { return; }
      setIsLoading(true);
      sectorService.getSectorInfo(mapState.currentSector).then((sectorInfo) => {
        dispatch(allActions.sectorActions.setSectorInfo(sectorInfo));
        setIsLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(widgets, 'locator.isOpened'), mapState.currentSector]);

  const handleShopEndButton = () => {
    changeMapState(TURN_STATES.IDLE);
    dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.locator.name, false));
    setReview('');
    handleClose();
  }

  const buttons = {
    // [MAP_STATES.EXPLORE]: [],
    // [MAP_STATES.OPEN]: [],
    [TURN_STATES.IDLE]: [
      () => (
        <div className={CSS.button}
         style={{ width: '250px', margin: 0 }}
          onClick={() => {
            changeMapState(TURN_STATES.PLAY);
          }}
        >
          Начать захват
        </div>
      ),
    ],
    [TURN_STATES.PLAY]: [
      (state, isCenter) => (
        <div className={CSS.buttonCapture}
          onClick={()=> {
            handleOpen(isCenter ? 'completedCenter' : 'completed');
          }}
        >
          ЗАХВАТ
        </div>
      ),
      ({ gameRoll: { link } }) => (
        <div className={CSS.button}
            style={{ margin: '0 8px' }}
            onClick={()=>window.open(link, "_blank")}
          >
            ПОИСК
          </div>
        ),
      (state, isCenter) => (
        <div className={CSS.buttonBack}
          onClick={()=> {
            handleOpen(isCenter ? 'dropCenter' : 'drop');
          }}
        >
          ОТСТУПИТЬ
        </div>
      )
    ],
    [TURN_STATES.SHOP]: [
      () => (
        <div className={CSS.button}
         style={{ width: '250px', margin: 0 }}
          onClick={() => handleOpen('buy')}
        >
          Завершить ход
        </div>
      )
    ],
  }

  const handleCharacterClick = useCallback((character) => {
    dispatch(allActions.dialogSwitcherActions.setCurrentCharacterInfo({ name: 'dialog', data: { ...character, message: [], items: [] }}));
    dispatch(allActions.dialogSwitcherActions.changeDialogIsOpened({ name: 'dialog', value: true }));
    dispatch(allActions.dialogSwitcherActions.changeDialogHasData({ name: 'dialog', value: true }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLevel = useCallback((e, valueType, characterName) => {
    e.stopPropagation();
    e.preventDefault();
    changeCharacterLevel(valueType, characterName, () => {
      sectorService.getSectorInfo(mapState.currentSector).then((sectorInfo) => {
        dispatch(allActions.sectorActions.setSectorInfo(sectorInfo));
      });
    });
  }, [dispatch, mapState.currentSector, sectorService]);

  const characters = useMemo(
    () => <Characters
      characters={get(sectorState, 'characters', [])}
      handleCharacterClick={handleCharacterClick}
      onChangeLevel={handleChangeLevel}
      mapState={mapState}
      currentPlayer={currentPlayer}
    />,
    [sectorState, handleCharacterClick, handleChangeLevel, mapState, currentPlayer]
  );

    if (isLoading) {
      return (
        <div className={CSS.noConnection}>
          Загрузка
        </div>
      );
    }

  if (isEmpty(sectorState)) {
    return (
      <div className={CSS.noConnection}>
        <img alt="no-connect" src={noConnectionIcon} />
        Нет связи
      </div>
    );
  }

  if (get(sectorState, 'message', "").includes("ERROR:")) {
    return (
      <div className={CSS.noConnection}>
        Здесь ничего нет
      </div>
    );
  }

    const renderModal = () => {
    switch (modalType) {
      case 'completedCenter':
        return (
          <div className={CSS.modalBox}>
            <div>Ты прошёл ядро и никто не намошнил?</div>
            <div>Pog</div>
            <div className={CSS.buttonsModal}>
              <div onClick={() => handleOpen('reviewWin')}  className={CSS.button}>Да</div>
              <div onClick={handleClose}  className={CSS.button}>Нет</div>
            </div>
          </div>
        )
      case 'dropCenter':
        return (
          <div className={CSS.modalBox}>
            <div>Ты думал здесь что то простое? Оооооо, нет, от твоего скила даже моей бабушке смешно.</div>
            <div>Дропай, дропай это дело и иди отсюда</div>
            <div className={CSS.buttonsModal}>
              <div onClick={() => handleOpen('reviewDrop')}  className={CSS.button}>Да</div>
              <div onClick={handleClose}  className={CSS.button}>Нет</div>
            </div>
          </div>
        )
      case 'completed':
        return (
          <div className={CSS.modalBox}>
            <div>Вы уверены, что прошли игру?</div>
            <div>Не забыли выставить на сайте ролла игры именно ту, которую прошли?</div>
            <div className={CSS.buttonsModal}>
              <div onClick={() => handleOpen('reviewWin')}  className={CSS.button}>Да</div>
              <div onClick={handleClose}  className={CSS.button}>Нет</div>
            </div>
          </div>
        )
      case 'reviewWin':
        if (sectorState.difficulty.displayName === 'Ядро' && sessionName?.toLowerCase().indexOf('whopg') >= 0) {
          return (
            <div  className={CSS.modalBox}>
              <div>Внесите время вашего спидрана в формате 05:35:36</div>
              <textarea className={CSS.reviewField} value={review} onChange={(e) => setReview(e.target.value)} />
              <div className={CSS.buttonsModal} style={{ width: "450px" }}>
                <div onClick={()=> {
                  changeMapState(TURN_STATES.SHOP);
                  playerComplete(review);
                  handleClose();
                }} className={CSS.button} style={{ width: "200px" }}>Внести</div>
              </div>
            </div>
          )
        }
          return (
            <div  className={CSS.modalBox}>
              <div>КОНГРАТУЛЕЙШЕН <img alt='ez' src='https://cdn.betterttv.net/emote/5590b223b344e2c42a9e28e3/3x' width='24px' height='24px' />. Напиши отзыв об игре.</div>
              <textarea className={CSS.reviewField} value={review} onChange={(e) => setReview(e.target.value)} />
              <div className={CSS.buttonsModal} style={{ width: "450px" }}>
              <div onClick={()=> {
                changeMapState(TURN_STATES.SHOP);
                playerComplete(review === '' ? review : "Стримеру было лень написать отзыв <img src='https://static-cdn.jtvnw.net/emoticons/v1/1/1.0'>");
                handleClose();
              }} className={CSS.button} style={{ width: "200px" }}>Escape</div>
                <div onClick={()=> {
                changeMapState(TURN_STATES.SHOP);
                playerComplete(review);
                handleClose();
              }} className={CSS.button} style={{ width: "200px" }}>Enter</div>
              </div>
            </div>
          )
      case 'reviewDrop':
        return (
          <div  className={CSS.modalBox}>
            <div>Тот самый счастливый билетик?<img alt='ez' src='https://cdn.betterttv.net/emote/5e574ba9ddc7a054d7f0c2ee/3x' width='24px' height='24px' /></div>
            <textarea className={CSS.reviewField} value={review} onChange={(e) => setReview(e.target.value)} />
            <div className={CSS.buttonsModal} style={{ width: "450px" }}>
              <div onClick={()=> {
                dispatch(allActions.playerActions.changeMapState(MAP_STATES.DROP));
                playerDrop(review === '' ? review : "Стримеру было лень написать отзыв <img src='https://static-cdn.jtvnw.net/emoticons/v1/1/1.0'>");
                handleClose();
                setReview('');
              }}  className={CSS.button} style={{ width: "200px" }}>Escape</div>
              <div onClick={()=> {
                dispatch(allActions.playerActions.changeMapState(MAP_STATES.DROP));
                playerDrop(review);
                handleClose();
                setReview('');
              }}  className={CSS.button} style={{ width: "200px" }}>Enter</div>
            </div>
          </div>
        )
      case 'drop':
        return (
          <div  className={CSS.modalBox}>
            <div>Вы уверены, что дропнули игру?</div>
            <div>Не забыли выставить на сайте ролла игры именно ту, которую дропнули?</div>
            <div className={CSS.buttonsModal}>
              <div onClick={() => handleOpen('reviewDrop')}  className={CSS.button}>Да</div>
              <div onClick={handleClose}  className={CSS.button}>Нет</div>
            </div>
          </div>
        )
        case 'buy':
          return (
            <div  className={CSS.modalBox}>
              <div>Вы уверены, что потратили ОП?</div>
              <div>Получили награду?</div>
              <div className={CSS.buttonsModal}>
                <div onClick={handleShopEndButton} className={CSS.button}>Да</div>
                <div onClick={handleClose}  className={CSS.button}>Нет</div>
              </div>
            </div>
          )
        default:
    }
  }

  if (sectorState.difficulty.displayName === 'Ядро' && sessionName?.toLowerCase().indexOf('whopg') >= 0) {
    return (
      <div className={CSS.wrapper}>
        <SpringModal open={open} handleClose={handleClose}>
          {renderModal()}
        </SpringModal>
        <div className={CSS.locatorHeaderText}>
          <div>{sectorState.difficulty.displayName}</div>
          <div>{sectorState.socialGroup.name}</div>
        </div>
        <img alt="sector" className={CSS.image} src={sectorState.image} />
        <div className={CSS.title}>{sectorState.title}</div>
        <div className={CSS.buttons}>
          {
            // eslint-disable-next-line eqeqeq
            currentSectorId == get(mapState, 'currentSector', 'none') && get(buttons, [mapState.turnState], []).map(button=>button(sectorState))
          }
        </div>
        <div className={CSS.information}>
          Текущее испытание: {parse(sectorState.gameRoll.features)}
        </div>
        <div className={CSS.informationCard}>
          <div className={CSS.informationCardText} style={{ color: '#6F3E8E', cursor: 'default' }}>
            Информация
          </div>
        </div>
        <div className={CSS.informationMoreWrapper}>
          <div style={{ marginLeft: "4px", whiteSpace: 'break-spaces' }}>
            {parse(sectorState.onComplete)}
          </div>
        </div>
        <div className={CSS.information}>
          Здесь очень плохая связь, никакой информации больше нет
        </div>
      </div>
    )
  }


  if (sectorState.difficulty.displayName === 'Ядро') {
    return (
      <div>
        <SpringModal open={open} handleClose={handleClose}>
          {renderModal()}
        </SpringModal>
        <div className={CSS.wrapper}>
        <div className={CSS.centerHeader} style={{ color: get(difficultiesColorList, [sectorState.difficulty.name], {}).value }}>
            <div>Вы находитесь в секторе</div>
            <div>{sectorState.difficulty.displayName}</div>
          </div>
          <img alt="sector" className={CSS.image} src={sectorState.image} />
          <div className={CSS.buttons}>
          {
            // eslint-disable-next-line eqeqeq
            currentSectorId == get(mapState, 'currentSector', 'none') && get(buttons, [mapState.turnState], []).map(button=>button(sectorState, true))
          }
          </div>
          <div className={CSS.information}>
            Текущее испытание: {parse(sectorState.gameRoll.features)}
          </div>
          <div className={CSS.informationCard}>
            <div className={CSS.informationCardText} style={{ color: '#6F3E8E', cursor: 'default' }}>
              Информация
            </div>
          </div>
          <div className={CSS.informationMoreWrapper}>
            <div style={{ marginLeft: "4px", whiteSpace: 'break-spaces' }}>
              {parse(sectorState.onComplete)}
            </div>
          </div>
          <div className={CSS.information}>
            Здесь очень плохая связь, никакой информации больше нет
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={CSS.wrapper}>
      <SpringModal open={open} handleClose={handleClose}>
        {renderModal()}
      </SpringModal>
      <div className={CSS.locatorHeaderText} style={{ color: difficultiesColorList[sectorState.difficulty.name].value }}>
        <div>{sectorState.difficulty.displayName} Сложность</div>
        <div>{sectorState.socialGroup.name}</div>
      </div>
      <img alt="sector" className={CSS.image} src={sectorState.image} />
      <div className={CSS.title} style={{ color: difficultiesColorList[sectorState.difficulty.name].value }}>{sectorState.title}</div>
      <div className={CSS.buttons}>
        {
        // eslint-disable-next-line eqeqeq
        currentSectorId == get(mapState, 'currentSector', 'none') && get(buttons, [mapState.turnState], []).map(button=>button(sectorState))
        }
      </div>
      <div className={CSS.information}>
        {sectorState.description && (
          <>
            <div className={CSS.informationCard} onClick={() => setIsOpenedDescriptions(prevState => !prevState)}>
              <div className={CSS.informationCardText} style={{ color: isOpenedDescriptions && '#6F3E8E' }} >
                Описание сектора
              </div>
            </div>
            <Collapse  direction="down" in={isOpenedDescriptions} mountOnEnter unmountOnExit>
              <div className={CSS.informationMoreWrapper}>
                {sectorState.description}
              </div>
            </Collapse>
          </>
        )}
        <div className={CSS.informationCard} onClick={() => setIsOpenedBuffs(prevState => !prevState)}>
          <div className={CSS.informationCardText} style={{ color: isOpenedBuffs && '#6F3E8E' }}>
            Бонусы и штрафы
          </div>
        </div>
        <Collapse  direction="down" in={isOpenedBuffs} mountOnEnter unmountOnExit>
          <div className={CSS.effectsWrapper}>
            {get(currentPlayer, 'player.name', '').toLowerCase() !== 'roadhouse'.toLowerCase()
              && sectorState.checksWarning && (
              <div className={CSS.effectTitle}>{sectorState.checksWarning}</div>
            )}
            {get(currentPlayer, 'player.name', '').toLowerCase() === 'roadhouse'.toLowerCase()
              && sectorState.checksWarning && (
                <div className={CSS.roadTitleWarning}>{sectorState.checksWarning}</div>
            )}
            {sectorState.buffs.map(buff=>(
              <div className={CSS.effect}>
                <div className={CSS.effectTitle} style={{ color: '#28DE94' }}>{buff.name}</div>
                {buff.description}
              </div>
            ))}
            {sectorState.debuffs.map(debuff=>(
              <div className={CSS.effect}>
                <div className={CSS.effectTitle} style={{ color: '#F24463'}}>{debuff.name}</div>
                {debuff.description}
              </div>
            ))}
          </div>
        </Collapse>
        <div className={CSS.informationCard} onClick={() => setIsOpenedFeatures(prevState => !prevState)}>
          <div className={CSS.informationCardText} style={{ color: isOpenedFeatures && '#6F3E8E' }}>
            Особенности игры
          </div>
        </div>
        <Collapse direction="down" in={isOpenedFeatures} mountOnEnter unmountOnExit>
          <div className={CSS.informationMoreWrapper}>
            <div style={{ marginLeft: "4px" }}>
              {parse(sectorState.gameRoll.features)}
            </div>
          </div>
        </Collapse>
        <div className={CSS.informationCard} onClick={() => setIsOpenedReward(prevState => !prevState)}>
          <div className={CSS.informationCardText} style={{ color: isOpenedReward && '#6F3E8E' }}>
            Награда
          </div>
        </div>
        <Collapse direction="down" in={isOpenedReward} mountOnEnter unmountOnExit>
          <div className={CSS.informationMoreWrapper}>
            <div style={{ marginLeft: "4px", whiteSpace: 'break-spaces' }}>
              {parse(sectorState.onComplete)}
            </div>
          </div>
        </Collapse>
      </div>
      {characters}
    </div>
  )
}

export default Locator;
