import React, { useState, useCallback, memo } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import classNames from 'classnames';
import searchIcon from "../../assets/icons/search.svg";
import Chat from "./Chat/Chat";
import allActions from "../../state/actions";
import { colorByTier } from "../../utils/utils";
import CSS from './Telepound.module.scss';
import parse from 'html-react-parser';
import Popover from '@material-ui/core/Popover';


const Telepound = () => {
  const currentPlayer = useSelector(state => state.currentPlayer);
  const currentUser = useSelector(state => state.currentUser);
  const mates = useSelector(state => state.mates);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState({});

  const handlePopoverOpen = (event, id) => {
    if (anchorEl.id === id) {
        return handlePopoverClose();
    }
    setAnchorEl({
        id: id,
        target: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({});
  };

  const [searchString, setSearchString] = useState("");
  const [isAllMatePage, setIsAllMatePage] = useState(true);

  const ByName = useCallback((mate) => {
    if (searchString) {
      return mate.name.toLowerCase().includes(searchString.toLowerCase());
    }
    return true;
  }, [searchString]);

  const openChat = useCallback((mate) => {
    const mateFromPlayerList = get(currentPlayer, 'player.mates.list', []).filter((m) => m && m.id === mate.id);
    dispatch(allActions.matesActions.setCurrentMate(!isEmpty(mateFromPlayerList) ? mateFromPlayerList[0] : mate));
  }, [currentPlayer]);

  const renderTeammates = (mates) => {
    if (!mates) { return; }
    if(get(mates, 'message', []).includes('ERROR')) { return; }
    return [...mates].filter(ByName).map((mate) => (
      <div className={CSS.mateCard} key={mate.id} onClick={(e) => {
        if (get(currentUser, 'user.type', '') === 'player') {
          openChat(mate);
        } else {
          handlePopoverOpen(e, mate.id);
        }
      }}>
        <img alt="person" className={CSS.mateImage} src={mate.image} />
        <div className={CSS.messageWrapper}>
          <div className={CSS.messageHeader} style={{ color: colorByTier(mate.tier) }}>
            {mate.name}
          </div>
          {mate.helloPhrase} 
        </div>
        <div className={CSS.messageTime}>
          {moment().format('HH:mm')}
        </div>
        {get(mate, 'description', '') !== '' && (
          <Popover
            className={CSS.popover}
            open={get(anchorEl, 'id', null) === mate.id}
            anchorEl={anchorEl.target}
            anchorOrigin={{
              vertical: 'right',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {parse(get(mate, 'description', ''))}
          </Popover>
        )}
      </div>
    ))
  }

  if (mates.currentMate && get(currentUser, 'user.type', '') === 'player') return (
    <Chat />
  )

  return (
    <div className={CSS.wrapper}>
      <div className={CSS.searchBox}>
        <input
          className={CSS.searchInput}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Поиск..."
        />
        <img alt="search" className={CSS.searchIcon} src={searchIcon} />
      </div>
      <div className={CSS.teammateWrapper}>
        {renderTeammates(isAllMatePage ? mates.mates : get(currentPlayer, 'player.mates.list', []).filter((mate) => !isEmpty(mate)))}
      </div>
      <div className={CSS.categoryWrapper}>
        {currentPlayer && (
          <button className={classNames(CSS.categoryButton, { [CSS.categoryButtonPushed]: !isAllMatePage })} onClick={() => setIsAllMatePage(false)}>
            Текущие напарники
          </button>
        )}
        <button className={classNames(CSS.categoryButton, { [CSS.categoryButtonPushed]: isAllMatePage })} onClick={() => setIsAllMatePage(true)}>
          Все персонажи
        </button>
      </div>
    </div>
  )
}

export default memo(Telepound);
