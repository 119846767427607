const saveNote = (note) => {
  return {
    type: "SAVE_NOTE",
    payload: note,
  }
}

const editNote = (note) => {
  return {
    type: "EDIT_NOTE",
    payload: note,
  }
}

const deleteNote = (removeId) => {
  return {
    type: "DELETE_NOTE",
    payload: removeId,
  }
}

export default {
  saveNote,
  deleteNote,
  editNote,
}
