import React, { useState } from 'react';
import CSS from './Bingo.module.scss';
import isEmpty from 'lodash/isEmpty';
import image1 from './1.png';
import image2 from './2.png';

const bingoTiles = [
    {
        row: 1,
        column: 1,
        name: 'Ласка стримит хпг 7 дней подряд',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 1,
        column: 2,
        name: 'Во время мошны мнение стороннего стримера используется как аргумент',
        link: 'https://clips.twitch.tv/embed?clip=AstuteSecretiveAlbatrossLitFam&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '#96c731',
        },
    },
    {
        row: 1,
        column: 3,
        name: 'Ласка путается в интерфейсе сайта',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '',
        },
    },
    {
        row: 1,
        column: 4,
        name: 'Мэл надевает свои злые усы',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 1,
        column: 5,
        name: 'Стример высказывает подозрения в подкрутке',
        link: 'https://clips.twitch.tv/embed?clip=MildBlitheClamTriHard&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 1,
        column: 6,
        name: 'Кто-то переобувается 3 раза за одну мошну',
        link: 'https://www.youtube.com/embed/DMQmcXcy0JU',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'G_Ans',
            color: '#802ad1',
        },
    },
    {
        row: 1,
        column: 7,
        name: 'Кто-то дропает хпг',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 2,
        column: 1,
        name: 'Стример 15 минут пытается понять по мануалу, как работает какая-то механика',
        link: 'https://www.youtube.com/embed/mmlf1z6lzB0',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '',
        },
    },
    {
        row: 2,
        column: 2,
        name: 'Мэл марафонит одну игру 10 часов подряд',
        link: 'https://cdn.discordapp.com/attachments/816712200517189684/819252977831116880/unknown.png',
        isComplete: false,
        type: 'image',
        sender: {
            name: 'PaulClaymees52rus',
            color: '',
        },
    },
    {
        row: 2,
        column: 3,
        name: 'Создаётся союз/коалиция',
        link: 'https://clips.twitch.tv/embed?clip=ExpensiveDifficultKoupreyStoneLightning&parent=hpg.su',
        isComplete: true,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 2,
        column: 4,
        name: 'Факер только проснулся, а его уже зовут в дс',
        link: 'https://clips.twitch.tv/embed?clip=SpicyColorfulTardigradeHassaanChop&parent=hpg.su',
        isComplete: true,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '#96c731',
        },
    },
    {
        row: 2,
        column: 5,
        name: 'Сигал включает рэп вместо саундтрека игры',
        link: 'https://www.youtube.com/embed/BKdPHB03ha8',
        isComplete: true,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '',
        },
    },
    {
        row: 2,
        column: 6,
        name: 'Ласка пропускает 2 съезда подряд',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 2,
        column: 7,
        name: 'Лизон помогает Ласке',
        link: 'https://www.youtube.com/embed/9srU9nkbtjM',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 3,
        column: 1,
        name: 'Бровян сгорает и орёт',
        link: 'https://clips.twitch.tv/embed?clip=BoldToughButterWoofer&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 3,
        column: 2,
        name: 'Звучит фраза в духе "На прошлом хпг было лучше"',
        link: 'https://www.youtube.com/embed/NaBxSzRMRPs',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 3,
        column: 3,
        name: 'Бьёрн безводмездно помогает другому стримеру',
        link: 'https://clips.twitch.tv/embed?clip=EntertainingSolidJamRuleFive-cIEVwzMBVb446lIA&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '',
        },
    },
    {
        row: 3,
        column: 4,
        name: 'Съезд идёт больше трёх часов',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 3,
        column: 5,
        name: 'Неделя без дропов',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 3,
        column: 6,
        name: 'Бровян офает хпг и идёт играть в танки',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 3,
        column: 7,
        name: 'Мэл кайфует в гонках',
        link: 'https://clips.twitch.tv/embed?clip=FriendlySecretiveStingrayArgieB8-fwKTCO9YPIATlbHp&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'ThesameCHEX',
            color: '',
        },
    },
    {
        row: 4,
        column: 1,
        name: 'Бровян становится чиляном и обещает больше не орать',
        link: 'https://clips.twitch.tv/embed?clip=GiantTubularSowTwitchRPG-9yL3dzxHqBukrG51&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'gyperdno',
            color: '#008001',
        },
    },
    {
        row: 4,
        column: 2,
        name: 'Кто-то превысил положенное количество игрочасов',
        link: 'https://clips.twitch.tv/embed?clip=TemperedExcitedUdonStrawBeary-gHuB_JC7Sa8jXx_5&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'pekarini',
            color: '',
        },
    },
    {
        row: 4,
        column: 3,
        name: 'Кто-то контролирует треть карты (47+ секторов)',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 4,
        column: 4,
        name: 'На стриме играет радио Дача',
        link: 'https://www.youtube.com/embed/dWjjfRo0BHM',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 4,
        column: 5,
        name: 'ХПГ остановлено (по техническим или иным причинам)',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 4,
        column: 6,
        name: 'Кто-то 3 раза подряд дропает игры',
        link: 'https://cdn.discordapp.com/attachments/816712200517189684/821395152001302538/Screenshot_1.png',
        isComplete: false,
        type: 'image',
        sender: {
            name: 'ThesameCHEX',
            color: '',
        },
    },
    {
        row: 4,
        column: 7,
        name: 'Два стримера одновременно играют в одну и ту же игру',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 5,
        column: 1,
        name: 'Кодеров заставляют что-то переделывать',
        link: 'https://clips.twitch.tv/embed?clip=FunnyLovelyTarsierFunRun-trV3qMKq25tBVgD8&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '',
        },
    },
    {
        row: 5,
        column: 2,
        name: 'Каждый стример хоть раз был забайчен чатом на невыгодное для него действие',
        link: 'https://www.youtube.com/embed/ehWYZNmkjGc',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub, Sokve',
            color: '',
        },
    },
    {
        row: 5,
        column: 3,
        name: 'Сигал орёт 5 раз в течение одной игры',
        link: 'https://www.youtube.com/embed/b2v8LK2i-q8',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'sanchezonok',
            color: '',
        },
    },
    {
        row: 5,
        column: 4,
        name: 'Не первая и не последняя неделя, а все шесть стримеров онлайн',
        link: image1,
        isComplete: false,
        type: 'image',
        sender: {
            name: 'qSHEMq',
            color: '#8a2be2',
        },
    },
    {
        row: 5,
        column: 5,
        name: 'Суд 1+ час над участником хпг за нарушение правил',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 5,
        column: 6,
        name: 'Сигалу выпадает любая часть Dark Souls',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 5,
        column: 7,
        name: '5 пройденых игр за стрим',
        link: 'https://clips.twitch.tv/embed?clip=PlumpInexpensiveMinkOhMyDog&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Tasol42',
            color: '#e17629',
        },
    },
    {
        row: 6,
        column: 1,
        name: 'Факер организовывает многоходовочку во время мошны',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 6,
        column: 2,
        name: 'Стример делает 7 легитимных рероллов подряд',
        link: image2,
        isComplete: false,
        type: 'image',
        sender: {
            name: 'ThesameCHEXx',
            color: '#ff0000',
        },
    },
    {
        row: 6,
        column: 3,
        name: 'Игры из ядра будут дропнуты участниками 5+ раз',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 6,
        column: 4,
        name: 'Найдена дырка в правилах',
        link: 'https://clips.twitch.tv/embed?clip=TsunderePhilanthropicRatJKanStyle-zVt6neFlw4FNRChj&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Damn_Nonsub',
            color: '#5f7f24',
        },
    },
    {
        row: 6,
        column: 5,
        name: 'Стримеру выпадает дропнутая им в прошлых сезонах игра',
        link: 'https://clips.twitch.tv/embed?clip=BigFuriousBubbleteaFreakinStinkin&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'CloudOrb',
            color: '#8e56c4',
        },
    },
    {
        row: 6,
        column: 6,
        name: 'Дроп игры после 20 потраченных часов',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 6,
        column: 7,
        name: 'Бьёрн отдыхает от хпг в вове',
        link: 'https://clips.twitch.tv/embed?clip=LivelyTameChamoisUncleNox&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Khornon',
            color: '#7b2de2',
        },
    },
    {
        row: 7,
        column: 1,
        name: 'Кто-то выбивает 12 на кубике 3 раза подряд',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
    {
        row: 7,
        column: 2,
        name: 'Сайт ХПГ упал',
        link: 'https://www.youtube.com/embed/oWKGixVHj-M',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'DeathTheDoorAnt',
            color: '#a963e8',
        },
    },
    {
        row: 7,
        column: 3,
        name: 'Факер плачет',
        link: 'https://clips.twitch.tv/embed?clip=TallCloudyParrotJebaited-NPnTyG3UhxxQq6EY&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'pekarini',
            color: '',
        },
    },
    {
        row: 7,
        column: 4,
        name: 'Бровян травит анекдоты',
        link: 'https://clips.twitch.tv/embed?clip=PleasantDreamyTriangleOSfrog-_641t3FlWng6ZYIR&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'PaulClaymees52rus',
            color: '',
        },
    },
    {
        row: 7,
        column: 5,
        name: 'Бьёрну выпадает игра, в которой есть медведи',
        link: 'https://clips.twitch.tv/embed?clip=SmoggyDrabFriesOSkomodo-EgTI2FV7fLwujz-D&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'ThesameCHEXx',
            color: '',
        },
    },
    {
        row: 7,
        column: 6,
        name: 'Стример объявляет другому войну',
        link: 'https://clips.twitch.tv/embed?clip=BreakableLittlePenguinCurseLit&parent=hpg.su',
        isComplete: false,
        type: 'none',
        sender: {
            name: 'Nokuto_',
            color: '',
        },
    },
    {
        row: 7,
        column: 7,
        name: 'Состав/количество участников меняется во время ивента',
        link: '',
        isComplete: false,
        type: 'none',
        sender: {
            name: '',
            color: '',
        },
    },
]


function Bingo() {

    const [currentInfo, setCurrentInfo] = useState({});

    return (
        <div className={CSS.wrapper}>
            {bingoTiles.map((bingoTile) => (
                <div 
                    className={CSS.box}
                    onClick={() => bingoTile.link && setCurrentInfo(bingoTile)}
                    style={{ gridRow: bingoTile.row, gridColumn: bingoTile.column, background: bingoTile.link ? '#6f3e8e' : '', cursor: bingoTile.link ? 'pointer' : 'default' }}
                >
                    {bingoTile.name}
                    {!isEmpty(bingoTile.sender) && (
                        <div className={CSS.sender} style={{ color: bingoTile.sender.color }}>
                            {bingoTile.sender.name}
                        </div>
                    )}
                </div>
            ))}
            {!isEmpty(currentInfo) && (
                <div className={CSS.infoBox}>
                    <div className={CSS.closeArrow} onClick={() => setCurrentInfo({})}>X</div>
                    {currentInfo.type !== 'image' ? (
                        <iframe width="800px" height="600px" src={currentInfo.link} title={currentInfo.name}></iframe>
                    ) : (
                        <img className={CSS.infoImage} src={currentInfo.link} alt="in" />
                    )}
                </div>
            )}
        </div>
    )
}

export default Bingo;