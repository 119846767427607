import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import isEmpty from "lodash/isEmpty";
import Draggable from 'react-draggable';
import TraderView from "./components/TraderView";
import StartView from "./components/StartView";
import TorView from "./components/TorView";
import RecruitView from "./components/RecruitView";
import ImplantView from "./components/ImplantView";
import SaboteurView from "./components/SaboteurVIew";
import ExchangeView from "./components/ExchangeView";
import CartographerView from "./components/CartographerView";
import EndView from "./components/EndView";
import CSS from './components/StartView.module.scss';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <div style={{ maxWidth: "1400px", background: "#1B1D1E" }} className={CSS.customScrollbar} {...props} />
    </Draggable>
  );
}

const Traders = ["Торговец оружием", "Барахольщик", "Барахольщик"];
const Implant = ["Торговец имплантами"];
const Recruiter = ["Рекрутер"];
const Saboteur = ["Диверсант"];
const Tor = ["ТОР"];
const TradeItems = ["Трейдер"];
const Cartographer = ["Картограф"];

export default function DraggableDialog({
  currentCharacterState, open, onActivate, onBuy, onRecruit, onSaboteur, handleClose, isDialogEnd, handleEnd, onTradeItems, onCartographerAction, onBuyAfterTrade,
}) {

  function dialogSwitcher() {
    if (Traders.includes(currentCharacterState.name)) {
      return TraderView(currentCharacterState, onBuy, handleEnd);
    }
    if (Tor.includes(currentCharacterState.name)) {
      return TorView(currentCharacterState, onBuy, handleEnd);
    }
    if (Recruiter.includes(currentCharacterState.name)) {
      return RecruitView(currentCharacterState, onRecruit, handleEnd, handleClose);
    }
    if (Implant.includes(currentCharacterState.name)) {
      return ImplantView(currentCharacterState, onBuy, handleEnd);
    }
    if (Saboteur.includes(currentCharacterState.name)) {
      return SaboteurView(currentCharacterState, onSaboteur, handleEnd);
    }
    if (TradeItems.includes(currentCharacterState.name)) {
      return ExchangeView(currentCharacterState, onTradeItems, onBuyAfterTrade, handleEnd);
    }
    if (Cartographer.includes(currentCharacterState.name)) {
      return CartographerView(currentCharacterState, onCartographerAction, handleEnd);
    }
  }

  function defaultView() {
    return StartView(currentCharacterState, onActivate, handleEnd);
  }

  function endView() {
    return EndView(currentCharacterState, handleEnd);
  }


  if (isEmpty(currentCharacterState)) { return <div/> }

  function renderByState() {
    if (isDialogEnd) {
      return endView();
    }
    return isEmpty(currentCharacterState.items) ? defaultView() : dialogSwitcher();
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <PaperComponent>
          {renderByState()}
        </PaperComponent>
      </Dialog>
  );
}
