import Configuration from "./_config";
import API from "./_api";
import {getCookie} from "../utils/utils";

class UserService {

  constructor() {
    this.config = new Configuration();
    this.user = {};
  }

  async retrieveUser() {
    return API.get(this.config.USER_URL, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.user = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async loginUser() {
    return API.get(this.config.LOGIN, { params: { steamToken: getCookie('steamToken'), sessionID: getCookie('sessionID') } })
      .then(response => {
        return this.user = response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default UserService;
