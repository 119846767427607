import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import get from "lodash/get";
import {
  Box,
  Description,
  ItemBox, ItemIcon, ItemName,
  ItemsBox, ItemWrapper,
  Level,
  Name,
  TextCloud,
  Wrapper,
} from "./StartView";
import {colorByTier} from '../../../../utils/utils';
import allActions from './../../../../state/actions/index';
import allWidgets from './../../../index';
import CSS from './StartView.module.scss';

function RecruitView(state, onRecruit, onEnd, handleClose) {

  const mates = useSelector(state => state.mates);
  const dispatch = useDispatch();
  const [currentSelectedItem, setCurrentSelectedItem] = useState(null);

  const openInfo = (mate) => {
    setCurrentSelectedItem(mate);
    dispatch(allActions.matesActions.setCurrentMate(mate));
    dispatch(allActions.widgetsActions.changeIsOpened(allWidgets.telepound.name, true));
    handleClose();
  }

  return (
    <Wrapper>
      <Box>
        <img alt="character" style={{ width: "250px", height: "250px" }} src={state.image} />
        <Name>{state.name}</Name>
        <Level>Уровень {state.level.current}</Level>
        <Description>{state.description}</Description>
        <div className={CSS.buttonGood} onClick={onEnd}>Уйти</div>
      </Box>
      <div>
        <TextCloud>
          {get(state, 'messages.start', '')}
        </TextCloud>
        <ItemsBox>
          {state.items && state.items.map((item) => (
            <ItemBox>
              <ItemWrapper
                color={colorByTier(item.tier)}
                onClick={() => openInfo(item)}
                isSelected={get(currentSelectedItem, 'name', '') === item.name}
              >
                <ItemIcon src={item.image} />
                <ItemName
                  color={colorByTier(item.tier)}
                  isSelected={get(currentSelectedItem, 'name', '') === item.name}
                >
                  {item.name}
                </ItemName>
              </ItemWrapper>
              <div className={CSS.buyButton} onClick={() => onRecruit(state.name, item)}>Нанять</div>
            </ItemBox>
          ))}
        </ItemsBox>
      </div>
    </Wrapper>
  )
}

export default RecruitView;
