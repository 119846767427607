const setInventoryLocked = (isLocked) => {
  return {
    type: "SET_INVENTORY_LOCKED",
    payload: isLocked
  }
}

export default {
  setInventoryLocked,
}
