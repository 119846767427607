// eslint-disable-next-line import/no-anonymous-default-export
export default {
    mainLinear: 'linear-gradient(270deg, #CE5D90 0%, rgba(255, 255, 255, 0) 100%), #928DF1',
    main: '#6f3e8e',
    white: '#F1F1F1',
    black14: '#14141C',
    black21: '#21212b',
    grey100: '#4C4C50',
    grey50: 'rgba(76, 76, 80, 0.5)',
    green: '#28DE94',
    red: '#F24463',
};
  