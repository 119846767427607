const setMapData = (mapPayload) => {
  return {
    type: "SET_MAP",
    payload: mapPayload,
  }
}

const setMapState = (mapState) => {
  return {
    type: "SET_MAP_STATE",
    payload: mapState,
  }
}

const changeSector = (sector) => {
  return {
    type: "CHANGE_SECTOR",
    payload: sector,
  }
}

const changePaintType = (paintType) => {
  return {
    type: "CHANGE_PAINT_TYPE",
    payload: paintType,
  }
}

const changeIsOpenAll = (isOpenAll) => {
  return {
    type: "CHANGE_IS_OPEN_ALL",
    payload: isOpenAll,
  }
}

const changeShowCaptured = (showCaptured) => {
  return {
    type: "CHANGE_SHOW_CAPTURED",
    payload: showCaptured,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setMapData,
  setMapState,
  changeSector,
  changePaintType,
  changeIsOpenAll,
  changeShowCaptured,
}
