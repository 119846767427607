const compare = (state = [], action) => {
  switch(action.type){
    case "ADD_COMPARE_ITEM":
      return [
        ...state,
        {
          ...action.payload,
          id: state.length,
        },
      ];
    case "DELETE_COMPARE_ITEM":
      return [...state].filter((v) => v.id !== action.payload);
    default:
      return state
  }
}

export default compare;
