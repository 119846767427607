const setSectorInfo = (sectorInfo) => {
  return {
    type: "SET_SECTOR_INFO",
    payload: sectorInfo
  }
}

export default {
  setSectorInfo,
}
