const sectorState = (state = {}, action) => {
  switch(action.type){
    case "SET_SECTOR_INFO":
      return action.payload;
    default:
      return state
  }
}

export default sectorState;
