import sendIcon from '../../../assets/icons/done.svg';
import React from "react";
import CSS from '../Notes.module.scss';

const AddNote = ({ title, setTitle, content, setContent, saveCallback, setEditedNote, hasEditedNote }) => (
  <div className={CSS.inputWrapper}>
    <input
      className={CSS.input}
      type="text"
      placeholder="Заголовок"
      height="30px"
      fontSize="16"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
    />
    <div className={CSS.divider} style={{ width: '80%', height: '1px' }} />
    <textarea
      className={CSS.textarea}
      type="text"
      placeholder="Содержание"
      height="100px"
      fontSize="14"
      value={content}
      onChange={(e) => setContent(e.target.value)}
    />
    <div className={CSS.row}>
      {hasEditedNote && (
        <div className={CSS.sendButton} onClick={() => {
          setEditedNote({});
          setTitle("");
          setContent("");
        }}>
          Отменить
        </div>
      )}
      <div className={CSS.sendButton} onClick={saveCallback}>
        Сохранить
      </div>
    </div>
  </div>
)

export default AddNote;
