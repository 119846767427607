import React, { useMemo, useState, useCallback } from 'react';
import CSS from './SocialGroups.module.scss';
import DataService from '../../api/DataService';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useSprings, useSpring, animated } from 'react-spring';
import { useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';

function SocialGroups() {

    const dataService = useMemo(() => new DataService(), []);
    const mapState = useSelector(state => state.mapState);
    const difficultiesColorList = get(mapState, "content.colors.difficulties", {});
    const widgets = useSelector(state => state.widgets);
    const [easy, setEasy] = useState([]);
    const [medium, setMedium] = useState([]);
    const [upperMedium, setUpperMedium] = useState([]);
    const [hard, setHard] = useState([]);
    const [hell, setHell] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState({});

    const handlePopoverOpen = (event, data, id) => {
      if (anchorEl.id === id) {
          return handlePopoverClose();
      }
      setAnchorEl({
          id: id,
          target: event.currentTarget,
          data: data,
      });
    };
  
    const handlePopoverClose = () => {
      setAnchorEl({});
    };

    const fetchData = async () => {
        const response = await dataService.retrieveSocialGroup();
        if (!response || get(response, 'message', "").includes("ERROR:")) { return; }
        setEasy(response.filter((r) => r.difficulty.displayName === 'Легкая'));
        setMedium(response.filter((r) => r.difficulty.displayName === 'Средняя'));
        setUpperMedium(response.filter((r) => r.difficulty.displayName === 'Выше среднего'));
        setHard(response.filter((r) => r.difficulty.displayName === 'Высокая'));
        setHell(response.filter((r) => r.difficulty.displayName === 'Адская'));
        setIsLoading(false);
    }

    useEffect(() => {
        if (get(widgets, 'socialGroups.isOpened', false)) {
            fetchData();
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgets]);

    if (isLoading) {
        return (
            <div className={CSS.wrapperLoader}>Загрузка...</div>
        )
    }

    return (
        <div className={CSS.wrapper}>
             <Popover
                className={CSS.popover}
                open={!!anchorEl.id}
                anchorEl={anchorEl.target}
                anchorOrigin={{
                            vertical: 'right',
                            horizontal: 'right',
                }}
                            transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                >
                {anchorEl.data && (
                    <div>
                        <div className={CSS.popoverGroupName} style={{ color: difficultiesColorList[get(anchorEl.data, 'difficulty.name', {})].value }}>{anchorEl.data.name}</div>
                        <div className={CSS.description}>{anchorEl.data.description}</div>
                        <div className={CSS.popoverRow}>
                            <div>
                            <div>Бонус от характеристик:</div>
                        <div>{anchorEl.data.buffs_from_stats.map((stat) => (
                            <div key={stat} className={CSS.buffs}>{stat}</div>
                        ))}</div>
                            </div>
                            <div>
                            <div>Штраф от характеристик:</div>
                        <div>{anchorEl.data.debuffs_from_stats.map((stat) => (
                            <div key={stat} className={CSS.debuffs}>{stat}</div>
                        ))}</div>
                            </div>
                        </div>
                    </div>
                )}
                </Popover>
            <div className={CSS.column}>
            <div className={CSS.row}>
                {hell.map((group) => {
                    return (
                            <div className={CSS.groupBox}>
                                <img  className={CSS.groupImage} alt="group" src={group.image} onClick={(e) => handlePopoverOpen(e, group, group.name)} />
                                <div className={CSS.groupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value}}>{group.name}</div>
                            </div>
                    )
                })}
            </div>
           </div>
           <div className={CSS.column}>
            <div className={CSS.row}>
                {hard.map((group) => {
                    return (
                            <div className={CSS.groupBox}>
                                <img  className={CSS.groupImage} alt="group" src={group.image}  onClick={(e) => handlePopoverOpen(e, group, group.name)} />
                                <div className={CSS.groupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value}}>{group.name}</div>
                            </div>
                    )
                })}
            </div>
           </div>
           <div className={CSS.column}>
            <div className={CSS.row}>
                {upperMedium.map((group) => {
                    return (
                            <div className={CSS.groupBox}>
                                <img  className={CSS.groupImage} alt="group" src={group.image}  onClick={(e) => handlePopoverOpen(e, group, group.name)} />
                                <div className={CSS.groupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value}}>{group.name}</div>
                            </div>
                    )
                })}
            </div>
           </div>
           <div className={CSS.column}>
            <div className={CSS.row}>
                {medium.map((group) => {
                    return (
                            <div className={CSS.groupBox}>
                                <img  className={CSS.groupImage} alt="group" src={group.image}  onClick={(e) => handlePopoverOpen(e, group, group.name)} />
                                <div className={CSS.groupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value}}>{group.name}</div>
                            </div>
                    )
                })}
            </div>
           </div>
           <div className={CSS.column}>
                <div className={CSS.row}>
                    {easy.map((group) => {
                        return (
                                <div className={CSS.groupBox}>
                                    <img  className={CSS.groupImage} alt="group" src={group.image}  onClick={(e) => handlePopoverOpen(e, group, group.name)} />
                                    <div className={CSS.groupName} style={{ color: difficultiesColorList[get(group, 'difficulty.name', {})].value}}>{group.name}</div>
                                </div>
                        )
                    })}
                </div>
           </div>
        </div>
    )
}

export default SocialGroups;